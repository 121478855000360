import React from 'react';
import { AmountInput, FlexColumns, Textarea } from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CertificateSaleRO } from '@escapenavigator/types/dist/certificate-sale/certificate-sale.ro';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { CreateCertificateSaleRefundDto } from '@escapenavigator/types/dist/transaction/create-certificate-sale-refund.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { CashboxesSelect } from 'src/components/selects/cashbox-select';
import { useApi } from 'src/providers/api/context';

type Props = {
    close: () => void;
    setCerificate: (data: CertificateSaleRO) => void;
    certificatesaleId: number;
    t: TFunction;
    currency: ProfileCurrencyEnum;
    amount?: number;
};

export const Refund: React.FC<Props> = ({
    t,
    close,
    certificatesaleId,
    setCerificate,
    currency,
    amount,
}) => {
    const { transactions } = useApi();

    const {
        createCertificateRefundFetch,
        createCertificateRefundError,
        createCertificateRefundLoading,
    } = useApiMethod({
        api: transactions.createCertificateRefund,
        successCallback: ({ data }) => {
            setCerificate(data);
            close();
        },
    });

    return (
        <RestForm
            t={ t }
            title={ t('Оформление возврата') }
            initialValues={ serializeRecord(CreateCertificateSaleRefundDto, {
                certificatesaleId,
                amount,
            }) }
            validate={ validateByDto(t) }
            loading={ false }
            softError={ createCertificateRefundError?.message }
            updating={ createCertificateRefundLoading }
            removing={ false }
            close={ close }
            save={ (data) => createCertificateRefundFetch({ data }) }
        >
            { ({
                values, handleChange, errors, touched, setFieldValue,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    <CashboxesSelect
                        selected={ values.cashboxId }
                        error={ touched.cashboxId && errors.cashboxId }
                        onChange={ ({ selected }) => setFieldValue('cashboxId', selected?.key) }
                        type="all"
                        label={ t('component.selectCashbox') }
                    />
                    <AmountInput
                        value={ +values.amount }
                        error={ touched.amount && errors.amount }
                        onChange={ (e, { value }) => setFieldValue('amount', value) }
                        label={ t('Сумма возврата') }
                        required={ true }
                        type="decimal"
                        suffix={ currency }
                        block={ true }
                    />
                    <Textarea
                        value={ values.comment }
                        onChange={ handleChange('comment') }
                        error={ touched.comment && errors.comment }
                        required={ true }
                        label={ t('Причина') }
                        maxLength={ 200 }
                        counter={ true }
                        block={ true }
                    />
                </FlexColumns>
            ) }
        </RestForm>
    );
};
