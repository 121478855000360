/* eslint-disable no-param-reassign */
import { initClientApi } from '@escapenavigator/services/dist/api/init-client-api';
import { CreateDashboardTaskDto } from '@escapenavigator/types/dist/dashboard/create-dashboard-task.dto';
import { DashboardTaskRO } from '@escapenavigator/types/dist/dashboard/dashboard-task.ro';
import { UpdateDashboardTaskDto } from '@escapenavigator/types/dist/dashboard/update-dashboard-task.dto';
import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';

import { RootState, SliceState } from '../store';

const tasksAdapter = createEntityAdapter<DashboardTaskRO>();

const { dashboard } = initClientApi(process.env.REACT_APP_API_DOMAIN);

export const fetchTasks = createAsyncThunk<DashboardTaskRO[]>(
    'task/fetchTasks',
    async () => {
        const { data } = await dashboard.getAllUserTasks(undefined);

        return data;
    },
);

export const removeTask = createAsyncThunk<DashboardTaskRO, number>(
    'task/removeTask',
    async (id) => {
        const { data } = await dashboard.removeTask(id);

        return data;
    },
);

export const updateTask = createAsyncThunk<DashboardTaskRO, {id: number; data: UpdateDashboardTaskDto}>(
    'task/updateTask',
    async ({ id, data }) => {
        const res = await dashboard.updateTask({ id, data });

        return res.data;
    },
);

export const createTask = createAsyncThunk<DashboardTaskRO, {data: CreateDashboardTaskDto}>(
    'task/createTask',
    async ({ data }) => {
        const res = await dashboard.createTaskt({ data });

        return res.data;
    },
);

const taskSlice = createSlice({
    name: 'task',
    initialState: tasksAdapter.getInitialState({
        loading: null,
    }),
    reducers: {
        upsertTask(state, action: PayloadAction<DashboardTaskRO>) {
            tasksAdapter.upsertOne(state, action.payload);
        },
        removeTask(state, action: PayloadAction<DashboardTaskRO['id']>) {
            tasksAdapter.removeOne(state, action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTasks.pending, (state) => { state.loading = SliceState.FETCHING; });
        builder.addCase(fetchTasks.rejected, (state) => { state.loading = null; });
        builder.addCase(fetchTasks.fulfilled, (state, action: PayloadAction<DashboardTaskRO[]>) => {
            tasksAdapter.upsertMany(state, action.payload);
            state.loading = null;
        });

        builder.addCase(removeTask.pending, (state) => { state.loading = SliceState.REMOVING; });
        builder.addCase(removeTask.rejected, (state) => { state.loading = null; });
        builder.addCase(removeTask.fulfilled, (state, action: PayloadAction<DashboardTaskRO>) => {
            tasksAdapter.removeOne(state, action.payload.id);
            state.loading = null;
        });

        builder.addCase(updateTask.pending, (state) => { state.loading = SliceState.UPDATING; });
        builder.addCase(updateTask.rejected, (state) => { state.loading = null; });
        builder.addCase(updateTask.fulfilled, (state, action: PayloadAction<DashboardTaskRO>) => {
            tasksAdapter.upsertOne(state, action.payload);
            state.loading = null;
        });

        builder.addCase(createTask.pending, (state) => { state.loading = SliceState.UPDATING; });
        builder.addCase(createTask.rejected, (state) => { state.loading = null; });
        builder.addCase(createTask.fulfilled, (state, action: PayloadAction<DashboardTaskRO>) => {
            tasksAdapter.upsertOne(state, action.payload);
            state.loading = null;
        });
    },
});

const { selectAll, selectById } = tasksAdapter.getSelectors<RootState>(
    (state) => state.tasks,
);

export const selectAllTasks = selectAll;

export const selectTaskById = (id) =>
    createSelector(
        (state: RootState) => state,
        (state) => selectById(state, id),
    );

export const selectTaskState = () => createSelector((state: RootState) => state, (state) => state.tasks.loading);

export default taskSlice.reducer;
