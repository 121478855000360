/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Amount, AmountProps, Flex } from '@alphakits/ui';
import { useCurrentUser } from 'src/providers/current-user/context';

type Props = {
    price: Record<string, any>;
} & Omit<AmountProps, 'value'>;

export const getPriceRange = (price: Record<string, any>) => {
    const keys = Object.keys(price);
    const firstPrice = +price[keys[0]];
    const lastPrice = +price[keys[keys.length - 1]];

    return `${firstPrice} - ${lastPrice}`;
};

export const PriceRange: React.FC<Props> = ({ price, view, color }) => {
    const {
        profile: { currency },
    } = useCurrentUser();

    if (!price) return <div>No price range</div>;

    const keys = Object.keys(price);

    const firstPrice = +price[keys[0]];
    const lastPrice = +price[keys[keys.length - 1]];

    return (
        <Flex gap="xs">
            <Amount
                view={ view }
                color={ color }
                value={ firstPrice }
                type="decimal"
                currency={ currency }
            />
            &ndash;
            <Amount
                view={ view }
                color={ color }
                value={ lastPrice }
                currency={ currency }
                type="decimal"
            />
        </Flex>
    );
};
