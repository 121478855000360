import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext, Table } from '@alphakits/ui';
import { CashboxTypeEnum } from '@escapenavigator/types';
import { CashboxModal } from 'src/modals/cashbox';
import { selectAllCashboxes, selectAllLocations } from 'src/store';
import { useAppSelector } from 'src/store/hooks';
import { getLanguage } from 'src/utils/get-language';

import { CashboxColumns } from './columns';

export const CashboxesPage: React.FC = () => {
    const { openModal } = useContext(ModalContext);
    const { i18n, t } = useTranslation();

    const cashboxes = useAppSelector(selectAllCashboxes) || [];
    const loactions = useAppSelector(selectAllLocations);

    const columns = useMemo(() => CashboxColumns(t, loactions), [t, loactions]);

    const openCashboxModal = openModal(CashboxModal);

    return (
        <Table.TableComponent
            columns={ columns }
            searchPlaceholder={ t('tables.cashboxSettingSearch') }
            onRowClick={ ({ id }) => openCashboxModal({ recordId: +id }) }
            onCreateClick={ () => openCashboxModal() }
            records={ cashboxes.filter(
                (c) => c.type !== CashboxTypeEnum.STRIPE && c.type !== CashboxTypeEnum.PAYPAL,
            ) }
            language={ getLanguage(i18n) }
        />
    );
};
