import { useCallback, useEffect, useState } from 'react';
import { record } from 'rrweb';
import io from 'socket.io-client';

export const useRrewb = () => {
    const [recordingName, setRecordingName] = useState('');

    const stopRecording = useCallback(async () => {
        setRecordingName('');
    }, []);

    const startRecording = useCallback(
        (form: string) => setRecordingName(form),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [recordingName],
    );

    useEffect(() => {
        let stopFn;
        let socket;

        if (recordingName) {
            socket = io(process.env.REACT_APP_API_DOMAIN, {
                reconnection: true,
                withCredentials: true,
            });

            socket.on('connect', () => {
                stopFn = record({
                    emit(event) {
                        socket.emit('rrweb', { event, type: recordingName });
                    },
                    collectFonts: false,
                    sampling: {
                        mousemove: 120,
                        scroll: 220,
                        input: 'last',
                    },
                });
            });

            socket.on('disconnect', () => {
                if (stopFn) stopFn();
            });
        }

        return () => {
            if (stopFn) stopFn();
            if (socket) socket.close();
        };
    }, [recordingName]);

    return {
        startRecording,
        stopRecording,
    };
};
