import { languageNameByLanguage } from '@escapenavigator/types/dist/constants/language-name-by-language';
import { CrossSaleDto } from '@escapenavigator/types/dist/profile/cross-sale/cross-sale.dto';
import { CrossSaleDiscountTypeEnum } from '@escapenavigator/types/dist/profile/cross-sale/crossale-discount-type.enum';
import { RetargetingDto } from '@escapenavigator/types/dist/profile/cross-sale/retargeting/retargeting.dto';
import { TFunction } from 'i18next';

export const getPromocodeError = ({
    values,
    t,
}: {
    values: CrossSaleDto | RetargetingDto;
    t: TFunction;
}) => {
    if (values.discountType === CrossSaleDiscountTypeEnum.NONE) {
        const localeWithError = values.locales.find(
            ({ feedbackText }) =>
                feedbackText.includes('{promocode}') ||
                feedbackText.includes('{validity}') ||
                feedbackText.includes('{discount}'),
        );

        if (localeWithError) {
            return `Remove {promocode}, {discount} and {validity} flags from feedback text in ${
                languageNameByLanguage[localeWithError.language]
            } locale`;
        }
    }

    if (values.discountType !== CrossSaleDiscountTypeEnum.NONE) {
        const localeWithError = values.locales.find(
            ({ feedbackText }) => !feedbackText.includes('promocode'),
        );

        if (localeWithError) {
            return `Add {promocode} flags to feedback text in ${
                languageNameByLanguage[localeWithError.language]
            } locale`;
        }
    }

    if (values.locales.some((l) => !l.feedbackText || !l.title)) return t('crossSaleValidationError');

    return '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
};
