/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Flex, FlexColumns, Typography } from '@alphakits/ui';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { formatAmount } from '@escapenavigator/utils/dist/format-amount';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { EmptyState } from 'src/components/empty-state';
import { Section } from 'src/components/layout/section';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#A28FFC', '#FF69B4'];

const CustomTooltip = ({
    active, payload, label, currency,
}) => {
    if (active && payload && payload.length) {
        const total = payload.reduce((acc, val) => acc + val.value, 0);

        return (
            <div style={ { width: 300 } }>
                <Section
                    title={ label }
                    buttonAddon={ (
                        <Typography.Title tag="div" view="xsmall" weight="bold">
                            { currency ? formatAmount(total, currency) : total }
                        </Typography.Title>
                    ) }
                >
                    <FlexColumns columns={ 1 } gr={ 8 }>
                        { payload.map(({ name, color, value }) => (
                            <Typography.Text view="title" weight="medium">
                                <Flex gap="md">
                                    <span style={ { color } }>{ name }</span>
                                    <b>{ currency ? formatAmount(value, currency) : value }</b>
                                </Flex>
                            </Typography.Text>
                        )) }
                    </FlexColumns>
                </Section>
            </div>
        );
    }

    return null;
};

export const BarChartComponent = ({
    data,
    currency,
}: {
    data: Array<Record<string, unknown>>;
    currency?: ProfileCurrencyEnum;
}) => {
    if (!data?.length) return <EmptyState title="No data" />;

    return (
        <ResponsiveContainer width="100%" height={ 400 }>
            <BarChart
                data={ data }
                margin={ {
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                } }
            >
                <XAxis dataKey="name" />

                <CartesianGrid strokeDasharray="3 3" />

                <YAxis
                    tickFormatter={ (value) => (currency ? formatAmount(value, currency) : value) }
                />

                <Tooltip
                    content={ (
                        <CustomTooltip
                            currency={ currency }
                            active={ undefined }
                            payload={ undefined }
                            label={ undefined }
                        />
                    ) }
                />
                <Legend />

                { !!data.length &&
                    Object.keys(data[0])
                        .filter((key) => key !== 'name')
                        .map((key, i) => <Bar dataKey={ key } stackId="a" fill={ COLORS[i] } />) }
            </BarChart>
        </ResponsiveContainer>
    );
};
