import { convertMinutesToHHMM } from '@escapenavigator/utils/dist';

import { getPx } from './get-px';

const isCollapsed = (collapsingTo: number, collapsingFrom: number, index: number) => {
    const c = index ? index > collapsingFrom : index >= collapsingFrom;

    return c && index < collapsingTo;
};

export const buildMinuteIntervals = (collapsingRange: [number, number], collapsing: boolean) => {
    const intervals: Array<{ time: string; top: number }> = [];

    const diff = collapsingRange[1] - collapsingRange[0];

    const rangeDiff = diff - (diff % 60);

    for (let index = 0; index <= 1440; index += 5) {
        if (
            !collapsing ||
            !(collapsingRange[1] && isCollapsed(collapsingRange[1], collapsingRange[0], index))
        ) {
            intervals.push({
                time: convertMinutesToHHMM(index),
                top: getPx(index < rangeDiff ? index : index - rangeDiff, 'compact'),
            });
        }
    }

    return intervals;
};
