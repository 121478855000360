import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button, Loader, ModalContext, SuperListHeader,
} from '@alphakits/ui/dist';
import { QueryDTO } from '@alphakits/ui/dist/table/types';
import { usePaginationQuery } from 'src/hooks/use-pagination-query';
import { ModalTable } from 'src/modals/components/modal-table';
import { OrderTableItem } from 'src/modals/components/order-table-item';
import { TableEmpty } from 'src/modals/components/table-empty';
import { OrderModal } from 'src/modals/order';
import { useApi } from 'src/providers/api/context';

type Props = {
    criteria: QueryDTO['where'];
    title?: string;
};

export const OrdersList = ({ criteria, title }: Props) => {
    const { orders } = useApi();
    const { openModal } = useContext(ModalContext);
    const { t } = useTranslation();

    const {
        records = [],
        hasMore,
        total,
        loading,
        nextPage,
        reload,
    } = usePaginationQuery({ api: orders.query, limit: 8 });

    const openOrderModal = openModal(OrderModal, 'm', true);

    useEffect(() => {
        reload(criteria);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <SuperListHeader
                text={ `${title || t('reservation')} (${total || 0})` }
                rightAddons={
                    hasMore && (
                        <Button view="outlined" onClick={ () => nextPage(criteria) } size="xs">
                            { t('loadMore') }
                        </Button>
                    )
                }
            />
            { records.length ? (
                <ModalTable>
                    { records.map((order) => (
                        <OrderTableItem
                            onClick={ () => openOrderModal({ recordId: order.id }) }
                            key={ order.id }
                            order={ order }
                        />
                    )) }
                </ModalTable>
            ) : (
                <React.Fragment>
                    { loading ? <Loader /> : <TableEmpty text={ t('typography.noEntriesHere') } /> }
                </React.Fragment>
            ) }
        </React.Fragment>
    );
};
