import React, { useContext } from 'react';
import { ModalContext, Table } from '@alphakits/ui';
import { AnswerModal } from 'src/modals/answer';
import { useApi } from 'src/providers/api/context';

import { columns } from './columns';

export const ReviewsSocial: React.FC = () => {
    const { socialReviews } = useApi();
    const { openModal } = useContext(ModalContext);

    return (
        <React.Fragment>
            <Table.TableComponent
                columns={ columns }
                onRowClick={ (row, cb) => openModal(AnswerModal)({ review: row, cb }) }
                searchPlaceholder="Serach"
                language="en"
                promise={ socialReviews.queryProfile }
            />
        </React.Fragment>
    );
};
