import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext, Table } from '@alphakits/ui/dist';
import { CertificateModal } from 'src/modals/certificate';
import { useCurrentUser } from 'src/providers/current-user/context';
import { selectAllCertificates } from 'src/store';
import { useAppSelector } from 'src/store/hooks';
import { getLanguage } from 'src/utils/get-language';

import { CertificateColumns } from './columns';

export const CertificatesPage: React.FC = () => {
    const { openModal } = useContext(ModalContext);
    const { i18n, t } = useTranslation();
    const {
        profile: { currency },
    } = useCurrentUser();

    const certificates = useAppSelector(selectAllCertificates) || [];
    const columns = useMemo(() => CertificateColumns({ t, currency }), [t, currency]);

    const openCashboxModal = openModal(CertificateModal);

    return (
        <Table.TableComponent
            columns={ columns }
            searchPlaceholder={ t('tables.certificateSettingSearch') }
            onRowClick={ ({ id }) => openCashboxModal({ recordId: +id }) }
            onCreateClick={ () => openCashboxModal() }
            records={ certificates }
            language={ getLanguage(i18n) }
        />
    );
};
