import React from 'react';
import { useTranslation } from 'react-i18next';
import { DotsM } from '@alphakits/icons';
import { Picker } from 'src/components/picker';

import styles from './index.module.css';

type SlotActionsAddonProps = {
    actions: Record<string, () => void>;
};

export const SlotActionsAddon = ({ actions }: SlotActionsAddonProps) => {
    const { t } = useTranslation();

    return (
        <div
            onClick={ (e) => {
                e.stopPropagation();
            } }
            role="button"
            aria-hidden="true"
        >
            <Picker t={ t } className={ styles.actions_button } actions={ actions } content={ DotsM } />
        </div>
    );
};
