import React from 'react';
import {
    Button, Flex, FlexColumns, Typography,
} from '@alphakits/ui/dist';

import styles from './index.module.css';

type Props = {
    title: string;
    error?: string;
    subtitle?: string;
    leftAddons?: React.ReactNode;
    rightAddons?: React.ReactNode;
    onClick?: () => void;
};

type ContainerProps = Pick<Props, 'onClick'>;

const Container: React.FC<ContainerProps> = ({ onClick, children }) => {
    if (onClick) {
        return (
            <Button onClick={ onClick } className={ styles.container } view="ghost">
                { children }
            </Button>
        );
    }

    return <div className={ styles.container }>{ children }</div>;
};

export const SummaryCell: React.FC<Props> = ({
    title,
    subtitle,
    rightAddons,
    leftAddons,
    error,
    onClick,
}) => (
    <Container onClick={ onClick }>
        <Flex align="start">
            { leftAddons && <div className={ styles.leftAddons }>{ leftAddons }</div> }

            <FlexColumns columns={ 1 } gr={ 2 } className={ styles.text }>
                <Typography.Text view="title" color="primary">
                    { title }
                </Typography.Text>

                { subtitle && (
                    <Typography.Text view="caps" color="secondary">
                        { subtitle }
                    </Typography.Text>
                ) }

                { error && (
                    <Typography.Text view="title" color="negative">
                        { error }
                    </Typography.Text>
                ) }
            </FlexColumns>

            { rightAddons }
        </Flex>
    </Container>
);
