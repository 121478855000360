/* eslint-disable complexity */
import React from 'react';
import {
    Checkbox, FlexColumns, Select, Switch, useCrudFormRequests,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { CashboxTypeEnum } from '@escapenavigator/types/dist';
import { CertificateServiceRO } from '@escapenavigator/types/dist/certificate/certificate-service.ro';
import { CreateCertificateServiceDto } from '@escapenavigator/types/dist/certificate/create-certificate-service.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { convertToOptions } from '@escapenavigator/utils/dist/convert-to-options';
import { TFunction } from 'i18next';
import { ModalSection } from 'src/components/layout/modal-section';
import { NominalsBuilder } from 'src/components/nominals-builder';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { selectAllCashboxes } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

type Props = {
    record: CertificateServiceRO;
    close: () => void;
    cb: () => void;
    t: TFunction;
};

export const CertificateServiceModal: React.FC<Props> = ({
    record, close, t, cb,
}) => {
    const { certificates } = useApi();

    const {
        profile: { currency },
    } = useCurrentUser();

    const cashboxes = useAppSelector(selectAllCashboxes);

    const {
        save,
        updating,
        error: softError,
    } = useCrudFormRequests({
        saveRequest: certificates.updateCertificateService,
        saveCallback: cb,
        close,
    });

    return (
        <RestForm
            title={ t('vertificateService') }
            initialValues={ serializeRecord(CreateCertificateServiceDto, record) }
            validate={ validateByDto(t) }
            save={ save }
            softError={ softError }
            updating={ updating }
            close={ close }
            t={ t }
        >
            { ({
                values, setFieldValue, errors, touched,
            }) => (
                <React.Fragment>
                    <FlexColumns columns={ 1 } gr={ 32 }>
                        <Switch
                            label={ t('Активирована') }
                            checked={ values.enabled }
                            onChange={ (e, { checked }) => setFieldValue('enabled', checked) }
                            block={ true }
                        />

                        <ModalSection title={ t('Варианты цен') }>
                            <NominalsBuilder
                                currency={ currency }
                                price={ values.nominals }
                                error={ touched.nominals && errors.nominals?.toString() }
                                onChange={ (price) => setFieldValue('nominals', price) }
                                t={ t }
                            />
                        </ModalSection>

                        <Checkbox
                            label={ t('Кастомный номинал') }
                            checked={ values.allowFreeNominal }
                            onChange={ (e, { checked }) =>
                                setFieldValue('allowFreeNominal', checked) }
                            block={ true }
                        />

                        <Select
                            dataTestId="onlinePaymentsCashbox"
                            label={ t('component.onlineBill') }
                            options={ convertToOptions(
                                cashboxes.filter((c) => c.type === CashboxTypeEnum.STRIPE),
                            ) }
                            selected={ values.onlinePaymentsCashbox }
                            error={ touched.onlinePaymentsCashbox && errors.onlinePaymentsCashbox }
                            allowUnselect={ true }
                            onChange={ ({ selected }) =>
                                setFieldValue('onlinePaymentsCashbox', selected?.key) }
                            block={ true }
                            optionsListWidth="field"
                        />
                    </FlexColumns>
                </React.Fragment>
            ) }
        </RestForm>
    );
};
