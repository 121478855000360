import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext, Table } from '@alphakits/ui/dist';
import { OrdersFiltersModal } from 'src/modals/filters/orders';
import { OrderModal } from 'src/modals/order';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { selectAllLocations, selectAllQuestrooms } from 'src/store';
import { useAppSelector } from 'src/store/hooks';
import { mapper } from 'src/tags';
import { getLanguage } from 'src/utils/get-language';

import { GameHistoryColumns } from './columns';

export const OrdersPage: React.FC = () => {
    const {
        current: { role },
    } = useCurrentUser();
    const { orders } = useApi();
    const { openModal } = useContext(ModalContext);
    const { i18n, t } = useTranslation();
    const questrooms = useAppSelector(selectAllQuestrooms({}));
    const locations = useAppSelector(selectAllLocations);

    const {
        profile: { currency },
    } = useCurrentUser();

    const columns = React.useMemo(
        () =>
            GameHistoryColumns({
                t,
                questrooms,
                currency,
                locations,
            }),
        [t, questrooms, currency, locations],
    );

    const openFilters = openModal(OrdersFiltersModal);
    const openOrder = openModal(OrderModal, 'm', true);

    const canEditOrders = role.totalAccess || role.accessToOrdersSection;

    return (
        <Table.TableComponent
            filtersButtonLabel={ t('filters.label') }
            tagsBuilder={ mapper(t) }
            onRowClick={
                canEditOrders
                    ? ({ id }, successCalback) => openOrder({ recordId: +id, successCalback })
                    : undefined
            }
            onFiltersClick={ ({ submitCallback, initialValues }) =>
                openFilters({
                    apply: submitCallback,
                    initialValues: initialValues.where,
                }) }
            columns={ columns }
            searchPlaceholder={ t('tables.gamehistorySearch') }
            promise={ orders.query }
            language={ getLanguage(i18n) }
        />
    );
};
