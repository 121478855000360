import React from 'react';
import {
    Amount, Avatar, Button, Flex, FlexColumns, Typography,
} from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { UserMonthlyReportRO } from '@escapenavigator/types/dist/user/reports/user-monthly-report.ro';
import { convertMinutesToHHMM } from '@escapenavigator/utils/dist';

export const Columns = (t, openTransactionModal): TableColumns<UserMonthlyReportRO> => [
    {
        header: t('tables.title'),
        accessor: 'title',
        row: {
            icon: ({ row }) => <Avatar src={ row.photo } text={ row.title } />,
        },
    },
    {
        header: t('tables.workedOut'),
        accessor: 'hours',
        row: {
            title: ({ row }) => convertMinutesToHHMM(row.hours),
        },
    },
    {
        header: t('Создано броней'),
        accessor: 'ordersCreated',
    },
    {
        header: t('Доп услуги'),
        accessor: 'upsellingsAmount',
        row: {
            title: ({ row }) => (
                <React.Fragment>
                    <Amount
                        value={ row.upsellingsAmount }
                        type="decimal"
                        currency={ ProfileCurrencyEnum.EUR }
                    />
                    <Typography.Text view="title" color="secondary">
                        &nbsp;({ row.upsellingsCreated })
                    </Typography.Text>
                </React.Fragment>
            ),
        },
    },
    {
        header: t('Проведено игр'),
        accessor: 'roles',
        row: {
            title: ({ row }) => (
                <FlexColumns columns={ 1 } gr={ 4 }>
                    <Typography.Text view="caps">
                        { t('Модератор') }: { row.ordersModerate }
                    </Typography.Text>

                    { Object.entries(row.roles).map(([role, count]) => (
                        <Typography.Text view="caps">
                            { role }: { count }
                        </Typography.Text>
                    )) }
                </FlexColumns>
            ),
        },
    },
    {
        header: t('Выплачено'),
        accessor: 'id',
        row: {
            title: ({ row }) => (
                <Amount value={ row.payed * -1 } type="decimal" currency={ ProfileCurrencyEnum.EUR } />
            ),
        },
    },
    {
        header: '',
        accessor: 'id',
        row: {
            title: ({ row }) => (
                <Flex gap="sm">
                    <Button
                        onClick={ () => openTransactionModal({ employeeId: row.id }) }
                        view="primary"
                        size="xs"
                    >
                        { t('Оплатить') }
                    </Button>
                </Flex>
            ),
        },
    },
];
