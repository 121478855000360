import { TFunction } from 'i18next';

type Type = 'crossSale' | 'upSale' | 'retargeting' | 'earlyBooking';

export type HandlerKey =
    | 'clientName'
    | 'questroomTitle'
    | 'promocode'
    | 'discount'
    | 'validity'
    | 'website'
    | 'howToFindLocation'
    | 'importantInfo'
    | 'prepareInfo'
    | 'gameResult'
    | 'phone'
    | 'date'
    | 'photos'
    | 'servicesList'
    | 'address';

const handlerKeys: Record<Type, HandlerKey[]> = {
    crossSale: [
        'clientName',
        'questroomTitle',
        'promocode',
        'discount',
        'validity',
        'website',
        'photos',
        'gameResult',
    ],
    retargeting: ['questroomTitle', 'promocode', 'discount', 'validity', 'website'],
    upSale: [
        'questroomTitle',
        'phone',
        'date',
        'howToFindLocation',
        'importantInfo',
        'prepareInfo',
        'servicesList',
        'address',
    ],
    earlyBooking: [
        'questroomTitle',
        'phone',
        'date',
        'howToFindLocation',
        'importantInfo',
        'prepareInfo',
        'servicesList',
        'address',
    ],
};

export const getHandlers = (type: Type, t: TFunction) =>
    handlerKeys[type].map((key) => ({
        title: t(`crm-crosssales:emailConstructor.${key}.title`),
        description: t(`crm-crosssales:emailConstructor.${key}.description`),
        key,
    }));
