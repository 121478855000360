import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Tag } from '@alphakits/ui';
import { selectAllLocations, selectAllQuestrooms } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

export const useLocationsSelect = () => {
    const { t } = useTranslation();
    const [location, setLocation] = useState(0);

    const questrooms = useAppSelector(selectAllQuestrooms({ deleted: false, closed: false }));
    const locations = useAppSelector(selectAllLocations);

    const selectedQuestroomsIds = useMemo(() => {
        if (location === 0) return questrooms.map((q) => q.id);

        return questrooms.filter((q) => q.locationId === location).map((q) => q.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return {
        selectedQuestroomsIds,
        Locations:
            locations.length > 1 ? (
                <Flex gap="xs" justify="start" wrap={ true }>
                    <Tag
                        key="all"
                        view={ location === 0 ? 'primary-inverted' : 'primary' }
                        onClick={ () => setLocation(location === 0 ? undefined : 0) }
                        text={ t('Все локации') }
                    />

                    { locations.map((l) => (
                        <Tag
                            key={ l.title }
                            view={ location === l.id ? 'primary-inverted' : 'primary' }
                            onClick={ () => setLocation(location === l.id ? undefined : l.id) }
                            text={ l.title }
                        />
                    )) }
                </Flex>
            ) : null,
    };
};
