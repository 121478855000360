import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckmarkCircleFillM, EditM } from '@alphakits/icons';
import { Button, Status } from '@alphakits/ui';
import { CrossSaleStatusEnum } from '@escapenavigator/types/dist/profile/cross-sale/cross-sale-status.enum';
import { Picker } from 'src/components/picker';

export const CardChildren: React.FC<{
    status: CrossSaleStatusEnum;
    open: () => void;
    disable: () => void;
    enable: () => void;
}> = ({
    status, open, disable, enable,
}) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            { status === 'disabled' && (
                <Button onClick={ enable } view="outlined" size="xs">
                    { t('Включить') }
                </Button>
            ) }

            { status === 'none' && (
                <Button onClick={ open } view="outlined" size="xs">
                    { t('Настроить рассылку') }
                </Button>
            ) }

            { status === 'enabled' && (
                <React.Fragment>
                    <Status color="green" view="soft" size="m">
                        <CheckmarkCircleFillM /> { t('Активирована') }
                    </Status>

                    <Picker
                        content={ <EditM /> }
                        t={ t }
                        actions={ {
                            [t('Редактировать')]: open,
                            [t('Отключить')]: disable,
                        } }
                    />
                </React.Fragment>
            ) }
        </React.Fragment>
    );
};
