import React from 'react';
import { FlexColumns, Input, useCrudFormRequests } from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { CertificateSaleRO } from '@escapenavigator/types/dist/certificate-sale/certificate-sale.ro';
import { UpdateCertificatesaleTrackingInfoDto } from '@escapenavigator/types/dist/certificate-sale/update-certificatesale-tracking-info.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

type Props = {
    close: () => void;
    t: TFunction;
    setCertificateSale: (values: CertificateSaleRO) => void;
    certificatesale: CertificateSaleRO;
};

export const TrackingInfo: React.FC<Props> = ({
    close,
    t,
    certificatesale,
    setCertificateSale,
}) => {
    const { certificatesales } = useApi();

    const { save, updating } = useCrudFormRequests({
        recordId: certificatesale.id,
        saveRequest: certificatesales.updateTrackingInfo,
        close,
        saveCallback: setCertificateSale,
    });

    return (
        <RestForm
            title={ t('Информация о доставке') }
            initialValues={ serializeRecord(UpdateCertificatesaleTrackingInfoDto, certificatesale) }
            validate={ validateByDto(t) }
            save={ save }
            loading={ false }
            updating={ updating }
            removing={ false }
            close={ close }
            t={ t }
        >
            { ({
                values, handleChange, errors, touched,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    <Input
                        label={ `${t('Служба доставки')}, ${t('Трек номер')}` }
                        onChange={ handleChange('deliveryTrackingInfo') }
                        required={ true }
                        error={ touched.deliveryTrackingInfo && errors.deliveryTrackingInfo }
                        value={ values.deliveryTrackingInfo }
                    />
                </FlexColumns>
            ) }
        </RestForm>
    );
};
