import React, { useCallback, useEffect, useState } from 'react';
import { FlexColumns, showError, ToastPlate } from '@alphakits/ui';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { ProfileEmailRO } from '@escapenavigator/types/dist/profile/email/profile-email.ro';
import axios from 'axios';
import { PageLoader } from 'src/components/page-loader';
import { useApi } from 'src/providers/api/context';

type Props = {
    profileEmailId: number;
};

export const ViewEmail: React.FC<Props> = ({ profileEmailId }) => {
    const { profiles } = useApi();
    const [loading, setLoading] = useState(false);
    const [html, setHtml] = useState();

    const getTemplate = useCallback(async (email: ProfileEmailRO) => {
        setLoading(true);
        try {
            const allParams = JSON.parse(email.text);
            const { data } = await axios.post('https://mailer.escapenavigator.com/render', {
                title: email.subject,
                tag: 'info',
                subscription: false,
                ...allParams,
            });

            setHtml(data);
        } catch (error) {
            showError('Render error');
        } finally {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { getCustomerEmailError, getCustomerEmailLoading, getCustomerEmailFetch } = useApiMethod({
        api: profiles.getCustomerEmail,
        successCallback: ({ data }) => getTemplate(data),
    });

    useEffect(() => {
        getCustomerEmailFetch({ profileEmailId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            style={ {
                background: '#F4F7F9',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto',
                minHeight: '100%',
            } }
        >
            { loading || getCustomerEmailLoading ? (
                <PageLoader />
            ) : (
                <FlexColumns columns={ 1 }>
                    { getCustomerEmailError?.message && (
                        <ToastPlate view="negative">{ getCustomerEmailError.message }</ToastPlate>
                    ) }

                    <div dangerouslySetInnerHTML={ { __html: html } } />
                </FlexColumns>
            ) }
        </div>
    );
};
