import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Button,
    EmptyPage,
    FlexColumns,
    Loader,
    showError,
    Typography,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CrmVerificationEnum } from '@escapenavigator/types/dist/profile/enum/crm-verifiaction.enum';
import { SUCCESS_IMAGE } from 'src/constants';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { useOnboarding } from 'src/providers/ongoarding/context';
import {
    ROUTE_BOOKING_RULES,
    ROUTE_CALENDAR,
    ROUTE_ORGANIZATION,
    ROUTE_QUESTROOMS,
    ROUTE_WIDGETS,
} from 'src/router/constants';

import { VerifcationCell } from '../verification-cell';

type Props = {
    close: () => void;
};

export const InitialStepsNotification: React.FC<Props> = ({ close }) => {
    const { t } = useTranslation();
    const { profile, setProfile } = useCurrentUser();
    const { steps, loading, refetchProgress } = useOnboarding();
    const { profiles } = useApi();

    const { updateCrmVerificationFetch, updateCrmVerificationLoading } = useApiMethod({
        api: profiles.updateCrmVerification,
        errorCallback: ({ message }) => showError(message),
        successCallback: ({ data: { crmVerification } }) => {
            setProfile({ ...profile, crmVerification });
        },
    });

    useEffect(() => {
        if (profile.crmVerification === CrmVerificationEnum.SUCCEED) {
            refetchProgress(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const stepsList = useMemo(() => {
        if (!steps?.profile.crmVerification) return [];

        return [
            {
                title: t('Верифицировать эскейп румы и локации'),
                done: true,
                link: ROUTE_QUESTROOMS,
            },
            {
                title: t('Завершить настройку профиля'),
                done: steps.prifileVerified,
                link: ROUTE_ORGANIZATION,
            },
            {
                title: t('Установить тарифы'),
                done: steps.tariffs,
                link: `${ROUTE_BOOKING_RULES}#tariffSettings`,
            },

            {
                title: t('Установить правила онлайн записи'),
                done: steps.bookingSettings,
                link: `${ROUTE_BOOKING_RULES}#bookingRulesSettings`,
            },
            {
                title: t('Добавить способы оплаты'),
                done: steps.paymentMethods,
                link: `${ROUTE_BOOKING_RULES}#onlinePayments`,
            },
            {
                title: t('Установить расписание квестов'),
                done: steps.schedule,
                link: ROUTE_CALENDAR,
            },
            {
                title: t('Настроить виджеты'),
                done: steps.widgets,
                link: ROUTE_WIDGETS,
            },
        ];
    }, [steps, t]);

    if (loading || !steps) return <Loader />;

    return (
        <FlexColumns columns={ 1 }>
            { profile.crmVerification === CrmVerificationEnum.SUCCEED && (
                <EmptyPage
                    size="xs"
                    image={ SUCCESS_IMAGE }
                    title={ t('Настройки завершены') }
                    subtitle={ t('Теперь вы можете') }
                />
            ) }

            { profile.crmVerification === CrmVerificationEnum.READY_TO_USE && (
                <React.Fragment>
                    <Box border={ true } padding="md" rounded="sm">
                        <FlexColumns columns={ 1 } gr={ 24 }>
                            <FlexColumns columns={ 1 } gr={ 8 }>
                                <Typography.Text view="title" weight="bold">
                                    { t('Все готово к запуску') }
                                </Typography.Text>
                                <Typography.Text view="title">
                                    { t('Вы заполнили всю необходимую информацию') }
                                </Typography.Text>
                            </FlexColumns>
                            <div>
                                <Button
                                    onClick={ () =>
                                        updateCrmVerificationFetch(CrmVerificationEnum.SUCCEED) }
                                    loading={ updateCrmVerificationLoading }
                                    view="primary"
                                    size="xs"
                                >
                                    { t('Все верно начать') }
                                </Button>
                            </div>
                        </FlexColumns>
                    </Box>
                </React.Fragment>
            ) }

            { /* TODO объяскнить про виджеты */ }

            <Box border={ true } padding="md" rounded="sm">
                <FlexColumns columns={ 1 } gr={ 20 }>
                    <FlexColumns columns={ 1 } gr={ 4 }>
                        <Typography.Text view="title" weight="bold">
                            { t('Настройки системы') }
                        </Typography.Text>

                        <Typography.Text view="title">{ t('Для начала работы') }</Typography.Text>
                    </FlexColumns>

                    <FlexColumns columns={ 1 } gr={ 0 }>
                        { stepsList.map((step) => (
                            <VerifcationCell
                                title={ step.title }
                                key={ step.title }
                                link={ step.link }
                                done={ step.done }
                                close={ close }
                            />
                        )) }
                    </FlexColumns>
                </FlexColumns>
            </Box>
        </FlexColumns>
    );
};
