import React from 'react';
import { Sidepanel, Typography } from '@alphakits/ui/dist';
import { TFunction } from 'i18next';

import SupportIcon from '../support/support.png';

type Props = {
    t: TFunction;
};

export const UploadCertificateModal: React.FC<Props> = ({ t }) => (
    <Sidepanel>
        <Typography.Text view="primary-medium" color="primary">
            { t('supportUploadCeetificatesText', { value: 'support@escapenavigator.com' }) }
        </Typography.Text>
        <img style={ { width: '100%' } } src={ SupportIcon } alt="support" />
    </Sidepanel>
);
