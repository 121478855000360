import React, { useEffect } from 'react';
import {
    convertToOptions,
    FlexColumns,
    Loader,
    Select,
    useCrudFormRequests,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { OrderWorkersAddDTO } from '@escapenavigator/types/dist/order/workers/order-workers-add.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { UsersSelect } from 'src/components/selects/users-select';
import { useApi } from 'src/providers/api/context';

type Props = {
    orderId?: number;
    close: () => void;
    cb: () => void;
    t: TFunction;
};

export const OrderWorkerModal: React.FC<Props> = ({
    orderId, close, t, cb,
}) => {
    const { users, orders } = useApi();
    const { getAllPositionsData, getAllPositionsFetch, getAllPositionsLoading } = useApiMethod({
        api: users.getAllPositions,
    });

    useEffect(() => {
        getAllPositionsFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        save,
        remove,
        removing,
        updating,
        error: softError,
    } = useCrudFormRequests({
        saveRequest: orders.addWorkers,
        saveCallback: cb,
        close,
    });

    if (getAllPositionsLoading || !getAllPositionsData) return <Loader />;

    return (
        <RestForm
            title={ t('Персонал') }
            initialValues={ serializeRecord(OrderWorkersAddDTO, { orderId }) }
            validate={ validateByDto(t) }
            save={ save }
            remove={ remove }
            softError={ softError }
            updating={ updating || removing }
            close={ close }
            t={ t }
        >
            { ({
                values, errors, touched, setFieldValue,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    <Select
                        dataTestId="positionId"
                        block={ true }
                        optionsListWidth="field"
                        label={ t('Должность') }
                        options={ convertToOptions(getAllPositionsData) }
                        selected={ values.positionId }
                        error={ touched.positionId && errors.positionId }
                        onChange={ ({ selected }) => setFieldValue('positionId', +selected?.key) }
                    />

                    <UsersSelect
                        label={ t('employee') }
                        selected={ values.userId }
                        error={ touched.userId && errors.userId }
                        onChange={ ({ selected }) => setFieldValue('userId', +selected?.key) }
                    />
                </FlexColumns>
            ) }
        </RestForm>
    );
};
