import React from 'react';
import {
    FlexColumns,
    Input,
    Select,
    Textarea,
    Typography,
    useCrudFormRequests,
    useGetOne,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { CreateFinanceitemDto } from '@escapenavigator/types/dist/financeitem/create-financeitem.dto';
import { FinanceitemTypeEnum } from '@escapenavigator/types/dist/financeitem/enum/financeitem-type.enum';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { enumToOptions } from '@escapenavigator/utils/dist/enum-to-options';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

type Props = {
    recordId?: number;
    close: () => void;
    cb: () => void;
    t: TFunction;
};

export const FinanceItemModal: React.FC<Props> = ({
    recordId, close, t, cb,
}) => {
    const { financeItems } = useApi();

    const { record, loading, error } = useGetOne(recordId, financeItems.getOne);

    const {
        save,
        remove,
        removing,
        updating,
        error: softError,
    } = useCrudFormRequests({
        recordId,
        saveRequest: financeItems.create,
        removeRequest: financeItems.remove,
        saveCallback: cb,
        removeCallback: cb,
        close,
    });

    return (
        <RestForm
            recordId={ +recordId }
            save={ save }
            remove={ remove }
            close={ close }
            initialValues={ serializeRecord(CreateFinanceitemDto, record) }
            validate={ validateByDto(t) }
            error={ error }
            softError={ softError }
            loading={ loading }
            updating={ updating || removing }
            title={ record?.title || t('modalsHeader.financeItem') }
            t={ t }
        >
            { ({
                values, handleChange, setFieldValue, errors, touched,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    <Input
                        disabled={ !!record?.id }
                        dataTestId="title"
                        label={ t('tables.title') }
                        value={ values.title }
                        onChange={ handleChange('title') }
                        error={ touched.title && errors.title }
                        required={ true }
                        block={ true }
                    />

                    <Select
                        required={ true }
                        disabled={ !!record?.id }
                        dataTestId="type"
                        label={ t('tables.type') }
                        options={ enumToOptions(FinanceitemTypeEnum, t, 'financeItem') }
                        selected={ values.type }
                        onChange={ ({ selected }) => setFieldValue('type', selected?.key) }
                        error={ touched.type && errors.type }
                        block={ true }
                        optionsListWidth="field"
                    />

                    <Textarea
                        disabled={ !!record?.id }
                        dataTestId="comment"
                        label={ t('Комментарий') }
                        maxLength={ 200 }
                        counter={ true }
                        value={ values.comment }
                        onChange={ handleChange('comment') }
                        block={ true }
                    />
                    { !!record.id && (
                        <Typography.Text view="primary-small" color="secondary" weight="medium">
                            { t('typography.warningFinanceItem') }
                        </Typography.Text>
                    ) }
                </FlexColumns>
            ) }
        </RestForm>
    );
};
