import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
    AmountInput, ModalContext, showError, Sidepanel,
} from '@alphakits/ui/dist';
import { BaseForm } from '@alphakits/ui/dist/form/templates/base-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CrossSaleStatusEnum } from '@escapenavigator/types/dist/profile/cross-sale/cross-sale-status.enum';
import { EarlyBookingDto } from '@escapenavigator/types/dist/profile/cross-sale/early-booking/early-booking.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils';
import { useApi } from 'src/providers/api/context';

import { getHandlers } from '../../../../components/quill/get-handlers';
import { EmailExampleModal } from '../common/email-example';
import { CommonCrossSaleForm } from '../common/form';
import { useLocales } from '../common/hooks/use-locale';
import { deepEqual } from '../common/utils/deep-equal';

type Props = {
    close: () => void;
    cb: () => void;
    data: EarlyBookingDto;
    defaultValues: EarlyBookingDto;
};

export const EarlyBookingModal: React.FC<Props> = ({
    close, data, cb, defaultValues,
}) => {
    const { crossSalesApi } = useApi();
    const { openModal } = useContext(ModalContext);

    const { t } = useTranslation();

    const { updateEarlyBookingFetch, updateEarlyBookingLoading } = useApiMethod({
        api: crossSalesApi.updateEarlyBooking,
        errorCallback: ({ message }) => showError(message),
        successCallback: () => {
            close();
            cb();
        },
    });

    const locales = useLocales({ locales: data.locales });

    return (
        <Sidepanel
            subtitle={ t('earlyBookingDescription') }
            title={ t('earlyBooking') }
            loading={ updateEarlyBookingLoading }
        >
            <BaseForm
                initialValues={ serializeRecord(EarlyBookingDto, {
                    ...data,
                    locales,
                    status: CrossSaleStatusEnum.ENABLED,
                }) }
                loading={ updateEarlyBookingLoading }
                validate={ validateByDto(t) }
                save={ (values) => updateEarlyBookingFetch(values) }
                t={ t }
            >
                { ({
                    values, errors, setFieldValue, submitForm, touched, setValues,
                }) => (
                    <CommonCrossSaleForm
                        errors={ errors }
                        handlers={ getHandlers('earlyBooking', t) }
                        close={ close }
                        submit={ submitForm }
                        setFieldValue={ setFieldValue }
                        onDefaultsClick={ () => setValues(defaultValues) }
                        showDefaults={ !deepEqual(values, defaultValues) }
                        locales={ values.locales }
                        onExampleClick={ () => {
                            openModal(
                                EmailExampleModal,
                                'm',
                            )({
                                record: values,
                                subtitile: t('earlyBooking'),
                            });
                        } }
                    >
                        <AmountInput
                            dataTestId="amount"
                            label={ t('Отправить до игры') }
                            suffix={ t('common:дней') }
                            value={ values.sendBefore }
                            onChange={ (e, { value }) => setFieldValue('sendBefore', value) }
                            error={ touched.sendBefore && errors.sendBefore }
                            block={ true }
                        />
                    </CommonCrossSaleForm>
                ) }
            </BaseForm>
        </Sidepanel>
    );
};
