/* eslint-disable complexity */
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PlusS } from '@alphakits/icons';
import {
    Box,
    Button,
    ButtonArrow,
    Flex,
    FlexColumns,
    Grid,
    IconButton,
    showSuccess,
    SuperListHeader,
    Tag,
    ToastPlate,
    Typography,
} from '@alphakits/ui';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { uploadCertificateRows } from '@escapenavigator/types/dist/constants/uploading/upload-certificate-rows';
import { uploadOrdersRows } from '@escapenavigator/types/dist/constants/uploading/upload-order-rows';
import { QuestroomsSelect } from 'src/components/selects/questrooms-select';
// import { Languages } from '@escapenavigator/types/dist/shared/enum/languages.enum';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { ROUTE_CERTIFICATE_SALES, ROUTE_GAMES_HISTORY } from 'src/router/constants';
import { selectAllQuestrooms } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

const ordersDescription: Record<keyof typeof uploadOrdersRows, string> = {
    source: 'Order source. Bookeo, Resova etc. (Required)',
    email: 'Client email (Required)',
    name: 'Client name',
    surname: 'Client surname',
    phone: 'Client phone',
    date: 'Order date and time. 02.03.2024 13:00:00 (Required)',
    players: 'Number of players (Required)',
    total: 'Total amoun to pay. 120,00 (Required)',
    payed: 'All payed amount. 100,00 (Required)',
};

const certificatesDescription: Record<keyof typeof uploadCertificateRows, string> = {
    source: 'Certificate source. Bookeo, Resova etc. (Required)',
    code: 'Certificate code (Required)',
    client: 'Any client information (Required)',
    nominal: 'Amount for applying in game (Required)',
    expireDate: 'Expiration date. 02.03.2024 13:00:00 (Required)',
    createdDate: 'Created date 02.03.2024 13:00:00 (Required)',
    used: 'Used date 02.03.2024 13:00:00 (Required)',
};

const FileDisplay = ({ file, remove }) => (
    <Box padding="sm" border={ true } rounded="sm">
        <Flex>
            <Typography.Text view="title">{ file.name }</Typography.Text>
            <ButtonArrow size="s" type="close" onClick={ remove } />
        </Flex>
    </Box>
);

type Type = 'order' | 'certificate';
type Source = 'bookeo' | 'other';

export const ImportPage: React.FC = () => {
    const { t } = useTranslation();
    const {
        current: { mode },
    } = useCurrentUser();
    const navigate = useNavigate();
    const { certificateUploadsApi, orders } = useApi();
    const innerRef = useRef<HTMLInputElement>(null);
    const questrooms = useAppSelector(selectAllQuestrooms({ deleted: false, closed: false }));
    const [data, setData] = useState<FormData>();
    const [type, setType] = useState<Type>();
    const [source, setSource] = useState<Source>();
    const [error, setError] = useState('');
    const [questroom, setQuestroom] = useState<number>();

    const { uploadOrdersFetch, uploadOrdersLoading } = useApiMethod({
        api: orders.uploadOrders,
        errorCallback: ({ message }) => setError(message),
        successCallback: () => {
            navigate(ROUTE_GAMES_HISTORY);
            showSuccess('Uploaded');
        },
    });

    const { uploadCertificateFetch, uploadCertificateLoading } = useApiMethod({
        api: certificateUploadsApi.uploadCertificate,
        errorCallback: ({ message }) => setError(message),
        successCallback: () => {
            navigate(ROUTE_CERTIFICATE_SALES);
            showSuccess('Uploaded');
        },
    });

    const handleChange = async ({ target: { files } }: React.ChangeEvent<HTMLInputElement>) => {
        setError('');

        if (!files) return;

        const file = new FormData();

        file.append('file', files[0]);
        setData(file);

        innerRef.current.value = null;
    };

    const handleUpload = () => {
        setError('');

        if (type === 'certificate') {
            uploadCertificateFetch({ data, onUploadProgress: () => {}, params: { type: source } });
        } else {
            uploadOrdersFetch({
                data,
                onUploadProgress: () => {},
                params: {
                    type: source,
                    questroomId: questroom,
                },
            });
        }
    };

    const handleSourceChange = (value: Source) => {
        setError('');
        setSource(value);
    };

    const handleTypeChange = (value: Type) => {
        setError('');
        setType(value);
    };

    return (
        <Grid.Row>
            <Grid.Col width={ 7 }>
                <FlexColumns columns={ 1 } gr={ 24 }>
                    { mode === 'admin' ? (
                        <React.Fragment>
                            <FlexColumns columns={ 1 } gr={ 8 }>
                                <Typography.Text view="title">
                                    Select what you want to upload
                                </Typography.Text>

                                <Flex justify="start" gap="md">
                                    <Tag
                                        view={ type === 'order' ? 'primary-inverted' : 'primary' }
                                        text={ t('games') }
                                        onClick={ () => handleTypeChange('order') }
                                    />
                                    <Tag
                                        view={
                                            type === 'certificate' ? 'primary-inverted' : 'primary'
                                        }
                                        text={ t('Подарочные сертификаты') }
                                        onClick={ () => handleTypeChange('certificate') }
                                    />
                                </Flex>
                            </FlexColumns>

                            <FlexColumns columns={ 1 } gr={ 12 }>
                                <Typography.Text view="title">
                                    Select booking system
                                </Typography.Text>
                                <Flex justify="start" gap="md">
                                    <Tag
                                        view={ source === 'bookeo' ? 'primary-inverted' : 'primary' }
                                        onClick={ () => handleSourceChange('bookeo') }
                                        text="Bookeo"
                                    />
                                    <Tag
                                        text="Other booking system"
                                        view={ source === 'other' ? 'primary-inverted' : 'primary' }
                                        onClick={ () => handleSourceChange('other') }
                                    />
                                </Flex>
                            </FlexColumns>

                            { type === 'order' && (
                                <QuestroomsSelect
                                    label="Select escape room"
                                    questrooms={ questrooms }
                                    selected={ questroom }
                                    onChange={ (val) => setQuestroom(+val.selected.key) }
                                    multiple={ false }
                                />
                            ) }

                            { mode === 'admin' && source === 'other' && (
                                <pre style={ { userSelect: 'text' } }>
                                    <code>
                                        { type === 'order' &&
                                            JSON.stringify(ordersDescription, null, 4) }

                                        { type === 'certificate' &&
                                            JSON.stringify(certificatesDescription, null, 4) }
                                    </code>
                                </pre>
                            ) }

                            <input
                                type="file"
                                style={ { height: 0, position: 'absolute' } }
                                ref={ innerRef }
                                onChange={ handleChange }
                                accept=".xls,.xlsx,.numbers"
                                multiple={ false }
                            />

                            { type && !data && (
                                <SuperListHeader
                                    text="Upload xls file"
                                    leftAddons={ (
                                        <IconButton
                                            onClick={ () => innerRef.current?.click() }
                                            icon={ PlusS }
                                            disabled={
                                                uploadCertificateLoading || uploadOrdersLoading
                                            }
                                            loading={
                                                uploadCertificateLoading || uploadOrdersLoading
                                            }
                                        />
                                    ) }
                                />
                            ) }
                            { type && data && (
                                <FileDisplay
                                    file={ data.get('file') }
                                    remove={ () => setData(undefined) }
                                />
                            ) }

                            { error && <ToastPlate view="negative">{ error }</ToastPlate> }
                            <div>
                                <Button
                                    disabled={ !data }
                                    view="primary"
                                    loading={ uploadOrdersLoading || uploadCertificateLoading }
                                    size="s"
                                    onClick={ handleUpload }
                                >
                                    Upload
                                </Button>
                            </div>
                        </React.Fragment>
                    ) : (
                        <ToastPlate title="Coming soon" view="default">
                            { t('supportUploadCeetificatesText', {
                                value: 'support@escapenavigator.com',
                            }) }
                        </ToastPlate>
                    ) }
                </FlexColumns>
            </Grid.Col>
        </Grid.Row>
    );
};
