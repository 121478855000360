import React from 'react';
import { Button, showError, showSuccess } from '@alphakits/ui';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { useApi } from 'src/providers/api/context';

export const SendInviteButton = ({ id, t }) => {
    const { users: usersApi } = useApi();

    const { sendInviteFetch, sendInviteLoading } = useApiMethod({
        api: usersApi.sendInvite,
        successCallback: () => {
            showSuccess(t('Приглашение отправлено'));
        },
        errorCallback: (err) => showError(err.message),
    });

    return (
        <Button
            loading={ sendInviteLoading }
            size="xs"
            view="outlined"
            onClick={ (e) => {
                e.stopPropagation();
                sendInviteFetch(id);
            } }
        >
            { t('sendInviteRepeat') }
        </Button>
    );
};
