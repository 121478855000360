// /* eslint-disable no-lone-blocks */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { PhoneS } from '@alphakits/icons';
import {
    AmountInput,
    Box,
    Button,
    Checkbox,
    Flex,
    FlexColumns,
    showError,
    Typography,
    useMatchMedia,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { LocationRO } from '@escapenavigator/types/dist/location/location.ro';
import { CollapsedTypography } from 'src/components/collapsed-typography';
import { useApi } from 'src/providers/api/context';
import { upsertQuestroom } from 'src/store';

import styles from '../index.module.css';

type FullLocation = LocationRO & { closed: boolean; questroomsIds: number[]; hasError: boolean };

type Props = {
    location: FullLocation;
    shaking: boolean;
    openLocationModal: (location: LocationRO) => void;
    setFieldValue: (location: LocationRO, key: keyof LocationRO, value: any) => void;
};

export const LocationCell: React.FC<Props> = ({
    location,
    shaking,
    setFieldValue,
    openLocationModal,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { questrooms } = useApi();
    const [isDesktop] = useMatchMedia('(min-width: 980px)');

    const { closeManyFetch, closeManyLoading } = useApiMethod({
        api: questrooms.closeMany,
        errorCallback: ({ message }) => showError(message),
        successCallback: ({ data }) => {
            data.map((q) => dispatch(upsertQuestroom(q)));
        },
    });

    return (
        <Box padding={ isDesktop ? 'xl' : 'md' } rounded="md" border={ true }>
            <FlexColumns className={ location.hasError && styles.error } columns={ 1 } gr={ 24 }>
                <FlexColumns columns={ 1 } gr={ 8 }>
                    { location.closed && (
                        <Typography.Text view="title" weight="bold">
                            [CLOSED]
                        </Typography.Text>
                    ) }

                    <Typography.Title
                        view="xsmall"
                        tag="div"
                        color={ location.closed ? 'tertiary' : 'primary' }
                        weight="bold"
                    >
                        { location?.address || 'Closed location' }
                    </Typography.Title>

                    { !location.closed && (
                        <Flex justify="start" gap="xs">
                            <PhoneS />

                            <Typography.Text
                                className={ !location.phone && shaking && styles.shake }
                                view="title"
                                weight="medium"
                                color={ location.phone ? 'primary' : 'negative' }
                            >
                                { location.phone || t('Нет данных') }
                            </Typography.Text>
                        </Flex>
                    ) }
                </FlexColumns>

                { !location.closed && (
                    <FlexColumns columns={ 1 } gr={ 16 }>
                        <FlexColumns gr={ 8 } columns={ 1 }>
                            <Typography.Text view="title" weight="bold">
                                { t('component.howToFind') }
                            </Typography.Text>

                            <CollapsedTypography
                                view="caps"
                                text={ location.howToFind || t('Нет данных') }
                            />
                        </FlexColumns>

                        <FlexColumns gr={ 8 } columns={ 1 }>
                            <Typography.Text view="title" weight="bold">
                                { t('Что важно знать перед бронированием') }
                            </Typography.Text>

                            <CollapsedTypography
                                view="caps"
                                text={ location.prepareText || t('Нет данных') }
                            />
                        </FlexColumns>
                    </FlexColumns>
                ) }

                { !location.closed && (
                    <React.Fragment>
                        <FlexColumns columns={ 1 } gr={ 12 }>
                            <Checkbox
                                dataTestId="wifi"
                                label={ t('component.availabilityWifi') }
                                checked={ location.wifi }
                                onChange={ (e, { checked }) =>
                                    setFieldValue(location, 'wifi', checked) }
                            />

                            <Checkbox
                                dataTestId="wardrobe"
                                label={ t('component.availabilityWardrobe') }
                                checked={ location.wardrobe }
                                onChange={ (e, { checked }) =>
                                    setFieldValue(location, 'wardrobe', checked) }
                            />

                            <Checkbox
                                dataTestId="freeParking"
                                label={ t('component.freeParking') }
                                checked={ location.freeParking }
                                onChange={ (e, { checked }) =>
                                    setFieldValue(location, 'freeParking', checked) }
                            />
                            <Checkbox
                                dataTestId="parking"
                                label={ t('component.parking') }
                                checked={ location.parking }
                                onChange={ (e, { checked }) =>
                                    setFieldValue(location, 'parking', checked) }
                            />
                            <Checkbox
                                dataTestId="waitingArea"
                                label={ t('component.waitingArea') }
                                checked={ location.waitingArea }
                                onChange={ (e, { checked }) =>
                                    setFieldValue(location, 'waitingArea', checked) }
                            />

                            { location.waitingArea && (
                                <AmountInput
                                    dataTestId="waitingAreaSize"
                                    label={ t('component.waitingAreaSize') }
                                    suffix={ t('ppl') }
                                    min="0"
                                    error={ !location.waitingAreaSize }
                                    value={ location.waitingAreaSize }
                                    onChange={ (e, { value }) =>
                                        setFieldValue(location, 'waitingAreaSize', value) }
                                    block={ true }
                                />
                            ) }
                        </FlexColumns>
                    </React.Fragment>
                ) }

                <Flex gap="md" justify="start" wrap={ true }>
                    { location.closed ? (
                        <Button
                            view="outlined"
                            size="xs"
                            loading={ closeManyLoading }
                            onClick={ () =>
                                closeManyFetch({ ids: location.questroomsIds, closed: false }) }
                        >
                            { t('Локация не закрыта') }
                        </Button>
                    ) : (
                        <React.Fragment>
                            <Button
                                view="outlined"
                                size="xs"
                                block={ true }
                                onClick={ () => openLocationModal(location) }
                            >
                                { t('Редактировать') }
                            </Button>
                            <Button
                                view="outlined"
                                size="xs"
                                block={ true }
                                loading={ closeManyLoading }
                                onClick={ () =>
                                    closeManyFetch({ ids: location.questroomsIds, closed: true }) }
                            >
                                { t('Локация закрыта') }
                            </Button>
                        </React.Fragment>
                    ) }
                </Flex>
            </FlexColumns>
        </Box>
    );
};
