import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import announcementsReducer from './slices/announcements-slice';
import cashboxesReducer from './slices/cashboxes-slice';
import certificatesReducer from './slices/certificates-slice';
import locationsReducer from './slices/locations-slice';
import optionsReducer from './slices/options-slice';
import questroomsReducer from './slices/questrooms-slice';
import rolesReducer from './slices/roles-slice';
import rulesReducer from './slices/rules-slice';
import sessionsReducer from './slices/sessions-slice';
import slotsReducer from './slices/slots-slice';
import tariffsReducer from './slices/tariffs-slice';
import tasksReducer from './slices/tasks-slice';
import usersReducer from './slices/users-slice';

export enum SliceState {
    FETCHING = 'fetching',
    CREATING = 'creating',
    UPDATING = 'updating',
    REMOVING = 'removing',
}

export const store = configureStore({
    reducer: {
        announcements: announcementsReducer,
        tasks: tasksReducer,
        rules: rulesReducer,
        questrooms: questroomsReducer,
        locations: locationsReducer,
        slots: slotsReducer,
        cashboxes: cashboxesReducer,
        certificates: certificatesReducer,
        options: optionsReducer,
        tariffs: tariffsReducer,
        roles: rolesReducer,
        users: usersReducer,
        sessions: sessionsReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
