import { CertificateSaleRO } from '@escapenavigator/types/dist/certificate-sale/certificate-sale.ro';
import { differenceInHours, parse } from 'date-fns';

import { Status } from '..';

export const getCertificateValidity = (row: CertificateSaleRO, questroomId: number): Status => {
    const expiried =
        differenceInHours(parse(row.expireDate, 'yyyy-MM-dd', new Date()), new Date()) < 0;

    if (row.order) return 'used';

    if (expiried) return 'expired';

    if (row.toPay > 0) return 'not_payed';

    if (!row.certificate?.allQuestrooms && !row.certificate?.questroomsIds?.includes(questroomId)) return 'wrongQuestroom';

    return 'active';
};
