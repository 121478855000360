/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Box, Button, FlexColumns, Grid, ModalContext, Typography,
} from '@alphakits/ui';
import { OrderRO } from '@escapenavigator/types/dist/order/order.ro';
import { convertUTCDateToZonedDate } from '@escapenavigator/utils';
import { formatAmount } from '@escapenavigator/utils/dist/format-amount';
import { getFullName } from '@escapenavigator/utils/dist/get-full-name';
import { ClientMessage } from 'src/components/client-message';
import { Comments } from 'src/components/comments';
import { TextCell } from 'src/components/text-cell';
import { OrderModal } from 'src/modals/order';
import { useCurrentUser } from 'src/providers/current-user/context';
import { selectLocationById, selectQuestroomById, selectUserById } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

type Props = {
    order: OrderRO;
};
export const PrintOrderCell: React.FC<Props> = ({ order }) => {
    const { t } = useTranslation();
    const {
        profile: { currency },
    } = useCurrentUser();

    const { openModal } = useContext(ModalContext);

    const questroom = useAppSelector(selectQuestroomById(order.questroomId));
    const location = useAppSelector(selectLocationById(questroom.locationId));
    const user = useAppSelector(selectUserById(order.moderatorId));

    return (
        <Button
            view="ghost"
            onClick={ () => openModal(OrderModal, 'm', true)({ recordId: order.id }) }
        >
            <Box padding="sm" border={ true } rounded="sm">
                <Grid.Row>
                    <Grid.Col width={ 8 }>
                        <FlexColumns columns={ 1 } gr={ 16 }>
                            <FlexColumns columns={ 1 } gr={ 8 }>
                                <Typography.Title
                                    tag="div"
                                    color="primary"
                                    weight="bold"
                                    view="xxsmall"
                                >
                                    { `${convertUTCDateToZonedDate({
                                        date: order.utcDate,
                                        timeZone: location.timeZone,
                                        format: 'HH:mm',
                                        showTZ: false,
                                    })}, 
                                ${questroom.title}
                                ${user ? ` (${getFullName(user)})` : ''}` }
                                </Typography.Title>

                                { order.comment && (
                                    <ClientMessage text={ `${t('Комментарий')}: ${order.comment}` } />
                                ) }
                            </FlexColumns>

                            <FlexColumns columns={ 3 } gr={ 12 }>
                                <TextCell
                                    title={ t('Основное') }
                                    subtitle={ `${order.players}ppl, ${order.language}` }
                                />

                                <TextCell
                                    title={ t('tables.client') }
                                    subtitle={ getFullName(order.client) }
                                />

                                <TextCell title={ t('phone') } subtitle={ order.client.phone || '' } />

                                <TextCell title="Email" subtitle={ order.client.email } />

                                <TextCell
                                    title={ t('Стоимость игры') }
                                    subtitle={ formatAmount(order.total, currency) }
                                />

                                <TextCell
                                    title={ t('paidUp') }
                                    subtitle={ formatAmount(order.payed, currency) }
                                />

                                <TextCell
                                    title={ t('К оплате') }
                                    subtitle={ formatAmount(order.total - order.payed, currency) }
                                />

                                { !!order.upsellings?.length && (
                                    <TextCell
                                        title={ t('Доп услуги') }
                                        subtitle={ (
                                            order.upsellings?.map((u) => u.title) || []
                                        ).join(', ') }
                                    />
                                ) }

                                { !!order.promocodes?.length && (
                                    <TextCell
                                        title={ t('Промокоды') }
                                        subtitle={ (
                                            order.promocodes?.map(
                                                (u) =>
                                                    `${u.code} - ${formatAmount(
                                                        u.amount,
                                                        currency,
                                                    )}`,
                                            ) || []
                                        ).join(', ') }
                                    />
                                ) }

                                { !!order.certificates?.length && (
                                    <TextCell
                                        title={ t('Сертификаты') }
                                        subtitle={ (
                                            order.certificates?.map(
                                                (u) =>
                                                    `${u.code} - ${formatAmount(
                                                        u.nominal,
                                                        currency,
                                                    )}`,
                                            ) || []
                                        ).join(', ') }
                                    />
                                ) }
                                { !!order.discounts?.length && (
                                    <TextCell
                                        title={ t('Скидка') }
                                        subtitle={ (
                                            order.discounts?.map(
                                                (u) =>
                                                    `${u.reason} - ${formatAmount(
                                                        u.amount,
                                                        currency,
                                                    )}`,
                                            ) || []
                                        ).join(', ') }
                                    />
                                ) }
                            </FlexColumns>
                        </FlexColumns>
                    </Grid.Col>
                    <Grid.Col width={ 4 }>
                        <FlexColumns columns={ 1 } gr={ 16 }>
                            <Typography.Title
                                tag="div"
                                color="primary"
                                weight="bold"
                                view="xxsmall"
                            >
                                Notices
                            </Typography.Title>

                            <Comments readonly={ true } t={ t } orderId={ order.id } />
                        </FlexColumns>
                    </Grid.Col>
                </Grid.Row>
            </Box>
        </Button>
    );
};
