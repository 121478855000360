import React from 'react';
import { ClockCircleM } from '@alphakits/icons';
import { Image, Typography } from '@alphakits/ui/dist';
import cn from 'classnames';

import styles from './index.module.css';

type Props = {
    title: string;
    fullView?: boolean;
    subtitle?: string;
};

export const EmptyState: React.FC<Props> = ({
    fullView, title, subtitle, children,
}) => (
    <div className={ cn(styles.container, { [styles.fullView]: fullView }) }>
        <Image icon={ <ClockCircleM /> } />

        { title && (
            <Typography.Title tag="div" view="xxsmall" weight="bold">
                { title }
            </Typography.Title>
        ) }

        { subtitle && (
            <Typography.Text view="primary-medium" color="secondary" weight="medium">
                { subtitle }
            </Typography.Text>
        ) }

        { children && <div className={ styles.children }>{ children }</div> }
    </div>
);
