import React, {
    useCallback, useContext, useEffect, useState,
} from 'react';
import {
    Button, Flex, FlexColumns, ModalContext, Typography,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { OrderRO } from '@escapenavigator/types/dist/order/order.ro';
import { CreateUserMessageDto } from '@escapenavigator/types/dist/user-message/create-user-message.dto';
import { UserMessageResponseObject } from '@escapenavigator/types/dist/user-message/user-message.ro';
import { TFunction } from 'i18next';
import { UserComment } from 'src/modals/user-comment';
import { useApi } from 'src/providers/api/context';

import { Comment } from './comment';

type CommentsSectionProps = {
    t: TFunction;
    readonly?: boolean;
    orderId?: OrderRO['id'];
    certificateSaleId?: CreateUserMessageDto['certificateSaleId'];
};

export const Comments: React.FC<CommentsSectionProps> = ({
    t,
    orderId,
    readonly,
    certificateSaleId,
}) => {
    const { userMessages } = useApi();
    const { openModal } = useContext(ModalContext);

    const [messages, setMessages] = useState<UserMessageResponseObject[]>([]);

    const { queryError, queryLoading, queryFetch } = useApiMethod({
        api: userMessages.query,
        successCallback: ({ data }) => setMessages(data),
    });

    useEffect(() => {
        queryFetch({ where: { orderId, certificateSaleId }, page: 1, limit: 10 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const removeMessage = (id: UserMessageResponseObject['id']) => {
        setMessages(messages.filter((m) => m.id !== id));
    };

    const handleOpenCommentModal = useCallback(
        () =>
            openModal(UserComment)({
                orderId,
                certificateSaleId,
                saveCallback: (message) => setMessages([...messages, message]),
            }),
        [certificateSaleId, messages, openModal, orderId],
    );

    if (queryLoading) return <div>Loading</div>;

    if (queryError) return <div>{ queryError }</div>;

    return (
        <FlexColumns columns={ 1 } gr={ 12 }>
            { !readonly && (
                <Flex>
                    <Typography.Title view="xsmall" tag="div" weight="bold">
                        { t('order.notes') }
                    </Typography.Title>
                    <Button
                        className="noPrint"
                        view="outlined"
                        size="xs"
                        onClick={ handleOpenCommentModal }
                    >
                        { t('Добавить заметку') }
                    </Button>
                </Flex>
            ) }

            { messages.map((comment) => (
                <Comment
                    key={ comment.id }
                    comment={ comment }
                    onRemove={ readonly ? undefined : removeMessage }
                />
            )) }
        </FlexColumns>
    );
};
