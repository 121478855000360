import React from 'react';
import { Typography } from '@alphakits/ui/dist';

import styles from './index.module.css';

type Props = {
    text: string;
};

export const ClientMessage: React.FC<Props> = ({ text }) => (
    <div>
        <Typography.Text view="title" className={ styles.text } color="primary">
            { text }
        </Typography.Text>
    </div>
);
