/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate, InfoBlockItem } from '@alphakits/ui/dist';
import { PromocodeRO } from '@escapenavigator/types/dist/promocode/promocode.ro';

type Props = {
    promocode: PromocodeRO;
};

export const PromocodeDetails: React.FC<Props> = ({ promocode }) => {
    const { t, i18n } = useTranslation();

    return (
        <React.Fragment>
            { (promocode.forOrdersFrom || promocode.forOrdersTo) && (
                <InfoBlockItem
                    label={ (
                        <React.Fragment>
                            { t('Может быть применен на игры, проходящие в период') }
                            { promocode.forOrdersFrom &&
                                ` ${t('from')} ${formatDate(promocode.forOrdersFrom, {
                                    lang: i18n.language,
                                    format: 'dd MMMM yyyy',
                                })}` }
                            { promocode.forOrdersTo &&
                                ` ${t('to')} ${formatDate(promocode.forOrdersTo, {
                                    lang: i18n.language,
                                    format: 'dd MMMM yyyy',
                                })}` }
                        </React.Fragment>
                    ) }
                />
            ) }
            <InfoBlockItem label={ promocode.description } />
        </React.Fragment>
    );
};
