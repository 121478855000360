import React from 'react';
import { FlexColumns } from '@alphakits/ui/dist/flex-columns';
import { Tooltip } from '@alphakits/ui/dist/tooltip';

import styles from './index.module.css';

type Icon = {
    icon: React.ReactNode;
    label: string;
};

export type OrderSlotAddonProps = {
    icons: Icon[];
};

export const OrderSlotAddon = ({ icons }: OrderSlotAddonProps) => (
    <React.Fragment>
        { !!icons.length && (
            <FlexColumns columns={ icons.length } gc={ 6 }>
                { icons.map((icon, idx) => {
                    const key = `addon_${idx}`;

                    return (
                        <Tooltip
                            key={ key }
                            trigger="hover"
                            position="top"
                            content={ <div style={ { width: '215px' } }>{ icon.label }</div> }
                            dataTestId="test-id"
                        >
                            <div className={ styles.icon_wrapper }>{ icon.icon }</div>
                        </Tooltip>
                    );
                }) }
            </FlexColumns>
        ) }
    </React.Fragment>
);
