import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    CalendarInput,
    Checkbox,
    FlexColumns,
    Input,
    Select,
    Typography,
    useCrudFormRequests,
    useGetOne,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { ClientRO } from '@escapenavigator/types/dist/client/client.ro';
import { CreateClientDto } from '@escapenavigator/types/dist/client/create-client.dto';
import { ClientTypeEnum } from '@escapenavigator/types/dist/client/emun/client-type.enum';
import { enumToOptions, serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';

type Props = {
    recordId?: number;
    successCallback?: (client: ClientRO) => void;
    close: () => void;
};

export const ClientEditModal: React.FC<Props> = ({ recordId, close, successCallback }) => {
    const { clients } = useApi();
    const { t, i18n } = useTranslation();
    const { profile } = useCurrentUser();
    const { record, loading, error } = useGetOne(recordId, clients.getOne);

    const {
        save,
        updating,
        error: softError,
    } = useCrudFormRequests({
        recordId: record?.id,
        saveRequest: record?.id ? clients.update : clients.create,
        saveCallback: successCallback ? (data) => successCallback(data) : null,
        close,
    });

    return (
        <RestForm
            title={ t('tables.client') }
            initialValues={ serializeRecord(CreateClientDto, {
                clientType: ClientTypeEnum.ADULT,
                ...record,
                fields: profile.adminBookingFields,
            }) }
            save={ save }
            close={ close }
            validate={ validateByDto(t) }
            t={ t }
            error={ error }
            softError={ softError }
            loading={ loading }
            updating={ updating }
        >
            { ({
                values, handleChange, touched, errors, setFieldValue,
            }) => (
                <FlexColumns columns={ 1 }>
                    <FlexColumns columns={ 1 } gr={ 12 }>
                        <Typography.Text view="caps" color="secondary" uppercase={ true }>
                            { t('Основное') }
                        </Typography.Text>

                        <Select
                            dataTestId="cancelationType"
                            selected={ values.clientType }
                            onChange={ ({ selected }) => setFieldValue('clientType', selected?.key) }
                            options={ enumToOptions(ClientTypeEnum) }
                            block={ true }
                            optionsListWidth="field"
                        />

                        { values.clientType === ClientTypeEnum.ADULT && (
                            <Input
                                label={ t('email') }
                                value={ values.email }
                                name="email"
                                onChange={ handleChange('email') }
                                error={ touched.email && errors.email }
                            />
                        ) }
                    </FlexColumns>

                    <FlexColumns columns={ 2 }>
                        <Input
                            label={ t('name') }
                            block={ true }
                            name="name"
                            value={ values.name }
                            onChange={ handleChange('name') }
                            error={ touched.name && errors.name }
                        />

                        <Input
                            label={ t('surname') }
                            value={ values.surname }
                            block={ true }
                            name="surname"
                            onChange={ handleChange('surname') }
                            error={ touched.surname && errors.surname }
                        />
                    </FlexColumns>

                    <CalendarInput
                        lang={ i18n.language }
                        calendarProps={ {
                            defaultView: 'months',
                        } }
                        mobileMode="popover"
                        block={ true }
                        label={ t('Дата рождения') }
                        value={ values.birthday }
                        error={ touched.birthday && errors.birthday }
                        onChange={ (e, { value }) => setFieldValue('birthday', value) }
                    />

                    <Input
                        label={ t('phone') }
                        value={ values.phone }
                        name="phone"
                        onChange={ handleChange('phone') }
                        error={ touched.phone && errors.phone }
                    />

                    <Input
                        label={ t('component.phone2') }
                        value={ values.phone2 }
                        name="phone"
                        onChange={ handleChange('phone2') }
                        error={ touched.phone2 && errors.phone2 }
                    />

                    <FlexColumns columns={ 1 } gr={ 16 }>
                        <FlexColumns columns={ 1 } gr={ 12 }>
                            <Typography.Text view="caps" color="secondary" uppercase={ true }>
                                { t('address') }
                            </Typography.Text>

                            <Input
                                label={ t('address') }
                                value={ values.address }
                                name="address"
                                onChange={ handleChange('address') }
                                error={ touched.address && errors.address }
                                block={ true }
                            />
                        </FlexColumns>

                        <FlexColumns columns={ 2 } gr={ 16 }>
                            <Input
                                label={ t('Postcode') }
                                value={ values.postcode }
                                name="postcode"
                                autoComplete="postal-code"
                                onChange={ handleChange('postcode') }
                                error={ touched.postcode && errors.postcode }
                            />

                            <Input
                                label={ t('Город') }
                                value={ values.city }
                                name="city"
                                autoComplete="address-level2"
                                onChange={ handleChange('city') }
                                error={ touched.city && errors.city }
                            />
                        </FlexColumns>

                        { values.clientType === ClientTypeEnum.ADULT && (
                            <Checkbox
                                label={ t('sendAds') }
                                checked={ !!values.sendAds }
                                onChange={ (e, { checked }) => setFieldValue('sendAds', checked) }
                            />
                        ) }
                    </FlexColumns>
                </FlexColumns>
            ) }
        </RestForm>
    );
};
