import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Button,
    FlexColumns,
    InfoBlockHeader,
    Loader,
    ModalContext,
    showError,
    SuperListItem,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { LocationRO } from '@escapenavigator/types/dist/location/location.ro';
import { useApi } from 'src/providers/api/context';

import { MarketingModal } from '../../sub-modals/marketing';
import { MarketingCell } from '../marketing-cell';

type Props = {
    orderId: number;
    slotId: number;
    location: LocationRO;
};

export const MarketingSection: React.FC<Props> = ({ orderId, slotId, location }) => {
    const { t } = useTranslation();
    const { orderEmailsApi } = useApi();
    const { openModal } = useContext(ModalContext);

    const { getOrderEmailsData, getOrderEmailsLoading, getOrderEmailsFetch } = useApiMethod({
        api: orderEmailsApi.getOrderEmails,
    });

    const { createRetargetingFetch, createRetargetingLoading } = useApiMethod({
        api: orderEmailsApi.createRetargeting,
        errorCallback: ({ message }) => showError(message),
        successCallback: () => getOrderEmailsFetch({ orderId }),
    });

    useEffect(() => {
        getOrderEmailsFetch({ orderId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId, slotId]);

    if (getOrderEmailsLoading) return <Loader />;

    return (
        <Box background="var(--color-bg-primary)" border={ true } rounded="sm" padding="md">
            { !slotId && !getOrderEmailsData?.length ? (
                <FlexColumns columns={ 1 } gr={ 16 }>
                    <SuperListItem
                        text={ t('retargeting') }
                        weight="bold"
                        hint={ t('retargetingDescription') }
                    />
                    <Button
                        onClick={ () => createRetargetingFetch({ orderId }) }
                        loading={ createRetargetingLoading }
                        view="outlined"
                        size="xs"
                    >
                        Add retargeting
                    </Button>
                </FlexColumns>
            ) : (
                <InfoBlockHeader
                    title={ t('имеилы') }
                    onClick={
                        getOrderEmailsData?.length
                            ? () =>
                                openModal(MarketingModal)({
                                    orderEmails: getOrderEmailsData || [],
                                    location,
                                    cb: () => getOrderEmailsFetch({ orderId }),
                                })
                            : undefined
                    }
                />
            ) }

            { getOrderEmailsData
                ?.sort((a, b) => (a.sendAt > b.sendAt ? 1 : -1))
                .map((orderEmail) => (
                    <MarketingCell
                        view="compact"
                        orderEmail={ orderEmail }
                        location={ location }
                        cb={ () => getOrderEmailsFetch({ orderId }) }
                    />
                )) }
        </Box>
    );
};
