import React from 'react';
import { Tag } from '@alphakits/ui';

import { TagProps } from './types';

export const FromToValue: React.FC<TagProps> = ({
    value, row, removeParam, t, rowField,
}) => {
    const from = value[0] ? ` ${t('from')} ${value[0]}` : '';
    const to = value[1] ? ` ${t('to')} ${value[1]}` : '';

    const text = `${rowField}${from}${to}`;

    return <Tag text={ text } onClick={ () => {} } onCloseClick={ () => removeParam(row) } />;
};
