import React, { useContext, useState } from 'react';
import { ModalContext } from '@alphakits/ui';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { ProfileStatusEnum } from '@escapenavigator/types/dist/profile/enum/profile-step.enum';
import { PageLoader } from 'src/components/page-loader';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';

import { VerificationCompleteModal } from './components/verification-complete';
import { VerifyLocations } from './locations-form';
import { OrganizationForm } from './organization-form';
import { VerifyQuestrooms } from './questrooms-form';

import styles from './index.module.css';

export const VerifyCompany: React.FC = () => {
    const { profiles } = useApi();

    const { openModal } = useContext(ModalContext);

    const { profile, setProfile } = useCurrentUser();

    const [shaking, setShaking] = useState(false);

    const { updateStepLoading, updateStepFetch, updateStepError } = useApiMethod({
        api: profiles.updateStep,
        successCallback: ({ data }) => {
            setProfile(data);

            if (data.status === ProfileStatusEnum.VERIFIED) {
                openModal(VerificationCompleteModal, 'm')();
            }

            setTimeout(() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }, 100);
        },
    });

    const scrollToError = () => {
        const section = document.querySelector(`.${styles.error}`);

        section.scrollIntoView({ behavior: 'smooth', block: 'start' });

        setTimeout(() => {
            setShaking(true);
            setTimeout(() => setShaking(false), 2000);
        }, 1100);
    };

    if (updateStepLoading) return <PageLoader />;

    if (profile.status === ProfileStatusEnum.VERIFICATION_LOCATIONS) {
        return (
            <VerifyLocations
                loading={ updateStepLoading }
                error={ updateStepError?.message }
                cb={ () => updateStepFetch(ProfileStatusEnum.VERIFICATION_QUESTROOMS) }
                back={ () => updateStepFetch(ProfileStatusEnum.VERIFICATION_PROFILE) }
                shaking={ shaking }
                scrollToError={ scrollToError }
            />
        );
    }

    if (profile.status === ProfileStatusEnum.VERIFICATION_QUESTROOMS) {
        return (
            <VerifyQuestrooms
                error={ updateStepError?.message }
                cb={ () => updateStepFetch(ProfileStatusEnum.VERIFIED) }
                back={ () => updateStepFetch(ProfileStatusEnum.VERIFICATION_LOCATIONS) }
                shaking={ shaking }
                scrollToError={ scrollToError }
            />
        );
    }

    return <OrganizationForm />;
};

// if (!start) {
//     return (
//         <div className={ styles.container }>
//             <FlexColumns columns={ 1 } gr={ 32 }>
//                 <Logo />

//                 <FlexColumns columns={ 1 }>
//                     <Typography.Title tag="div" view="xsmall" weight="bold">
//                         { t('verifyCompany.intro.title') }!
//                     </Typography.Title>

//                     <Typography.Text view="primary-medium">
//                         { t('verifyCompany.intro.description') }
//                     </Typography.Text>

//                     <div>
//                         <Button view="primary" onClick={ () => setStart(true) }>
//                             { t('verifyCompany.intro.button') }
//                         </Button>
//                     </div>
//                 </FlexColumns>
//             </FlexColumns>
//         </div>
//     );
// }

// if (profile.step === ProfileStatusEnum.VERIFICATION_RULES) {
//     return (
//         <VerifyCompanyContainer>
//             <VerifyRules
//                 loading={ updateStepLoading }
//                 error={ updateStepError?.message }
//                 cb={ () => updateProfileStep(ProfileStatusEnum.VERIFICATION_LOCATIONS) }
//                 back={ () => updateProfileStep(ProfileStatusEnum.VERIFICATION_PROFILE) }
//             />
//         </VerifyCompanyContainer>
//     );
// }
