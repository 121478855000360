import React from 'react';
import {
    FlexColumns, Input, Textarea, useCrudFormRequests, useGetOne,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { CreatePartnerDto } from '@escapenavigator/types/dist/partner/create-partner.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

type Props = {
    recordId?: number;
    close: () => void;
    cb: () => void;
    t: TFunction;
};

export const PartnerModal: React.FC<Props> = ({
    recordId, close, t, cb,
}) => {
    const { partners } = useApi();

    const { record, loading, error } = useGetOne(recordId, partners.getOne);

    const {
        save,
        remove,
        removing,
        updating,
        error: softError,
    } = useCrudFormRequests({
        recordId,
        removeRequest: partners.remove,
        saveRequest: recordId ? partners.update : partners.create,
        saveCallback: cb,
        removeCallback: cb,
        close,
    });

    return (
        <RestForm
            recordId={ recordId }
            title={ record?.title || t('modalsHeader.partner') }
            initialValues={ serializeRecord(CreatePartnerDto, record) }
            validate={ validateByDto(t) }
            save={ save }
            remove={ remove }
            error={ error }
            softError={ softError }
            loading={ loading }
            updating={ updating || removing }
            close={ close }
            t={ t }
        >
            { ({
                values, handleChange, errors, touched,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    <Input
                        dataTestId="title"
                        label={ t('tables.title') }
                        value={ values.title }
                        error={ touched.title && errors.title }
                        onChange={ handleChange('title') }
                        block={ true }
                        required={ true }
                    />
                    <Input
                        dataTestId="contact"
                        label={ t('component.contactPerson') }
                        value={ values.contact }
                        onChange={ handleChange('contact') }
                        block={ true }
                    />
                    <Input
                        dataTestId="email"
                        label={ t('email') }
                        value={ values.email }
                        onChange={ handleChange('email') }
                        block={ true }
                    />
                    <Input
                        dataTestId="phone"
                        label={ t('phone') }
                        value={ values.phone }
                        onChange={ handleChange('phone') }
                        block={ true }
                    />
                    <Textarea
                        dataTestId="comment"
                        label={ t('Комментарий') }
                        value={ values.comment }
                        onChange={ handleChange('comment') }
                        block={ true }
                        maxLength={ 200 }
                        counter={ true }
                    />
                </FlexColumns>
            ) }
        </RestForm>
    );
};
