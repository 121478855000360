import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    FlexColumns, Select, THEME, useCrudFormRequests, useTheme,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { languageOptions } from '@escapenavigator/types/dist/constants/language-options';
import { Languages } from '@escapenavigator/types/dist/shared/enum/languages.enum';
import { UpdateCurrentPreferencesDto } from '@escapenavigator/types/dist/user/current/update-current-preferences.dto copy';
import { enumToOptions, serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { upsertUser } from 'src/store';
import { useAppDispatch } from 'src/store/hooks';

type Props = {
    close: () => void;
    t: TFunction;
};

export const CurrentUserPreferencesModal: React.FC<Props> = ({ close, t }) => {
    const { users } = useApi();
    const { i18n } = useTranslation();
    const { setTheme } = useTheme();

    const dispatch = useAppDispatch();

    const { current, setUser } = useCurrentUser();

    const {
        updating,
        error: softError,
        save,
    } = useCrudFormRequests({
        saveRequest: users.currentUpdatePreferences,
        saveCallback: (data) => {
            dispatch(upsertUser(data));
            setUser({ ...current, user: data });
            setTheme(data.theme === 'dark' ? THEME.DARK : THEME.LIGHT);

            i18n.changeLanguage(data.language);
        },
        close,
    });

    return (
        <React.Fragment>
            <RestForm
                title={ t('openCurrentUserPreferencesModal') }
                initialValues={ serializeRecord(UpdateCurrentPreferencesDto, current.user) }
                validate={ validateByDto(t) }
                save={ save }
                close={ close }
                updating={ updating }
                t={ t }
                softError={ softError }
            >
                { ({
                    values, errors, touched, setFieldValue,
                }) => (
                    <React.Fragment>
                        <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                            <Select
                                dataTestId="language"
                                label={ t('component.systemLanguage') }
                                options={ languageOptions([
                                    Languages.EN,
                                    Languages.DE,
                                    Languages.RU,
                                ]) }
                                selected={ values.language }
                                error={ touched.language && errors.language }
                                onChange={ ({ selected }) =>
                                    setFieldValue('language', selected?.key) }
                                block={ true }
                                optionsListWidth="field"
                            />

                            <Select
                                dataTestId="theme"
                                label={ `${t('Тема')} (Soon)` }
                                options={ enumToOptions(THEME) }
                                selected={ values.theme }
                                error={ touched.theme && errors.theme }
                                onChange={ ({ selected }) => setFieldValue('theme', selected?.key) }
                                block={ true }
                                optionsListWidth="field"
                            />
                        </FlexColumns>
                    </React.Fragment>
                ) }
            </RestForm>
        </React.Fragment>
    );
};
