import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronBackS } from '@alphakits/icons';
import { Button, Flex, Image } from '@alphakits/ui';

type Props = {
    onClick: () => void;
};

export const BackButton: React.FC<Props> = ({ onClick }) => {
    const { t } = useTranslation();

    return (
        <Button view="ghost" onClick={ onClick }>
            <Flex gap="sm" justify="start">
                <Image icon={ <ChevronBackS /> } view="circle" size="s" />
                { t('Назад') }
            </Flex>
        </Button>
    );
};
