/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext, Table } from '@alphakits/ui/dist';
import { EmptyState } from 'src/components/empty-state';
import { CertificatesaleModal } from 'src/modals/certificatesale';
import { CertificatesaleNewModal } from 'src/modals/certificatesale-new';
import { CertificatesFiltersModal } from 'src/modals/filters/certificates';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { mapper } from 'src/tags';
import { getLanguage } from 'src/utils/get-language';

import { CertificatesColumns } from './columns';

export const NavigatorCertificates: React.FC = () => {
    const { t, i18n } = useTranslation();

    const { openModal } = useContext(ModalContext);

    const {
        profile: { currency },
    } = useCurrentUser();
    const { certificatesales } = useApi();
    const openFilters = openModal(CertificatesFiltersModal);
    const openNewCertificatesale = openModal(CertificatesaleNewModal);
    const openCertificatesale = openModal(CertificatesaleModal, 'm', true);

    const columns = React.useMemo(
        () => CertificatesColumns({ t, currency, i18n }),
        [t, currency, i18n],
    );

    return (
        <React.Fragment>
            <Table.TableComponent
                filtersButtonLabel={ t('filters.label') }
                tagsBuilder={ mapper(t) }
                onFiltersClick={ ({ submitCallback, initialValues }) =>
                    openFilters({ apply: submitCallback, initialValues: initialValues.where }) }
                onRowClick={ (row, successCallback) =>
                    openCertificatesale({ certificatesaleId: row.id, successCallback }) }
                onCreateClick={ (successCallback) =>
                    openNewCertificatesale({ successCallback, openModal }) }
                columns={ columns }
                searchPlaceholder={ t('tables.certificatesSearch') }
                promise={ certificatesales.query }
                language={ getLanguage(i18n) }
            />
        </React.Fragment>
    );
};
