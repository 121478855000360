import React from 'react';
import { Tag } from '@alphakits/ui';
import { selectCashboxByIdOrIds } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

import { TagProps } from './types';

export const Cashbox: React.FC<TagProps> = ({ value, row, removeParam }) => {
    const cashboxes = useAppSelector(
        selectCashboxByIdOrIds(Array.isArray(value) ? value : [`${value}`]),
    );

    const text = `${cashboxes.map((cashbox) => ` ${cashbox?.title}`)}`;

    return <Tag text={ text } onClick={ () => {} } onCloseClick={ () => removeParam(row) } />;
};
