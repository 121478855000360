import { initClientApi } from '@escapenavigator/services/dist/api/init-client-api';
import { QuestroomRO } from '@escapenavigator/types/dist/questroom/questroom.ro';
import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';

import { RootState } from '../store';

const questroomsAdapter = createEntityAdapter<QuestroomRO>();
const { questrooms } = initClientApi(process.env.REACT_APP_API_DOMAIN);

export const fetchQuestrooms = createAsyncThunk<QuestroomRO[]>(
    'questrooms/fetchQuestrooms',
    async () => {
        const { data } = await questrooms.query(undefined);

        return data;
    },
);

const questroomsSlice = createSlice({
    name: 'questrooms',
    initialState: questroomsAdapter.getInitialState(),
    reducers: {
        upsertQuestroom(state, action: PayloadAction<QuestroomRO>) {
            questroomsAdapter.upsertOne(state, action.payload);
        },
        removeQuestroom(state, action: PayloadAction<QuestroomRO['id']>) {
            questroomsAdapter.removeOne(state, action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchQuestrooms.fulfilled,
            (state, action: PayloadAction<QuestroomRO[]>) => {
                questroomsAdapter.upsertMany(state, action.payload);
            },
        );
    },
});

const { selectAll, selectById } = questroomsAdapter.getSelectors<RootState>(
    (state) => state.questrooms,
);

export const selectAllQuestrooms = ({ deleted, closed }: { deleted?: boolean; closed?: boolean }) =>
    createSelector(
        (state: RootState) => state,
        (state) =>
            selectAll(state)
                .filter((q) => (typeof deleted === 'boolean' ? !!q.deletedAt === deleted : true))
                .filter((q) => (typeof closed === 'boolean' ? q.closed === closed : true)),
    );

export const selectQuestroomById = (id) =>
    createSelector(
        (state: RootState) => state,
        (state) => selectById(state, id) || ({ title: 'Unknown escape room', id } as QuestroomRO),
    );

export const selectQuestroomsByIds = (ids: number[] = []) =>
    createSelector(
        (state: RootState) => state,
        (state) =>
            ids?.map(
                (id) =>
                    selectById(state, id) || ({ title: 'Unknown escape room', id } as QuestroomRO),
            ),
    );

export const selectQuestroomsByLocationId = (locationId: number) =>
    createSelector(
        (state: RootState) => state,
        (state) =>
            selectAll(state).filter(
                (q) => q.locationId === locationId && !q.deletedAt && !q.closed,
            ),
    );

export const { upsertQuestroom, removeQuestroom } = questroomsSlice.actions;

export default questroomsSlice.reducer;
