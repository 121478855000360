/* eslint-disable no-param-reassign */
import { initClientApi } from '@escapenavigator/services/dist/api/init-client-api';
import { AnnouncementRO } from '@escapenavigator/types/dist/dashboard/announcement.ro';
import { UpdateAnnouncementDto } from '@escapenavigator/types/dist/dashboard/update-announcement.dto';
import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';

import { RootState, SliceState } from '../store';

const announcementsAdapter = createEntityAdapter<AnnouncementRO>();

const { dashboard } = initClientApi(process.env.REACT_APP_API_DOMAIN);

export const fetchAnnouncements = createAsyncThunk<AnnouncementRO[]>(
    'announcement/fetchAnnouncements',
    async () => {
        const { data } = await dashboard.getAllUserAnnouncements(undefined);

        return data;
    },
);

export const removeAnnouncement = createAsyncThunk<AnnouncementRO, number>(
    'announcement/removeAnnouncement',
    async (id) => {
        const { data } = await dashboard.removeAnnouncement(id);

        return data;
    },
);

export const updateAnnouncement = createAsyncThunk<
    AnnouncementRO,
    { id: number; data: UpdateAnnouncementDto }
>('announcement/updateAnnouncement', async ({ id, data }) => {
    const res = await dashboard.updateAnnouncement({ id, data });

    return res.data;
});

export const createAnnouncement = createAsyncThunk<AnnouncementRO, { data: UpdateAnnouncementDto }>(
    'announcement/createAnnouncement',
    async ({ data }) => {
        const res = await dashboard.createAnnouncement({ data });

        return res.data;
    },
);

export const viewAnnouncement = createAsyncThunk<
    AnnouncementRO,
    { announceId: number; userId: number }
>('announcement/viewAnnouncement', async ({ announceId, userId }) => {
    const res = await dashboard.viewAnnouncement({ announceId, userId });

    return res.data;
});

const announcementSlice = createSlice({
    name: 'announcement',
    initialState: announcementsAdapter.getInitialState({
        loading: null,
    }),
    reducers: {
        upsertAnnouncement(state, action: PayloadAction<AnnouncementRO>) {
            announcementsAdapter.upsertOne(state, action.payload);
        },
        removeAnnouncement(state, action: PayloadAction<AnnouncementRO['id']>) {
            announcementsAdapter.removeOne(state, action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAnnouncements.pending, (state) => {
            state.loading = SliceState.FETCHING;
        });
        builder.addCase(fetchAnnouncements.rejected, (state) => {
            state.loading = null;
        });
        builder.addCase(
            fetchAnnouncements.fulfilled,
            (state, action: PayloadAction<AnnouncementRO[]>) => {
                announcementsAdapter.upsertMany(state, action.payload);
                state.loading = null;
            },
        );

        builder.addCase(removeAnnouncement.pending, (state) => {
            state.loading = SliceState.REMOVING;
        });
        builder.addCase(removeAnnouncement.rejected, (state) => {
            state.loading = null;
        });
        builder.addCase(
            removeAnnouncement.fulfilled,
            (state, action: PayloadAction<AnnouncementRO>) => {
                announcementsAdapter.removeOne(state, action.payload.id);
                state.loading = null;
            },
        );

        builder.addCase(updateAnnouncement.pending, (state) => {
            state.loading = SliceState.UPDATING;
        });
        builder.addCase(updateAnnouncement.rejected, (state) => {
            state.loading = null;
        });
        builder.addCase(
            updateAnnouncement.fulfilled,
            (state, action: PayloadAction<AnnouncementRO>) => {
                announcementsAdapter.upsertOne(state, action.payload);
                state.loading = null;
            },
        );

        builder.addCase(createAnnouncement.pending, (state) => {
            state.loading = SliceState.UPDATING;
        });
        builder.addCase(createAnnouncement.rejected, (state) => {
            state.loading = null;
        });
        builder.addCase(
            createAnnouncement.fulfilled,
            (state, action: PayloadAction<AnnouncementRO>) => {
                announcementsAdapter.upsertOne(state, action.payload);
                state.loading = null;
            },
        );

        builder.addCase(
            viewAnnouncement.fulfilled,
            (state, action: PayloadAction<AnnouncementRO>) => {
                announcementsAdapter.upsertOne(state, action.payload);
            },
        );
    },
});

const { selectAll, selectById } = announcementsAdapter.getSelectors<RootState>(
    (state) => state.announcements,
);

export const selectAllAnnouncements = selectAll;

export const selectAnnouncementState = () =>
    createSelector(
        (state: RootState) => state,
        (state) => state.announcements.loading,
    );

export const selectAnnouncementById = (id) =>
    createSelector(
        (state: RootState) => state,
        (state) => selectById(state, id),
    );

export const { upsertAnnouncement } = announcementSlice.actions;

export default announcementSlice.reducer;
