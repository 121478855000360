/* eslint-disable complexity */
import React, { useState } from 'react';
import { ChevronForwardM } from '@alphakits/icons';
import {
    Amount,
    Button,
    Checkbox,
    convertToOptions,
    Flex,
    FlexColumns,
    Select,
    Tag,
    Typography,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CreateCertificatesaleDto } from '@escapenavigator/types/dist/certificate-sale/create-certificatesale.dto';
import { CertificateRO } from '@escapenavigator/types/dist/certificate/certificate.ro';
import { ClientRO } from '@escapenavigator/types/dist/client/client.ro';
import {
    convertDateToZonedDate,
    serializeRecord,
    validateByDto,
} from '@escapenavigator/utils/dist';
import { getFullName } from '@escapenavigator/utils/dist/get-full-name';
import { TFunction } from 'i18next';
import { CertificatesaleDeliveryInfo } from 'src/components/certificate-delivery-info';
import { ClientSearch } from 'src/components/client-search';
import { CashboxesSelect } from 'src/components/selects/cashbox-select';
import { TextCell } from 'src/components/text-cell';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { selectAllCertificates } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

import { CertificatesaleModal } from '../certificatesale';
import { ClientModal } from '../client';

type Props = {
    close: () => void;
    t: TFunction;
    successCallback?: () => void;
    openModal: any;
};

export const CertificatesaleNewModal: React.FC<Props> = ({
    close,
    t,
    successCallback,
    openModal,
}) => {
    const { certificatesales } = useApi();

    const [client, setClient] = useState<ClientRO>();
    const {
        profile: { currency },
    } = useCurrentUser();
    const certificates = useAppSelector(selectAllCertificates);

    const { createError, createFetch, createLoading } = useApiMethod({
        api: certificatesales.create,
        successCallback: ({ data }) => {
            if (successCallback) successCallback();
            close();
            openModal(CertificatesaleModal, 'm', true)({ certificatesaleId: data.id });
        },
    });

    const [paid, setPaid] = useState(false);

    const handleOpenClientModal = () => {
        openModal(ClientModal, 'm', true)({ recordId: client.id, cb: setClient });
    };

    return (
        <RestForm
            title={ t('Оформление нового сертификата') }
            initialValues={ serializeRecord(CreateCertificatesaleDto, {}) }
            validate={ validateByDto(t) }
            save={ ({ certificate, sameRecipient, ...data }) => {
                const sendingEmailUtcDate = data.sendAfterPayment
                    ? null
                    : convertDateToZonedDate({
                        date: data.sendingDate,
                        time: data.sendingTime,
                        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    }).toISOString();

                createFetch({
                    data: { ...data, sendingEmailUtcDate },
                });
            } }
            updating={ createLoading }
            softError={ createError?.message }
            close={ close }
            t={ t }
            submitButtonText={ t('Оформить') }
        >
            { ({
                values, handleChange, setFieldValue, errors, touched,
            }) => {
                const certificate = values.certificate as unknown as CertificateRO;

                return (
                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                        <TextCell
                            view="header"
                            onClick={ client ? handleOpenClientModal : undefined }
                            title={ client ? getFullName(client) : t('tables.client') }
                            subtitle={ client?.phone }
                            rightAddons={ client && <ChevronForwardM /> }
                        />

                        { client && (
                            <React.Fragment>
                                <TextCell title="Email" subtitle={ client.email } />

                                <div>
                                    <Button
                                        size="xs"
                                        onClick={ () => {
                                            setFieldValue('clientId', undefined);
                                            setClient(undefined);
                                        } }
                                        view="outlined"
                                    >
                                        { t('Отменить выбор клиента') }
                                    </Button>
                                </div>
                            </React.Fragment>
                        ) }

                        { !client && (
                            <ClientSearch
                                t={ t }
                                error={ touched.clientId && errors.clientId }
                                onSelect={ (v) => {
                                    setFieldValue('clientId', v.id);
                                    setClient(v);
                                } }
                            />
                        ) }
                        { client && (
                            <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                                <Select
                                    dataTestId="certifiactes"
                                    label={ t('tables.certificate') }
                                    options={ convertToOptions(certificates) }
                                    selected={ values.certificateId }
                                    onChange={ ({ selected }) => {
                                        const certifiacte = certificates.filter(
                                            (c) => +selected?.key === c.id,
                                        )[0];

                                        setFieldValue('certificateId', selected?.key);
                                        setFieldValue('deliveryType', null);
                                        setFieldValue('certificate', certifiacte);
                                    } }
                                    error={ touched.certificateId && errors.certificateId }
                                    required={ true }
                                    block={ true }
                                />

                                { certificate && (
                                    <React.Fragment>
                                        <Flex justify="start" gap="sm" wrap={ true }>
                                            { certificate.nominals.map((n) => (
                                                <Tag
                                                    onClick={ () => setFieldValue('nominal', n) }
                                                    view={
                                                        values.nominal === n
                                                            ? 'primary-inverted'
                                                            : 'primary'
                                                    }
                                                    text={
                                                        (
                                                            <Amount
                                                                weight="bold"
                                                                value={ n }
                                                                type="decimal"
                                                                currency={ currency }
                                                            />
                                                        ) as any as string
                                                    }
                                                />
                                            )) }
                                        </Flex>

                                        { touched.nominal && errors.nominal && (
                                            <Typography.Text view="title" color="negative">
                                                { errors.nominal }
                                            </Typography.Text>
                                        ) }

                                        <CertificatesaleDeliveryInfo
                                            setFieldValue={ setFieldValue }
                                            handleChange={ handleChange }
                                            selectedCertificate={ certificate }
                                            values={ values }
                                            client={ client }
                                            errors={ errors }
                                            touched={ touched }
                                        />

                                        <Checkbox
                                            label={ t('Сертификат уже оплачен') }
                                            checked={ paid }
                                            onChange={ (e, { checked }) => {
                                                if (!checked) setFieldValue('cashboxId', undefined);
                                                setPaid(checked);
                                            } }
                                            block={ true }
                                        />

                                        { paid && (
                                            <CashboxesSelect
                                                popoverPosition="top"
                                                allowUnselect={ true }
                                                label={ t('component.cashbox') }
                                                type="not_online"
                                                selected={ values.cashboxId }
                                                error={ touched.cashboxId && errors.cashboxId }
                                                onChange={ ({ selected }) =>
                                                    setFieldValue('cashboxId', selected?.key) }
                                            />
                                        ) }
                                    </React.Fragment>
                                ) }
                            </FlexColumns>
                        ) }
                        <br />
                    </FlexColumns>
                );
            } }
        </RestForm>
    );
};
