import React from 'react';
import { TrashS } from '@alphakits/icons';
import { Button } from '@alphakits/ui/dist';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { ProfileApiKeyRO } from '@escapenavigator/types/dist/profile/api-key/profile-api-key.ro';
import { format } from 'date-fns';
import { TFunction } from 'i18next';

type Props = {
    t: TFunction;
    onRemove: (id: number) => void;
};

const Remove = ({ onRemove }: { onRemove: () => void }) => (
    <Button
        onClick={ (e) => {
            e.stopPropagation();
            onRemove();
        } }
        view="outlined"
        size="xs"
        leftAddons={ <TrashS /> }
    />
);

export const Columns = ({ t, onRemove }: Props): TableColumns<ProfileApiKeyRO> => [
    {
        header: t('Дата создания'),
        accessor: 'createdAt',
        row: {
            title: ({ row }) => format(new Date(row.createdAt), 'dd.MM.yyyy'),
        },
    },

    {
        header: 'Api Key',
        accessor: 'key',
    },

    {
        header: '',
        accessor: 'id',
        width: '100px',
        row: {
            title: ({ row }) => <Remove onRemove={ () => onRemove(row.id) } />,
        },
    },
];
