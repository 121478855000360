import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectProps } from '@alphakits/ui/dist';
import { CashboxRO, CashboxTypeEnum } from '@escapenavigator/types';
import { SelectSearch } from 'src/components/select-search';
import { useCurrentUser } from 'src/providers/current-user/context';
import { selectAllCashboxes, selectAllLocations } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

type Props = {
    locationId?: number;
    type: 'all' | 'not_online' | CashboxTypeEnum;
} & Pick<
    SelectProps,
    | 'selected'
    | 'disabled'
    | 'label'
    | 'onChange'
    | 'hint'
    | 'error'
    | 'multiple'
    | 'allowUnselect'
    | 'popoverPosition'
>;

export const CashboxesSelect: React.FC<Props> = ({
    disabled,
    type,
    locationId,
    onChange,
    multiple,
    label,
    hint,
    selected,
    allowUnselect,
    popoverPosition,
    error,
}) => {
    const { t } = useTranslation();
    const {
        current: { user, mode },
    } = useCurrentUser();

    const cashboxes = useAppSelector(selectAllCashboxes);
    const locations = useAppSelector(selectAllLocations);

    const getTitle = (key: string) => {
        const keys = {
            current: t('Текущая локация'),
            allLocations: t('Другие способы оплаты'),
        };

        return keys[key] || key;
    };

    const casbhoxesList = useMemo(() => {
        const hasAccess = (location: number) => {
            if (user.allLocations || mode === 'admin') return true;

            return user.locationIds?.includes(location);
        };

        const getTitle = (c: CashboxRO) => {
            if (c.allLocations) return 'allLocations';

            if (!c.locationIds.some(hasAccess)) return null;

            if (c.locationIds?.includes(locationId)) return 'current';

            if (c.locationIds?.length > 1) return 'allLocations';

            if (c.locationIds?.length === 1) return locations.find((l) => l.id === c.locationIds[0])?.title || 'allLocations';

            return null;
        };

        const filtered = cashboxes.filter((c) => {
            if (type === 'all') return true;
            if (type === 'not_online') return c.type !== CashboxTypeEnum.PAYPAL && c.type !== CashboxTypeEnum.STRIPE;

            return c.type === type;
        });

        const { current, allLocations, ...other } = filtered.reduce((acc, c) => {
            const title = getTitle(c);

            console.log(title, c);

            if (title) {
                acc[title] = acc[title] || {
                    label: title,
                    options: [],
                };
                acc[title].options.push({
                    key: c.id,
                    value: c.title,
                    content: c.title,
                });
            }

            return acc;
        }, {} as Record<string, { label: string; options: Array<{ key: number; content: string; value: string }> }>);

        return locationId
            ? [current, allLocations, ...Object.values(other)].filter((l) => !!l)
            : [current, ...Object.values(other), allLocations].filter((l) => !!l);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationId, type, cashboxes, locations, user]);

    return (
        <SelectSearch
            disabled={ disabled }
            popoverPosition={ popoverPosition }
            label={ label }
            allowUnselect={ allowUnselect }
            showEmptyOptionsList={ true }
            multiple={ multiple }
            options={ casbhoxesList.map((l) => ({
                label: getTitle(l.label),
                key: l.label,
                options: l.options,
            })) }
            onChange={ onChange }
            selected={ selected }
            hint={ hint }
            error={ error }
            block={ true }
        />
    );
};
