import React from 'react';

export const Fire = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.7781 8.4288C16.6733 6.30481 14.8974 4.35093 13.3377 2.63126C13.2079 2.50396 13.0772 2.37667 12.9256 2.29124C12.1675 1.78121 11.9724 1.90934 11.7563 2.92856C11.3232 4.86109 10.3264 7.81275 8.98356 9.23617C8.85373 9.36347 8.74567 9.49077 8.61499 9.61807C8.41981 9.34212 8.05209 8.59883 8.0303 7.81286C7.96496 6.5382 7.66172 6.98499 7.12061 7.55826C5.95125 8.81156 4.86815 10.3399 4.41332 11.9547C3.74153 14.1632 3.95848 16.6057 4.56494 18.3264C4.56494 18.3478 4.58671 18.3691 4.58671 18.411C4.58671 18.4323 4.60849 18.4537 4.60849 18.4751C4.63027 18.5178 4.63027 18.5391 4.65205 18.581C5.02063 19.537 5.88674 20.4708 6.7311 21.214C7.09967 21.554 8.31258 21.9573 8.91901 22C8.50772 21.49 8.31256 21.0867 8.29077 20.6835C8.26899 20.4075 8.26899 20.1315 8.26899 19.8761C8.37705 18.8996 8.96174 18.2409 10.4569 17.6676C12.3634 17.1789 13.7706 15.6078 13.7706 13.7384C13.7706 13.2497 13.6844 12.8038 13.511 12.3792C14.1828 13.0584 14.5941 13.8871 14.8537 14.8004C15.3521 16.5416 15.3521 17.4334 15.0489 19.1968C14.7239 20.4288 14.269 20.8107 14.0529 21.9999C14.2263 21.9999 14.4424 21.9572 14.6376 21.894C17.8433 21.1507 19.5328 18.6449 19.9225 16.0965C20.3338 13.3985 19.0346 10.8501 17.7781 8.4288Z"
            fill="url(#paint0_linear_178_2167)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_178_2167"
                x1="12"
                y1="2"
                x2="12"
                y2="22"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#1189FA" />
                <stop offset="1" stopColor="#B65EF7" />
            </linearGradient>
        </defs>
    </svg>
);
