import React from 'react';
import {
    Button, Flex, FlexColumns, Typography,
} from '@alphakits/ui/dist';

import styles from './index.module.css';

type Props = {
    title: string;
    view?: 'header' | 'default';
    subtitle?: string;
    rightAddons?: React.ReactNode;
    onClick?: () => void;
};

type Container = Pick<Props, 'onClick'>;

const Container: React.FC<Container> = ({ onClick, children }) => {
    if (onClick) {
        return (
            <Button onClick={ onClick } className={ styles.container } view="ghost">
                { children }
            </Button>
        );
    }

    return <div className={ styles.container }>{ children }</div>;
};

export const TextCell: React.FC<Props> = ({
    title,
    subtitle,
    rightAddons,
    view = 'default',
    onClick,
}) => (
    <Container onClick={ onClick }>
        <Flex gap="md">
            <FlexColumns columns={ 1 } gr={ view === 'default' ? 2 : 4 }>
                { view === 'default' ? (
                    <Typography.Text view="caps" color="secondary">
                        { title }
                    </Typography.Text>
                ) : (
                    <Typography.Title tag="div" view="xsmall" color="primary" weight="bold">
                        { title }
                    </Typography.Title>
                ) }

                { subtitle && (
                    <Typography.Text view="title" color="primary">
                        { subtitle }
                    </Typography.Text>
                ) }
            </FlexColumns>

            { rightAddons }
        </Flex>
    </Container>
);
