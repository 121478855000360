import React from 'react';
import { Typography } from '@alphakits/ui';

import styles from './index.module.css';

interface MenuButtonProps {
    leftAddons?: React.ReactNode;
    rightAddons?: React.ReactNode;
}

export const Base: React.FC<MenuButtonProps> = ({ children, leftAddons, rightAddons }) => (
    <React.Fragment>
        <div className={ styles.leftSide }>
            { leftAddons }
            <Typography.Text weight="medium" view="title">
                { children }
            </Typography.Text>
        </div>

        { rightAddons && (
            <div className={ styles.rightSide }>
                <div className={ styles.rightAddons }>{ rightAddons }</div>
            </div>
        ) }
    </React.Fragment>
);
