import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { defaultThemes, THEME, ThemeProvider } from '@alphakits/ui';
import * as Sentry from '@sentry/react';

import { LayoutLoader } from './components/layout/layout-loader';
import { store } from './store/store';
import App from './app';

import './index.css';
import '@alphakits/ui/dist/index.css';

import './locales/i18n';
import 'core-js/proposals/reflect-metadata';

Sentry.init({
    dsn: 'https://8c69e15ae48ba6bb71e675350f406802@o4506035965853696.ingest.sentry.io/4506035967492096',
    integrations: [
        new Sentry.BrowserTracing({
            tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
        }),
        new Sentry.Replay(),
    ],
    enabled: process.env.NODE_ENV === 'production',
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

ReactDOM.render(
    <ThemeProvider themes={ defaultThemes } selectedTheme={ THEME.LIGHT }>
        <Provider store={ store }>
            <Suspense fallback={ <LayoutLoader /> }>
                <App />
            </Suspense>
        </Provider>
    </ThemeProvider>,
    // </React.StrictMode>,
    document.getElementById('root'),
);
