import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    CalendarInput, FlexColumns, OptionsType, Select, Typography,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { TransactionTypeEnum } from '@escapenavigator/types/dist/transaction/enum/transaction-type.enum';
import { QueryTransactionDto } from '@escapenavigator/types/dist/transaction/query-transaction.dto';
import { serializeRecord } from '@escapenavigator/utils/dist';
import { enumToOptions } from '@escapenavigator/utils/dist/enum-to-options';
import { FormikProps, FormikValues } from 'formik';
import { ExportAndCount } from 'src/components/export-and-count';
import { SelectSearch } from 'src/components/select-search';
import { CashboxesSelect } from 'src/components/selects/cashbox-select';
import { useApi } from 'src/providers/api/context';
import { downloadBlob } from 'src/utils/download-blob';

type Props = {
    apply: (values: FormikValues) => void;
    initialValues: Partial<QueryTransactionDto>;
    close: () => void;
    userOptions: OptionsType<number>;
    financeitemOptions: OptionsType<number>;
};

const Form = ({
    props: { values, setFieldValue },
    close,
    userOptions,
    financeitemOptions,
}: { props: FormikProps<QueryTransactionDto> } & Pick<
    Props,
    'close' | 'financeitemOptions' | 'userOptions'
>) => {
    const { t, i18n } = useTranslation();
    const { transactions } = useApi();

    const { exportTransactionsFetch, exportTransactionsLoading } = useApiMethod({
        api: transactions.exportTransactions,
        successCallback: ({ data, headers }) => {
            downloadBlob(data, headers);
            close();
        },
    });

    const { countData, countLoading, countFetch } = useApiMethod({
        api: transactions.count,
    });

    useEffect(() => {
        countFetch(values);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    return (
        <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
            <FlexColumns columns={ 1 } gr={ 8 } gc={ 0 }>
                <Typography.Text view="title" weight="medium">
                    { t('Дата создания') }
                </Typography.Text>
                <FlexColumns columns={ 2 } gr={ 16 } gc={ 16 }>
                    <CalendarInput
                        dataTestId="created.0"
                        label={ t('from') }
                        value={ values.created[0] }
                        clear={ true }
                        onClear={ () => {
                            setFieldValue('created.0', '');
                        } }
                        onChange={ (e, { value }) => {
                            setFieldValue('created.0', value);
                        } }
                        block={ true }
                        lang={ i18n.language }
                    />
                    <CalendarInput
                        dataTestId="created.1"
                        label={ t('to') }
                        clear={ true }
                        onClear={ () => {
                            setFieldValue('created.0', '');
                        } }
                        value={ values.created[1] }
                        onChange={ (e, { value }) => {
                            setFieldValue('created.1', value);
                        } }
                        block={ true }
                        lang={ i18n.language }
                    />
                </FlexColumns>
            </FlexColumns>

            <Select
                dataTestId="type"
                allowUnselect={ true }
                block={ true }
                optionsListWidth="field"
                label={ t('options.transactionType.title') }
                options={ enumToOptions(TransactionTypeEnum, t, 'transactionType') }
                selected={ values.transactionType }
                onChange={ ({ selected }) => setFieldValue('transactionType', selected?.key) }
            />
            <CashboxesSelect
                allowUnselect={ true }
                label={ t('component.cashbox') }
                selected={ values.cashboxId }
                onChange={ ({ selected }) => setFieldValue('cashboxId', selected?.key) }
                type="all"
            />
            <Select
                dataTestId="financeitemId"
                block={ true }
                optionsListWidth="field"
                allowUnselect={ true }
                label={ t('financeitemId') }
                options={ financeitemOptions }
                selected={ values.financeitemId }
                onChange={ ({ selected }) => setFieldValue('financeitemId', selected?.key) }
            />
            <SelectSearch
                dataTestId="userId"
                block={ true }
                optionsListWidth="field"
                allowUnselect={ true }
                label={ t('author') }
                options={ userOptions }
                selected={ values.userId }
                onChange={ ({ selected }) => setFieldValue('userId', selected?.key) }
            />

            <ExportAndCount
                loading={ countLoading }
                exportLoading={ exportTransactionsLoading }
                exportFetch={ () => exportTransactionsFetch(values) }
                count={ countData }
            />
        </FlexColumns>
    );
};

export const TransactionsFiltersModal: React.FC<Props> = ({
    initialValues,
    close,
    apply,
    ...rest
}) => {
    const { t } = useTranslation();

    return (
        <RestForm
            save={ (values) => {
                apply(values);
                close();
            } }
            title={ t('filters.label') }
            submitButtonText={ t('Применить') }
            initialValues={ serializeRecord(QueryTransactionDto, initialValues || {}) }
            close={ close }
            t={ t }
        >
            { (props: FormikProps<QueryTransactionDto>) => (
                <Form props={ props } close={ close } { ...rest } />
            ) }
        </RestForm>
    );
};
