import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
    CheckmarkS, InfoMarkM, PauseM, PlayM,
} from '@alphakits/icons';
import {
    Button,
    Flex,
    FlexColumns,
    ModalContext,
    PageWithControls,
    showError,
    Status,
    Typography,
} from '@alphakits/ui';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { PartnerProgramEnum } from '@escapenavigator/types/dist/profile/enum/partner-program.enum';
import { ProfileSubscriptionTypeEnum } from '@escapenavigator/types/dist/profile/enum/profile-subscription-type.enum';
import { Section } from 'src/components/layout/section';
import { SubscriptionCards } from 'src/modals/change-subscription-plan/subscription-cards';
import { SuccessSubscribe } from 'src/modals/change-subscription-plan/success';
import { TabPanel, Tabs } from 'src/modals/components/tabs';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';

import { Bookings } from './components/bookings';
import { OtherCrmCard } from './components/other-crm-card';
import { ReviewsSocial } from './components/reviews';
import { useProgramStatus } from './hooks/use-program-status';

import styles from './index.module.css';

export const PartnersProgramm: React.FC = () => {
    const { profile, setProfile } = useCurrentUser();
    const { openModal } = useContext(ModalContext);
    const { profiles } = useApi();
    const { t } = useTranslation();

    const programmTitle =
        profile.subscriptionType === ProfileSubscriptionTypeEnum.NONE
            ? t('Интеграция c', { value: profile.crm })
            : t('Статус программы');

    const { updatePartnerProgramFetch, updatePartnerProgramLoading } = useApiMethod({
        api: profiles.updatePartnerProgram,
        successCallback: ({ data }) => setProfile(data),
        errorCallback: ({ message }) => showError(message),
    });

    const cb = () => {
        updatePartnerProgramFetch(PartnerProgramEnum.ACTIVE);
        openModal(SuccessSubscribe)();
    };

    const status = useProgramStatus({
        updateProgram: updatePartnerProgramFetch,
        loading: updatePartnerProgramLoading,
        profile,
    });

    const paused = profile.partnerProgram === PartnerProgramEnum.PAUSED;
    const activeteText = paused ? t('Возобновить') : t('Приостановить');
    const activeteIcon = paused ? <PlayM /> : <PauseM />;
    const activeteAction = paused
        ? () => updatePartnerProgramFetch(PartnerProgramEnum.ACTIVE)
        : () => updatePartnerProgramFetch(PartnerProgramEnum.PAUSED);

    if (status) {
        return (
            <FlexColumns columns={ 1 } gr={ 32 }>
                <Section
                    title={ programmTitle }
                    initial={ true }
                    buttonLabel={ status.buttonLabel }
                    buttonLoading={ status.buttonLoading }
                    onClick={ status.onClick }
                >
                    <PageWithControls
                        buttons={ [
                            {
                                text: activeteText,
                                leftAddons: activeteIcon,
                                loading: updatePartnerProgramLoading,
                                onClick: activeteAction,
                            },
                            {
                                text: t('Условия партнерства'),
                                leftAddons: <InfoMarkM />,
                                href: 'https://orders.escapenavigator.com?type=agreement&country=de',
                            },
                        ] }
                    >
                        <FlexColumns columns={ 1 } gr={ 32 }>
                            <FlexColumns columns={ 1 } gr={ 8 }>
                                <div>
                                    <Status view="soft" size="m" color={ status.color }>
                                        { status.icon } { status.title }
                                    </Status>
                                </div>
                                <Typography.Text view="title" weight="medium" color="secondary">
                                    { status.subtitle }
                                </Typography.Text>
                            </FlexColumns>

                            { /* { status.color === 'green' && (
                                <FlexColumns columns={ 1 } gr={ 16 }>
                                    <Typography.Title
                                        tag="div"
                                        view="xxsmall"
                                        weight="bold"
                                        color="primary"
                                    >
                                        Our partners
                                    </Typography.Title>
                                    <Switch
                                        disabled={ updateCurrentLoading }
                                        label="NowEscape integration"
                                        onChange={ (_, value) => {
                                            updateCurrentFetch({
                                                data: {
                                                    ...serializeRecord(UpdateCurrentDto, profile),
                                                    nowEscape: value.checked,
                                                },
                                            });
                                        } }
                                        checked={ profile.nowEscape }
                                    />
                                </FlexColumns>
                            ) } */ }
                        </FlexColumns>
                    </PageWithControls>
                </Section>

                <Section title={ t('Привлеченные клиенты') }>
                    <Tabs defaultActive={ t('menu./games-history') }>
                        <TabPanel name={ t('menu./games-history') }>
                            <Bookings />
                        </TabPanel>

                        <TabPanel name={ `${t('Отзывы')} Escape Navigator` }>
                            <ReviewsSocial />
                        </TabPanel>
                    </Tabs>
                </Section>
            </FlexColumns>
        );
    }

    return (
        <FlexColumns columns={ 1 } gr={ 24 }>
            <div>
                <div className={ styles.container }>
                    <Typography.Text view="title" color="primary">
                        <FlexColumns columns={ 1 } gr={ 12 }>
                            { t('pertnerDescription.one') }

                            <FlexColumns columns={ 1 } gr={ 4 }>
                                <Flex gap="xs" justify="start" align="start">
                                    <CheckmarkS color="var(--color-bg-positive)" />
                                    { t('Разместим в самых горячих подборках') }
                                </Flex>
                                <Flex gap="xs" justify="start" align="start">
                                    <CheckmarkS color="var(--color-bg-positive)" />
                                    { t('Будем показывать первыми в поисковой выдаче') }
                                </Flex>
                                <Flex gap="xs" justify="start" align="start">
                                    <CheckmarkS color="var(--color-bg-positive)" />
                                    { t('Добавим на карточки квестов акцентные лейблы.') }
                                </Flex>
                            </FlexColumns>

                            { profile.subscriptionType === ProfileSubscriptionTypeEnum.NONE
                                ? t('pertnerDescription.two')
                                : t('pertnerDescription.two_crm', { value: '8%' }) }

                            <div>
                                <Button
                                    size="xs"
                                    view="link"
                                    nowrap={ true }
                                    href="https://orders.escapenavigator.com?type=partner_programm&country=de"
                                    target="_blank"
                                >
                                    { t('Подключаясь вы принимаете') }
                                </Button>
                            </div>
                        </FlexColumns>
                    </Typography.Text>
                </div>
            </div>

            { profile.subscriptionType === ProfileSubscriptionTypeEnum.NONE ? (
                <FlexColumns columns={ 3 }>
                    <OtherCrmCard
                        profile={ profile }
                        onClick={ updatePartnerProgramFetch }
                        loading={ updatePartnerProgramLoading }
                    />

                    <SubscriptionCards cb={ cb } />
                </FlexColumns>
            ) : (
                <div>
                    <Button
                        onClick={ () => updatePartnerProgramFetch(PartnerProgramEnum.ACTIVE) }
                        loading={ updatePartnerProgramLoading }
                        view="primary"
                        size="s"
                    >
                        { t('Подключиться к программе') }
                    </Button>
                </div>
            ) }
        </FlexColumns>
    );
};
