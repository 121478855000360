import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Amount,
    Button,
    Flex,
    FlexColumns,
    ModalContext,
    showError,
    showSuccess,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CertificateSaleRO } from '@escapenavigator/types/dist/certificate-sale/certificate-sale.ro';
import { CertificatesaleDeliveryTypeEnum } from '@escapenavigator/types/dist/certificate-sale/enum/certificatesales-delivery-type.enum';
import { FormFooter } from 'src/components/form-footer';
import { Picker } from 'src/components/picker';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { CertificateData } from 'src/utils/get-certificate-data';

import { Transaction } from './sub-modals/transaction';
import { Refund, TrackingInfo } from './sub-modals';

type Props = {
    certificatesale: CertificateSaleRO;
    setCertificateSale: (values: CertificateSaleRO) => void;

    ship: () => void;
    close: () => void;
    certifiateData: CertificateData;
};

export const CertificatesaleModalFooter: React.FC<Props> = ({
    certifiateData,
    certificatesale,
    setCertificateSale,
    ship,

    close,
}) => {
    const { openModal } = useContext(ModalContext);
    const { t } = useTranslation();
    const { certificatesales } = useApi();
    const {
        profile: { currency },
    } = useCurrentUser();

    const { resendFetch, resendLoading } = useApiMethod({
        api: certificatesales.resend,
        errorCallback: ({ message }) => showError(message),
        successCallback: () => showSuccess('Email sent'),
    });

    const openTrackingInfoModal = () =>
        openModal(TrackingInfo)({
            certificatesale,
            setCertificateSale,
        });

    const pasymentMethods = () => ({
        [t('resendPaymentLink')]: () =>
            resendFetch({ id: `${certificatesale.id}`, type: 'client' }),

        [t('openAddTransactionToOrderModal')]: () =>
            openModal(Transaction)({
                currency,
                certificatesaleId: certificatesale.id,
                setCerificate: setCertificateSale,
                amount: certificatesale.toPay,
            }),
    });

    const isPostDelivery = certificatesale.deliveryType === CertificatesaleDeliveryTypeEnum.POST;

    return (
        <FormFooter>
            <FlexColumns columns={ 1 } gr={ 12 }>
                { !certificatesale.annul && certificatesale.toPay > 0 && (
                    <Picker
                        t={ t }
                        block={ true }
                        position="top"
                        actions={ pasymentMethods() }
                        content={ (
                            <Button loading={ resendLoading } block={ true } size="s" view="primary">
                                <React.Fragment>
                                    { t('Внести оплату') }{ ' ' }
                                    <Amount
                                        type="decimal"
                                        currencyOpacity={ false }
                                        weight="bold"
                                        currency={ currency }
                                        value={ certificatesale.toPay }
                                    />
                                </React.Fragment>
                            </Button>
                        ) }
                    />
                ) }

                { !certificatesale.annul &&
                    certificatesale.toPay <= 0 &&
                    certifiateData.type === 'not_sended' && (
                    <Button
                        block={ true }
                        size="s"
                        onClick={ () => (isPostDelivery ? openTrackingInfoModal() : ship()) }
                        view="primary"
                    >
                        { t('certificatesale.send') }
                    </Button>
                ) }

                { certificatesale.toPay < 0 && (
                    <Button
                        block={ true }
                        size="s"
                        onClick={ () =>
                            openModal(Refund)({
                                currency,
                                certificatesaleId: certificatesale.id,
                                setCerificate: setCertificateSale,
                                amount: certificatesale.toPay * -1,
                            }) }
                        view="primary"
                    >
                        { t('openRefundModal') }
                    </Button>
                ) }

                { !certificatesale.annul && certifiateData.type !== 'notPayed' && (
                    <Flex gap="md">
                        <Button
                            block={ true }
                            size="s"
                            loading={ resendLoading }
                            onClick={ () =>
                                resendFetch({
                                    id: `${certificatesale.id}`,
                                    type: 'client',
                                }) }
                            view="outlined"
                        >
                            { `${t('resend')}` }
                        </Button>

                        { certificatesale.deliveryType === CertificatesaleDeliveryTypeEnum.EMAIL &&
                            certificatesale.client.email !== certificatesale.deliveryEmail && (
                            <Button
                                block={ true }
                                size="s"
                                loading={ resendLoading }
                                onClick={ () =>
                                    resendFetch({
                                        id: `${certificatesale.id}`,
                                        type: 'celebrate',
                                    }) }
                                view="outlined"
                            >
                                { `${t('resendCelebrate')}` }
                            </Button>
                        ) }
                    </Flex>
                ) }

                <Button block={ true } size="s" onClick={ close } view="outlined">
                    { `${t('Готово')}` }
                </Button>
            </FlexColumns>
        </FormFooter>
    );
};
