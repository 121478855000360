export const ROUTE_DASHBOARD = '/dashboard';
export const ROUTE_CALENDAR = '/';
export const ROUTE_GAMES_HISTORY = '/games-history';
export const ROUTE_CLIENTS = '/clients';

export const ROUTE_CERTIFICATE_SALES = '/marketing/certificate-sales';
export const ROUTE_PROMOCODES = '/marketing/promocodes';
export const ROUTE_UPSELLINGS = '/marketing/upsellings';

export const ROUTE_CROSS_SALES = '/marketing/cross-sales';

export const ROUTE_QUESTROOMS = '/settings/questrooms';
export const ROUTE_WIDGETS = '/settings/widgets';
export const ROUTE_IMPORT = '/settings/import';
export const ROUTE_WAIVER = '/settings/waiver';
export const ROUTE_API_KEYS = '/settings/api-keys';
export const ROUTE_INTEGRATIONS = '/settings/integration';
export const ROUTE_BOOKING_RULES = '/settings/rules';
export const ROUTE_PARTNERS_PROGRAMM = '/settings/partners-programm';
export const ROUTE_SUBSCRIPTION = '/settings/subscription';
export const ROUTE_ORGANIZATION = '/settings/profile';

export const ROUTE_USERS_LIST = '/users/users-list';
export const ROUTE_USERS_SESSIONS = '/users/sessions';
export const ROUTE_USERS_REPORT = '/users/users-report';
export const ROUTE_ROLES = '/users/roles';
export const POSITION_ROLES = '/users/positions';

export const ROUTE_CASHBOXES = '/finance/cashboxes';
export const ROUTE_FINANCEITEMS = '/finance/financeitems';
export const ROUTE_PARTNERS = '/finance/partners';
export const ROUTE_TRANSACTIONS = '/finance/transactions';

export const ROUTE_STATISTICS = '/statistics';

export const ROUTE_FOBIDEN = '/forbiden';
