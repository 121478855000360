import React from 'react';
import { useMatchMedia } from '@alphakits/ui/dist';
import classNames from 'classnames';

import styles from './index.module.css';

export const StepperContainer: React.FC = ({ children }) => {
    const [isDesktop] = useMatchMedia('(min-width: 980px)');

    return (
        <div className={ classNames(styles.container, !isDesktop && styles.mobile) }>{ children }</div>
    );
};
