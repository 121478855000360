import React, {
    useContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronBackThinM, ChevronForwardThinM } from '@alphakits/icons';
import {
    Box,
    Button,
    Flex,
    FlexColumns,
    formatDate,
    Image,
    ModalContext,
    Skeleton,
    Table,
    Typography,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { QueryOrderDto } from '@escapenavigator/types/dist/order/query-order.dto';
import { SourceEnum } from '@escapenavigator/types/dist/shared/source.enum';
import {
    addMonths, endOfMonth, isSameMonth, startOfMonth, subMonths,
} from 'date-fns';
import format from 'date-fns/format';
import { OrderModal } from 'src/modals/order';
import { GameHistoryColumns } from 'src/pages/orders/columns';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { selectAllLocations, selectAllQuestrooms } from 'src/store';
import { useAppSelector } from 'src/store/hooks';
import { getLanguage } from 'src/utils/get-language';

export const Bookings: React.FC = () => {
    const { i18n, t } = useTranslation();
    const {
        profile: { currency },
    } = useCurrentUser();
    const { openModal } = useContext(ModalContext);
    const { orders } = useApi();
    const questrooms = useAppSelector(selectAllQuestrooms({}));
    const locations = useAppSelector(selectAllLocations);

    const columns = React.useMemo(
        () =>
            GameHistoryColumns({
                t,
                questrooms,
                currency,
                locations,
            }),
        [t, questrooms, currency, locations],
    );

    const [query, setQuery] = useState({ date: new Date(), view: 'month' as const });

    const { queryData, queryFetch, queryLoading } = useApiMethod({
        api: orders.query,
    });

    const canShowNextDate = useMemo(() => {
        if (query.view === 'month') {
            return isSameMonth(query.date, new Date());
        }

        return false;
    }, [query]);

    const where: QueryOrderDto = useMemo(
        () => ({
            source: [SourceEnum.AGREGATOR, SourceEnum.nowEscape],
            created: [
                format(startOfMonth(query.date), 'yyyy-MM-dd'),
                format(endOfMonth(query.date), 'yyyy-MM-dd'),
            ],
        }),
        [query],
    );

    useEffect(() => {
        queryFetch({
            where,
            limit: 1000,
            page: 1,
            sort: 'createdAt',
            order: 'DESC',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [where]);

    const next = () => {
        if (query.view) {
            setQuery({ ...query, date: addMonths(query.date, 1) });
        }
    };

    const prev = () => {
        if (query.view) {
            setQuery({ ...query, date: subMonths(query.date, 1) });
        }
    };

    return (
        <FlexColumns columns={ 1 }>
            <Box background="var(--color-bg-secondary)" rounded="xs" padding="l">
                <FlexColumns columns={ 1 } gr={ 8 }>
                    <Flex>
                        <Typography.Title tag="div" view="xsmall" weight="bold" color="primary">
                            { formatDate(query.date, { format: 'LLLL', lang: i18n.language }) }
                        </Typography.Title>
                        <Flex gap="sm" justify="end">
                            <Button onClick={ prev } view="ghost">
                                <Image
                                    bgColor="primary"
                                    size="s"
                                    icon={ <ChevronBackThinM /> }
                                    view="circle"
                                />
                            </Button>

                            <Button onClick={ next } view="ghost" disabled={ canShowNextDate }>
                                <Image
                                    size="s"
                                    bgColor={ canShowNextDate ? 'secondary' : 'primary' }
                                    icon={ <ChevronForwardThinM /> }
                                    view="circle"
                                />
                            </Button>
                        </Flex>
                    </Flex>
                    <Flex justify="start" gap="sm">
                        <Skeleton visible={ queryLoading }>
                            <Typography.Text view="title" weight="medium" color="secondary">
                                <Typography.Text view="title" weight="medium" color="primary">
                                    { queryData?.meta.totalItems }
                                </Typography.Text>
                            </Typography.Text>
                        </Skeleton>
                    </Flex>
                </FlexColumns>
            </Box>

            <Table.TableComponent
                hideSearch={ true }
                columns={ columns }
                loading={ queryLoading }
                onRowClick={ (row, successCalback) =>
                    openModal(OrderModal, 'm', true)({ recordId: row.id, successCalback }) }
                language={ getLanguage(i18n) }
                records={ queryData?.items || [] }
            />
        </FlexColumns>
    );
};
