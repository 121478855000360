import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    CancelS, CheckmarkCircleFillS, ClockCircleS, LetterS,
} from '@alphakits/icons';
import {
    Button,
    Flex,
    FlexColumns,
    InfoBlockItem,
    SuperListItem,
    ToastPlate,
    Tooltip,
    Typography,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { LocationRO } from '@escapenavigator/types/dist/location/location.ro';
import { OrderEmailRO } from '@escapenavigator/types/dist/order-emails/order-email.ro';
import { convertUTCDateToZonedDate } from '@escapenavigator/utils/dist';
import { isBefore } from 'date-fns';
import { LogEmail } from 'src/components/log-email';
import { useApi } from 'src/providers/api/context';

export type SaleType = 'crossSale' | 'upSale' | 'earlyBooking' | 'retargeting';

type Props = {
    orderEmail: OrderEmailRO;
    cb: () => void;
    location: LocationRO;
    view: 'default' | 'compact';
};

export const MarketingCell: React.FC<Props> = ({
    orderEmail, location, view, cb,
}) => {
    const { t } = useTranslation();
    const { orderEmailsApi } = useApi();

    const past = isBefore(new Date(orderEmail.sendAt), new Date());
    const sent = !!orderEmail.profileEmails.length;

    const sendingDate = useMemo(
        () => {
            if (!location?.timeZone) return 'Deleted';

            return orderEmail.sendAt && location?.timeZone
                ? convertUTCDateToZonedDate({
                    date:
                          (orderEmail.profileEmails?.[0]?.createdAt as any as string) ||
                          orderEmail.sendAt,
                    timeZone: location.timeZone,
                    format: 'dd MMM, HH:mm',
                    showTZ: false,
                })
                : '';
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const emailTitle = useMemo(() => {
        if (orderEmail.type === 'scenario') {
            const title = orderEmail.scenario?.title || 'Scenario';

            return title.length > 24 ? `${title.slice(0, 21)}...` : title;
        }

        return t(orderEmail.type);
    }, [orderEmail, t]);

    const emailIcon = useMemo(() => {
        if (orderEmail.profileEmails.length) return <CheckmarkCircleFillS fill="var(--color-text-positive)" />;
        if (orderEmail.disabled) return <CancelS fill="var(--color-text-attention)" />;

        return <ClockCircleS fill="var(--color-text-accent)" />;
    }, [orderEmail]);

    const { toggleLoading, toggleFetch, toggleError } = useApiMethod({
        api: orderEmailsApi.toggle,
        successCallback: cb,
    });

    const { testLoading, testFetch, testError } = useApiMethod({
        api: orderEmailsApi.test,
        successCallback: cb,
    });

    const stauts = useMemo(() => {
        if (orderEmail.disabled || (past && !sent)) {
            return {
                color: 'secondary' as const,
                title: t('Не запланировано'),
            };
        }

        if (sent) {
            return {
                color: 'positive' as const,
                title: t('уже отправлено', { date: sendingDate }),
            };
        }

        return {
            color: 'accent' as const,
            title: t('отправим', { date: sendingDate }),
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderEmail, sendingDate, past]);

    if (view === 'default') {
        return (
            <FlexColumns columns={ 1 } gr={ 12 }>
                <SuperListItem
                    text={ emailTitle }
                    weight="bold"
                    hint={ orderEmail.type !== 'scenario' && t(`${orderEmail.type}Description`) }
                />

                <Flex>
                    <Flex justify="start" gap="xs">
                        { emailIcon }

                        <Typography.Text view="caps" weight="bold" color={ stauts.color }>
                            { stauts.title }
                        </Typography.Text>
                    </Flex>
                </Flex>

                <Flex justify="start" gap="md">
                    { !orderEmail.profileEmails?.length && (
                        <Button
                            loading={ testLoading }
                            leftAddons={ <LetterS /> }
                            onClick={ () =>
                                testFetch({
                                    orderEmailId: orderEmail.id,
                                }) }
                            view="outlined"
                            size="xs"
                        >
                            { t('sendNow') }
                        </Button>
                    ) }

                    { !sent && !past && (
                        <Button
                            loading={ toggleLoading }
                            onClick={ () =>
                                toggleFetch({
                                    data: {
                                        orderEmailId: orderEmail.id,
                                        disabled: !orderEmail.disabled,
                                    },
                                }) }
                            view="outlined"
                            size="xs"
                        >
                            { orderEmail.disabled ? t('Включить') : t('Отменить') }
                        </Button>
                    ) }
                </Flex>

                { orderEmail.profileEmails.map((email) => (
                    <Flex>
                        <LogEmail email={ email } />
                    </Flex>
                )) }

                { (toggleError?.message || testError?.message) && (
                    <ToastPlate view="attention">
                        { toggleError.message || testError?.message }
                    </ToastPlate>
                ) }
            </FlexColumns>
        );
    }

    if (orderEmail.type === 'scenario') {
        return <InfoBlockItem label={ emailTitle } value={ emailIcon } />;
    }

    return (
        <Tooltip
            content={ (
                <div style={ { width: 300, overflow: 'auto' } }>
                    { t(`${orderEmail.type}Description`) }
                </div>
            ) }
            trigger="hover"
            position="top"
        >
            <div>
                <InfoBlockItem label={ emailTitle } value={ emailIcon } />
            </div>
        </Tooltip>
    );
};
