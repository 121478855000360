import React, { ReactNode } from 'react';
import { CheckmarkS, InfoMarkS } from '@alphakits/icons';
import {
    Box, Flex, Skeleton, Tooltip, Typography,
} from '@alphakits/ui';

import styles from './index.module.css';

export type MarketingCellProps = {
    brand?: boolean;
    title?: string;
    description: string;
    loading?: boolean;
    prices: Array<{
        amount: string | ReactNode;
        description: string;
        hint?: string;
    }>;
    features?: Array<{
        title: string;
        hint?: string;
    }>;
};

export const MarketingCell: React.FC<MarketingCellProps> = ({
    brand,
    title,
    description,
    children,
    prices,
    features,
    loading,
}) => (
    <Skeleton visible={ loading }>
        <Box padding="l" border={ true } rounded="md">
            <Flex
                gap="md"
                direction="column"
                align="start"
                justify="between"
                className={ styles.card }
            >
                <Flex gap="sm" direction="column" align="start" justify="start">
                    { title && (
                        <Typography.Title
                            className={ brand && 'brandText' }
                            tag="div"
                            weight="bold"
                            view="xsmall"
                            color="primary"
                        >
                            { title }
                        </Typography.Title>
                    ) }

                    <Typography.Text view="title" color="primary" className={ styles.description }>
                        { description }
                    </Typography.Text>

                    { prices?.map((price) => (
                        <Flex gap="xs" justify="start" align="center">
                            <Typography.Title
                                tag="div"
                                weight="bold"
                                view="xxsmall"
                                color="primary"
                            >
                                { price.amount }
                            </Typography.Title>

                            <Typography.Text view="title" color="secondary">
                                { price.description }
                            </Typography.Text>

                            { price.hint && (
                                <Tooltip
                                    trigger="hover"
                                    position="bottom"
                                    content={ (
                                        <div style={ { width: 220, overflow: 'auto' } }>
                                            <Typography.Text view="primary-small" color="primary">
                                                { price.hint }
                                            </Typography.Text>
                                        </div>
                                    ) }
                                >
                                    <InfoMarkS />
                                </Tooltip>
                            ) }
                        </Flex>
                    )) }
                </Flex>

                <Flex
                    direction="column"
                    align="stretch"
                    justify="start"
                    gap="xs"
                    className={ styles.footer }
                >
                    { children }

                    { features?.map((feature) => (
                        <Flex gap="xs" align="start">
                            <Flex gap="xs" justify="start" align="start">
                                <CheckmarkS
                                    style={ { flexShrink: 0, paddingTop: 4 } }
                                    color="var(--color-bg-positive)"
                                />
                                <Typography.Text view="primary-small" color="primary">
                                    { feature.title }
                                </Typography.Text>
                            </Flex>

                            { feature.hint && (
                                <Tooltip
                                    trigger="hover"
                                    position="bottom"
                                    content={ (
                                        <div style={ { width: 220, overflow: 'auto' } }>
                                            <Typography.Text view="primary-small" color="primary">
                                                { feature.hint }
                                            </Typography.Text>
                                        </div>
                                    ) }
                                >
                                    <InfoMarkS style={ { flexShrink: 0, paddingTop: 4 } } />
                                </Tooltip>
                            ) }
                        </Flex>
                    )) }
                </Flex>
            </Flex>
        </Box>
    </Skeleton>
);
