import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext, Table } from '@alphakits/ui';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { convertToOptions } from '@escapenavigator/utils/dist/convert-to-options';
import { CertificatesaleModal } from 'src/modals/certificatesale';
import { ClientModal } from 'src/modals/client';
import { TransactionsFiltersModal } from 'src/modals/filters/transactions';
import { OrderModal } from 'src/modals/order';
import { TransactionModal } from 'src/modals/transaction';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { selectAllUsers } from 'src/store';
import { useAppSelector } from 'src/store/hooks';
import { mapper } from 'src/tags';
import { getLanguage } from 'src/utils/get-language';

import { TransactionsColumns } from './columns';

export const TransactionsPage: React.FC = () => {
    const { financeItems, partners, transactions } = useApi();

    const {
        profile: { currency },
        current,
    } = useCurrentUser();

    const { i18n, t } = useTranslation();

    const { getAllData: partnersData, getAllFetch: partnersFetch } = useApiMethod({
        api: partners.getAll,
    });
    const { getAllData: financeitemsData, getAllFetch: financeitemsFetch } = useApiMethod({
        api: financeItems.getAll,
    });

    const users = useAppSelector(selectAllUsers(current.user.id));

    useEffect(() => {
        financeitemsFetch(undefined);
        partnersFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { openModal } = useContext(ModalContext);

    const openTransactionModal = openModal(TransactionModal);
    const openFiltersModal = openModal(TransactionsFiltersModal);
    const openClientModal = openModal(ClientModal, 'm', true);

    const openOrderModal = openModal(OrderModal, 'm', true);
    const openCertificatesaleModal = openModal(CertificatesaleModal, 'm', true);

    const columns = React.useMemo(
        () =>
            TransactionsColumns({
                t,
                onCertificateOpenClick: (recordId) =>
                    openCertificatesaleModal({ certificatesaleId: recordId }),
                onOrderOpenClick: (recordId) =>
                    openOrderModal({ recordId, successCalback: () => {} }),
                currency,
                i18n,
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [t, openClientModal],
    );

    return (
        <Table.TableComponent
            columns={ columns }
            filtersButtonLabel={ t('filters.label') }
            searchPlaceholder={ t('tables.transactionsSearch') }
            tagsBuilder={ mapper(t) }
            promise={ transactions.query as any }
            onCreateClick={ (cb) =>
                openTransactionModal({
                    partnerOptions: convertToOptions(partnersData || []),
                    financeitemOptions: convertToOptions(financeitemsData || []),
                    cb,
                }) }
            onFiltersClick={ ({ submitCallback, initialValues }) =>
                openFiltersModal({
                    apply: submitCallback,
                    initialValues: initialValues.where,
                    financeitemOptions: convertToOptions(financeitemsData || []),
                    userOptions: convertToOptions(users),
                }) }
            onRowClick={ ({ id }) =>
                openTransactionModal({
                    recordId: +id,
                    partnerOptions: convertToOptions(partnersData || []),
                    financeitemOptions: convertToOptions(financeitemsData || []),
                }) }
            language={ getLanguage(i18n) }
        />
    );
};
