import { initClientApi } from '@escapenavigator/services/dist/api/init-client-api';
import { RoleRO } from '@escapenavigator/types/dist/role/role.ro';
import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';

import { RootState } from '../store';

const rolesAdapter = createEntityAdapter<RoleRO>();
const { roles } = initClientApi(process.env.REACT_APP_API_DOMAIN);

export const fetchRoles = createAsyncThunk<RoleRO[]>('roles/fetchRoles', async () => {
    const { data } = await roles.query(undefined);

    return data;
});

const rolesSlice = createSlice({
    name: 'roles',
    initialState: rolesAdapter.getInitialState(),
    reducers: {
        upsertRole(state, action: PayloadAction<RoleRO>) {
            rolesAdapter.upsertOne(state, action.payload);
        },
        removeRole(state, action: PayloadAction<RoleRO['id']>) {
            rolesAdapter.removeOne(state, action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchRoles.fulfilled, (state, action: PayloadAction<RoleRO[]>) => {
            rolesAdapter.addMany(state, action.payload);
        });
    },
});

export const { selectAll: selectAllRoles, selectById: selectRoleById } =
    rolesAdapter.getSelectors<RootState>((state) => state.roles);

export const { upsertRole, removeRole } = rolesSlice.actions;

export default rolesSlice.reducer;
