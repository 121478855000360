import React from 'react';
import { Padding, SidepanelHeader, Typography } from '@alphakits/ui/dist';
import { TFunction } from 'i18next';

import SupportIcon from './support.png';

import styles from './index.module.css';

type Props = {
    t: TFunction;
};

export const SupportModal: React.FC<Props> = ({ t }) => {
    const email = t('supportPhone');

    return (
        <div className={ styles.form_wrapper }>
            <SidepanelHeader title={ t('options.support') } />
            <div className={ styles.content_wrapper }>
                <Typography.Text view="primary-medium" color="primary">
                    { t('supportText', { email }) }
                </Typography.Text>
                <Padding padding="8px" />
                <img style={ { width: '100%' } } src={ SupportIcon } alt="support" />
            </div>
        </div>
    );
};
