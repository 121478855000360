import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    FlexColumns, formatDate, Typography, useCrudFormRequests,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { DaySessionsRO } from '@escapenavigator/types/dist/user/sessions/day-sessions.ro';
import { UpdateDaySessionsDto } from '@escapenavigator/types/dist/user/sessions/update-day-sessions.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import format from 'date-fns/format';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { removeSession, selectAllUsers, upsetSession } from 'src/store';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';

import { SessionsList } from './sessions-list';

type Props = {
    session: DaySessionsRO;
    close: () => void;
    cb?: () => void;
};

export const UpdateUserSessionModal: React.FC<Props> = ({ close, cb, session }) => {
    const { users: usersApi } = useApi();
    const { t, i18n } = useTranslation();
    const {
        current: { role, user },
    } = useCurrentUser();

    const dispatch = useAppDispatch();
    const users = useAppSelector(selectAllUsers(user.id));

    const ownSession = session.userId === user.id;
    const accessedDate =
        format(new Date(), 'yyyy-MM-dd') <= session.date &&
        (role.totalAccess || role.canEditOwnWorkedHours);

    const caneditOtherSessions = role.totalAccess || role.canEditWorkedHours;
    const canEditFileds = caneditOtherSessions || (ownSession && accessedDate);

    const {
        save,
        remove,
        removing,
        updating,
        error: softError,
    } = useCrudFormRequests({
        recordId: session.id,
        saveRequest: usersApi.updateSession,
        removeRequest: usersApi.removeSession,
        removeCallback: () => {
            if (cb) cb();

            dispatch(removeSession(session.id));
        },
        saveCallback: (data) => {
            if (cb) cb();

            dispatch(upsetSession(data));
        },
        close,
    });

    const currentUserAndDate = useMemo(() => {
        const user = users.find((u) => u.id === session.userId);

        if (!user || !session.id) return null;

        return {
            name: `${user.name} ${user.surname}`,
            date: formatDate(session.date, { lang: i18n.language, format: 'dd MMMM' }),
        };
    }, [session, users, i18n.language]);

    return (
        <RestForm
            headerBottomAddons={
                currentUserAndDate && (
                    <Typography.Text view="title">
                        { `${currentUserAndDate.name}, ${currentUserAndDate.date}` }
                    </Typography.Text>
                )
            }
            initialValues={ serializeRecord(UpdateDaySessionsDto, session) }
            validate={ validateByDto(t) }
            save={ save }
            recordId={ session.id }
            remove={ canEditFileds && remove }
            removing={ removing }
            title={ t('Смена') }
            close={ close }
            updating={ updating }
            softError={ softError }
            t={ t }
        >
            { ({
                values, errors, touched, setFieldValue, handleChange,
            }) => (
                <React.Fragment>
                    <FlexColumns columns={ 1 }>
                        <SessionsList
                            values={ values }
                            errors={ errors }
                            touched={ touched }
                            setFieldValue={ setFieldValue }
                            handleChange={ handleChange }
                            canEditFileds={ canEditFileds }
                        />
                    </FlexColumns>
                </React.Fragment>
            ) }
        </RestForm>
    );
};
