/* eslint-disable no-restricted-globals */
/* eslint-disable complexity */
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    CancelM, ChevronBackS, ChevronDownS, CloseS, EditM, EditS, PlusS,
} from '@alphakits/icons';
import {
    Amount,
    Box,
    Button,
    Divider,
    Flex,
    FlexColumns,
    formatDate,
    IconButton,
    Image,
    ImageGallery,
    InfoBlockHeader,
    InfoBlockItem,
    ModalContext,
    showError,
    Sidepanel,
    Status,
    SuperListItem,
    ToastNotifier,
    Typography,
} from '@alphakits/ui/dist';
import { FormLoading } from '@alphakits/ui/dist/form/components/form-loading';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CashboxTypeEnum } from '@escapenavigator/types';
import { languageFlagByLanguage } from '@escapenavigator/types/dist/constants/language-flag-by-language';
import { languageNameByLanguage } from '@escapenavigator/types/dist/constants/language-name-by-language';
import { OrderPenaltyTypeEnum } from '@escapenavigator/types/dist/order-penalty/enum/order-penalty-type.enum';
import { SourceEnum } from '@escapenavigator/types/dist/shared/source.enum';
import { TransactionSourceEnum } from '@escapenavigator/types/dist/transaction/enum/transaction-source.enum';
import { TransactionTypeEnum } from '@escapenavigator/types/dist/transaction/enum/transaction-type.enum';
import { convertUTCDateToZonedDate } from '@escapenavigator/utils/dist';
import { formatAmount } from '@escapenavigator/utils/dist/format-amount';
import { getFullName } from '@escapenavigator/utils/dist/get-full-name';
import differenceInHours from 'date-fns/differenceInHours';
import { ClientInfo } from 'src/components/client-info';
import { ClientMessage } from 'src/components/client-message';
import { Comments } from 'src/components/comments';
import { Logs } from 'src/components/logs/logs';
import { Picker } from 'src/components/picker';
import { TransactionsList } from 'src/components/transactions-list';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { selectCashboxByLocation, selectLocationById, selectQuestroomById } from 'src/store';
import { useAppSelector } from 'src/store/hooks';
import { getOrderData } from 'src/utils/get-order-data';

import { DoubleModal, ModalRightSide } from '../components/double-modal';

import { MarketingSection } from './components/marketing-section';
import { OrderMetadata } from './components/metadata';
import { NotConfirmedOrderAlert } from './components/not-confirmed-order-alert';
import { OrderWorkers } from './components/personal';
import { useOrderApi } from './hooks/use-order-api';
import { Cancel } from './sub-modals/cancel';
import { Participants } from './sub-modals/participants';
import { Result } from './sub-modals/result';
import {
    AddHandDiscount,
    AddPenalty,
    AddUpselling,
    Certificate,
    ChangeSlot,
    Refund,
    Transaction,
} from './sub-modals';

type Props = {
    recordId: number;
    successCalback?: () => void;
    close: () => void;
};

export const OrderModal: React.FC<Props> = ({ recordId, successCalback, close }) => {
    const { t, i18n } = useTranslation();
    const { orders } = useApi();
    const { openModal } = useContext(ModalContext);
    const {
        current: { role, mode },
        profile: { currency, availableLanguages },
    } = useCurrentUser();

    const {
        order,
        updating,
        loading,
        setOrder,
        bookImmediately,
        removeUpselling,
        removePenalty,
        createUpselling,
        updatePLayers,
        changeResult,
        removeCertificate,
        removeUploadCertificate,
        removeHandDiscount,
        removePromocode,
        updateLanguage,
        addTransaction,
        addRefund,
    } = useOrderApi(recordId, successCalback, t);

    const questroom = useAppSelector(selectQuestroomById(order?.questroomId));
    const location = useAppSelector(selectLocationById(questroom.locationId));
    const onlineCashbox = useAppSelector(selectCashboxByLocation(location.id, CashboxTypeEnum.POS));
    const cashCashbox = useAppSelector(selectCashboxByLocation(location.id, CashboxTypeEnum.CASH));

    const { resendEmailFetch, resendEmailLoading } = useApiMethod({
        api: orders.resendEmail,
        errorCallback: (err) => showError(err?.message),
        successCallback: () => {
            ToastNotifier({
                text: 'Email sent',
                position: 'bottom',
                offset: 0,
                view: 'black',
            });
        },
    });

    const [showLogs, setShowLogs] = useState(false);

    const toPay = order.total - order.payed;

    const stripeTransactionForRefund = useMemo(() => {
        if (!order || toPay >= 0) return null;

        return (order.transactions || [])
            .filter(
                (transaction) =>
                    transaction.paymentId &&
                    transaction.provider === TransactionSourceEnum.STRIPE &&
                    transaction.type === TransactionTypeEnum.SUCCEEDED &&
                    transaction.amount > 0,
            )
            .sort((a, b) => (a.amount > b.amount ? 1 : -1))[0];
    }, [order, toPay]);

    const [galleryOpen, setGalleryOpen] = useState(false);

    if (loading || updating || !order.id || resendEmailLoading) {
        return (
            <DoubleModal>
                <FormLoading />
                <ModalRightSide loading={ true } />
            </DoubleModal>
        );
    }

    const orderData = getOrderData(order, t);

    const notConfirmedBooking =
        orderData.status.type === 'BOOKING_IN_PROCESS' ||
        orderData.status.type === 'FAILED_BOOKING';

    const editAccess =
        mode === 'admin' ||
        role.totalAccess ||
        role.canAlwaysEditOrders ||
        differenceInHours(new Date(), orderData.gameDate) < 24;

    // const canAddRefunds =
    //     (mode === 'admin' || role.totalAccess || role.canAddRefunds) && !!order.payed;

    const canDeleteFines = mode === 'admin' || role.totalAccess || role.canDeleteFines;
    const data = { orderId: order.id, currency, setOrder };

    const changeLanguageActions = () =>
        Object.fromEntries(
            availableLanguages.map((lang) => [
                languageNameByLanguage[lang],
                () => updateLanguage(lang),
            ]),
        );

    const changePlayersActions = () =>
        Object.fromEntries(
            Object.entries(order.price)
                .filter(([, price]) => !!price)
                .map(([players, price]) => [
                    `${players} ${t('ppl')} - ${formatAmount(price, currency)}`,
                    () => updatePLayers(+players),
                ]),
        );

    const toPayPickerActions = () => ({
        [t('openUpsellingModal')]: () =>
            openModal(AddUpselling)({
                ...data,
                questroomId: order.questroomId,
                addUpsellings: createUpselling,
            }),
        [t('Применить сертификат')]: () =>
            openModal(Certificate)({
                ...data,
                order,
                questroomId: questroom.id,
            }),
        [t('openHandDiscountModal')]: () =>
            openModal(AddHandDiscount)({ ...data, order, amount: toPay }),
        [t('openAddPenaltyModal')]: () => openModal(AddPenalty)(data),
    });

    const openCancelModal = () =>
        openModal(Cancel)({
            order,
            setOrder,
        });

    const openChangeDateModal = () => openModal(ChangeSlot)({ order, setOrder });
    const openResultModal = () => openModal(Result)({ order, changeResult });

    const pasymentMethods = () => {
        const res = {};

        if (cashCashbox) {
            res[t('Платеж наличными', { value: cashCashbox.title })] = () =>
                addTransaction({
                    orderId: order.id,
                    amount: toPay,
                    cashboxId: cashCashbox.id,
                    comment: '',
                });
        }
        if (onlineCashbox) {
            res[t('Платеж картой', { value: onlineCashbox.title })] = () =>
                addTransaction({
                    orderId: order.id,
                    amount: toPay,
                    cashboxId: onlineCashbox.id,
                    comment: '',
                });
        }

        res[t('openAddTransactionToOrderModal')] = () =>
            openModal(Transaction)({ ...data, amount: toPay, locationId: location.id });

        return res;
    };

    // const feedbackActions = () =>
    //     (order.feedbackDate
    //         ? {
    //             [t('Не отправлять письмо')]: () => updateFeedback('no'),
    //         }
    //         : {
    //             [t('Запланировать отправку письма')]: () => updateFeedback('yes'),
    //         });

    // const disableFeedback = !editAccess || !isBefore(new Date(), addDays(new Date(), 1));

    return (
        <React.Fragment>
            <DoubleModal>
                <Sidepanel
                    title={ questroom?.title }
                    subtitle={
                        (
                            <Flex justify="start" gap="md">
                                { convertUTCDateToZonedDate({
                                    date: order.utcDate,
                                    timeZone: location.timeZone,
                                    format: 'dd MMMM yyyy, HH:mm',
                                    showTZ: false,
                                }) }

                                { availableLanguages.length > 1 && (
                                    <Picker
                                        t={ t }
                                        actions={ changeLanguageActions() }
                                        content={ (
                                            <React.Fragment>
                                                <img
                                                    alt=""
                                                    src={ languageFlagByLanguage[order.language] }
                                                    style={ {
                                                        position: 'relative',
                                                        top: 2,
                                                        height: 14,
                                                    } }
                                                />
                                            </React.Fragment>
                                        ) }
                                    />
                                ) }
                            </Flex>
                        ) as any as string
                    }
                    bottomAddons={ (
                        <FlexColumns className="noPrint" columns={ 1 } gr={ 12 }>
                            { toPay <= 0 && !order.result && order.slotId && !notConfirmedBooking && (
                                <Button
                                    block={ true }
                                    size="s"
                                    onClick={ openResultModal }
                                    view="primary"
                                >
                                    { t('Добавить результат игры') }
                                </Button>
                            ) }

                            { toPay > 0 && !notConfirmedBooking && (
                                <Picker
                                    t={ t }
                                    block={ true }
                                    position="top"
                                    disabled={ !toPay }
                                    actions={ pasymentMethods() }
                                    content={ (
                                        <Button
                                            block={ true }
                                            disabled={ !toPay }
                                            size="s"
                                            view="primary"
                                        >
                                            <React.Fragment>
                                                { t('Внести оплату') }{ ' ' }
                                                <Amount
                                                    type="decimal"
                                                    currencyOpacity={ false }
                                                    weight="bold"
                                                    currency={ currency }
                                                    value={ toPay }
                                                />
                                            </React.Fragment>
                                        </Button>
                                    ) }
                                />
                            ) }
                            { toPay < 0 && !notConfirmedBooking && (
                                <Button
                                    block={ true }
                                    disabled={ !toPay }
                                    size="s"
                                    onClick={ () =>
                                        openModal(Refund)({
                                            ...data,
                                            amount: toPay * -1,
                                            locationId: location.id,
                                        }) }
                                    view="primary"
                                >
                                    <React.Fragment>
                                        { t('Оформить возврат вручную') }{ ' ' }
                                        <Amount
                                            type="decimal"
                                            currencyOpacity={ false }
                                            weight="bold"
                                            currency={ currency }
                                            value={ toPay * -1 }
                                        />
                                    </React.Fragment>
                                </Button>
                            ) }

                            { stripeTransactionForRefund &&
                                !notConfirmedBooking &&
                                stripeTransactionForRefund.amount >= toPay * -1 && (
                                <Button
                                    block={ true }
                                    size="s"
                                    onClick={ () =>
                                        addRefund({
                                            orderId: order.id,
                                            amount: toPay * -1,
                                            cashboxId: stripeTransactionForRefund.cashboxId,
                                            stripePaymentId:
                                                    stripeTransactionForRefund.paymentId,
                                        }) }
                                    view="primary"
                                >
                                    <React.Fragment>
                                        { t('Возврат на карту') }{ ' ' }
                                        <Amount
                                            type="decimal"
                                            currencyOpacity={ false }
                                            weight="bold"
                                            currency={ currency }
                                            value={ toPay * -1 }
                                        />
                                    </React.Fragment>
                                </Button>
                            ) }

                            { !notConfirmedBooking && (
                                <Button
                                    loading={ resendEmailLoading }
                                    block={ true }
                                    size="s"
                                    onClick={ () => resendEmailFetch(order.id) }
                                    view="outlined"
                                >
                                    { `${t('Оповестить клиента об изменениях')}` }
                                </Button>
                            ) }

                            <Button block={ true } size="s" onClick={ close } view="outlined">
                                { `${t('Готово')}` }
                            </Button>
                        </FlexColumns>
                    ) }
                    headerRightAddons={ (
                        <React.Fragment>
                            { !notConfirmedBooking && order.slotId && (
                                <Flex gap="md" className="noPrint">
                                    <Button
                                        view="ghost"
                                        size="xs"
                                        leftAddons={ <EditM /> }
                                        onClick={ openChangeDateModal }
                                    />
                                    <Button
                                        view="ghost"
                                        size="xs"
                                        leftAddons={ <CancelM /> }
                                        onClick={ openCancelModal }
                                    />
                                </Flex>
                            ) }
                        </React.Fragment>
                    ) }
                    headerBottomAddons={ (
                        <FlexColumns columns={ 1 } gr={ 12 }>
                            <div>
                                <Status view="contrast" color={ orderData.status.color }>
                                    { orderData.status.title }
                                </Status>
                            </div>

                            { order.comment && (
                                <ClientMessage text={ `${t('Комментарий')}: ${order.comment}` } />
                            ) }

                            { order.mode && (
                                <ClientMessage text={ `${t('Режим игры')}: ${order.mode}` } />
                            ) }
                        </FlexColumns>
                    ) }
                >
                    { /* <Padding padding="32px" className={ styles.inner_wrapper }> */ }

                    <FlexColumns columns={ 1 } gr={ 32 }>
                        { (!!order.result || !!order.photos?.length) && (
                            <FlexColumns columns={ 1 } gr={ 16 }>
                                <SuperListItem
                                    leftAddons={ (
                                        <Button
                                            view="ghost"
                                            disabled={ !editAccess }
                                            leftAddons={ <EditS /> }
                                            size="xs"
                                            onClick={ openResultModal }
                                        />
                                    ) }
                                    text={ t('Результат игры') }
                                    rightAddons={ (
                                        <Typography.Text view="title" weight="bold">
                                            { `${order.result} min` }
                                        </Typography.Text>
                                    ) }
                                />

                                <Flex justify="start" gap="sm" wrap={ true }>
                                    { order.photos.map((src) => (
                                        <Button view="ghost" onClick={ () => setGalleryOpen(true) }>
                                            <Image key={ src } src={ src } view="default" size="xl" />
                                        </Button>
                                    )) }
                                </Flex>
                            </FlexColumns>
                        ) }

                        { notConfirmedBooking && (
                            <NotConfirmedOrderAlert
                                order={ order }
                                orderData={ orderData }
                                successCalback={ successCalback }
                                bookImmediately={ bookImmediately }
                                close={ close }
                            />
                        ) }
                        { !order.slotId && (
                            <div>
                                <Button
                                    nowrap={ true }
                                    view="outlined"
                                    size="xs"
                                    onClick={ openChangeDateModal }
                                >
                                    { t('Возобновить игру') }
                                </Button>
                            </div>
                        ) }

                        <FlexColumns columns={ 1 } gr={ 16 }>
                            <Flex>
                                <Typography.Title view="xsmall" tag="div" weight="bold">
                                    { t('К оплате') }
                                </Typography.Title>

                                <Typography.Title view="xsmall" tag="div" weight="bold">
                                    <Flex gap="sm" justify="end">
                                        { order.total !== toPay && (
                                            <Amount
                                                value={ order.total }
                                                color="secondary"
                                                currencyOpacity={ false }
                                                isCanceled={ true }
                                                weight="bold"
                                                type="decimal"
                                                currency={ currency }
                                            />
                                        ) }

                                        <Amount
                                            value={ +toPay }
                                            color="primary"
                                            weight="bold"
                                            type="decimal"
                                            currency={ currency }
                                        />
                                    </Flex>
                                </Typography.Title>
                            </Flex>

                            <Divider />

                            <FlexColumns columns={ 1 } gr={ 12 }>
                                <SuperListItem
                                    leftAddons={ (
                                        <Picker
                                            t={ t }
                                            actions={ changePlayersActions() }
                                            content={ ChevronDownS }
                                        />
                                    ) }
                                    disabled={ notConfirmedBooking || !editAccess || !order.slotId }
                                    text={ `${t('gameLink')}, ${order.players} ${t('ppl')}.` }
                                    rightAddons={ (
                                        <Amount
                                            value={ +order.price[order.players] }
                                            currency={ currency }
                                            through={ !order.slotId }
                                            color="primary"
                                            weight="bold"
                                            view="title"
                                            type="decimal"
                                        />
                                    ) }
                                />

                                { order.upsellings.map((upselling) => (
                                    <SuperListItem
                                        key={ upselling.id }
                                        leftAddons={ (
                                            <IconButton
                                                onClick={ () => removeUpselling(upselling.id) }
                                                icon={ CloseS }
                                            />
                                        ) }
                                        text={ `${t('Доп услуга')} "${upselling.title}"` }
                                        hint={ `${formatDate(upselling.createdAt, {
                                            lang: i18n.language,
                                        })} ${upselling.comment || ''}` }
                                        disabled={ notConfirmedBooking || !editAccess }
                                        rightAddons={ (
                                            <Amount
                                                value={ upselling.amount }
                                                through={ !order.slotId }
                                                currency={ data.currency }
                                                color="primary"
                                                weight="bold"
                                                view="title"
                                                type="decimal"
                                            />
                                        ) }
                                    />
                                )) }

                                { order.penalties.map((penalty) => (
                                    <SuperListItem
                                        key={ penalty.id }
                                        disabled={
                                            notConfirmedBooking || !editAccess || !canDeleteFines
                                        }
                                        leftAddons={ (
                                            <IconButton
                                                onClick={ () => removePenalty(penalty.id) }
                                                icon={ CloseS }
                                            />
                                        ) }
                                        text={
                                            penalty.type === OrderPenaltyTypeEnum.CANCELATION
                                                ? t('Штраф за отмену игры')
                                                : t('Выставлен штраф')
                                        }
                                        hint={ `${formatDate(penalty.createdAt, {
                                            lang: i18n.language,
                                        })}, ${penalty.reason}` }
                                        rightAddons={ (
                                            <Amount
                                                value={ penalty.amount }
                                                currency={ data.currency }
                                                color="negative"
                                                weight="bold"
                                                view="title"
                                                type="decimal"
                                            />
                                        ) }
                                    />
                                )) }

                                { (order.certificates || []).map((certificate) => (
                                    <SuperListItem
                                        key={ certificate.id }
                                        leftAddons={ (
                                            <IconButton
                                                onClick={ () => removeCertificate(certificate.id) }
                                                icon={ CloseS }
                                            />
                                        ) }
                                        hint={ formatDate(certificate.createdAt, {
                                            lang: i18n.language,
                                        }) }
                                        disabled={ !editAccess }
                                        text={ `${t('Применен сертификат')} ${certificate.code}` }
                                        rightAddons={ (
                                            <Amount
                                                value={ +certificate.nominal * -1 }
                                                currency={ currency }
                                                color="positive"
                                                weight="bold"
                                                view="title"
                                                type="decimal"
                                            />
                                        ) }
                                    />
                                )) }

                                { (order.uploadedCertificates || []).map((certificate) => (
                                    <SuperListItem
                                        key={ certificate.id }
                                        leftAddons={ (
                                            <IconButton
                                                onClick={ () =>
                                                    removeUploadCertificate(certificate.id) }
                                                icon={ CloseS }
                                            />
                                        ) }
                                        hint={ formatDate(certificate.createdAt, {
                                            lang: i18n.language,
                                        }) }
                                        disabled={ !editAccess }
                                        text={ `${t('Применен сертификат')} ${certificate.code}` }
                                        rightAddons={ (
                                            <Amount
                                                value={ +certificate.nominal * -1 }
                                                currency={ currency }
                                                color="positive"
                                                weight="bold"
                                                view="title"
                                                type="decimal"
                                            />
                                        ) }
                                    />
                                )) }

                                { order.promocodes?.map((p) => (
                                    <SuperListItem
                                        key={ p.id }
                                        leftAddons={ (
                                            <IconButton
                                                onClick={ () => removePromocode(p.id) }
                                                icon={ CloseS }
                                            />
                                        ) }
                                        text={ `${t('Применен промокод')} ${p.code}` }
                                        hint={ `${formatDate(p.createdAt, {
                                            lang: i18n.language,
                                        })} ${
                                            p.code.startsWith('SRS')
                                                ? t('Сгенерирован для Cross')
                                                : ''
                                        }` }
                                        disabled={ notConfirmedBooking || !editAccess }
                                        rightAddons={ (
                                            <Amount
                                                value={ +p.amount * -1 }
                                                currency={ currency }
                                                color="positive"
                                                weight="bold"
                                                view="title"
                                                type="decimal"
                                            />
                                        ) }
                                    />
                                )) }

                                { order.discounts.map((discount) => (
                                    <SuperListItem
                                        key={ discount.id }
                                        leftAddons={ (
                                            <IconButton
                                                onClick={ () => removeHandDiscount(discount.id) }
                                                icon={ CloseS }
                                            />
                                        ) }
                                        text={ t('Ручная скидка') }
                                        hint={ `${formatDate(discount.createdAt, {
                                            lang: i18n.language,
                                        })}, ${discount.reason}` }
                                        disabled={ notConfirmedBooking || !editAccess }
                                        rightAddons={ (
                                            <Amount
                                                value={ discount.amount * -1 }
                                                currency={ currency }
                                                color="positive"
                                                weight="bold"
                                                view="title"
                                                type="decimal"
                                            />
                                        ) }
                                    />
                                )) }

                                { !!order.otherPayment && (
                                    <SuperListItem
                                        text={ t('Оплачено в другой') }
                                        hint={ formatDate(order.createdAt, {
                                            lang: i18n.language,
                                        }) }
                                        disabled={ true }
                                        rightAddons={ (
                                            <Amount
                                                value={ +order.otherPayment * -1 }
                                                currency={ currency }
                                                color="positive"
                                                weight="bold"
                                                view="title"
                                                type="decimal"
                                            />
                                        ) }
                                    />
                                ) }

                                <TransactionsList
                                    setData={ setOrder }
                                    type="order"
                                    transactions={ order.transactions }
                                />

                                <Picker
                                    t={ t }
                                    className="noPrint"
                                    block={ true }
                                    actions={ toPayPickerActions() }
                                    content={ (
                                        <SuperListItem
                                            leftAddons={ <PlusS /> }
                                            text={ t('Действия') }
                                        />
                                    ) }
                                />
                            </FlexColumns>
                        </FlexColumns>

                        <OrderWorkers
                            editAccess={ editAccess }
                            t={ t }
                            setOrder={ setOrder }
                            order={ order }
                        />
                        <Comments t={ t } orderId={ order.id } />

                        { order.source === SourceEnum.exportded && (
                            <OrderMetadata orderId={ order.id } />
                        ) }
                    </FlexColumns>
                </Sidepanel>

                <ModalRightSide loading={ loading }>
                    { showLogs ? (
                        <React.Fragment>
                            <Button
                                size="xs"
                                leftAddons={ <ChevronBackS /> }
                                view="outlined"
                                onClick={ () => setShowLogs(false) }
                            >
                                { t('Детали') }
                            </Button>

                            <Logs recordId={ order.id } modelName="order" />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Box
                                background="var(--color-bg-primary)"
                                border={ true }
                                rounded="sm"
                                padding="md"
                            >
                                <InfoBlockHeader title={ t('Детали') } />

                                <InfoBlockItem
                                    withCopyButton={ true }
                                    label={ t('Код бронирования') }
                                    value={ `${order.code}, (${order.id})` }
                                />

                                <InfoBlockItem
                                    label={ t('Дата создания') }
                                    value={ formatDate(order.createdAt, { lang: i18n.language }) }
                                />

                                <InfoBlockItem
                                    label={ t('tables.source') }
                                    value={
                                        t(`options.source.${order.source}`) +
                                        (order.source === SourceEnum.WIDGET && order.otherId
                                            ? `(${order.otherId})`
                                            : '')
                                    }
                                />
                            </Box>

                            <ClientInfo
                                cb={ (client) => setOrder({ ...order, client }) }
                                client={ order.client }
                                short={ true }
                            />

                            <Box
                                background="var(--color-bg-primary)"
                                border={ true }
                                rounded="sm"
                                padding="md"
                            >
                                <InfoBlockHeader
                                    onClick={ () => openModal(Participants)({ order }) }
                                    title={ t('Участники игры') }
                                />

                                { !order.participants.length && (
                                    <Typography.Text view="title" color="primary">
                                        { t('Участники не добавлены') }
                                    </Typography.Text>
                                ) }

                                { order.participants?.map((participant, index) => (
                                    <InfoBlockItem
                                        label={ `№${index + 1}` }
                                        value={ getFullName(participant.client) }
                                    />
                                )) }
                            </Box>

                            { order.utm && (
                                <Box
                                    background="var(--color-bg-primary)"
                                    border={ true }
                                    rounded="sm"
                                    padding="md"
                                >
                                    <InfoBlockHeader title="UTM" />
                                    { Object.entries(order.utm)
                                        .filter(([, value]) => !!value)
                                        .map(([label, value]) => (
                                            <InfoBlockItem
                                                withCopyButton={ true }
                                                label={ label }
                                                value={ value }
                                            />
                                        )) }
                                </Box>
                            ) }

                            <MarketingSection
                                slotId={ order.slotId }
                                location={ location }
                                orderId={ order.id }
                            />

                            <Box
                                background="var(--color-bg-primary)"
                                border={ true }
                                rounded="sm"
                                padding="md"
                            >
                                <InfoBlockHeader
                                    title={ t('Логи') }
                                    onClick={ () => setShowLogs(true) }
                                />
                                <InfoBlockItem
                                    label={ t('Последнее изменение') }
                                    value={ formatDate(order.updatedAt, {
                                        lang: i18n.language,
                                        format: 'dd MMM, HH:mm',
                                    }) }
                                />
                            </Box>
                        </React.Fragment>
                    ) }
                </ModalRightSide>
            </DoubleModal>

            <ImageGallery
                initialSlide={ 0 }
                onClose={ () => setGalleryOpen(false) }
                open={ galleryOpen }
                photos={ order.photos.map((src) => ({ src, previewSrc: src })) }
            />
        </React.Fragment>
    );
};
