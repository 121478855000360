import { RuleRO } from '@escapenavigator/types/dist/slot-rule/rule.ro';
import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

const rulesAdapter = createEntityAdapter<RuleRO>();

const rulesSlice = createSlice({
    name: 'rules',
    initialState: rulesAdapter.getInitialState(),
    reducers: {
        upsertRule(state, action: PayloadAction<RuleRO>) {
            rulesAdapter.upsertOne(state, action.payload);
        },
        upsertRules(state, action: PayloadAction<RuleRO[]>) {
            rulesAdapter.addMany(state, action.payload);
        },
        removeRule(state, action: PayloadAction<RuleRO['id']>) {
            rulesAdapter.removeOne(state, action.payload);
        },
    },
});

export const { selectAll: selectAllRules, selectById: selectRuleById } =
    rulesAdapter.getSelectors<RootState>((state) => state.rules);

export const { upsertRule, removeRule, upsertRules } = rulesSlice.actions;

export default rulesSlice.reducer;
