import { Languages } from '@escapenavigator/types/dist/shared/enum/languages.enum';

type Props<T> = {
    language: Languages;
    availableLanguages: Languages[];
    defaultLocale: T;
    locales: Array<{ language: Languages } & T>;
};

export const getLocales = <T>({
    locales,
    defaultLocale,
    availableLanguages,
    language,
}: Props<T>) => {
    const otherLanguages = availableLanguages.filter((l) => l !== language);

    return otherLanguages.map((locale) => {
        const current = locales.find((l) => l.language === locale);

        return {
            language: locale,
            ...Object.fromEntries(
                Object.entries(defaultLocale).map(([key, value]) => [key, current?.[key] || value]),
            ),
        };
    });
};
