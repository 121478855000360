import React from 'react';
import {
    Button, Flex, Image, Typography,
} from '@alphakits/ui';
import { QuestroomRO } from '@escapenavigator/types/dist/questroom/questroom.ro';
import cn from 'classnames';
import { CalendarView } from 'src/pages/calendar/types';

import styles from './index.module.css';

type Props = {
    questrooms: QuestroomRO[];
    selectedQuestroomsIds: number[];
    view: CalendarView;
    onChange: (v: number[]) => void;
};

export const QuestroomsGroup = ({
    questrooms, selectedQuestroomsIds, onChange, view,
}: Props) => {
    const allIds = questrooms.map((q) => q.id);

    const selectedAll = allIds.length === selectedQuestroomsIds.length;

    const handleChange = (id: number) => {
        if (view === 'week' || selectedAll) {
            return onChange([id]);
        }

        if (selectedQuestroomsIds.includes(id)) {
            return onChange(selectedQuestroomsIds.filter((s) => s !== id));
        }

        return onChange([...selectedQuestroomsIds, id]);
    };

    return (
        <Flex className={ styles.container } justify="start">
            { view !== 'week' && (
                <Button
                    key="all"
                    className={ styles.button }
                    onClick={ () => onChange(allIds) }
                    view="ghost"
                >
                    <Image
                        view="circle"
                        size="m"
                        bgColor="accent"
                        icon={ (
                            <Typography.Text view="caps" color="primary-inverted" weight="bold">
                                ALL
                            </Typography.Text>
                        ) }
                        className={ cn(styles.image, { [styles.active]: selectedAll }) }
                    />
                </Button>
            ) }

            { questrooms.map((q) => (
                <Button
                    className={ styles.button }
                    key={ q.id }
                    onClick={ () => handleChange(q.id) }
                    view="ghost"
                >
                    <Image
                        view="circle"
                        size="m"
                        className={ cn(styles.image, {
                            [styles.active]: !selectedAll && selectedQuestroomsIds.includes(q.id),
                        }) }
                        src={ q.photo }
                    />
                </Button>
            )) }
        </Flex>
    );
};
