import React from 'react';
import { StarM } from '@alphakits/icons';
import { Amount } from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { UpsellingResponseObject } from '@escapenavigator/types/dist/upselling/upselling.ro';
import { TFunction } from 'i18next';

type Props = {
    t: TFunction;
    currency: ProfileCurrencyEnum;
};

export const OptionColumns = ({ t, currency }: Props): TableColumns<UpsellingResponseObject> => [
    {
        header: t('tables.title'),
        accessor: 'title',
        row: {
            icon: () => <StarM />,
        },
    },
    {
        header: t('tables.price'),
        accessor: 'price',
        row: {
            title: ({ row }) =>
                (row.flexiblePrice ? (
                    'Flex price'
                ) : (
                    <Amount value={ row.price } type="decimal" currency={ currency } />
                )),
        },
    },
    {
        header: t('tables.description'),
        accessor: 'description',
    },
];
