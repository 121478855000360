import { useEffect, useState } from 'react';
import { showError } from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CreateRuleDto } from '@escapenavigator/types/dist/slot-rule/create-rule.dto';
import { defaultRule } from '@escapenavigator/types/dist/slot-rule/defailt-rule';
import { useApi } from 'src/providers/api/context';

import {
    removeRule, selectAllRules, upsertRule, upsertRules,
} from '..';

import { useAppDispatch, useAppSelector } from '.';

export const useRulesApi = () => {
    const rules = useAppSelector(selectAllRules);

    const { slotRulesApi } = useApi();

    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);

    const { queryFetch, queryLoading } = useApiMethod({
        api: slotRulesApi.query,
        successCallback: ({ data }) => dispatch(upsertRules(data)),
    });

    const { updateFetch, updateLoading } = useApiMethod({
        api: slotRulesApi.update,
        successCallback: ({ data }) => {
            dispatch(upsertRule(data));

            return data;
        },
        errorCallback: ({ message }) => showError("Can't save booking rule", message),
    });

    const { removeFetch, removeLoading } = useApiMethod({
        api: slotRulesApi.remove,
        successCallback: ({ data }) => {
            dispatch(removeRule(data.id));

            return data;
        },
        errorCallback: ({ message }) => showError("Can't remove booking rule", message),
    });

    useEffect(() => {
        if (!rules.length) queryFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rules.length]);

    const handleSave = (dto: CreateRuleDto & { title?: string }) => {
        let title = 'Custom rule';
        let saved = true;

        if (rules.length) {
            if (dto.saved) title = dto.title;
            saved = dto.saved;
        } else {
            title = 'Standart';
        }

        setLoading(true);

        return slotRulesApi
            .create({ data: { ...dto, title, saved } })
            .then(({ data }) => {
                if (data.saved) dispatch(upsertRule(data));
                setLoading(false);

                return data;
            })
            .catch((err) => {
                showError("Can't create booking rule", err.message);
                setLoading(false);
            });
    };

    return {
        rules,
        defaultRule: rules[0] || defaultRule,
        createRule: handleSave,
        updateRule: updateFetch,
        removeRule: removeFetch,
        loading: queryLoading,
        updating: loading || updateLoading || removeLoading,
    };
};
