// /* eslint-disable no-lone-blocks */
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusS } from '@alphakits/icons';
import {
    Button,
    Cell,
    Flex,
    FlexColumns,
    Image,
    ListAllert,
    ModalContext,
    showError,
} from '@alphakits/ui/dist';
import { BaseForm } from '@alphakits/ui/dist/form/templates/base-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CreateLocationDto } from '@escapenavigator/types/dist/location/create-location.dto';
import { LocationRO } from '@escapenavigator/types/dist/location/location.ro';
import { serializeRecord } from '@escapenavigator/utils/dist';
import { PageLoader } from 'src/components/page-loader';
import { LocationModal } from 'src/modals/location';
import { useApi } from 'src/providers/api/context';
import { selectAllLocations, selectAllQuestrooms, upsertLocation } from 'src/store';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';

import { VerifyCompanyContainer } from '../components/container';
import { LocationCell } from '../components/location-cell';

type Props = {
    loading: boolean;
    error: string;
    shaking: boolean;
    scrollToError: () => void;
    cb: () => void;
    back: () => void;
};

export const VerifyLocations: React.FC<Props> = ({
    loading: initialLoading,
    scrollToError,
    error,
    shaking,
    cb,
    back,
}) => {
    const { t } = useTranslation();
    const { openModal } = useContext(ModalContext);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);

    const { locations } = useApi();

    const allLocations = useAppSelector(selectAllLocations);
    const allQuestrooms = useAppSelector(selectAllQuestrooms({}));

    const serializedLocations = allLocations.map((l) => {
        const q = allQuestrooms.filter((q) => q.locationId === l.id);

        const closed = !!q.length && q.every((q) => q.closed);

        return {
            ...l,
            questroomsIds: q.map((q) => q.id),
            closed,
            hasError: !closed && !l.phone,
        };
    });

    const openLocationModal = (location?: LocationRO) =>
        openModal(LocationModal)({ location, initial: true });

    const { updateFetch, updateError } = useApiMethod({
        api: locations.update,
        errorCallback: ({ message }) => showError(message),
    });

    const insideError = updateError?.message || error;

    if (loading || initialLoading) return <PageLoader />;

    return (
        <BaseForm
            save={ async () => {
                if (serializedLocations.filter((l) => l.hasError).length) {
                    scrollToError();
                } else {
                    setLoading(true);

                    await Promise.all(
                        allLocations.map((l) =>
                            updateFetch({ id: l.id, data: serializeRecord(CreateLocationDto, l) })),
                    );

                    await cb();

                    setLoading(false);
                }
            } }
            enableReinitialize={ true }
            initialValues={ {} }
            t={ t }
        >
            { ({ submitForm }) => {
                const setFieldValue = (location: LocationRO, filed: keyof LocationRO, value) => {
                    dispatch(upsertLocation({ ...location, [filed]: value }));
                };

                return (
                    <VerifyCompanyContainer>
                        <FlexColumns columns={ 1 } gr={ 16 }>
                            { serializedLocations.map((location) => (
                                <LocationCell
                                    location={ location }
                                    setFieldValue={ setFieldValue }
                                    openLocationModal={ openLocationModal }
                                    shaking={ shaking }
                                />
                            )) }

                            <Button view="ghost" onClick={ () => openLocationModal() }>
                                <Cell.Base
                                    title={ t('createLoaction') }
                                    addon={ <Image icon={ <PlusS /> } /> }
                                />
                            </Button>

                            { insideError && <ListAllert view="negative" text={ insideError } /> }

                            <Flex gap="md" wrap={ true }>
                                <Button view="outlined" block={ true } onClick={ back }>
                                    { t('Назад') }
                                </Button>

                                <Button block={ true } view="primary" onClick={ submitForm }>
                                    { t('verifyCompany.steps.next') }
                                </Button>
                            </Flex>
                        </FlexColumns>
                    </VerifyCompanyContainer>
                );
            } }
        </BaseForm>
    );
};
