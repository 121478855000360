import React, {
    useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { SearchM } from '@alphakits/icons';
import {
    Amount,
    Button,
    Cell,
    Flex,
    FlexColumns,
    InfoBlockWrapper,
    Input,
    Loader,
    ModalContext,
    Status,
    ToastPlate,
    Typography,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CertificateSaleRO } from '@escapenavigator/types/dist/certificate-sale/certificate-sale.ro';
import { CertificateUploadedRO } from '@escapenavigator/types/dist/certificate-uploaded/certificate-uploaded.ro';
import { OrderRO } from '@escapenavigator/types/dist/order/order.ro';
import { PromocodeTypeEnum } from '@escapenavigator/types/dist/promocode/emun/promocode-type.enum';
import { PromocodeRO } from '@escapenavigator/types/dist/promocode/promocode.ro';
import { validatePromocode } from '@escapenavigator/utils/dist/validate-promocode';
import { TFunction } from 'i18next';
import { CertificatesaleDescription } from 'src/components/details/certificatesale-description.tsx';
import { CertificateuploadDescription } from 'src/components/details/certificateupload-description.tsx';
import { PromocodeDescription } from 'src/components/details/promodoe-description.tsx';
import { CertificatesaleModal } from 'src/modals/certificatesale';
import { PromocodeInfoModal } from 'src/modals/promocode-info';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';

import { getCertificateValidity } from './utils/get-certificate-validity';
import { getUploadedCertificateValidity } from './utils/get-uploaded-certificate-validity';

type OrderProps = {
    type: 'order';
    order: OrderRO;
    certificate?: never;
};

type CertificateProps = {
    type: 'certificate';
    certificate: CertificateSaleRO;
    order?: never;
};

type Props = {
    timeZone: string;
    apply: (props: { type: string; id: number; code: string }) => void;
} & (OrderProps | CertificateProps);

export type Status =
    | 'active'
    | 'used'
    | 'expired'
    | 'wrongQuestroom'
    | 'not_payed'
    | 'wrong_dates'
    | 'other_coupons_conflict';

const translation = (status: Status, t: TFunction) => {
    if (status === 'used') return t('Использован');
    if (status === 'expired') return t('Просрочен');
    if (status === 'wrongQuestroom') return t('Для другого квеста');
    if (status === 'not_payed') return t('notPaidUp');
    if (status === 'wrong_dates') return t('Действует в другие даты');
    if (status === 'other_coupons_conflict') return t('Не совсестим с другими купонами');

    return '';
};

type Promocode = {
    type: 'promocode';
    data: PromocodeRO;
};

type Certificate = {
    type: 'certificate';
    data: CertificateSaleRO;
};

type CertificateUpload = {
    type: 'certificateUploaded';
    data: CertificateUploadedRO;
};

type SerializedCoupon = {
    open?: () => void;
    status: Status | string[];
} & (Promocode | CertificateUpload | Certificate);

export const AddCertificate: React.FC<Props> = ({
    apply, order, certificate, timeZone, type,
}) => {
    const { t } = useTranslation();
    const { certificatesales, promocodes, certificateUploadsApi } = useApi();

    const { openModal } = useContext(ModalContext);
    const {
        profile: { currency },
    } = useCurrentUser();

    const [text, setText] = useState('');
    const [showResults, setShowResults] = useState(false);

    const {
        queryFetch: queryCertificate,
        queryLoading: loadingCertificate,
        queryError: errorCertificate,
        queryData: certificatesData,
    } = useApiMethod({
        api: certificatesales.query,
    });

    const {
        queryFetch: queryCertificateUploads,
        queryLoading: loadingCertificateUploads,
        queryError: errorCertificateUploads,
        queryData: certificateUploadsData,
    } = useApiMethod({
        api: certificateUploadsApi.query,
    });

    const {
        queryFetch: queryPromocodes,
        queryLoading: loadingPromocodes,
        queryError: errorPromocodes,
        queryData: promocodesData,
    } = useApiMethod({
        api: promocodes.query,
    });

    const { queryFetch: queryPresavePromocodes, queryData: presavePromocodesData } = useApiMethod({
        api: promocodes.query,
    });

    useEffect(() => {
        queryPresavePromocodes({
            page: 1,
            limit: 20,
            sort: 'code',
            where: {
                multiple: true,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validatePromo = useCallback(
        (promocode: PromocodeRO) =>
            (type === 'order'
                ? validatePromocode({
                    questroomId: order.questroomId,
                    hasCertificates: !!order.certificates?.length,
                    otherPromocodes: order.promocodes,
                    orderDate: order.utcDate,
                    type: 'order',
                    isInside: true,
                    timeZone,
                    players: order.players,
                    promocode,
                })
                : validatePromocode({
                    otherPromocodes: certificate.promocodes,
                    type: 'certificate',
                    isInside: true,
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    promocode,
                })),
        [order, certificate, type, timeZone],
    );

    const loading = loadingPromocodes || loadingCertificateUploads || loadingCertificate;

    const error = errorPromocodes || errorCertificateUploads || errorCertificate;

    const finded = useMemo(() => {
        const res: {
            [x: string]: SerializedCoupon;
        } = {};

        promocodesData?.items.forEach((p) => {
            const status = validatePromo(p);

            res[`promocode${p.code}`] = {
                type: 'promocode',
                data: p,
                open: () => openModal(PromocodeInfoModal, 'm', true)({ recordId: p.id }),
                status: status.valid ? 'active' : status.errors,
            };
        });
        if (type === 'order') {
            certificateUploadsData?.items.forEach((p) => {
                res[`certificateUploaded${p.code}`] = {
                    type: 'certificateUploaded',
                    data: p,
                    status: getUploadedCertificateValidity(p),
                };
            });

            certificatesData?.items.forEach((p) => {
                res[`certificate${p.code}`] = {
                    type: 'certificate',
                    data: p,
                    open: () =>
                        openModal(CertificatesaleModal, 'm', true)({ certificatesaleId: p.id }),
                    status: getCertificateValidity(p, order.questroomId),
                };
            });
        }

        return {
            res,
            valid: Object.values(res).filter((f) => f.status === 'active'),
            noValid: Object.values(res).filter((f) => f.status !== 'active'),
        };
    }, [
        promocodesData?.items,
        certificateUploadsData?.items,
        certificatesData?.items,
        type,
        validatePromo,
        openModal,
        order,
    ]);

    const multiple = useMemo(() => {
        if (!presavePromocodesData) return [];

        return presavePromocodesData?.items.filter((p) => {
            const { valid } = validatePromo(p);

            return valid;
        });
    }, [presavePromocodesData, validatePromo]);

    const fetch = (code) => {
        setShowResults(true);

        const params = {
            page: 1,
            limit: 3,
            searchText: code,
            where: {},
        };

        setText('');

        if (type === 'order') {
            queryCertificate(params);
            queryCertificateUploads(params);
        }

        queryPromocodes(params);
    };

    return (
        <FlexColumns columns={ 1 } gr={ 32 } gc={ 0 }>
            <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                <Typography.Title tag="div" view="xxsmall" weight="bold">
                    { t('Поиск по коду') }
                </Typography.Title>
                { showResults ? (
                    <React.Fragment>
                        { error && (
                            <ToastPlate title="Error" view="negative">
                                { error?.message }
                            </ToastPlate>
                        ) }

                        { !finded.noValid.length && !finded.valid.length && !loading && (
                            <Typography.Text view="title" weight="bold">
                                { t('Нет результатов') }
                            </Typography.Text>
                        ) }

                        { !!finded.valid.length && (
                            <React.Fragment>
                                <Typography.Text
                                    color="positive"
                                    tag="div"
                                    view="title"
                                    weight="bold"
                                >
                                    { t('Активные сертификаты') }
                                </Typography.Text>

                                { finded.valid.map(
                                    (f) =>
                                        (
                                            <InfoBlockWrapper view="bordered">
                                                { f.type === 'promocode' && (
                                                    <PromocodeDescription
                                                        currency={ currency }
                                                        onClick={ f.open }
                                                        promocode={ f.data }
                                                    />
                                                ) }
                                                { f.type === 'certificate' && (
                                                    <CertificatesaleDescription
                                                        onClick={ f.open }
                                                        certificatesale={ f.data }
                                                    />
                                                ) }
                                                { f.type === 'certificateUploaded' && (
                                                    <CertificateuploadDescription
                                                        certificatesale={ f.data }
                                                    />
                                                ) }

                                                <br />

                                                <Button
                                                    size="s"
                                                    view="primary"
                                                    onClick={ () =>
                                                        apply({
                                                            id: f.data.id,
                                                            type: f.type,
                                                            code: f.data.code,
                                                        }) }
                                                >
                                                    { t('Применить') }
                                                </Button>
                                            </InfoBlockWrapper>
                                        ) as unknown as string,
                                ) }
                            </React.Fragment>
                        ) }

                        { !!finded.noValid.length && (
                            <React.Fragment>
                                <Typography.Text
                                    color="negative"
                                    tag="div"
                                    view="title"
                                    weight="bold"
                                >
                                    { t('Не действительные сертификаты') }
                                </Typography.Text>

                                { finded.noValid.map((f) => (
                                    <InfoBlockWrapper view="bordered">
                                        { f.type === 'promocode' && (
                                            <PromocodeDescription
                                                currency={ currency }
                                                promocode={ f.data }
                                            />
                                        ) }
                                        { f.type === 'certificate' && (
                                            <CertificatesaleDescription certificatesale={ f.data } />
                                        ) }
                                        { f.type === 'certificateUploaded' && (
                                            <React.Fragment>
                                                <CertificateuploadDescription
                                                    certificatesale={ f.data }
                                                />
                                                <br />

                                                <Button
                                                    size="s"
                                                    view="primary"
                                                    onClick={ () =>
                                                        apply({
                                                            id: f.data.id,
                                                            type: f.type,
                                                            code: f.data.code,
                                                        }) }
                                                >
                                                    { t('Применить') }
                                                </Button>
                                            </React.Fragment>
                                        ) }

                                        <ToastPlate view="attention">
                                            { typeof f.status === 'object'
                                                ? f.status.join(', ')
                                                : `${t('component.status')}: ${translation(
                                                    f.status as Status,
                                                    t,
                                                )}` }
                                        </ToastPlate>
                                    </InfoBlockWrapper>
                                )) }
                            </React.Fragment>
                        ) }
                        { loading ? (
                            <Loader />
                        ) : (
                            <Button size="s" onClick={ () => setShowResults(false) } view="outlined">
                                { t('Повторить поиск') }
                            </Button>
                        ) }
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Input
                            value={ text }
                            onKeyDown={ (e) => {
                                if (e.key === 'Enter') {
                                    if (text.length > 2) fetch(text);
                                }
                            } }
                            rightAddons={ <SearchM /> }
                            onChange={ (e, { value }) => setText(value) }
                            label={ t('Введите номер сертификата') }
                            block={ true }
                        />

                        <Button
                            size="s"
                            disabled={ text.length < 3 }
                            onClick={ () => {
                                if (text.length > 2) fetch(text);
                            } }
                            view="outlined"
                        >
                            { t('Найти сертификат') }
                        </Button>
                    </React.Fragment>
                ) }
            </FlexColumns>

            { !!multiple.length && (
                <FlexColumns columns={ 1 } gr={ 12 }>
                    <Typography.Title tag="div" view="xxsmall" weight="bold">
                        { t('Многоразовые промокоды') }
                    </Typography.Title>

                    { multiple.map((p) => (
                        <Flex>
                            <Cell.Base
                                subtitle={ (
                                    <React.Fragment>
                                        { t('Скидка') }{ ' ' }
                                        <Amount
                                            value={ p.discount }
                                            type="decimal"
                                            currencyOpacity={ false }
                                            currency={
                                                p.type === PromocodeTypeEnum.FIXED ? currency : '%'
                                            }
                                        />
                                    </React.Fragment>
                                ) }
                                title={ p.code }
                            />
                            <Button
                                size="xs"
                                view="outlined"
                                onClick={ () => apply({ id: p.id, type: 'promocode', code: p.code }) }
                            >
                                { t('Применить') }
                            </Button>
                        </Flex>
                    )) }
                </FlexColumns>
            ) }
        </FlexColumns>
    );
};
