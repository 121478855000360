import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Flex, FlexColumns, ModalContext, Table, Tag,
} from '@alphakits/ui/dist';
import { UserInviteModal } from 'src/modals/user/invite';
import { UserModal } from 'src/modals/user/main';
import { useCurrentUser } from 'src/providers/current-user/context';
import { selectAllLocations, selectAllRoles, selectAllUsers } from 'src/store';
import { useAppSelector } from 'src/store/hooks';
import { getLanguage } from 'src/utils/get-language';

import { UsersColumns } from './columns';

export const UsersListPage: React.FC = () => {
    const { openModal } = useContext(ModalContext);
    const { i18n, t } = useTranslation();
    const { current } = useCurrentUser();
    const [location, setLocation] = useState<number>();
    const users = useAppSelector(selectAllUsers(current.user.id));
    const roles = useAppSelector(selectAllRoles);
    const locations = useAppSelector(selectAllLocations);

    const columns = React.useMemo(() => UsersColumns({ t, roles }), [t, roles]);

    const openCreateUser = openModal(UserInviteModal);
    const openUser = openModal(UserModal, 'm');

    const handleRowClick = (id: number) => {
        if (current.user.id === id || current.role.totalAccess || current.role.canEditModerators) {
            return openUser({ recordId: id });
        }

        return null;
    };

    const selectedUsers = useMemo(() => {
        if (typeof location === 'undefined') return users;
        if (location === 0) return users.filter((u) => u.allLocations);

        return users.filter((u) => u.locationIds.includes(location));
    }, [users, location]);

    return (
        <FlexColumns columns={ 1 }>
            { locations.length > 1 && (
                <Flex gap="xs" justify="start" wrap={ true }>
                    <Tag
                        key="all"
                        view={ location === 0 ? 'primary-inverted' : 'primary' }
                        onClick={ () => setLocation(location === 0 ? undefined : 0) }
                        text={ t('Все локации') }
                    />

                    { locations.map((l) => (
                        <Tag
                            key={ l.title }
                            view={ location === l.id ? 'primary-inverted' : 'primary' }
                            onClick={ () => setLocation(location === l.id ? undefined : l.id) }
                            text={ l.title }
                        />
                    )) }
                </Flex>
            ) }
            <Table.TableComponent
                searchPlaceholder={ t('tables.usersSearch') }
                records={ selectedUsers }
                columns={ columns }
                onCreateClick={ () =>
                    (current.role.totalAccess ||
                        (current.role.canEditUsersAccessAndTime &&
                            current.role.canEditUsersAccess)) &&
                    openCreateUser() }
                onRowClick={ ({ id }) => handleRowClick(id) }
                language={ getLanguage(i18n) }
            />
        </FlexColumns>
    );
};
