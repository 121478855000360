import React, { useContext, useEffect } from 'react';
import { ChevronDownS, PlusS } from '@alphakits/icons';
import {
    Button,
    FlexColumns,
    IconButton,
    IconButtonProps,
    Loader,
    ModalContext,
    SuperListItem,
    ToastNotifier,
    Typography,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { OrderRO } from '@escapenavigator/types/dist/order/order.ro';
import { TFunction } from 'i18next';
import { UsersSelect } from 'src/components/selects/users-select';
import { useModeratorData } from 'src/hooks/use-moderator-data';
import { useApi } from 'src/providers/api/context';

import { OrderWorkerModal } from '../../sub-modals/order-worker';

import { WorkerRow } from './row';

type CommentsSectionProps = {
    t: TFunction;
    order?: OrderRO;
    setOrder: (order: OrderRO) => void;
    editAccess: boolean;
};

export const OrderWorkers: React.FC<CommentsSectionProps> = ({
    t,
    order,
    setOrder,
    editAccess,
}) => {
    const { orders } = useApi();
    const { openModal } = useContext(ModalContext);
    const {
        getWorkersError, getWorkersLoading, getWorkersFetch, getWorkersData,
    } = useApiMethod({
        api: orders.getWorkers,
    });

    // const { addWorkersLoading, addWorkersFetch } = useApiMethod({
    //     api: orders.addWorkers,
    //     errorCallback: ({ message }) => showError(message),
    // });

    useEffect(() => {
        getWorkersFetch(order.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addWorker = () => {
        openModal(OrderWorkerModal)({ orderId: order.id, cb: () => getWorkersFetch(order.id) });
    };

    const moderatorData = useModeratorData({
        orderId: order.id,
        moderatorId: order.moderatorId,
        t,
        successCallback: (moderatorId) => {
            setOrder({ ...order, moderatorId });
            ToastNotifier({
                text: 'Moderator saved',
                position: 'bottom',
                offset: 0,
                view: 'black',
            });
        },
    });

    if (getWorkersLoading) return <Loader />;

    if (getWorkersError) return <div>{ getWorkersError?.message }</div>;

    return (
        <FlexColumns columns={ 1 } gr={ 16 }>
            <Typography.Title view="xsmall" tag="div" weight="bold">
                { t('Персонал') }
            </Typography.Title>

            <FlexColumns columns={ 1 } gr={ 12 }>
                <SuperListItem
                    leftAddons={
                        moderatorData.loading ? (
                            <Loader />
                        ) : (
                            <UsersSelect
                                label={ t('employee') }
                                selected={ order.moderatorId }
                                Field={ ({ innerProps }) => (
                                    <IconButton
                                        loading={ moderatorData.loading }
                                        { ...(innerProps as IconButtonProps) }
                                        icon={ ChevronDownS }
                                    />
                                ) }
                                onChange={ ({ selected }) => moderatorData.update(+selected?.key) }
                            />
                        )
                    }
                    text={ t('staffConductedGame') }
                    disabled={ !editAccess }
                    rightAddons={ (
                        <Typography.Text view="title">
                            { moderatorData.moderator
                                ? `${moderatorData.moderator.name} ${moderatorData.moderator.surname}`
                                : '-' }
                        </Typography.Text>
                    ) }
                />

                { getWorkersData?.map((w) => (
                    <WorkerRow
                        worker={ w }
                        editAccess={ editAccess }
                        cb={ () => getWorkersFetch(order.id) }
                    />
                )) }

                <Button view="ghost" onClick={ addWorker }>
                    <SuperListItem leftAddons={ <PlusS /> } text={ t('Добавить персонал') } />
                </Button>
            </FlexColumns>
        </FlexColumns>
    );
};
