import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClockCircleM } from '@alphakits/icons';
import { Button, Flex, Status } from '@alphakits/ui';
import { useCurrentUser } from 'src/providers/current-user/context';

import { InitialStepsNotification } from '../initial-steps-notification';
import { NotificationsList } from '../notifications-list';

enum TagsEnum {
    ALL = 'all',
    NEW_ORDERS = 'new_orders',
    NEW_CETIFICATES = 'new_certificates',
    TRANSACTIONS = 'transactions',
    ALL_ORDERS = 'all_orders',
    IMPORTANT = 'inportant',
    ONBOARDING = 'onboarding',
}

type Props = {
    close: () => void;
    openSettings: () => void;
    tag: TagsEnum;
};

export const NotificationsBody: React.FC<Props> = ({ close, tag, openSettings }) => {
    const { t } = useTranslation();
    const { current } = useCurrentUser();

    if (tag === TagsEnum.ONBOARDING) {
        return <InitialStepsNotification close={ close } />;
    }

    if (tag === TagsEnum.IMPORTANT && !current.user.pushNotifications?.length) {
        return (
            <Flex gap="md" direction="column" align="start">
                <Status view="soft" color="grey">
                    <ClockCircleM />

                    { t('Настройте важные уведомления') }
                </Status>

                <Button view="primary" size="xs" onClick={ openSettings }>
                    { t('Изменить') }
                </Button>
            </Flex>
        );
    }

    return <NotificationsList tag={ tag } />;
};
