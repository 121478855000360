import React from 'react';
import {
    FlexColumns, Input, ListAllert, useCrudFormRequests, useGetOne,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { CreateTariffDto } from '@escapenavigator/types/dist/tariff/create-tariff.dto';
import { TariffRO } from '@escapenavigator/types/dist/tariff/tariff.ro';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { TariffBuilder } from 'src/components/tariff-builder';
import { useApi } from 'src/providers/api/context';
import { useOnboarding } from 'src/providers/ongoarding/context';
import { removeTariff, selectAllTariffs, upsertTariff } from 'src/store';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';

type Props = {
    recordId?: number;
    close: () => void;
    cb?: (record: TariffRO) => void;
    t: TFunction;
};

export const TariffModal: React.FC<Props> = ({
    recordId, close, t, cb,
}) => {
    const dispatch = useAppDispatch();
    const { tariffs } = useApi();
    const otherTarifs = useAppSelector(selectAllTariffs);
    const { refetchProgress } = useOnboarding();

    const { record, loading, error } = useGetOne(recordId, tariffs.getOne);

    // const customValidate = useCallback((values) => {
    //     const res = {} as any;

    //     if (!values.title) res.title = t('Required');

    //     return res;
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const {
        save,
        remove,
        removing,
        updating,
        error: softError,
    } = useCrudFormRequests({
        recordId,
        saveRequest: recordId ? tariffs.update : tariffs.create,
        removeRequest: tariffs.remove,
        saveCallback: (saveRecord) => {
            dispatch(upsertTariff(saveRecord));
            refetchProgress();
            cb(saveRecord);
        },
        removeCallback: (removedRecord) => dispatch(removeTariff(removedRecord.id)),
        close,
    });

    if (recordId && !record.id) return null;

    const title = record?.id || !!otherTarifs.length ? record?.title : 'Standart';

    return (
        <RestForm
            recordId={ recordId }
            title={ record?.title || t('modalsHeader.tariff') }
            initialValues={ {
                ...serializeRecord(CreateTariffDto, {
                    ...record,
                    title,
                }),
                price: record.price,
            } }
            validate={ validateByDto(t) }
            save={ save }
            remove={ remove }
            headerBottomAddons={
                !!record.id && (
                    <ListAllert dataTestId="listAllert" text={ t('component.attentionEditTariff') } />
                )
            }
            error={ error }
            softError={ softError }
            loading={ loading }
            updating={ updating || removing }
            close={ close }
            t={ t }
        >
            { ({
                values, handleChange, setFieldValue, errors, touched,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    <Input
                        dataTestId="title"
                        label={ t('component.tariffTitle') }
                        hint={ t('tariffHint') }
                        value={ values.title }
                        error={ touched.title && (errors.title as string) }
                        onChange={ handleChange('title') }
                        block={ true }
                        required={ true }
                    />

                    <TariffBuilder
                        price={ values.price }
                        error={ errors.price as any as string }
                        onChange={ (price) => setFieldValue('price', price) }
                    />
                </FlexColumns>
            ) }
        </RestForm>
    );
};
