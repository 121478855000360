import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusS, TrashS } from '@alphakits/icons';
import {
    Button,
    Divider,
    Flex,
    FlexColumns,
    Grid,
    Image,
    ImageGallery,
    Input,
    Typography,
} from '@alphakits/ui/dist';
import { BaseForm } from '@alphakits/ui/dist/form/templates/base-form';
import { WidgetTypeEnum } from '@escapenavigator/types/dist/widget/enum/widget-type.enum';
import { InitWidgetsFormDto } from '@escapenavigator/types/dist/widget/init-widgets-form.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils';
import { FormikErrors } from 'formik';
import { LocationsPicker } from 'src/components/locations-picker';

type Props = {
    title: string;
    hint: string;
    showAddButton: boolean;
    showLocationsPicker: boolean;
    initialValues: InitWidgetsFormDto;
    type: WidgetTypeEnum;
    images?: string[];
    prevStep?: () => void;
    nextStep: (values: InitWidgetsFormDto) => void;
};

export const Step: React.FC<Props> = ({
    title,
    hint,
    showAddButton,
    showLocationsPicker,
    initialValues,
    type,
    images = [],
    prevStep,
    nextStep,
}) => {
    const { t } = useTranslation();
    const [initialSlide, setInitialSlide] = useState(0);

    return (
        <Grid.Row>
            <Grid.Col width={ 6 }>
                <BaseForm
                    save={ nextStep }
                    validate={ validateByDto(t) }
                    initialValues={ serializeRecord(InitWidgetsFormDto, initialValues) }
                    t={ t }
                >
                    { ({
                        values, setFieldValue, submitForm, errors, touched,
                    }) => (
                        <FlexColumns columns={ 1 } gr={ 24 }>
                            <Typography.Text color="primary" view="primary-medium">
                                { hint }
                            </Typography.Text>

                            { values.widgets.map((widget, i) => {
                                const error = (errors?.widgets?.[i] || {}) as FormikErrors<
                                    InitWidgetsFormDto['widgets'][0]
                                >;

                                return (
                                    <FlexColumns key={ widget.id } columns={ 1 }>
                                        <FlexColumns columns={ 1 } gr={ 8 }>
                                            <Input
                                                label={ `${t('Ссылка на страницу')} ${
                                                    widget.label || ''
                                                }` }
                                                rightAddons={
                                                    !widget.label && (
                                                        <Button
                                                            view="ghost"
                                                            onClick={ () => {
                                                                setFieldValue(
                                                                    'widgets',
                                                                    values.widgets.filter(
                                                                        (l) => l.id !== widget.id,
                                                                    ),
                                                                );
                                                            } }
                                                            leftAddons={ <TrashS /> }
                                                        />
                                                    )
                                                }
                                                value={ widget.url }
                                                onChange={ (_, { value }) =>
                                                    setFieldValue(`widgets.${i}.url`, value) }
                                                block={ true }
                                                error={ touched?.widgets?.[0]?.url && error?.url }
                                            />
                                        </FlexColumns>

                                        { showLocationsPicker && (
                                            <LocationsPicker
                                                allLocations={ widget.allLocations }
                                                locationIds={ widget.locationIds }
                                                title={ t('Добавить в виджет все локации') }
                                                error={
                                                    !widget.allLocations &&
                                                    touched.widgets?.[0]?.allLocations &&
                                                    !!error?.locationIds
                                                }
                                                onChange={ (data) => {
                                                    setFieldValue(
                                                        `widgets.${i}.allLocations`,
                                                        data.allLocations,
                                                    );
                                                    setFieldValue(
                                                        `widgets.${i}.locationIds`,
                                                        data.locationIds,
                                                    );
                                                } }
                                            />
                                        ) }

                                        <Divider />
                                    </FlexColumns>
                                );
                            }) }

                            { showAddButton && (
                                <Button
                                    view="ghost"
                                    size="xs"
                                    leftAddons={ <PlusS /> }
                                    onClick={ () => {
                                        setFieldValue('widgets', [
                                            ...values.widgets,
                                            {
                                                allLocations: false,
                                                locationIds: [],
                                                type,
                                                id: `${Math.random()}`,
                                            },
                                        ]);
                                    } }
                                >
                                    { t('Добавить еще виджет') }
                                </Button>
                            ) }

                            <Flex gap="md">
                                { !!prevStep && (
                                    <Button view="outlined" block={ true } onClick={ prevStep }>
                                        { t('Назад') }
                                    </Button>
                                ) }
                                <Button block={ true } view="primary" onClick={ submitForm }>
                                    { t('verifyCompany.steps.next') }
                                </Button>
                            </Flex>
                        </FlexColumns>
                    ) }
                </BaseForm>
            </Grid.Col>
            <Grid.Col width={ 4 } offset={ 1 }>
                <FlexColumns columns={ 1 } gr={ 16 }>
                    <Typography.Text color="secondary" view="primary-medium">
                        { title }
                    </Typography.Text>

                    <FlexColumns columns={ 3 }>
                        { images.map((src, i) => (
                            <Button key={ src } view="ghost" onClick={ () => setInitialSlide(i + 1) }>
                                <Image size="auto" src={ src } />
                            </Button>
                        )) }
                    </FlexColumns>
                </FlexColumns>
            </Grid.Col>
            <Grid.Col width={ 1 } />

            <ImageGallery
                initialSlide={ initialSlide - 1 }
                open={ !!initialSlide }
                onClose={ () => setInitialSlide(0) }
                photos={ images.map((src) => ({ src })) }
            />
        </Grid.Row>
    );
};
