/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useRef, useState } from 'react';
import {
    DndProvider, useDrag, useDrop, XYCoord,
} from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ChevronDownS, ChevronUpS, ClockCircleM } from '@alphakits/icons';
import { Button, Tooltip, Typography } from '@alphakits/ui/dist';
import { useLocalstoreState } from '@escapenavigator/hooks';
// import { useLocalstoreState } from '@escapenavigator/hooks';
import cn from 'classnames';
import update from 'immutability-helper';

import { buildMinuteIntervals } from './utils/build-minute-intervals';
import { assignIndexOffsets } from './utils/get-slot-offset';
import { buildTimeIntervals, getTimetableSlotsAndRange } from './utils';

import styles from './index.module.css';

export type Resource = {
    id: number | string;
    data: {
        title: string;
        photo?: string;
        slotsWidth?: number;
        addon?: React.ReactNode;
    };
};

export type TableSlot<T> = {
    id: number | string;
    resourceId: number | string;
    start: string;
    end: string;
    data: T;
};

export type SerializedTableSlot<T> = TableSlot<T> & {
    startM: number;
    endM: number;
    indexOffset?: number;
};

export type View = 'default' | 'compact';

export type Props<T> = {
    renderSlot: (slot: TableSlot<T>) => React.ReactNode;
    resources: Resource[];
    timeZone?: string;
    slots: Array<TableSlot<T>>;
    view?: View;
    date?: string;
    clockTooltip?: string;
    loading?: boolean;
    locationId?: number;
    draggable?: boolean;
    emptyState?: React.ReactNode;
    onEmptyIntervalClick?: (time: string, day) => void;
    renderResource: (
        recource: {
            title: string;
            photo?: string;
            addon?: React.ReactNode;
        },
        draggable: boolean,
    ) => React.ReactNode;
};

export const Draggable = ({
    children, id, index, handleSort, className, style, draggable,
}) => {
    const ref = useRef<HTMLDivElement>(null);

    const [{ handlerId }, drop] = useDrop<unknown, void, { handlerId: unknown | null }>({
        accept: 'card',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },

        hover(item: any, monitor) {
            if (!ref.current) return;

            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
                return;
            }

            // moveCard(dragIndex, hoverIndex); /////
            handleSort({ id, dragIndex, hoverIndex });

            // eslint-disable-next-line no-param-reassign
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'card',
        item: () => ({ id, index }),
        options: {
            dropEffect: 'move',
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    if (draggable) {
        drag(drop(ref));
    }

    const opacity = isDragging ? 0.1 : 1;

    return (
        <div
            ref={ ref }
            style={ { ...style, opacity } }
            className={ className }
            data-handler-id={ handlerId }
        >
            { children }
        </div>
    );
};

export const TimeTable = <T, >({
    resources,
    timeZone,
    view = 'default',
    slots,
    clockTooltip = '',
    emptyState,
    date,
    onEmptyIntervalClick,
    loading,
    locationId,
    draggable,
    renderResource,
    renderSlot,
}: Props<T>) => {
    const [collapsing, setCollapsing] = useState(true);
    const [resourceOrder, setRecourceOrder] = useLocalstoreState(
        `calendarResourceOrder${locationId}`,
        resources.map((r) => r.id),
    );

    const defaultWidth = view === 'default' ? '170px' : '80px';

    const { range, serializedSlots, nowPosition } = useMemo(
        () => getTimetableSlotsAndRange(slots, collapsing, timeZone, view, date),
        [slots, collapsing, timeZone, view, date],
    );

    const timeIntervals = useMemo(() => buildTimeIntervals(range, collapsing), [collapsing, range]);

    const minuteIntervals = useMemo(
        () => (view === 'compact' ? buildMinuteIntervals(range, collapsing) : []),
        [collapsing, range, view],
    );

    const serializedResources = useMemo(
        () =>
            resources.map((recource) => {
                const slotsWithOffset = assignIndexOffsets(
                    serializedSlots.filter((s) => s.resourceId === recource.id),
                );
                const maxIndex = slotsWithOffset.reduce(
                    (acc, slot) => Math.max(acc, slot.indexOffset),
                    0,
                );

                return {
                    recource,
                    slots: slotsWithOffset,
                    maxIndex,
                    sort: 0,
                };
            }),
        [resources, serializedSlots],
    );

    const handleSort = ({
        dragIndex,
        hoverIndex,
    }: {
        id: number;
        dragIndex: number;
        hoverIndex: number;
    }) => {
        setRecourceOrder(
            update(resourceOrder, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, resourceOrder[dragIndex]],
                ],
            }),
        );
    };

    const sortedResources = useMemo(() => {
        if (!locationId) return serializedResources;

        return serializedResources
            .map((r) => ({ ...r, sort: resourceOrder.indexOf(r.recource.id) }))
            .sort((a, b) => (a.sort > b.sort ? 1 : -1));
    }, [serializedResources, resourceOrder, locationId]);

    return (
        <div className={ cn(styles.component, styles[view], { [styles.loading]: loading }) }>
            <div className={ styles.table }>
                <div className={ styles.horizontalScroll }>
                    <div className={ styles.content }>
                        <div className={ styles.clock }>
                            { view === 'default' && (
                                <Tooltip
                                    trigger="hover"
                                    position="top"
                                    content={ clockTooltip }
                                    dataTestId="test-id"
                                >
                                    <ClockCircleM />
                                </Tooltip>
                            ) }
                        </div>

                        <div className={ styles.resources }>
                            <div className={ styles.scroll }>
                                <DndProvider backend={ HTML5Backend }>
                                    { sortedResources.map(
                                        ({ recource, slots: s, maxIndex }, index) => {
                                            const width = recource.data.slotsWidth
                                                ? `${recource.data.slotsWidth * (maxIndex + 1)}px`
                                                : '100%';
                                            const minWidth = recource.data.slotsWidth
                                                ? width
                                                : defaultWidth;

                                            return (
                                                <Draggable
                                                    draggable={ draggable }
                                                    handleSort={ handleSort }
                                                    key={ recource.id }
                                                    id={ recource.id }
                                                    index={ index }
                                                    style={ { width, minWidth } }
                                                    className={ styles.recource }
                                                >
                                                    <div className={ styles.header }>
                                                        { renderResource(recource.data, draggable) }
                                                    </div>

                                                    { s.map((slot) => {
                                                        const slotWidth = 100 / (maxIndex + 1);
                                                        const left = slotWidth * slot.indexOffset;

                                                        return (
                                                            <div
                                                                key={ slot.id }
                                                                style={ {
                                                                    top: `${slot.startM}px`,
                                                                    left: `${left}%`,
                                                                    width: `${slotWidth}%`,
                                                                    height: `${
                                                                        slot.endM - slot.startM
                                                                    }px`,
                                                                } }
                                                                data-index={ `${slot.indexOffset} ${maxIndex}` }
                                                                className={ styles.slot }
                                                            >
                                                                { renderSlot(slot) }
                                                            </div>
                                                        );
                                                    }) }

                                                    { !!onEmptyIntervalClick &&
                                                        minuteIntervals.map((interval) => (
                                                            <div
                                                                key={ interval.time + recource.id }
                                                                onClick={ () =>
                                                                    onEmptyIntervalClick(
                                                                        interval.time,
                                                                        recource.id,
                                                                    ) }
                                                                style={ { top: `${interval.top}px` } }
                                                                className={ styles.minuteSlot }
                                                            >
                                                                { interval.time }
                                                            </div>
                                                        )) }
                                                </Draggable>
                                            );
                                        },
                                    ) }
                                </DndProvider>
                            </div>

                            { !!nowPosition && !loading && view !== 'compact' && (
                                <div
                                    style={ {
                                        position: 'absolute',
                                        top: nowPosition - 1,
                                        left: 0,
                                        right: 0,
                                        zIndex: 2,
                                        height: 2,
                                        background: 'rgba(33 116 238)',
                                    } }
                                />
                            ) }

                            { emptyState && <div className={ styles.emptyState }>{ emptyState }</div> }
                        </div>

                        { view === 'compact' && (
                            <Button
                                view="ghost"
                                className={ styles.collapse }
                                rightAddons={
                                    collapsing ? (
                                        <div>
                                            <ChevronUpS />
                                            <ChevronDownS />
                                        </div>
                                    ) : (
                                        <div>
                                            <ChevronDownS />
                                            <ChevronUpS />
                                        </div>
                                    )
                                }
                                onClick={ () => setCollapsing(!collapsing) }
                            />
                        ) }

                        { timeIntervals.map((i, idx) => {
                            const key = `${i.time}_${idx}`;

                            return (
                                <div className={ styles.row } key={ key }>
                                    <React.Fragment>
                                        { i.divider && <div className={ styles.divider } /> }

                                        <Typography.Text
                                            view="caps"
                                            weight="medium"
                                            color="primary"
                                        >
                                            { i.time }
                                        </Typography.Text>
                                    </React.Fragment>
                                </div>
                            );
                        }) }
                    </div>
                </div>
            </div>
        </div>
    );
};
