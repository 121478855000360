import React from 'react';
import { Typography } from '@alphakits/ui';

import styles from './index.module.css';

export const PageTitle = ({ title }) => (
    <Typography.Title
        className={ styles.component }
        view="medium"
        tag="div"
        weight="bold"
        color="primary"
    >
        { title }
    </Typography.Title>
);
