import React from 'react';
import { useTranslation } from 'react-i18next';
import { ListM } from '@alphakits/icons';
import { Amount } from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { QuestroomCancelationTypeEnum } from '@escapenavigator/types/dist/questroom/enum/questroom-cancelation-type.enum';
import { RuleRO } from '@escapenavigator/types/dist/slot-rule/rule.ro';
import { PrepaymentTypeEnum } from '@escapenavigator/types/dist/slot/enum/prepayment-type.enum';
import { useCurrentUser } from 'src/providers/current-user/context';
import { getHoursLabel } from 'src/utils/get-hours-label';

export const getPrepaymentTitle = (
    prepaymentType: PrepaymentTypeEnum,
    prepayment: number,
    currency: ProfileCurrencyEnum,
    t,
) => {
    if (prepaymentType === PrepaymentTypeEnum.NO) return t('options.prepayment.no');
    if (prepaymentType === PrepaymentTypeEnum.FIXED) return <Amount type="decimal" value={ prepayment } currency={ currency } />;

    return <Amount type="decimal" value={ prepayment } currency="%" />;
};

export const Ruleolumns = (): TableColumns<RuleRO> => {
    const { t } = useTranslation();

    const { profile } = useCurrentUser();

    return [
        {
            header: t('tables.title'),
            accessor: 'title',
            row: {
                icon: () => <ListM />,
            },
        },
        {
            header: t('Блокировать онлайн запись'),
            accessor: 'blockingHours',
            row: {
                title: ({ row }) => `${row.blockingHours || 0} ${t('часов')}`,
            },
        },

        {
            header: t('component.minHoursForBooking'),
            accessor: 'minHoursForBooking',
            row: {
                title: ({ row }) => `${row.minHoursForBooking} ${t('часов')}`,
            },
        },
        {
            header: t('Правило отмены квеста не в сррок'),
            accessor: 'cancelationRule',
            row: {
                title: ({ row }) => t(`options.cancelationRiles.${row.cancelationRule}`),
            },
        },
        {
            header: t('component.minDaysForFreeCanceling'),
            accessor: 'minHoursForFreeCanceling',
            row: {
                title: ({ row }) =>
                    (row.cancelationRule === QuestroomCancelationTypeEnum.NO
                        ? '-'
                        : `${row.minHoursForFreeCanceling} ${getHoursLabel(
                            row.minHoursForFreeCanceling,
                            t,
                        )}`),
            },
        },
        {
            header: t('component.amount'),
            accessor: 'prepayment',
            row: {
                title: ({ row }) =>
                    getPrepaymentTitle(row.prepaymentType, row.prepayment, profile.currency, t),
            },
        },
    ];
};
