import React from 'react';
import {
    AmountInput,
    Checkbox,
    FlexColumns,
    Select,
    Typography,
    useCrudFormRequests,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { CreateUserDto } from '@escapenavigator/types/dist/user/crud/create-user.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { convertToOptions } from '@escapenavigator/utils/dist/convert-to-options';
import { TFunction } from 'i18next';
import { EmailListBuilder } from 'src/components/email-list-builder';
import { LocationsPicker } from 'src/components/locations-picker';
import { useApi } from 'src/providers/api/context';
import { selectAllRoles, upsertUsers } from 'src/store';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';

type Props = {
    close: () => void;
    t: TFunction;
};

export const UserInviteModal: React.FC<Props> = ({ close, t }) => {
    const { users } = useApi();

    const title = t('createUser');

    const roles = useAppSelector(selectAllRoles) || [];
    const dispatch = useAppDispatch();

    const {
        save,
        updating,
        error: softError,
    } = useCrudFormRequests({
        saveRequest: users.create,
        saveCallback: (savedRecord) => dispatch(upsertUsers(savedRecord)),
        close,
    });

    return (
        <RestForm
            initialValues={ serializeRecord(CreateUserDto, {
                emails: [''],
            }) }
            validate={ validateByDto(t) }
            save={ save }
            title={ title }
            close={ close }
            loading={ false }
            updating={ updating }
            removing={ false }
            softError={ softError }
            t={ t }
        >
            { ({
                values, errors, touched, setFieldValue, setValues,
            }) => (
                <React.Fragment>
                    <FlexColumns columns={ 1 } gr={ 48 } gc={ 0 }>
                        <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                            <EmailListBuilder
                                error={ touched.emails && (errors.emails as string) }
                                emailList={ values.emails }
                                onChange={ (emails) => setFieldValue('emails', emails) }
                                t={ t }
                            />
                            <Select
                                block={ true }
                                label={ t('component.roleUser') }
                                onChange={ ({ selected }) => setFieldValue('roleId', selected?.key) }
                                selected={ values.roleId }
                                required={ true }
                                error={ touched.roleId && errors.roleId }
                                options={ convertToOptions(roles) }
                            />

                            <LocationsPicker
                                error={ !!errors.locationIds }
                                allLocations={ values.allLocations }
                                locationIds={ values.locationIds }
                                onChange={ (value) => setValues({ ...values, ...value }) }
                            />
                        </FlexColumns>

                        <FlexColumns columns={ 1 } gr={ 16 }>
                            <Typography.Title view="xxsmall" tag="div" weight="bold">
                                { t('Рабочее время') }
                            </Typography.Title>

                            <AmountInput
                                dataTestId="hoursContract"
                                label={ t('component.workingHoursPerMonth') }
                                min={ 0 }
                                suffix={ t('hour', { count: +values.hoursContract }) }
                                value={ values.hoursContract }
                                onChange={ (e, { value }) => setFieldValue('hoursContract', value) }
                                block={ true }
                            />

                            <Checkbox
                                label={ t('showInReports') }
                                checked={ values.showInReports }
                                onChange={ (e, { checked }) =>
                                    setFieldValue('showInReports', checked) }
                            />
                        </FlexColumns>
                    </FlexColumns>
                </React.Fragment>
            ) }
        </RestForm>
    );
};
