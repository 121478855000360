import React, { useMemo } from 'react';
import {
    FlexColumns,
    Input,
    Select,
    Textarea,
    Typography,
    useCrudFormRequests,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { languageNameByLanguage } from '@escapenavigator/types/dist/constants/language-name-by-language';
import { CreateQuestroomDto } from '@escapenavigator/types/dist/questroom/create-questroom.dto';
import { QuestroomRO } from '@escapenavigator/types/dist/questroom/questroom.ro';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { FeedbackTextarea } from 'src/pages/marketing/sales/common/feedback-textarea';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { upsertQuestroom } from 'src/store';
import { useAppDispatch } from 'src/store/hooks';
import { getLocales } from 'src/utils/get-locales';

type Props = {
    questroom?: QuestroomRO;

    close: () => void;
    t: TFunction;
};

export const QuestroomLocalizationModal: React.FC<Props> = ({ close, t, questroom }) => {
    const recordId = questroom?.id;
    const dispatch = useAppDispatch();
    const { profile } = useCurrentUser();

    const { questrooms: questroomsApi } = useApi();

    const {
        save,
        removing,
        updating,
        error: softError,
    } = useCrudFormRequests({
        recordId,
        saveRequest: recordId ? questroomsApi.update : questroomsApi.create,
        saveCallback: (savedRecord) => {
            dispatch(upsertQuestroom(savedRecord));
        },
        close,
    });

    const languagesForException = useMemo(
        () =>
            profile.availableLanguages
                .filter((l) => l !== profile.language)
                .map((l) => ({
                    key: l,
                    content: languageNameByLanguage[l],
                })),
        [profile],
    );

    let title = questroom?.title || t('modalsHeader.questroom');

    if (questroom?.closed) {
        title = `[CLOSED] ${title}`;
    }

    const locales = getLocales({
        language: profile.language,
        availableLanguages: profile.availableLanguages,
        locales: questroom?.locales || [],
        defaultLocale: {
            title: questroom?.title,
            legend: questroom?.legend,
            teaser: questroom?.teaser,
            importantInfo: questroom?.importantInfo,
        },
    });

    return (
        <RestForm
            recordId={ +recordId }
            title={ title }
            initialValues={ serializeRecord(CreateQuestroomDto, { ...questroom, locales }) }
            validate={ validateByDto(t) }
            save={ (values) =>
                save({
                    ...values,
                    locales: values.locales.filter(
                        (l) => !values.disabledLanguages.includes(l.language),
                    ),
                }) }
            softError={ softError }
            updating={ updating || removing }
            close={ close }
            t={ t }
        >
            { ({
                values, setFieldValue, errors, touched,
            }) => (
                <FlexColumns columns={ 1 } gr={ 24 } gc={ 0 }>
                    <Select
                        dataTestId="disabledLanguages"
                        block={ true }
                        multiple={ true }
                        optionsListWidth="field"
                        allowUnselect={ true }
                        label={ t('Исключить языки') }
                        options={ languagesForException }
                        selected={ values.disabledLanguages }
                        onChange={ ({ selectedMultiple }) => {
                            setFieldValue(
                                'disabledLanguages',
                                selectedMultiple.map((tag) => tag.key),
                            );
                        } }
                    />

                    { values.locales
                        .filter((l) => !values.disabledLanguages.includes(l.language))
                        .map((l, i) => {
                            const language = languageNameByLanguage[l.language];
                            const err = errors.locales?.[i] as any;
                            const touch = touched.locales?.[i];

                            return (
                                <FlexColumns columns={ 1 } gr={ 12 } gc={ 8 }>
                                    <Typography.Title tag="div" view="xsmall" weight="bold">
                                        { language }
                                    </Typography.Title>

                                    <Input
                                        dataTestId="title"
                                        label={ t('component.questroomTitle') }
                                        value={ l.title }
                                        onChange={ (_, { value }) =>
                                            setFieldValue(`locales.${i}.title`, value) }
                                        block={ true }
                                    />

                                    <Textarea
                                        label={ t('Тизер') }
                                        value={ l.teaser }
                                        error={ touch?.teaser && err?.teaser }
                                        onChange={ (_, { value }) =>
                                            setFieldValue(`locales.${i}.teaser`, value) }
                                        block={ true }
                                        hint={ t('ТизерХинт') }
                                        maxLength={ 1000 }
                                    />

                                    <FeedbackTextarea
                                        id="legend"
                                        text={ l.legend }
                                        noLinks={ true }
                                        title={ t('component.legend') }
                                        error={ touch?.legend && err?.legend }
                                        lang={ l.language }
                                        handlers={ [] }
                                        onChange={ (value) =>
                                            setFieldValue(`locales.${i}.legend`, value) }
                                    />

                                    <FeedbackTextarea
                                        id="importantInfo"
                                        text={ l.importantInfo }
                                        noLinks={ true }
                                        error={ touch?.importantInfo && err?.importantInfo }
                                        title={ t('component.importantInfo') }
                                        lang={ l.language }
                                        handlers={ [] }
                                        onChange={ (value) =>
                                            setFieldValue(`locales.${i}.importantInfo`, value) }
                                    />

                                    { /*
                                    <Textarea
                                        dataTestId="legend"
                                        label={ t('component.legend') }
                                        value={ l.legend }
                                        onChange={ handleChange(`locales.${i}.legend`) }
                                        block={ true }
                                        maxLength={ 2500 }
                                        counter={ true }
                                    /> */ }
                                    { /* <Textarea
                                        dataTestId="importantInfo"
                                        label={ t('component.importantInfo') }
                                        value={ l.importantInfo }
                                        onChange={ handleChange(`locales.${i}.importantInfo`) }
                                        block={ true }
                                        maxLength={ 1500 }
                                        counter={ true }
                                    /> */ }
                                </FlexColumns>
                            );
                        }) }
                </FlexColumns>
            ) }
        </RestForm>
    );
};
