import { useState } from 'react';
import { QueryDTO } from '@alphakits/ui/dist/table/types';
import { QueryRO } from '@escapenavigator/types/dist/shared/query.ro';
import { AxiosPromise } from 'axios';

type Meta = {
    itemCount: number;
    totalItems: number;
};

export type ApiMethod<T, R, DisplayName> = {
    (params: QueryDTO, transportConfig?: T): AxiosPromise<R>;
    displayName: DisplayName;
};

type Props<T, R, DisplayName> = {
    api: ApiMethod<T, R, DisplayName>;
    limit: number;
};

export const usePaginationQuery = <T, R extends QueryRO<unknown>, DisplayName>({
    api,
    limit,
}: Props<T, R, DisplayName>) => {
    const [records, setRecords] = useState<R['items']>([]);
    const [meta, setMeta] = useState({} as Meta);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);

    const getRecordsByQuery = async (oldRecords, currentPage, where) => {
        setUpdating(true);

        const { data } = await api({ page: currentPage, limit, where });

        setRecords([...oldRecords, ...data.items]);
        setPage(currentPage);
        setLoading(false);
        setUpdating(false);
        setMeta(data.meta);
    };

    const reload = (where: QueryDTO['where']) => {
        setLoading(true);
        getRecordsByQuery([], 1, where);
    };

    const nextPage = (where: QueryDTO['where']) => {
        getRecordsByQuery(records, page + 1, where);
    };

    const hasMore = records.length < meta.totalItems;

    return {
        records,
        hasMore,
        loading,
        updating,
        total: meta.totalItems,
        reload,
        nextPage,
    };
};
