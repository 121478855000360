import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Image, ModalContext, showError, Skeleton,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { ProfileSubscriptionTypeEnum } from '@escapenavigator/types/dist/profile/enum/profile-subscription-type.enum';
import { QuestroomRO } from '@escapenavigator/types/dist/questroom/questroom.ro';
import { Picker } from 'src/components/picker';
import { QuestroomModal } from 'src/modals/questroom';
import { QuestroomLocalizationModal } from 'src/modals/questroom/questroom-localization';
import { QuestroomModeModal } from 'src/modals/questroom/questroom-mode';
import { ScenarioEmailsModal } from 'src/modals/scenario-emails';
import { ChangeScheduleModal } from 'src/modals/slots/change-schedule';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { removeQuestroom, upsertQuestroom } from 'src/store';
import { useAppDispatch } from 'src/store/hooks';

type Props = {
    questroom: QuestroomRO;
};

export const QuestroomCell: React.FC<Props> = ({ questroom }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { profile } = useCurrentUser();
    const { questrooms: questroomsApi } = useApi();

    const { openModal } = useContext(ModalContext);

    const openNewScheduleModal = (questroomId) =>
        openModal(
            ChangeScheduleModal,
            'full',
        )({
            questroomsIds: [questroomId],
        });

    const { closeManyFetch, closeManyLoading } = useApiMethod({
        api: questroomsApi.closeMany,
        successCallback: ({ data }) => {
            dispatch(upsertQuestroom(data[0]));
        },
        errorCallback: ({ message }) => showError(message),
    });

    const { removeFetch, removeLoading } = useApiMethod({
        api: questroomsApi.remove,
        errorCallback: ({ message }) => showError(message),
        successCallback: () => {
            dispatch(removeQuestroom(questroom.id));
        },
    });

    const questroomPicker = () => {
        if (questroom.closed) {
            return {
                [t('Квест снова работает')]: () =>
                    closeManyFetch({ ids: [questroom.id], closed: false }),
                [t('remove')]: () => removeFetch(questroom.id),
            };
        }
        if (profile.subscriptionType === ProfileSubscriptionTypeEnum.NONE) {
            return {
                [t('Редактировать')]: () => openModal(QuestroomModal)({ questroom }),
            };
        }

        return {
            [t('Редактировать')]: () => openModal(QuestroomModal)({ questroom }),
            [t('Локализация')]: () => openModal(QuestroomLocalizationModal)({ questroom }),
            [t('openScheduleTemplateModal')]: () => openNewScheduleModal(questroom.id),
            [t('Режимы игры')]: () => openModal(QuestroomModeModal)({ questroom }),
            [t('scenarioEmails')]: () =>
                openModal(ScenarioEmailsModal)({ questroomId: questroom.id }),
        };
    };

    return (
        <Skeleton visible={ removeLoading || closeManyLoading }>
            <Picker
                t={ t }
                actions={ questroomPicker() }
                position="bottom-end"
                block={ true }
                content={ (
                    <Image
                        hint={ `${questroom.closed ? '[CLOSED] ' : ''}${questroom.title}` }
                        src={ questroom.photo }
                        text={ questroom.title }
                        size="auto"
                    />
                ) }
            />
        </Skeleton>
    );
};
