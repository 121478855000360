import React from 'react';
import { Image } from '@alphakits/ui';

import styles from '../index.module.css';

type Props = {
    images: Array<{
        photo?: string;
        title?: string;
        id: number;
    }>;
};

export const ImagesGroup: React.FC<Props> = ({ images = [] }) => {
    const showMore = images.length > 4;

    const shown = images.slice(0, 4);

    return (
        <div className={ styles.wrapper }>
            { shown.map((q) => (
                <div className={ styles.img }>
                    <Image size="m" view="circle" src={ q.photo } text={ q.title } key={ q.id } />
                </div>
            )) }

            { showMore && (
                <Image
                    size="m"
                    view="circle"
                    className={ styles.alt }
                    text={ `+${images.length - 4}` }
                />
            ) }
        </div>
    );
};
