import React from 'react';
import { useTranslation } from 'react-i18next';
import { PlusS, TrashS } from '@alphakits/icons';
import {
    Button, Divider, FlexColumns, Select, Textarea,
} from '@alphakits/ui/dist';
import { CreateDaySessionsDto } from '@escapenavigator/types/dist/user/sessions/create-day-sessions.dto';
import { convertToOptions } from '@escapenavigator/utils/dist';
import { FormikErrors, FormikTouched } from 'formik';
import { TimeInput } from 'src/components/time-input';
import { selectAllLocations } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

import { getOptionForTimeSelect } from '../slots/autocomplite/utils';

type Props = {
    values: any;
    touched: any;
    errors: any;
    setFieldValue: any;
    handleChange: any;
    canEditFileds: boolean;
};

export const SessionsList: React.FC<Props> = ({
    values,
    touched,
    errors,
    canEditFileds,
    setFieldValue,
    handleChange,
}) => {
    const locations = useAppSelector(selectAllLocations);
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Divider size="s" />

            { values.sessions?.map((session, i) => {
                const touch = touched?.sessions?.[i] as FormikTouched<
                    CreateDaySessionsDto['sessions'][0]
                >;
                const error = errors?.sessions?.[i] as FormikErrors<
                    CreateDaySessionsDto['sessions'][0]
                >;

                return (
                    <React.Fragment key={ session.start + session.locationId }>
                        <Select
                            dataTestId="locationId"
                            block={ true }
                            optionsListWidth="field"
                            label={ t('Локация') }
                            disabled={ !canEditFileds }
                            options={ convertToOptions(locations) }
                            selected={ session.locationId }
                            error={ touch?.locationId && error?.locationId }
                            onChange={ ({ selected }) =>
                                setFieldValue(`sessions.${i}.locationId`, +selected?.key) }
                        />

                        <FlexColumns columns={ 2 }>
                            <TimeInput
                                dataTestId="start"
                                options={ getOptionForTimeSelect() }
                                label={ t('Время начала') }
                                selected={ session.start }
                                disabled={ !canEditFileds }
                                onChange={ ({ key }) => setFieldValue(`sessions.${i}.start`, key) }
                                error={ touch?.start && error?.start }
                                block={ true }
                            />

                            <TimeInput
                                dataTestId="end"
                                options={ getOptionForTimeSelect(5, session.start || '00:00') }
                                label={ t('Время окончания') }
                                selected={ session.end }
                                disabled={ !canEditFileds }
                                onChange={ ({ key }) => setFieldValue(`sessions.${i}.end`, key) }
                                error={ touch?.end && error?.end }
                                block={ true }
                            />
                        </FlexColumns>
                        { canEditFileds && (
                            <div>
                                <Button
                                    onClick={ () => {
                                        values.sessions.splice(i, 1);
                                        setFieldValue('sessions', values.sessions);
                                    } }
                                    leftAddons={ <TrashS /> }
                                    view="ghost"
                                    size="xs"
                                >
                                    { t('Удалить интервал') }
                                </Button>
                            </div>
                        ) }

                        <Divider size="s" />
                    </React.Fragment>
                );
            }) }
            <div>
                { canEditFileds && (
                    <Button
                        onClick={ () => setFieldValue('sessions', [...values.sessions, {}]) }
                        leftAddons={ <PlusS /> }
                        view="outlined"
                        size="xs"
                    >
                        { t('Добавить интервал') }
                    </Button>
                ) }
            </div>

            <Textarea
                value={ values.comment || '' }
                onChange={ handleChange('comment') }
                label={ t('Комментарий') }
                block={ true }
                maxLength={ 2000 }
            />
        </React.Fragment>
    );
};
