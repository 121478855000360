import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button, FlexColumns, Sidepanel, Typography,
} from '@alphakits/ui/dist';

import { SubscriptionCards } from './subscription-cards';
import { SubscriptionChanged } from './subscription-changed';
import { SuccessSubscribe } from './success';

type Props = {
    close: () => void;
    type: 'new' | 'change';
};

export const ChangeSubscriptionPlanModal: React.FC<Props> = ({ close, type }) => {
    const { t } = useTranslation();
    const [succeed, setSucceed] = useState(false);

    if (succeed && type === 'new') return <SuccessSubscribe close={ close } />;
    if (succeed && type === 'change') return <SubscriptionChanged close={ close } />;

    return (
        <Sidepanel title={ t('Подключите CRM систему') }>
            <FlexColumns columns={ 1 }>
                <FlexColumns columns={ 1 } gr={ 0 }>
                    <Typography.Text view="title" color="primary">
                        { t('У нас есть CRM') }
                    </Typography.Text>

                    <div>
                        <Button
                            size="xs"
                            view="link"
                            href="https://landing.escapenavigator.com"
                            target="_blank"
                        >
                            { t('Подключаясь к crm вы принимаете') }
                        </Button>
                    </div>
                </FlexColumns>

                <FlexColumns columns={ 2 } gr={ 36 }>
                    <SubscriptionCards cb={ () => setSucceed(true) } />
                </FlexColumns>
            </FlexColumns>
        </Sidepanel>
    );
};
