import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { PersonIosM } from '@alphakits/icons';
import { Button, ButtonProps, Skeleton } from '@alphakits/ui/dist';
import { Image } from '@alphakits/ui/dist/image';
import { SlotRO } from '@escapenavigator/types/dist/slot/slot.ro';
import { getFullName } from '@escapenavigator/utils/dist/get-full-name';
import cn from 'classnames';
import { UsersSelect } from 'src/components/selects/users-select';
import { useModeratorData } from 'src/hooks/use-moderator-data';
import { updateSlot } from 'src/store';
import { useAppDispatch } from 'src/store/hooks';

import styles from './index.module.css';

type ImageButtonProps = {
    slot: SlotRO;
    moderatorId: number;
    color: 'green' | 'orange' | 'red' | 'blue' | 'grey' | 'teal' | 'purple';
};

function Component({ slot, moderatorId }: ImageButtonProps) {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const { moderator, loading, update } = useModeratorData({
        t,
        orderId: slot.order?.id,
        moderatorId,
        successCallback: (moderatorId) =>
            dispatch(
                updateSlot({ id: slot.id, changes: { order: { ...slot.order, moderatorId } } }),
            ),
    });

    return (
        <Skeleton visible={ loading }>
            <div
                className={ cn(styles.image_wrapper) }
                onClick={ (e) => {
                    e.stopPropagation();
                } }
                role="button"
                aria-hidden="true"
            >
                <UsersSelect
                    label={ t('employee') }
                    selected={ moderatorId }
                    block={ false }
                    Field={ ({ innerProps }) => (
                        <Button view="ghost" loading={ loading } { ...(innerProps as ButtonProps) }>
                            <Image
                                text={ moderator ? getFullName(moderator) : undefined }
                                view="circle"
                                className={ styles.image }
                                src={ moderator ? moderator.photo : undefined }
                                size="l"
                                icon={ moderator ? undefined : <PersonIosM /> }
                            />
                        </Button>
                    ) }
                    onChange={ ({ selected }) => update(+selected?.key) }
                />
            </div>
        </Skeleton>
    );
}

export const ImageButton = memo(Component);
