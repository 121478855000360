export const getOptions = (
    start: number,
    end: number,
    prefix = '',
    step = 1,
): Array<{ key: number; content: string }> => {
    const res = [];

    for (let i = start; i <= end; i += step) {
        res.push({
            key: i,
            content: `${i} ${prefix}`,
        });
    }

    return res;
};

export const ageOptions = new Array(8).fill(0).map((i, idx) => {
    const start = 4;
    const gap = 2;
    const result = idx === 0 ? start : start + gap * idx;

    return {
        key: result,
        content: `${result} +`,
    };
});
