import React, { useEffect } from 'react';
import InputColor, { Color } from 'react-input-color';
import {
    Button,
    Checkbox,
    Flex,
    FlexColumns,
    showSuccess,
    ToastPlate,
    Typography,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { WidgetStyleEnum } from '@escapenavigator/types/dist/profile/widget-customization/enum/widget-style.enum';
import { WidgetViewEnum } from '@escapenavigator/types/dist/profile/widget-customization/enum/widget-view.enum';
import { UpdateWidgetCustomizationDto } from '@escapenavigator/types/dist/profile/widget-customization/update-widget-customization.dto';
import { enumToOptions, serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { ChipSelect } from 'src/components/chip-select';
import { PageLoader } from 'src/components/page-loader';
import { useApi } from 'src/providers/api/context';

import { isReadableOnWhite } from './is-valid';

import styles from './index.module.css';

type Props = {
    close: () => void;
    t: TFunction;
};

const colors = [
    '#2174EF',
    '#FF8C00', // Тёмно-оранжевый
    '#FF6347', // Томатный
    '#DA70D6', // Орхидея
    '#8A2BE2', // Синий фиолетовый
    '#5F9EA0', // Кадетский синий
    '#20B2AA', // Средний морской волны
    '#FF1493', // Ярко-розовый
    '#FF4500', // Оранжево-красный
    '#32CD32', // Лаймовый зелёный
    '#4169E1', // Королевский синий
    '#6A5ACD', // Сланцево-синий
];

export const WidgetCustomizationModal: React.FC<Props> = ({ close, t }) => {
    const { profiles } = useApi();
    const {
        getWidgetCustomizationData,
        getWidgetCustomizationFetch,
        getWidgetCustomizationLoading,
    } = useApiMethod({
        api: profiles.getWidgetCustomization,
    });

    const {
        updateWidgetCustomizationFetch,
        updateWidgetCustomizationLoading,
        updateWidgetCustomizationError,
    } = useApiMethod({
        api: profiles.updateWidgetCustomization,
        successCallback: () => {
            close();
            showSuccess('Data saved successfully');
        },
    });

    useEffect(() => {
        getWidgetCustomizationFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!getWidgetCustomizationData || getWidgetCustomizationLoading) return <PageLoader />;

    return (
        <RestForm
            title={ t('customization') }
            initialValues={ serializeRecord(
                UpdateWidgetCustomizationDto,
                getWidgetCustomizationData,
            ) }
            validate={ validateByDto(t) }
            save={ (data) => updateWidgetCustomizationFetch({ data }) }
            softError={ updateWidgetCustomizationError?.message }
            updating={ updateWidgetCustomizationLoading }
            close={ close }
            t={ t }
        >
            { ({ values, setFieldValue, errors }) => (
                <FlexColumns columns={ 1 } gr={ 24 } gc={ 0 }>
                    <ChipSelect
                        options={ enumToOptions(WidgetStyleEnum, t, 'widgetStyle') }
                        value={ values.style }
                        label={ t('widgetStyleDesctiption') }
                        onChange={ (value) => setFieldValue('style', value) }
                    />

                    <ChipSelect
                        options={ enumToOptions(WidgetViewEnum, t, 'widgetView') }
                        value={ values.view }
                        label={ t('widgetViewDesctiption') }
                        onChange={ (value) => setFieldValue('view', value) }
                    />

                    <FlexColumns columns={ 1 } gr={ 8 }>
                        <Typography.Text view="caps" color="secondary">
                            { t('Отображение элементов') }
                        </Typography.Text>
                        <FlexColumns columns={ 1 } gr={ 12 }>
                            <Checkbox
                                label={ t('Показывать занятые') }
                                checked={ values.showBookedSlots }
                                onChange={ (e, { checked }) =>
                                    setFieldValue('showBookedSlots', checked) }
                                block={ true }
                            />

                            <Checkbox
                                label={ t('Показывать обертку') }
                                checked={ values.showWrapper }
                                onChange={ (e, { checked }) => setFieldValue('showWrapper', checked) }
                                block={ true }
                            />

                            <Checkbox
                                label={ t('showOtherQuests') }
                                checked={ values.showOtherQuests }
                                onChange={ (e, { checked }) =>
                                    setFieldValue('showOtherQuests', checked) }
                                block={ true }
                            />

                            <Checkbox
                                label={ t('showCertificatesButton') }
                                checked={ values.showCertificatesButton }
                                onChange={ (e, { checked }) =>
                                    setFieldValue('showCertificatesButton', checked) }
                                block={ true }
                            />
                        </FlexColumns>
                    </FlexColumns>

                    <FlexColumns columns={ 1 } gr={ 8 }>
                        <Typography.Text view="caps" color="secondary">
                            { t('Основной цвет сайта') }
                        </Typography.Text>

                        <div>
                            <InputColor
                                initialValue={ values.mainColor }
                                onChange={ (color: Color) => {
                                    setFieldValue('mainColor', color.hex);
                                } }
                                placement="right"
                            />
                        </div>

                        <Flex justify="start" gap="xxs" wrap={ true }>
                            { colors.map((color) => (
                                <Button
                                    key={ color }
                                    view="ghost"
                                    onClick={ () => setFieldValue('mainColor', color) }
                                >
                                    <div
                                        style={ {
                                            background: color,
                                            borderColor:
                                                color === values.mainColor
                                                    ? color
                                                    : 'var(--color-bg-primary)',
                                        } }
                                        className={ styles.color }
                                    />
                                </Button>
                            )) }
                        </Flex>
                        { !!errors.mainColor && (
                            <Typography.Text view="caps" color="negative">
                                Wrong color code
                            </Typography.Text>
                        ) }

                        { !isReadableOnWhite(values.mainColor) && (
                            <ToastPlate view="attention">
                                The color is too light for white text. Please try using a darker
                                color.
                            </ToastPlate>
                        ) }
                    </FlexColumns>
                </FlexColumns>
            ) }
        </RestForm>
    );
};
