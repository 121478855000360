import React from 'react';
import { Flex, Image, Skeleton } from '@alphakits/ui';

import styles from '../core/index.module.css';

export const LayoutLoader: React.FC = () => (
    <div className={ styles.container }>
        <div className={ styles.menu }>
            <div className={ styles.logo }>
                <Image loading={ true } size="m" view="ellipse" src="" />
                <Skeleton visible={ true } animate={ true }>
                    { '    ' }
                </Skeleton>
            </div>

            <Skeleton className={ styles.menuLoader } visible={ true }>
                <div className={ styles.menuItems } />
            </Skeleton>
        </div>

        <div className={ styles.content }>
            <div className={ styles.header }>
                <Skeleton visible={ true } animate={ true }>
                    <div style={ { width: 300, height: 40 } } />
                </Skeleton>

                <Flex gap="md">
                    <Image loading={ true } size="m" view="ellipse" src="" />
                    <Image loading={ true } size="m" view="ellipse" src="" />
                    <Image loading={ true } size="m" view="ellipse" src="" />
                </Flex>
            </div>

            <br />
            <br />

            <Skeleton visible={ true } animate={ true }>
                <div style={ { height: 50, width: 500 } } />
            </Skeleton>

            <br />
            <br />

            <Skeleton className={ styles.menuLoader } visible={ true } />
        </div>
    </div>
);
