import React from 'react';
import {
    AllertMarkS, ChecklistM, CheckmarkS, TimerS,
} from '@alphakits/icons';
import {
    Button, formatDate, Image, Status, StatusProps,
} from '@alphakits/ui/dist';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { InvoiceStatusEnum } from '@escapenavigator/types/dist/profile/enum/invoice-status.enum';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { InvoiceRO } from '@escapenavigator/types/dist/profile/invoice.ro';
import { formatAmount } from '@escapenavigator/utils/dist/format-amount';
import { TFunction } from 'i18next';

const colors: Record<InvoiceStatusEnum, StatusProps['color']> = {
    [InvoiceStatusEnum.DRAFT]: 'grey',
    [InvoiceStatusEnum.OPEN]: 'orange',
    [InvoiceStatusEnum.PAID]: 'green',
    [InvoiceStatusEnum.UNCOLLECTIBLE]: 'grey',
    [InvoiceStatusEnum.VOID]: 'grey',
};

const icons: Record<InvoiceStatusEnum, React.ReactNode> = {
    [InvoiceStatusEnum.DRAFT]: <AllertMarkS />,
    [InvoiceStatusEnum.OPEN]: <TimerS />,
    [InvoiceStatusEnum.PAID]: <CheckmarkS />,
    [InvoiceStatusEnum.UNCOLLECTIBLE]: <AllertMarkS />,
    [InvoiceStatusEnum.VOID]: <AllertMarkS />,
};

export const Columns = (
    t: TFunction,
    currency: ProfileCurrencyEnum,
    i18n,
): TableColumns<InvoiceRO> => [
    {
        header: t('Дата создания'),
        accessor: 'month',
        row: {
            title: ({ row }) =>
                formatDate(row.createdAt, { format: 'dd MMMM, yyyy', lang: i18n.language }),
            icon: () => <Image view="ellipse" icon={ <ChecklistM /> } />,
        },
    },
    {
        header: t('component.status'),
        accessor: 'status',
        row: {
            title: ({ row }) => (
                <Status color={ colors[row.status] } view="soft" upperCase={ true }>
                    { icons[row.status] }
                    { t(`invoiceStatus.${row.status}`) }
                </Status>
            ),
        },
    },
    {
        header: t('logs.amount'),
        accessor: 'amount',
        row: {
            title: ({ row }) => formatAmount(row.amount, currency),
        },
    },
    {
        header: '',
        accessor: 'status',
        align: 'right',
        row: {
            title: ({ row }) =>
                (row.status === InvoiceStatusEnum.OPEN || row.status === InvoiceStatusEnum.PAID) &&
                row.invoiceLink && (
                    <Button
                        href={ row.invoiceLink }
                        view={ row.status === InvoiceStatusEnum.OPEN ? 'primary' : 'outlined' }
                        target="_blank"
                        size="xs"
                    >
                        { row.status === InvoiceStatusEnum.OPEN ? t('Оплатить') : t('Скачать') }
                    </Button>
                ),
        },
    },
];
