import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDownS } from '@alphakits/icons';
import { Flex, Tag } from '@alphakits/ui';
import {
    addMonths,
    addYears,
    differenceInCalendarYears,
    differenceInMonths,
    format,
    startOfMonth,
} from 'date-fns';
import { Picker } from 'src/components/picker';
import { useCurrentUser } from 'src/providers/current-user/context';

export type View = 'month' | 'year';

export type YearsPickerProps = {
    onChange: (props: { view: View; date: string }) => void;
};

export const YearsPicker: React.FC<YearsPickerProps> = ({ onChange }) => {
    const { t } = useTranslation();

    const { profile } = useCurrentUser();
    const [view, setView] = useState<View>('year');

    const years = useMemo(() => {
        const diff = differenceInCalendarYears(new Date(), new Date(profile.createdAt)) + 2;

        return new Array(diff)
            .fill('')
            .map((_, i) => format(addYears(startOfMonth(new Date()), i * -1), 'yyyy'));
    }, [profile.createdAt]);

    const [monthes, otherMonthes] = useMemo(() => {
        const diff = differenceInMonths(new Date(), new Date(profile.createdAt)) + 10;

        const m = new Array(diff).fill('').map((_, i) => {
            const monthStart = addMonths(startOfMonth(new Date()), i * -1);

            return {
                key: format(monthStart, 'MM-yyyy'),
                value: format(
                    monthStart,
                    differenceInCalendarYears(new Date(), monthStart) ? 'MMMM yyyy' : 'MMMM',
                ),
            };
        });

        return [m.slice(0, 6), m.slice(6)];
    }, [profile.createdAt]);

    const [selectedMonth, setSelectedMonth] = useState(monthes[0]);
    const [selectedYear, setSelectedYear] = useState(years[0]);

    const otherSelected = useMemo(
        () => otherMonthes.some((m) => m.key === selectedMonth.key),
        [otherMonthes, selectedMonth],
    );

    useEffect(() => {
        onChange({ view, date: view === 'month' ? selectedMonth.key : selectedYear });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [view, selectedMonth, selectedYear]);

    return (
        <Flex justify="start" gap="xl" align="start">
            <Flex gap="sm" wrap={ false } justify="start">
                <Tag
                    text="Year"
                    weight="medium"
                    size="m"
                    view={ view === 'year' ? 'primary-inverted' : 'primary' }
                    onClick={ () => setView('year') }
                />
                <Tag
                    text="Month"
                    weight="medium"
                    size="m"
                    view={ view === 'month' ? 'primary-inverted' : 'primary' }
                    onClick={ () => setView('month') }
                />
            </Flex>

            <Flex gap="sm" wrap={ true } justify="start">
                { view === 'year' &&
                    years.map((year) => (
                        <Tag
                            key={ year }
                            text={ year }
                            weight="medium"
                            size="m"
                            view={ selectedYear === year ? 'primary-inverted' : 'primary' }
                            onClick={ () => setSelectedYear(year) }
                        />
                    )) }

                { view === 'month' &&
                    monthes.map((month) => (
                        <Tag
                            key={ month.key }
                            text={ month.value }
                            weight="medium"
                            size="m"
                            view={ selectedMonth.key === month.key ? 'primary-inverted' : 'primary' }
                            onClick={ () => setSelectedMonth(month) }
                        />
                    )) }

                { view === 'month' && !!otherMonthes.length && (
                    <Picker
                        t={ t }
                        actions={ Object.fromEntries(
                            otherMonthes.map((month) => [
                                month.value,
                                () => setSelectedMonth(month),
                            ]),
                        ) }
                        content={ (
                            <Tag
                                text={ otherSelected ? selectedMonth.value : t('Другое') }
                                size="m"
                                weight="medium"
                                rightAddons={ <ChevronDownS /> }
                                view={
                                    otherMonthes.some((m) => m.key === selectedMonth.key)
                                        ? 'primary-inverted'
                                        : 'primary'
                                }
                            />
                        ) }
                    />
                ) }
            </Flex>
        </Flex>
    );
};
