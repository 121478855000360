import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext, showError } from '@alphakits/ui';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CrossSaleStatusEnum } from '@escapenavigator/types/dist/profile/cross-sale/cross-sale-status.enum';
import { CrossSaleDto } from '@escapenavigator/types/dist/profile/cross-sale/cross-sale.dto';
import { serializeRecord } from '@escapenavigator/utils';
import { IntegrationCard } from 'src/components/integration-card';
import { useApi } from 'src/providers/api/context';

import { CrossSalesModal } from '../cross-sales';

import { CardChildren } from './card-children';

type Props = {
    data: CrossSaleDto;
    defaultValues: CrossSaleDto;
    loading: boolean;
    cb: () => void;
};

export const CrossSaleCard: React.FC<Props> = ({
    loading, data, cb, defaultValues,
}) => {
    const { t } = useTranslation();
    const { crossSalesApi } = useApi();
    const { openModal } = useContext(ModalContext);

    const { updateCrossSaleFetch, updateCrossSaleLoading } = useApiMethod({
        api: crossSalesApi.updateCrossSale,
        successCallback: cb,
        errorCallback: ({ message }) => showError(message),
    });

    const handleChange = (status: CrossSaleStatusEnum) => {
        updateCrossSaleFetch(serializeRecord(CrossSaleDto, { ...data, status }));
    };

    return (
        <IntegrationCard
            loading={ loading || updateCrossSaleLoading }
            title={ t('crossSale') }
            description={ t('crossSaleDescription') }
            image="https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/art/marketing/cross-sale.png"
        >
            <CardChildren
                status={ data?.status }
                enable={ () => handleChange(CrossSaleStatusEnum.ENABLED) }
                disable={ () => handleChange(CrossSaleStatusEnum.DISABLED) }
                open={ () =>
                    openModal(
                        CrossSalesModal,
                        'm',
                    )({
                        data,
                        defaultValues,
                        cb,
                    }) }
            />
        </IntegrationCard>
    );
};
