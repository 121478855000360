// /* eslint-disable no-lone-blocks */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlexColumns, Typography } from '@alphakits/ui/dist';
import { SteppedProgressBar } from '@alphakits/ui/dist/stepped-progress-bar';
import { ProfileStatusEnum } from '@escapenavigator/types/dist/profile/enum/profile-step.enum';
import { Logo } from 'src/components/layout/logo';
import { StepperContainer } from 'src/components/stepper-container';
import { useCurrentUser } from 'src/providers/current-user/context';

export const VerifyCompanyContainer: React.FC = ({ children }) => {
    const { t } = useTranslation();

    const { profile } = useCurrentUser();

    const titles = {
        [ProfileStatusEnum.VERIFICATION_PROFILE]: {
            title: `${t('Данные компании')}`,
            description: t('verifyCompanyDescription'),
        },
        [ProfileStatusEnum.VERIFICATION_LOCATIONS]: {
            title: `${t('Локации')}`,
            description: t('verifyLocationsDescription'),
        },
        [ProfileStatusEnum.VERIFICATION_QUESTROOMS]: {
            title: `${t('Квесты')}`,
            description: t('verifyQuestroomsDescription'),
        },
    };

    return (
        <StepperContainer>
            <FlexColumns columns={ 1 } gr={ 24 }>
                <Logo />

                <FlexColumns columns={ 1 } gr={ 12 }>
                    <Typography.Title tag="div" view="xsmall" weight="bold">
                        { titles[profile.status]?.title ||
                            titles[ProfileStatusEnum.VERIFICATION_PROFILE].title }
                    </Typography.Title>

                    <SteppedProgressBar
                        step={ Object.keys(titles).indexOf(profile.status) + 1 || 1 }
                        maxStep={ 3 }
                    />

                    <Typography.Text view="caps" color="secondary">
                        { titles[profile.status]?.description ||
                            titles[ProfileStatusEnum.VERIFICATION_PROFILE].description }
                    </Typography.Text>
                </FlexColumns>

                { children }
            </FlexColumns>
        </StepperContainer>
    );
};
