import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    CalendarInput, FlexColumns, Select, Textarea,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { UpdateBreaksDto } from '@escapenavigator/types/dist/slot/update-breaks.dto';
import { serializeRecord } from '@escapenavigator/utils/dist';
import { QuestroomsSelect } from 'src/components/selects/questrooms-select';
import { TimeInput } from 'src/components/time-input';
import { useApi } from 'src/providers/api/context';
import { selectAllQuestrooms } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

import { getOptionForTimeSelect } from '../autocomplite/utils';

type ActionType = 'add' | 'remove';

type Props = {
    callback?: () => void;
    close: () => void;
    questroomId?: number;
    startDate?: string;
    startTime?: string;
    endDate?: string;
    endTime?: string;
    type?: ActionType;
};

type DefaultValues = {
    breakReason: string | null;
    startDate: string;
    questroomIds: number[];
    startTime: string;
    endDate: string;
    endTime: string;
    type: ActionType;
};

export const ManageBreaksModal = ({
    callback,
    close,
    questroomId,
    startDate = '',
    startTime = '00:00',
    type,
    endDate = '',
    endTime = '23:55',
}: Props) => {
    const questrooms = useAppSelector(selectAllQuestrooms({ deleted: false, closed: false }));

    const { t, i18n } = useTranslation();

    const { slots } = useApi();

    const { updateBreaksError, updateBreaksFetch, updateBreaksLoading } = useApiMethod({
        api: slots.updateBreaks,
        successCallback: () => {
            close();
            if (callback) callback();
        },
    });

    const submit = async (data: DefaultValues) => {
        updateBreaksFetch({
            data,
        });
    };

    return (
        <RestForm
            title={ t('Управление перерывами') }
            save={ submit }
            close={ close }
            initialValues={ serializeRecord(UpdateBreaksDto, {
                startDate,
                startTime,
                endDate,
                endTime,
                breakReason: '',
                questroomIds: questroomId ? [questroomId] : [],
                type,
            }) }
            updating={ updateBreaksLoading }
            softError={ updateBreaksError?.message }
            t={ t }
        >
            { ({
                values, setFieldValue, touched, errors,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 }>
                    <Select
                        label={ t('Действие') }
                        block={ true }
                        options={ [
                            { key: 'add', content: t('Добавить перерыв') },
                            { key: 'remove', content: t('Убрать перерыв') },
                        ] }
                        selected={ values.type }
                        onChange={ ({ selected: { key } }) => setFieldValue('type', key) }
                        required={ true }
                    />

                    { values.type && (
                        <React.Fragment>
                            <QuestroomsSelect
                                multiple={ true }
                                label={ t('Один или несколько квестов') }
                                questrooms={ questrooms }
                                selected={ values.questroomIds }
                                error={ touched.questroomIds && (errors.questroomIds as string) }
                                onChange={ ({ selectedMultiple }) => {
                                    setFieldValue(
                                        'questroomIds',
                                        selectedMultiple.map((q) => +q.key),
                                    );
                                } }
                            />
                            <FlexColumns columns={ 2 } gc={ 16 }>
                                <CalendarInput
                                    value={ values.startDate }
                                    lang={ i18n.language }
                                    label={ t('Дата начала') }
                                    onChange={ (e, { value }) => setFieldValue('startDate', value) }
                                    block={ true }
                                    required={ true }
                                    error={ touched.startDate && errors.startDate }
                                />

                                <CalendarInput
                                    value={ values.endDate }
                                    lang={ i18n.language }
                                    label={ t('Дата окончания') }
                                    onChange={ (e, { value }) => setFieldValue('endDate', value) }
                                    block={ true }
                                    required={ true }
                                    error={ touched.endDate && errors.endDate }
                                />
                            </FlexColumns>

                            <FlexColumns columns={ 2 } gc={ 16 }>
                                <TimeInput
                                    label={ t('Время начала') }
                                    block={ true }
                                    options={ getOptionForTimeSelect() }
                                    selected={ values.startTime }
                                    onChange={ ({ key }) => setFieldValue('startTime', key) }
                                    required={ true }
                                />

                                <TimeInput
                                    label={ t('Время окончания') }
                                    block={ true }
                                    options={ getOptionForTimeSelect() }
                                    selected={ values.endTime }
                                    onChange={ ({ key }) => setFieldValue('endTime', key) }
                                    required={ true }
                                />
                            </FlexColumns>

                            { values.type === 'add' && (
                                <Textarea
                                    dataTestId="breakReason"
                                    label={ t('Причина') }
                                    value={ values.breakReason }
                                    maxLength={ 200 }
                                    counter={ true }
                                    required={ true }
                                    error={ touched.breakReason && errors.breakReason }
                                    onChange={ (e, { value }) => setFieldValue('breakReason', value) }
                                    block={ true }
                                />
                            ) }
                        </React.Fragment>
                    ) }
                </FlexColumns>
            ) }
        </RestForm>
    );
};
