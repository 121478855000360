export const convertSecondsToHHMMSS = (seconds: number) => {
    const h = Math.floor(seconds / 60 / 60);
    const m = Math.floor((seconds - h * 60 * 60) / 60);
    const s = seconds % 60;

    const hh = h < 10 ? `0${h}` : h;
    const mm = m < 10 ? `0${m}` : m;
    const ss = s < 10 ? `0${s}` : s;

    return `${hh}:${mm}:${ss}`;
};
