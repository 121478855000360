import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    formatDate, Image, Status, Table,
} from '@alphakits/ui/dist';
import { CertificateSaleRO } from '@escapenavigator/types/dist/certificate-sale/certificate-sale.ro';
import { getCertificateData } from 'src/utils/get-certificate-data';

type Props = {
    certificate: CertificateSaleRO;
    onClick: () => void;
};

export const CertificateTableItem: React.FC<Props> = ({ certificate, onClick }) => {
    const { t, i18n } = useTranslation();
    const data = getCertificateData(certificate, t);

    return (
        <tr onClick={ onClick } style={ { cursor: 'pointer' } }>
            <Table.Cell
                title={ certificate.certificate.title }
                subtitle={ formatDate(certificate.createdAt, {
                    lang: i18n.language,
                    format: 'dd MMMM yyyy, HH:mm',
                }) }
                addon={ <Image size="s" src={ certificate.certificate.photo || undefined } /> }
            />

            <Table.Cell
                title={ (
                    <Status view="contrast" color={ data.color }>
                        { data.title }
                    </Status>
                ) }
            />
        </tr>
    );
};
