import React, { useMemo } from 'react';
import {
    InitApiDeclaration,
    initClientApi,
} from '@escapenavigator/services/dist/api/init-client-api';

type Context = InitApiDeclaration | null;

export const ApiContext = React.createContext<Context>(null);

export const ApiProvider = ({ children }) => {
    const api = useMemo(() => initClientApi(process.env.REACT_APP_API_DOMAIN), []);

    return <ApiContext.Provider value={ api }>{ children }</ApiContext.Provider>;
};

export const useApi = () => React.useContext(ApiContext);
