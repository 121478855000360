import React from 'react';
import { Tag } from '@alphakits/ui';
import { selectQuestroomsByIds } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

import { TagProps } from './types';

export const Questroom: React.FC<TagProps> = ({
    value, row, removeParam, rowField,
}) => {
    const questrooms = useAppSelector(
        selectQuestroomsByIds(Array.isArray(value) ? value : [value]),
    );

    const text =
        questrooms.length === 1
            ? `${questrooms[0].title}`
            : `${questrooms.map((questroom) => ` ${questroom.title}`)}`;

    return (
        <Tag
            text={ (rowField ? `${rowField}: ` : '') + text }
            onClick={ () => {} }
            onCloseClick={ () => removeParam(row) }
        />
    );
};
