import { convertHHMMToMinutes } from '@escapenavigator/utils/dist';

import { SlotsObject } from '../types';

function getSlotHeigh(start: string, end: string) {
    return (+convertHHMMToMinutes(end) - +convertHHMMToMinutes(start)) / 2;
}

function getResourceId(slot, mode) {
    if (mode === 'calendar_day') {
        return slot.questroomId;
    }

    return slot.date;
}

export const serializeSlots = (slots, mode) =>
    slots
        .map((slot) => {
            const start = slot.start.split(':');
            const end = slot.end.split(':');

            return {
                ...slot,
                start: `${start[0]}:${start[1]}`,
                end: `${end[0]}:${end[1]}`,
            };
        })
        .reduce(
            (acc, slot): SlotsObject => {
                const resourceId = getResourceId(slot, mode);

                acc.legal[resourceId] = acc.legal[resourceId] || {};

                if (acc.legal[resourceId][slot.start]) {
                    acc.unlegal.push(slot);

                    return acc;
                }

                acc.legal[resourceId][slot.start] = {
                    ...slot,
                    height: getSlotHeigh(slot.start, slot.end),
                };

                return acc;
            },
            {
                legal: {},
                unlegal: [],
            },
        );
