import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    CalendarInput,
    Collapse,
    convertToOptions,
    FlexColumns,
    Input,
    Select,
    Typography,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { languageOptions } from '@escapenavigator/types/dist/constants/language-options';
import { OrderStatusEnum } from '@escapenavigator/types/dist/order/enum/order-status.enum';
import { QueryOrderDto } from '@escapenavigator/types/dist/order/query-order.dto';
import { Languages } from '@escapenavigator/types/dist/shared/enum/languages.enum';
import { SourceEnum } from '@escapenavigator/types/dist/shared/source.enum';
import { serializeRecord } from '@escapenavigator/utils/dist';
import { enumToOptions } from '@escapenavigator/utils/dist/enum-to-options';
import { FormikProps, FormikValues } from 'formik';
import { ExportAndCount } from 'src/components/export-and-count';
import { SelectSearch } from 'src/components/select-search';
import { QuestroomsSelect } from 'src/components/selects/questrooms-select';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { selectAllQuestrooms, selectAllUsers } from 'src/store';
import { useAppSelector } from 'src/store/hooks';
import { downloadBlob } from 'src/utils/download-blob';

type Props = {
    apply: (values: FormikValues) => void;
    initialValues: Partial<QueryOrderDto>;
    close: () => void;
};

const Form = ({
    props: { values, setFieldValue, handleChange },
    close,
}: {
    props: FormikProps<QueryOrderDto>;
    close: () => void;
}) => {
    const { t, i18n } = useTranslation();
    const { orders } = useApi();
    const {
        current: { user },
    } = useCurrentUser();

    const { exportOrdersFetch, exportOrdersLoading } = useApiMethod({
        api: orders.exportOrders,
        successCallback: ({ data, headers }) => {
            downloadBlob(data, headers);
            close();
        },
    });

    const questrooms = useAppSelector(selectAllQuestrooms({})) || [];
    const users = useAppSelector(selectAllUsers(user.id)) || [];

    const { countData, countLoading, countFetch } = useApiMethod({
        api: orders.count,
    });

    useEffect(() => {
        countFetch(values);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    const playersCountOptions = Array.from({ length: 30 }, (_, index) => {
        const count = index + 1;

        return { key: count, content: `${count} ${t('ppl')}` };
    });

    return (
        <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
            <FlexColumns columns={ 1 } gr={ 8 } gc={ 0 }>
                <Typography.Text view="title" weight="medium">
                    { t('order.gameDate') }
                </Typography.Text>

                <FlexColumns columns={ 2 } gr={ 16 } gc={ 16 }>
                    <CalendarInput
                        dataTestId="date.0"
                        label={ t('from') }
                        value={ values.date[0] }
                        clear={ true }
                        onClear={ () => {
                            setFieldValue('date.0', '');
                        } }
                        onChange={ (e, { value }) => {
                            setFieldValue('date.0', value);
                        } }
                        block={ true }
                        lang={ i18n.language }
                    />
                    <CalendarInput
                        dataTestId="date.1"
                        label={ t('to') }
                        clear={ true }
                        onClear={ () => {
                            setFieldValue('date.1', '');
                        } }
                        value={ values.date[1] }
                        onChange={ (e, { value }) => {
                            setFieldValue('date.1', value);
                        } }
                        block={ true }
                        lang={ i18n.language }
                    />
                </FlexColumns>
            </FlexColumns>
            <FlexColumns columns={ 1 } gr={ 8 } gc={ 0 }>
                <Typography.Text view="title" weight="medium">
                    { t('Дата создания') }
                </Typography.Text>

                <FlexColumns columns={ 2 } gr={ 16 } gc={ 16 }>
                    <CalendarInput
                        dataTestId="created.0"
                        label={ t('from') }
                        value={ values.created[0] }
                        clear={ true }
                        onClear={ () => {
                            setFieldValue('created.0', '');
                        } }
                        onChange={ (e, { value }) => {
                            setFieldValue('created.0', value);
                        } }
                        block={ true }
                        lang={ i18n.language }
                    />
                    <CalendarInput
                        dataTestId="created.1"
                        label={ t('to') }
                        clear={ true }
                        onClear={ () => {
                            setFieldValue('created.1', '');
                        } }
                        value={ values.created[1] }
                        onChange={ (e, { value }) => {
                            setFieldValue('created.1', value);
                        } }
                        block={ true }
                        lang={ i18n.language }
                    />
                </FlexColumns>
            </FlexColumns>

            <QuestroomsSelect
                label={ t('tables.quest') }
                questrooms={ questrooms }
                selected={ values.questroomsIds }
                multiple={ true }
                onChange={ ({ selectedMultiple }) =>
                    setFieldValue(
                        'questroomsIds',
                        selectedMultiple.map((i) => i.key),
                    ) }
            />

            <Select
                dataTestId="orderStatus"
                block={ true }
                optionsListWidth="field"
                label={ t('options.orderStatus.title') }
                allowUnselect={ true }
                options={ enumToOptions(OrderStatusEnum, t, 'orderStatus') }
                selected={ values.orderStatus }
                onChange={ ({ selected }) => setFieldValue('orderStatus', selected?.key) }
            />
            <Select
                dataTestId="source"
                block={ true }
                allowUnselect={ true }
                optionsListWidth="field"
                label={ t('tables.source') }
                options={ enumToOptions(SourceEnum, t, 'source') }
                selected={ values.source }
                onChange={ ({ selected }) => setFieldValue('source', selected?.key) }
            />
            <SelectSearch
                dataTestId="moderatorsIds"
                multiple={ true }
                block={ true }
                optionsListWidth="field"
                allowUnselect={ true }
                label={ t('staffConductedGame') }
                options={ convertToOptions(users) }
                selected={ values.moderatorsIds }
                onChange={ ({ selectedMultiple }) =>
                    setFieldValue(
                        'moderatorsIds',
                        selectedMultiple.map((i) => i.key),
                    ) }
            />

            <Select
                dataTestId="players"
                selected={ values.players }
                popoverPosition="top"
                block={ true }
                allowUnselect={ true }
                optionsListWidth="field"
                label={ t('component.numberPeople') }
                onChange={ ({ selected }) => setFieldValue('players', selected?.key) }
                options={ playersCountOptions }
            />

            <Select
                dataTestId="language"
                block={ true }
                popoverPosition="top"
                optionsListWidth="field"
                allowUnselect={ true }
                label={ t('language') }
                options={ languageOptions(Object.values(Languages)) }
                selected={ values.language }
                onChange={ ({ selected }) => setFieldValue('language', selected?.key) }
            />

            <Collapse collapsedLabel="UTM" expandedLabel="Hide">
                <FlexColumns columns={ 1 }>
                    <Input
                        label="Utm campaign"
                        value={ values.utmCampaign }
                        onChange={ handleChange('utmCampaign') }
                        block={ true }
                    />
                    <Input
                        label="Utm content"
                        value={ values.utmContent }
                        onChange={ handleChange('utmContent') }
                        block={ true }
                    />
                    <Input
                        label="Utm medium"
                        value={ values.utmMedium }
                        onChange={ handleChange('utmMedium') }
                        block={ true }
                    />
                    <Input
                        label="Utm source"
                        value={ values.utmSource }
                        onChange={ handleChange('utmSource') }
                        block={ true }
                    />
                    <Input
                        label="Utm term"
                        value={ values.utmTerm }
                        onChange={ handleChange('utmTerm') }
                        block={ true }
                    />
                </FlexColumns>
            </Collapse>

            { typeof countData === 'number' && (
                <ExportAndCount
                    loading={ countLoading }
                    exportLoading={ exportOrdersLoading }
                    exportFetch={ () => exportOrdersFetch(values) }
                    count={ countData }
                />
            ) }
        </FlexColumns>
    );
};

export const OrdersFiltersModal: React.FC<Props> = ({ initialValues, close, apply }) => {
    const { t } = useTranslation();

    return (
        <RestForm
            save={ (values) => {
                apply(values);
                close();
            } }
            title={ t('filters.label') }
            submitButtonText={ t('Применить') }
            initialValues={ serializeRecord(QueryOrderDto, initialValues || {}) }
            close={ close }
            t={ t }
        >
            { (props: FormikProps<QueryOrderDto>) => <Form props={ props } close={ close } /> }
        </RestForm>
    );
};
