/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import { useEffect, useState } from 'react';
import { showError, ToastNotifier } from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CreateOrderPaymentDto } from '@escapenavigator/types/dist/order-transaction/create-order-payment.dto';
import { CreateOrderStripeRefundDto } from '@escapenavigator/types/dist/order-transaction/create-order-stripe-refund.dto';
import { CreateOrderUpsellingDto } from '@escapenavigator/types/dist/order-upselling/create-order-upselling.dto';
import { OrderRO } from '@escapenavigator/types/dist/order/order.ro';
import { Languages } from '@escapenavigator/types/dist/shared/enum/languages.enum';
import { getServiceError } from '@escapenavigator/utils/dist/get-service-error';
import { AxiosPromise } from 'axios';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

export const useOrderApi = (id: number, successCalback: () => void, t: TFunction) => {
    const [order, setOrderState] = useState<OrderRO>({} as OrderRO);

    const [updating, setUpdating] = useState(false);

    const setOrder = (values?: OrderRO, initial?: boolean) => {
        setOrderState(values);
        if (successCalback && !initial) {
            successCalback();
        }
    };

    const {
        orders,
        orderUpsellngs,
        orderDiscounts,
        orderPenalties,
        orderCertificates,
        orderCertificateUploads,
        orderPromocodes,
        orderTransactionsApi,
    } = useApi();

    const { getOneFetch, getOneLoading } = useApiMethod({
        api: orders.getOne,
        successCallback: ({ data }) => setOrder(data, true),
        errorCallback: ({ message }) => showError(message),
    });

    useEffect(() => {
        getOneFetch(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const asyncRequest = (promise: AxiosPromise<unknown>) => {
        // eslint-disable-next-line no-restricted-globals

        setUpdating(true);

        return promise
            .then(({ data }) => {
                const orderData = data as unknown as OrderRO;

                setOrder(orderData);
                setUpdating(false);

                ToastNotifier({
                    text: 'Data saved successfully',
                    position: 'bottom',
                    offset: 0,
                    view: 'black',
                });

                return orderData;
            })
            .catch((err) => {
                showError(getServiceError(err).message);
                setUpdating(false);
            });
    };

    const removePenalty = (penaltyId: number) =>
        confirm('are you sure?') && asyncRequest(orderPenalties.remove(penaltyId));

    const updatePLayers = (players: number) =>
        asyncRequest(orders.updatePlayers({ id: order.id, data: { players } }));

    const removeCertificate = (certificateId: number) =>
        confirm('are you sure?') && asyncRequest(orderCertificates.remove(certificateId));

    const removeUploadCertificate = (certificateId: number) =>
        confirm('are you sure?') && asyncRequest(orderCertificateUploads.remove(certificateId));

    const createUpselling = ({ upsellings }: Pick<CreateOrderUpsellingDto, 'upsellings'>) =>
        confirm('are you sure?') &&
        asyncRequest(
            orderUpsellngs.create({
                id: order.id,
                data: { upsellings },
            }),
        );

    const removePromocode = (id: number) =>
        confirm('are you sure?') && asyncRequest(orderPromocodes.remove(id));

    const updateLanguage = (language: Languages) =>
        asyncRequest(orders.update({ data: { language }, id }));

    const removeUpselling = (upsellingId: number) =>
        confirm('are you sure?') && asyncRequest(orderUpsellngs.remove(upsellingId));

    const bookImmediately = (id: number) =>
        confirm(t('bookImmediatelyDescription')) && asyncRequest(orders.bookImmediately({ id }));

    const removeHandDiscount = (discountID: number) =>
        confirm('are you sure?') && asyncRequest(orderDiscounts.remove(discountID));

    const changeResult = ({ result, photos }: { result: number; photos: string[] }) =>
        asyncRequest(
            orders.update({
                id: order.id,
                data: {
                    photos,
                    result,
                },
            }),
        );

    const addTransaction = (data: CreateOrderPaymentDto) =>
        asyncRequest(orderTransactionsApi.createOrderPayment({ data }));

    const addRefund = (data: CreateOrderStripeRefundDto) =>
        asyncRequest(orderTransactionsApi.createOrderStripeRefund({ data }));

    return {
        order,
        loading: getOneLoading,
        updating,
        changeResult,
        createUpselling,
        bookImmediately,
        setOrder,
        removePenalty,
        removeUploadCertificate,
        addRefund,
        removeUpselling,
        updateLanguage,
        updatePLayers,
        removeCertificate,
        removeHandDiscount,
        removePromocode,
        addTransaction,
    };
};
