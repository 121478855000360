// /* eslint-disable no-lone-blocks */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckmarkCircleS } from '@alphakits/icons';
import { Padding, Status } from '@alphakits/ui/dist';

// import bookeo from './bookeo.png';
// import simplebooks from './simplebooks.jpeg';

export const ConnectedCell: React.FC = () => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Padding padding="8px 0">
                <Status view="contrast" color="grey">
                    <CheckmarkCircleS /> { t('Подключена') }
                </Status>
            </Padding>
        </React.Fragment>
    );
};
