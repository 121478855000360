import React from 'react';
import { Link } from 'react-router-dom';
import { CheckmarkCircleM, ChevronForwardM, ClockCircleM } from '@alphakits/icons';
import { Cell, Flex, Image } from '@alphakits/ui/dist';

import styles from './index.module.css';

type Props = {
    done: boolean;
    title: string;
    link: string;
    close: () => void;
};

export const VerifcationCell: React.FC<Props> = ({
    done, title, link, close,
}) => (
    <Link to={ link } onClick={ close } className={ styles.container }>
        <Flex gap="sm">
            <Flex gap="sm" align="start">
                <Cell.Base
                    title={ title }
                    addon={ (
                        <Image
                            className={ done && styles.done }
                            icon={ done ? <CheckmarkCircleM /> : <ClockCircleM /> }
                        />
                    ) }
                />
            </Flex>

            <ChevronForwardM />
        </Flex>
    </Link>
);
