import React from 'react';

export const AlphaLogo = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="32"
        height="32"
        style={ { width: 28 } }
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        { ...props }
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.6756 3.08218C4.67672 3.08218 3.0563 4.7026 3.0563 6.70148V23.2698C3.0563 25.3132 4.71273 26.9696 6.75603 26.9696H7.59175C8.57698 26.9696 9.52493 26.5929 10.2415 25.9167L12.9953 23.3178L10.3074 20.6123C9.71256 20.0136 9.7157 19.046 10.3144 18.4512C10.9131 17.8563 11.8807 17.8595 12.4755 18.4582L19.8467 25.8774C20.5412 26.5765 21.4859 26.9696 22.4713 26.9696H23.244C25.2873 26.9696 26.9437 25.3132 26.9437 23.2698C26.9437 21.8037 26.3624 20.3974 25.3273 19.3592L10.5366 4.52434C9.61606 3.60109 8.36595 3.08218 7.06222 3.08218H6.6756ZM26.9437 16.6517L26.9437 6.78191C26.9437 4.73861 25.2873 3.08218 23.244 3.08218H22.8157C21.6185 3.08218 20.4725 3.56738 19.6393 4.42697L17.2318 6.9108L26.9437 16.6517ZM15.0735 4.74614L12.7009 2.36643C11.207 0.868041 9.17811 0.0258789 7.06222 0.0258789H6.6756C2.98877 0.0258789 0 3.01465 0 6.70148V23.2698C0 27.0011 3.02478 30.0259 6.75603 30.0259H7.59175C9.35695 30.0259 11.0554 29.351 12.3391 28.1395L15.1501 25.4866L17.6785 28.0315C18.9468 29.308 20.6718 30.0259 22.4713 30.0259H23.244C26.9752 30.0259 30 27.0011 30 23.2698L30 6.78191C30 3.05066 26.9752 0.0258812 23.244 0.0258809H22.8157C20.7914 0.0258807 18.8536 0.846302 17.4448 2.29979L15.0735 4.74614Z"
            fill="#1189FA"
        />
    </svg>
);
