import React from 'react';
import { FlexColumns } from '@alphakits/ui/dist';

import { BookingRules } from './booking-rules';
import { Fields } from './fileds';
import { OnlinePayments } from './online-payments';
import { Resources } from './resources';
import { Tariffs } from './tariffs';

export const BookingSettingsPage: React.FC = () => (
    <FlexColumns columns={ 1 } gr={ 32 }>
        <Tariffs />
        <BookingRules />
        <OnlinePayments />
        <Fields />
        <Resources />
        <br />
        <br />
        <br />
    </FlexColumns>
);
