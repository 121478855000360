/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ChevronDownS } from '@alphakits/icons';
import {
    Button,
    ButtonProps,
    IButtonProps,
    IconButton,
    Position,
    Select,
} from '@alphakits/ui/dist';
import classNames from 'classnames';
import { TFunction } from 'i18next';

import { PickerOptionButton } from './picker-option-button';

import styles from './index.module.css';

type Props = {
    options?: any;
    actions?: Record<string, () => void>;
    content: any;
    loading?: boolean;
    size?: IButtonProps['size'];
    view?: 'link' | 'ghost' | 'primary' | 'secondary' | 'outlined' | 'filled' | 'transparent';
    position?: Position;
    className?: string;
    block?: boolean;
    disabled?: boolean;
    t: TFunction;
};

export const Picker: React.FC<Props> = ({
    actions,
    loading,
    size = 'xs',
    content,
    view = 'outlined',
    position = 'bottom',
    block = false,
    options,
    className,
    disabled,
}) => {
    if (!options && !actions) return null;

    const optionsList =
        options ||
        Object.entries(actions).map(([key, onClick]) => ({
            key,
            content: <PickerOptionButton content={ key } onClick={ onClick } />,
        }));

    return (
        <Select
            offset={ [0, 10] }
            options={ optionsList }
            optionsListWidth="content"
            block={ block }
            className={ classNames(styles.picker, className) }
            popoverPosition={ position }
            selected={ [] }
            disabled={ disabled }
            closeOnSelect={ true }
            optionClassName={ styles.option_wrap }
            Field={ ({ innerProps }) => {
                if (typeof content === 'object') {
                    return (
                        <Button
                            size={ size }
                            loading={ loading }
                            { ...(innerProps as ButtonProps) }
                            block={ block }
                            view="ghost"
                        >
                            { content }
                        </Button>
                    );
                }

                if (typeof content === 'string') {
                    return (
                        <Button
                            size={ size }
                            block={ block }
                            loading={ loading }
                            rightAddons={ <ChevronDownS /> }
                            { ...(innerProps as ButtonProps) }
                            onClick={ (e: any) => {
                                innerProps.onClick(e);
                                e.stopPropagation();
                            } }
                            view={ view }
                        >
                            { content }
                        </Button>
                    );
                }

                return (
                    <div>
                        <IconButton
                            icon={ content }
                            loading={ loading }
                            { ...(innerProps as ButtonProps) }
                            onClick={ (e: any) => {
                                innerProps.onClick(e);
                                e.stopPropagation();
                            } }
                            view="ghost"
                            size="s"
                        />
                    </div>
                );
            } }
        />
    );
};
