import React from 'react';
import {
    Checkbox, FlexColumns, Input, useCrudFormRequests,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { UpdateCurrentGaDto } from '@escapenavigator/types/dist/profile/update-current-ga.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';

type Props = {
    close: () => void;
    t: TFunction;
};

export const GaModal: React.FC<Props> = ({ close, t }) => {
    const { profiles } = useApi();
    const { profile, setProfile } = useCurrentUser();

    const {
        save,
        updating,
        error: softError,
    } = useCrudFormRequests({
        saveRequest: profiles.updateGa,
        saveCallback: (savedRecords) => setProfile(savedRecords),
        close,
    });

    return (
        <RestForm
            title="Google analytics"
            initialValues={ serializeRecord(UpdateCurrentGaDto, profile) }
            validate={ validateByDto(t) }
            save={ save }
            softError={ softError }
            updating={ updating }
            close={ close }
            t={ t }
        >
            { ({
                values, handleChange, setFieldValue, touched, errors,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    <Checkbox
                        dataTestId="gaEnabled"
                        label={ t('Сбор аналитики включен') }
                        checked={ values.gaEnabled }
                        onChange={ (e, { checked }) => setFieldValue('gaEnabled', checked) }
                    />
                    { values.gaEnabled && (
                        <React.Fragment>
                            <Input
                                dataTestId="ga4Id"
                                label="Measurement ID"
                                value={ values.ga4Id }
                                error={ touched.ga4Id && errors.ga4Id }
                                onChange={ handleChange('ga4Id') }
                                block={ true }
                            />
                            <Input
                                dataTestId="gaCoversationId"
                                label="AW-CONVERSION_ID"
                                value={ values.gaCoversationId }
                                error={ touched.gaCoversationId && errors.gaCoversationId }
                                onChange={ handleChange('gaCoversationId') }
                                block={ true }
                            />
                            <Input
                                dataTestId="gaCoversationLabel"
                                label="CONVERSION_LABEL"
                                error={ touched.gaCoversationLabel && errors.gaCoversationLabel }
                                value={ values.gaCoversationLabel }
                                onChange={ handleChange('gaCoversationLabel') }
                                block={ true }
                            />
                        </React.Fragment>
                    ) }
                </FlexColumns>
            ) }
        </RestForm>
    );
};
