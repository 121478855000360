import React from 'react';
import { formatDate } from '@alphakits/ui/dist';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { UserRO } from '@escapenavigator/types/dist/user/crud/user.ro';
import { differenceInMinutes } from 'date-fns';

import { SendInviteButton } from './send-invite-button';

export const UsersColumns = ({ t, roles }): TableColumns<UserRO> => [
    {
        header: t('name'),
        accessor: 'name',
        row: {
            title: ({ row }) => `${row.name} ${row.surname}`,
            subtitle: ({ row }) => roles.filter((r) => r.id === row.roleId)[0]?.title || '-',
            image: 'photo',
        },
    },

    {
        header: t('tables.contact'),
        accessor: 'email',
        row: {
            subtitle: 'phone',
        },
    },
    {
        header: t('lastVisit'),
        accessor: 'lastVisit',
        row: {
            title: ({ row }) => {
                if (row.lastVisit) return formatDate(row.lastVisit, { format: 'dd.MM.yyyy' });
                if (differenceInMinutes(new Date(), new Date(row.updatedAt)) > 2) {
                    return <SendInviteButton t={ t } id={ row.id } />;
                }

                return t('Приглашение отправлено');
            },
            subtitle: 'lastDevice',
        },
    },
];
