/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { EditS, TrashS } from '@alphakits/icons';
import {
    Box,
    Button,
    Divider,
    Flex,
    IconButton,
    ModalContext,
    Typography,
} from '@alphakits/ui/dist';
import { QuestroomRO } from '@escapenavigator/types/dist/questroom/questroom.ro';
import { SlotTemplateResponseObject } from '@escapenavigator/types/dist/slot-template/slot-template.ro';
import { ChangeTemplateSlotsFormDto } from '@escapenavigator/types/dist/slot/change-template-slots-form.dto';

import { EditTemplateSlotsModal } from '../edit-template-slots';

import { ScheduleSlot as ScheduleSlotType } from './utils';

import styles from './index.module.css';

export type SerializedTemplate = {
    questroom: QuestroomRO;
    hash: number;
    schedule: SlotTemplateResponseObject;
};

type Props = {
    selectedIds: string[];
    slots: ScheduleSlotType[];
    setSelectedIds: (selectedIds: string[]) => void;

    editSlots: (values: Pick<ChangeTemplateSlotsFormDto, 'ruleId' | 'tariffId'>) => void;
    deleteSlots: () => void;
};

export const SlotsMenu: React.FC<Props> = ({
    selectedIds,
    setSelectedIds,
    deleteSlots,
    slots,
    editSlots,
}) => {
    const { t } = useTranslation();

    const { openModal } = useContext(ModalContext);

    const handleRemoveSlots = () => {
        deleteSlots();
        setSelectedIds([]);
    };

    const selectSameSlots = () => {
        const rulesAndTarifs = selectedIds.reduce(
            (acc, id) => {
                const slot = slots.find((s) => s.id === id);

                acc.tariffs[slot.slot.tariffId] = true;
                acc.rules[slot.slot.ruleId] = true;

                return acc;
            },
            { tariffs: {}, rules: {} },
        );

        const ids = slots
            .filter(
                (s) =>
                    Object.keys(rulesAndTarifs.rules).includes(`${s.slot.ruleId}`) &&
                    Object.keys(rulesAndTarifs.tariffs).includes(`${s.slot.tariffId}`),
            )
            .map((t) => t.id);

        setSelectedIds(ids);
    };

    const openChangeSlotsModal = () =>
        openModal(EditTemplateSlotsModal)({
            tariffId: slots.find((slot) => slot.id === selectedIds[0]).slot.tariffId,
            ruleId: slots.find((slot) => slot.id === selectedIds[0]).slot.ruleId,
            submit: (values) => {
                editSlots(values);
                setSelectedIds([]);
            },
        });

    return (
        <Box
            border={ true }
            rounded="xs"
            padding="xs"
            background="var(--color-bg-secondary)"
            className={ styles.menu }
        >
            <Flex justify="start" gap="md">
                <Typography.Text view="primary-medium">
                    { t('Выбрано слотов') }:{ ' ' }
                    <Typography.Text view="primary-medium" weight="bold">
                        { selectedIds.length }
                    </Typography.Text>
                </Typography.Text>

                <Divider view="vertical" />

                <Button onClick={ () => setSelectedIds([]) } view="link" size="xs">
                    { t('Отменить') }
                </Button>

                <React.Fragment>
                    <Divider view="vertical" />

                    <Button onClick={ selectSameSlots } view="link" size="xs">
                        { t('Выбрать с такими же условиями') }
                    </Button>
                </React.Fragment>

                <Divider view="vertical" />

                <IconButton onClick={ openChangeSlotsModal } icon={ EditS } />

                <IconButton onClick={ handleRemoveSlots } icon={ TrashS } />
            </Flex>
        </Box>
    );
};
