import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CertificateTypeM, DownloadS, TrashS } from '@alphakits/icons';
import {
    Button,
    Flex,
    FlexColumns,
    formatDate,
    showError,
    Sidepanel,
    Tooltip,
    Typography,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { OrderRO } from '@escapenavigator/types/dist/order/order.ro';
import { getFullName } from '@escapenavigator/utils/dist/get-full-name';
import { differenceInYears } from 'date-fns';
import { ClientSearch } from 'src/components/client-search';
import { TextCell } from 'src/components/text-cell';
import { useApi } from 'src/providers/api/context';

type Props = {
    order: OrderRO;
};

export const Participants: React.FC<Props> = ({ order }) => {
    const { orders } = useApi();
    const { t, i18n } = useTranslation();

    const { getOrderParticipantsFetch, getOrderParticipantsLoading, getOrderParticipantsData } =
        useApiMethod({
            api: orders.getOrderParticipants,
        });

    const { addParticipantFetch, addParticipantLoading } = useApiMethod({
        api: orders.addParticipant,
        successCallback: () => getOrderParticipantsFetch({ orderId: order.id }),
        errorCallback: () => showError('Please, try to add participant later'),
    });

    const { removeParticipantByAdminFetch, removeParticipantByAdminLoading } = useApiMethod({
        api: orders.removeParticipantByAdmin,
        successCallback: () => getOrderParticipantsFetch({ orderId: order.id }),
        errorCallback: () => showError('Please, try to remove participant later'),
    });

    const { getWaiverPdfFetch, getWaiverPdfLoading } = useApiMethod({
        api: orders.getWaiverPdf,
        errorCallback: () => showError('Please, try to generate pdf later'),
        successCallback: ({ data }) => {
            const blob = new Blob([data], { type: 'application/pdf' });

            const link = document.createElement('a');

            link.href = window.URL.createObjectURL(blob);
            link.download = 'waiver.pdf';
            link.click();

            window.URL.revokeObjectURL(link.href);
        },
    });

    useEffect(() => {
        getOrderParticipantsFetch({ orderId: order.id });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Sidepanel
            title={ t('Участники игры') }
            loading={
                getOrderParticipantsLoading ||
                addParticipantLoading ||
                removeParticipantByAdminLoading ||
                removeParticipantByAdminLoading
            }
        >
            <FlexColumns columns={ 1 } gr={ 32 }>
                <FlexColumns columns={ 1 }>
                    { !getOrderParticipantsData?.participants.length && (
                        <Typography.Text view="title" color="primary">
                            { t('Участники не добавлены') }
                        </Typography.Text>
                    ) }

                    { getOrderParticipantsData?.participants.map((p) => {
                        const age =
                            p.client.birthday &&
                            differenceInYears(new Date(), new Date(p.client.birthday));

                        return (
                            <TextCell
                                subtitle={ getFullName(p.client) }
                                title={ age && age < 18 ? t('Minor', { age }) : t('Adult') }
                                rightAddons={ (
                                    <Flex justify="start" gap="md">
                                        { p.orderWaiver && (
                                            <Tooltip
                                                trigger="hover"
                                                position="bottom-end"
                                                content={ (
                                                    <div style={ { width: 200, overflow: 'auto' } }>
                                                        { t('signed by', {
                                                            date: formatDate(
                                                                new Date(p.orderWaiver.createdAt),
                                                                {
                                                                    lang: i18n.language,
                                                                },
                                                            ),
                                                        }) }
                                                    </div>
                                                ) }
                                            >
                                                <CertificateTypeM fill="var(--color-graphic-positive)" />
                                            </Tooltip>
                                        ) }

                                        { !p.orderWaiver &&
                                            getOrderParticipantsData?.isWaiverActive && (
                                            <Button
                                                view="outlined"
                                                href={ `${process.env.REACT_APP_ORDERS_DOMAIN}?waiver=${getOrderParticipantsData?.orderToken}` }
                                                target="_blank"
                                                size="xs"
                                            >
                                                { t('Подписать') }
                                            </Button>
                                        ) }

                                        <Button
                                            onClick={ () => {
                                                removeParticipantByAdminFetch({
                                                    participantId: p.id,
                                                });
                                            } }
                                            leftAddons={ <TrashS /> }
                                            size="xs"
                                            view="outlined"
                                        />
                                    </Flex>
                                ) }
                            />
                        );
                    }) }
                </FlexColumns>

                <FlexColumns columns={ 1 } gr={ 12 }>
                    <Typography.Title tag="div" view="xxsmall" weight="bold">
                        { t('Добавить участника') }
                    </Typography.Title>

                    <ClientSearch
                        t={ t }
                        onSelect={ (v) => {
                            addParticipantFetch({
                                orderId: order.id,
                                clientId: v.id,
                            });
                        } }
                    />
                </FlexColumns>

                { getOrderParticipantsData?.isWaiverActive && (
                    <Button
                        view="outlined"
                        onClick={ () => getWaiverPdfFetch({ orderId: order.id }) }
                        leftAddons={ <DownloadS /> }
                        loading={ getWaiverPdfLoading }
                        size="s"
                    >
                        { t('Download waiver PDF') }
                    </Button>
                ) }
            </FlexColumns>
        </Sidepanel>

    //     <RestForm
    //         t={ t }
    //         title={ t('Участники игры') }
    //         initialValues={ serializeRecord(UpdateParticipantsDto, {
    //             participants: order.participants || [],
    //         }) }
    //         validate={ validateByDto(t) }
    //         loading={ false }
    //         updating={ updateParticipantsLoading }
    //         removing={ false }
    //         close={ close }
    //         save={ async (data) => {
    //             updateParticipantsFetch({ id: order.id, data });
    //         } }
    //     >
    //         { ({
    //             values, handleChange, setFieldValue, touched,
    //         }) => (
    //             <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
    //                 { !values.participants.length && (
    //                     <Typography.Text view="title" color="primary">
    //                         { t('Участники не добавлены') }
    //                     </Typography.Text>
    //                 ) }

    //                 { values.participants.map((participant, index) => (
    //                     // eslint-disable-next-line react/no-array-index-key
    //                     <FlexColumns columns={ 1 } gr={ 12 }>
    //                         <FlexColumns columns={ 3 } gc={ 12 }>
    //                             <Input
    //                                 label={ t('email') }
    //                                 value={ participant.email }
    //                                 onChange={ handleChange(`participants.${index}.email`) }
    //                                 error={ touched.participants && !participant.email && 'Required' }
    //                             />

    //                             <Input
    //                                 label={ t('name') }
    //                                 block={ true }
    //                                 value={ participant.name }
    //                                 onChange={ handleChange(`participants.${index}.name`) }
    //                                 error={ touched.participants && !participant.name && 'Required' }
    //                             />

    //                             <Input
    //                                 label={ t('surname') }
    //                                 value={ participant.surname }
    //                                 block={ true }
    //                                 onChange={ handleChange(`participants.${index}.surname`) }
    //                             />
    //                         </FlexColumns>
    //                         <Flex>
    //                             <Checkbox
    //                                 label={ t('sendAds') }
    //                                 checked={ !!participant.sendAds }
    //                                 onChange={ (e, { checked }) =>
    //                                     setFieldValue(`participants.${index}.sendAds`, checked) }
    //                             />

    //                             <Button
    //                                 onClick={ () => {
    //                                     values.participants.splice(index, 1);
    //                                     setFieldValue('participants', values.participants);
    //                                 } }
    //                                 leftAddons={ <TrashS /> }
    //                                 view="ghost"
    //                                 size="xs"
    //                             >
    //                                 { t('remove') }
    //                             </Button>
    //                         </Flex>

    //                         <Divider />
    //                     </FlexColumns>
    //                 )) }

    //                 <Button
    //                     onClick={ () =>
    //                         setFieldValue('participants', [
    //                             ...values.participants,
    //                             {
    //                                 name: '',
    //                                 surname: '',
    //                                 email: '',
    //                                 sendAds: true,
    //                             },
    //                         ]) }
    //                     leftAddons={ <PlusS /> }
    //                     view="ghost"
    //                     size="xs"
    //                 >
    //                     { t('Добавить') }
    //                 </Button>
    //             </FlexColumns>
    //         ) }
    //     </RestForm>
    // );
    );
};
