/* eslint-disable react/no-array-index-key */
import React from 'react';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { formatAmount } from '@escapenavigator/utils/dist/format-amount';
import {
    Cell, Pie, PieChart, ResponsiveContainer,
} from 'recharts';
import { EmptyState } from 'src/components/empty-state';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;

export const DoublePieComponent = ({
    data1,
    data2,
    currency,
}: {
    data1?: Array<Record<string, unknown>>;
    data2?: Array<Record<string, unknown>>;
    currency?: ProfileCurrencyEnum;
}) => {
    if (!data1?.length && !data2?.length) return <EmptyState title="No data" />;

    const renderInsideLabel = ({
        cx, cy, midAngle, innerRadius, outerRadius, percent,
    }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text
                fontSize={ 12 }
                x={ x }
                y={ y }
                fill="white"
                textAnchor={ x > cx ? 'start' : 'end' }
                dominantBaseline="central"
            >
                { `${(percent * 100).toFixed(0)}%` }
            </text>
        );
    };

    const renderCustomizedLabel = ({
        cx, cy, midAngle, innerRadius, outerRadius, name, value,
    }) => {
        const radius = innerRadius + (outerRadius - innerRadius) + 30;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text
                fontSize={ 12 }
                x={ x }
                y={ y }
                fill="var(--color-text-primary)"
                textAnchor={ x > cx ? 'start' : 'end' }
                dominantBaseline="central"
            >
                { `${name} (${currency ? formatAmount(value, currency) : value})` }
            </text>
        );
    };

    return (
        <ResponsiveContainer width="100%" height={ 500 }>
            <PieChart>
                { data1 && (
                    <Pie
                        data={ data1 }
                        dataKey="value"
                        cx="50%"
                        cy="50%"
                        outerRadius={ 120 }
                        labelLine={ false }
                        fill="#8884d8"
                        label={ renderInsideLabel }
                    />
                ) }
                { data2 && (
                    <Pie
                        data={ data2 }
                        dataKey="value"
                        label={ renderCustomizedLabel }
                        cx="50%"
                        cy="50%"
                        innerRadius={ data1 ? 140 : 100 }
                        outerRadius={ data1 ? 200 : 180 }
                        fill="#82ca9d"
                    >
                        { data2.map((entry, index) => (
                            <Cell key={ `cell-${index}` } fill={ COLORS[index % COLORS.length] } />
                        )) }
                    </Pie>
                ) }
            </PieChart>
        </ResponsiveContainer>
    );
};
