import React, { useCallback, useRef, useState } from 'react';
import {
    InputAutocomplete,
    OptionShape,
    OptionsList,
    OptionsListProps,
    SelectProps,
    useClickOutside,
} from '@alphakits/ui';

type TimeInputProps = {
    options: OptionShape[];
    onChange: (option?: OptionShape) => void;
} & Omit<SelectProps, 'onChange'>;

export const CustomOptionsList = React.forwardRef<HTMLDivElement, OptionsListProps>(
    ({ inputProps, ...props }, ref) => <OptionsList { ...props } ref={ ref } />,
);

export const TimeInput = ({
    options, onChange, selected = '', ...restProps
}: TimeInputProps) => {
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [searchText, setSearchText] = useState(selected as string);
    const listRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleSelect = useCallback(
        (option: OptionShape) => {
            const humanResult =
                typeof option.content === 'string' ? option.content : option.key.toString();

            setSearchText(humanResult);
            onChange(option);
            setFilteredOptions(options);
        },
        [onChange, options],
    );

    function handleInputChange(value: string) {
        setSearchText(value);
        const res = [...options].filter(
            (option) =>
                (option.content as string)?.startsWith?.(value) ||
                option.key.toString().startsWith(value),
        );

        setFilteredOptions(res);
        onChange();
    }

    const handleClear = useCallback(() => {
        setFilteredOptions(options);
        setSearchText('');
        onChange({ key: '' });
    }, [onChange, options]);

    const handleClickOutside = useCallback(() => {
        if (filteredOptions.length === 1) {
            return handleSelect(filteredOptions[0]);
        }

        return !selected && handleClear();
    }, [filteredOptions, handleSelect, handleClear, selected]);

    useClickOutside(listRef, handleClickOutside, [inputRef]);

    return (
        <InputAutocomplete
            { ...restProps }
            selected={ selected }
            options={ filteredOptions.length ? filteredOptions : options }
            size="s"
            optionsListProps={ {
                ref: listRef,
            } }
            inputProps={ {
                ref: inputRef,
                // clear: true,
                onClear: handleClear,
            } }
            closeOnSelect={ true }
            onInput={ (e) => {
                handleInputChange(e.target.value);
            } }
            onChange={ (e) => handleSelect(e.selected) }
            value={ searchText }
            OptionsList={ CustomOptionsList }
        />
    );
};
