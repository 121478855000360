import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchM } from '@alphakits/icons';
import {
    Button, Flex, ModalContext, Typography, useMatchMedia,
} from '@alphakits/ui/dist';
import { GlobalSearchModal } from 'src/modals/global-search';

export const SearchButton = () => {
    const { openModal } = useContext(ModalContext);
    const [isDesktop] = useMatchMedia('(min-width: 981px)');

    const { t } = useTranslation();
    const openSearch = openModal(GlobalSearchModal);

    return (
        <Button onClick={ () => openSearch({ header: { title: 'chat' } }) } view="ghost">
            <Typography.Text view="title" color="secondary">
                <Flex gap="sm">
                    <SearchM style={ { color: 'var(--color-graphic-secondary)' } } />

                    { isDesktop && t('Глобальный поиск') }
                </Flex>
            </Typography.Text>
        </Button>
    );
};
