import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    FlexColumns, Grid, Loader, showError, Typography,
} from '@alphakits/ui/dist';
import { SteppedProgressBar, useSteps } from '@alphakits/ui/dist/stepped-progress-bar';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CreateWidgetDto } from '@escapenavigator/types/dist/widget/create-widget.dto';
import { WidgetTypeEnum } from '@escapenavigator/types/dist/widget/enum/widget-type.enum';
import { InitWidgetsFormDto } from '@escapenavigator/types/dist/widget/init-widgets-form.dto';
import { WidgetRO } from '@escapenavigator/types/dist/widget/widget.ro';
import { useApi } from 'src/providers/api/context';
import { selectAllQuestrooms } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

import { Step } from './step';
import { Step0 } from './step0';

const exampes = {
    all: [
        'https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/widget-guide/all1.webp',
        'https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/widget-guide/all2.webp',
        'https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/widget-guide/all3.webp',
    ],
    one: [
        'https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/widget-guide/one1.webp',
        'https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/widget-guide/one2.webp',
        'https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/widget-guide/one3.webp',
    ],
    button: [
        'https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/widget-guide/button1.webp',
        'https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/widget-guide/button2.webp',
        'https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/widget-guide/button3.webp',
    ],
    cert: [
        'https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/widget-guide/cert1.webp',
        'https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/widget-guide/cert2.webp',
    ],
};

const MAX_STEPS = 4;

type Props = {
    setWidgets: (widgets: WidgetRO[]) => void;
};

export const InitWidgets: React.FC<Props> = ({ setWidgets }) => {
    const { t } = useTranslation();
    const { widgets } = useApi();

    const { step, nextStep, prevStep } = useSteps({ maxStep: MAX_STEPS, initialStep: 0 });

    const questrooms = useAppSelector(selectAllQuestrooms({ deleted: false, closed: false }));

    const [questroomsWidgets, setQuestroomsWidgets] = useState<InitWidgetsFormDto['widgets']>(
        questrooms.map((q) => ({
            type: WidgetTypeEnum.ONE_QUESTROOM,
            id: `${q.id}`,
            label: q.title,
            locationIds: [],
            allLocations: true,
            url: '',
        })),
    );

    const [locationsWidgets, setLocationsWidgets] = useState<InitWidgetsFormDto['widgets']>([
        {
            type: WidgetTypeEnum.ALL_QUESTROOM,
            locationIds: [],
            allLocations: true,
            url: '',
            id: `${Math.random()}`,
        },
    ]);

    const [buttonWidgets, setButtonWidgets] = useState<InitWidgetsFormDto['widgets']>([
        {
            type: WidgetTypeEnum.WIDGET_BUTTON,
            locationIds: [],
            allLocations: true,
            url: '',
            id: `${Math.random()}`,
        },
    ]);

    const { initFetch, initLoading } = useApiMethod({
        api: widgets.init,
        errorCallback: ({ message }) => showError(message),
        successCallback: ({ data }) => {
            setWidgets(data);
        },
    });

    const handleSubmit = (certificates: InitWidgetsFormDto['widgets']) => {
        initFetch({
            widgets: [
                ...questroomsWidgets
                    .filter((q) => q.url)
                    .map((w) => ({
                        type: WidgetTypeEnum.ONE_QUESTROOM,
                        questroomId: +w.id,
                        url: w.url,
                        allCertificates: true,
                        allLocations: true,
                        locationIds: [],
                    })),

                ...buttonWidgets
                    .filter((q) => q.url)
                    .map((w) => ({
                        type: WidgetTypeEnum.WIDGET_BUTTON,
                        url: w.url,
                        allCertificates: true,
                        allLocations: w.allLocations,
                        locationIds: w.locationIds,
                    })),

                ...locationsWidgets
                    .filter((q) => q.url)
                    .map((w) => ({
                        type: WidgetTypeEnum.ALL_QUESTROOM,
                        url: w.url,
                        allCertificates: true,
                        allLocations: w.allLocations,
                        locationIds: w.locationIds,
                    })),

                ...certificates
                    .filter((q) => q.url)
                    .map((w) => ({
                        type: WidgetTypeEnum.ALL_CERTIFICATES,
                        url: w.url,
                        allCertificates: true,
                        allLocations: true,
                    })),
            ] as CreateWidgetDto[],
        });
    };

    const titles = {
        1: `${t('widget-guide:guide.all_questrooms.title')}`,
        2: `${t('widget-guide:guide.one_questroom.title')}`,
        3: `${t('widget-guide:guide.button_widget.title')}`,
        4: `${t('widget-guide:guide.all_certificates.title')}`,
    };

    if (initLoading) return <Loader />;

    return (
        <React.Fragment>
            <Grid.Row>
                <Grid.Col width={ 6 }>
                    { !!step && (
                        <FlexColumns columns={ 1 } gr={ 12 }>
                            <Typography.Title tag="div" view="xsmall" weight="bold">
                                { titles[step] }
                            </Typography.Title>

                            <SteppedProgressBar step={ step } maxStep={ MAX_STEPS } />
                        </FlexColumns>
                    ) }
                    { step === 0 && <Step0 onClick={ nextStep } /> }
                </Grid.Col>
            </Grid.Row>
            <br />
            { step === 1 && (
                <Step
                    title={ t('widget-guide:guide.all_questrooms.description') }
                    hint={ t('widget-guide:guide.all_questrooms.hint') }
                    type={ WidgetTypeEnum.ALL_QUESTROOM }
                    initialValues={ { widgets: locationsWidgets } }
                    images={ exampes.all }
                    nextStep={ (values) => {
                        setLocationsWidgets(values.widgets);
                        nextStep();
                    } }
                    showAddButton={ true }
                    showLocationsPicker={ true }
                />
            ) }

            { step === 2 && (
                <Step
                    title={ t('widget-guide:guide.one_questroom.description') }
                    type={ WidgetTypeEnum.ONE_QUESTROOM }
                    hint={ t('widget-guide:guide.one_questroom.hint') }
                    initialValues={ { widgets: questroomsWidgets } }
                    nextStep={ (values) => {
                        setQuestroomsWidgets(values.widgets);
                        nextStep();
                    } }
                    images={ exampes.one }
                    prevStep={ prevStep }
                    showAddButton={ false }
                    showLocationsPicker={ false }
                />
            ) }

            { step === 3 && (
                <Step
                    title={ t('widget-guide:guide.button_widget.description') }
                    hint={ t('widget-guide:guide.button_widget.hint') }
                    type={ WidgetTypeEnum.WIDGET_BUTTON }
                    initialValues={ { widgets: buttonWidgets } }
                    nextStep={ (values) => {
                        setButtonWidgets(values.widgets);
                        nextStep();
                    } }
                    prevStep={ prevStep }
                    showAddButton={ true }
                    images={ exampes.button }
                    showLocationsPicker={ true }
                />
            ) }

            { step === 4 && (
                <Step
                    title={ t('widget-guide:guide.all_certificates.description') }
                    hint={ t('widget-guide:guide.all_certificates.hint') }
                    initialValues={ {
                        widgets: [
                            {
                                type: WidgetTypeEnum.ALL_CERTIFICATES,
                                allLocations: true,
                                allCertificates: true,
                                locationIds: [],
                                url: '',
                                id: `${Math.random()}`,
                            },
                        ],
                    } }
                    type={ WidgetTypeEnum.ALL_CERTIFICATES }
                    nextStep={ (values) => {
                        handleSubmit(values.widgets);
                    } }
                    images={ exampes.cert }
                    prevStep={ prevStep }
                    showAddButton={ true }
                    showLocationsPicker={ false }
                />
            ) }
        </React.Fragment>
    );
};
