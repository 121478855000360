import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, FlexColumns, showError } from '@alphakits/ui';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CrossSaleStatusEnum } from '@escapenavigator/types/dist/profile/cross-sale/cross-sale-status.enum';
import { CrossSaleDto } from '@escapenavigator/types/dist/profile/cross-sale/cross-sale.dto';
import { CrossSaleDiscountTypeEnum } from '@escapenavigator/types/dist/profile/cross-sale/crossale-discount-type.enum';
import { EarlyBookingDto } from '@escapenavigator/types/dist/profile/cross-sale/early-booking/early-booking.dto';
import { RetargetingDto } from '@escapenavigator/types/dist/profile/cross-sale/retargeting/retargeting.dto';
import { UpSaleDto } from '@escapenavigator/types/dist/profile/cross-sale/up-sale/up-sale.dto';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';

import { CrossSaleCard } from './cards/cross-sale-card';
import { EarlyBookingCard } from './cards/early-bookong-card';
import { RetargetingCard } from './cards/retargeting-card';
import { UpsaleCard } from './cards/upsale-card';

export const SalesPage: React.FC = () => {
    const { t, i18n } = useTranslation();
    const { profile } = useCurrentUser();
    const { crossSalesApi, orders } = useApi();

    const [initLoading, setInitLoading] = useState(true);

    const { getCrossSalesLoading, getCrossSalesData, getCrossSalesFetch } = useApiMethod({
        api: crossSalesApi.getCrossSales,
        errorCallback: ({ message }) => showError(message),
    });

    const { getUpSalesLoading, getUpSalesData, getUpSalesFetch } = useApiMethod({
        api: crossSalesApi.getUpSales,
        errorCallback: ({ message }) => showError(message),
    });

    const { recreateMarketingEmailsFetch } = useApiMethod({
        api: orders.recreateMarketingEmails,
    });

    const { getRetargetingLoading, getRetargetingData, getRetargetingFetch } = useApiMethod({
        api: crossSalesApi.getRetargeting,
        errorCallback: ({ message }) => showError(message),
    });

    const { getEarlyBookingLoading, getEarlyBookingData, getEarlyBookingFetch } = useApiMethod({
        api: crossSalesApi.getEarlyBooking,
        errorCallback: ({ message }) => showError(message),
    });

    const crossSaleDefaultValues = useRef({} as CrossSaleDto);
    const upsaleDefaultValues = useRef({} as UpSaleDto);
    const earlyBookingDefaultValues = useRef({} as EarlyBookingDto);
    const retargetingDefaultValues = useRef({} as RetargetingDto);

    useEffect(() => {
        i18n.reloadResources(
            ['en', 'de', 'et', 'ru', 'es', 'pt', 'pl', 'fr', 'it', 'el', 'nl', 'cs'],
            'crm-crosssales',
            () => {
                crossSaleDefaultValues.current = {
                    status: CrossSaleStatusEnum.ENABLED,
                    sendAfter: 24,
                    discount: 1000,
                    discountValidityDays: 30,
                    discountType: CrossSaleDiscountTypeEnum.PERCENT,
                    locales: profile.availableLanguages.map((l) => ({
                        title: t('crm-crosssales:templates.crossSale.title', { lng: l }),
                        feedbackText: t('crm-crosssales:templates.crossSale.text', {
                            profileTitle: profile.title,
                            lng: l,
                        }),
                        language: l,
                    })),
                };

                upsaleDefaultValues.current = {
                    status: CrossSaleStatusEnum.ENABLED,
                    sendImmediately: false,
                    sendBefore: 2,
                    locales: profile.availableLanguages.map((l) => ({
                        title: t('crm-crosssales:templates.upSale.title', { lng: l }),
                        feedbackText: t('crm-crosssales:templates.upSale.text', {
                            profileTitle: profile.title,
                            lng: l,
                        }),
                        language: l,
                    })),
                };

                earlyBookingDefaultValues.current = {
                    status: CrossSaleStatusEnum.ENABLED,
                    sendBefore: 3,
                    locales: profile.availableLanguages.map((l) => ({
                        title: t('crm-crosssales:templates.earlyBooking.title', { lng: l }),
                        feedbackText: t('crm-crosssales:templates.earlyBooking.text', {
                            profileTitle: profile.title,
                            lng: l,
                        }),
                        language: l,
                    })),
                };

                retargetingDefaultValues.current = {
                    status: CrossSaleStatusEnum.ENABLED,
                    discount: 1000,
                    discountValidityDays: 30,
                    discountType: CrossSaleDiscountTypeEnum.PERCENT,
                    locales: profile.availableLanguages.map((l) => ({
                        title: t('crm-crosssales:templates.retargeting.title', { lng: l }),
                        feedbackText: t('crm-crosssales:templates.retargeting.text', {
                            profileTitle: profile.title,
                            lng: l,
                        }),
                        language: l,
                    })),
                };

                getCrossSalesFetch(crossSaleDefaultValues.current);
                getUpSalesFetch(upsaleDefaultValues.current);
                getRetargetingFetch(retargetingDefaultValues.current);
                getEarlyBookingFetch(earlyBookingDefaultValues.current);
                setInitLoading(false);
            },
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FlexColumns columns={ 1 } gr={ 32 }>
            <Flex wrap={ true } gap="l" justify="start">
                <CrossSaleCard
                    data={ getCrossSalesData }
                    defaultValues={ crossSaleDefaultValues.current }
                    cb={ () => {
                        getCrossSalesFetch(undefined);
                        recreateMarketingEmailsFetch({ type: 'crossSale' });
                    } }
                    loading={ getCrossSalesLoading || initLoading }
                />

                <UpsaleCard
                    data={ getUpSalesData }
                    defaultValues={ upsaleDefaultValues.current }
                    cb={ () => {
                        getUpSalesFetch(undefined);
                        recreateMarketingEmailsFetch({ type: 'upSale' });
                    } }
                    loading={ getUpSalesLoading || initLoading }
                />

                <EarlyBookingCard
                    data={ getEarlyBookingData }
                    defaultValues={ earlyBookingDefaultValues.current }
                    cb={ () => {
                        getEarlyBookingFetch(undefined);
                        recreateMarketingEmailsFetch({ type: 'earlyBooking' });
                    } }
                    loading={ getEarlyBookingLoading || initLoading }
                />

                <RetargetingCard
                    data={ getRetargetingData }
                    defaultValues={ retargetingDefaultValues.current }
                    cb={ () => getRetargetingFetch(undefined) }
                    loading={ getRetargetingLoading || initLoading }
                />
            </Flex>
        </FlexColumns>
    );
};
