import React from 'react';
import { AmountInput, FlexColumns, Textarea } from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CertificateSaleRO } from '@escapenavigator/types/dist/certificate-sale/certificate-sale.ro';
import { CertificateRO } from '@escapenavigator/types/dist/certificate/certificate.ro';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { CreateCertificateSalePaymentDto } from '@escapenavigator/types/dist/transaction/create-certificate-sale-payment.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { CashboxesSelect } from 'src/components/selects/cashbox-select';
import { useApi } from 'src/providers/api/context';

type Props = {
    close: () => void;
    setCerificate: (data: CertificateSaleRO) => void;
    certificatesaleId: CertificateRO['id'];
    t: TFunction;
    currency: ProfileCurrencyEnum;
    amount?: number;
};

export const Transaction: React.FC<Props> = ({
    close,
    t,
    certificatesaleId,
    setCerificate,
    currency,
    amount = 0,
}) => {
    const { transactions } = useApi();

    const { createCertificatePaymentFetch, createCertificatePaymentLoading } = useApiMethod({
        api: transactions.createCertificatePayment,
        successCallback: ({ data }) => {
            setCerificate(data);
            close();
        },
    });

    return (
        <RestForm
            t={ t }
            title={ t('Добавление платежа в кассу') }
            initialValues={ serializeRecord(CreateCertificateSalePaymentDto, {
                certificatesaleId,
                amount,
            }) }
            validate={ validateByDto(t) }
            close={ close }
            save={ async (data) => {
                createCertificatePaymentFetch({ data });
            } }
            updating={ createCertificatePaymentLoading }
            removing={ false }
        >
            { ({
                values, handleChange, setFieldValue, errors, touched,
            }) => (
                <React.Fragment>
                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                        <CashboxesSelect
                            selected={ values.cashboxId }
                            error={ touched.cashboxId && errors.cashboxId }
                            onChange={ ({ selected }) => setFieldValue('cashboxId', selected?.key) }
                            label={ t('component.selectCashbox') }
                            type="all"
                        />
                        <AmountInput
                            value={ +values.amount }
                            error={ touched.amount && errors.amount }
                            onChange={ (e, { value }) => setFieldValue('amount', value) }
                            label={ t('component.amount') }
                            suffix={ currency }
                            required={ true }
                            type="decimal"
                            block={ true }
                        />
                        <Textarea
                            value={ values.comment }
                            onChange={ handleChange('comment') }
                            error={ touched.comment && errors.comment }
                            label={ t('Комментарий') }
                            block={ true }
                            maxLength={ 200 }
                            counter={ true }
                        />
                    </FlexColumns>
                </React.Fragment>
            ) }
        </RestForm>
    );
};
