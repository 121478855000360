import { format } from 'date-fns';
import { CalendarView } from 'src/pages/calendar/types';
import {
    createEnumParam,
    NumberParam,
    NumericArrayParam,
    StringParam,
    withDefault,
} from 'use-query-params';

export const QuestroomsIdsParam = (ids: number[]) => withDefault(NumericArrayParam, ids);

export const LocationIdParam = (locationId) => withDefault(NumberParam, locationId);

export const DateParam = withDefault(StringParam, format(new Date(), 'yyyy-MM-dd'));

export const ViewParam = withDefault<CalendarView, CalendarView>(
    createEnumParam(['day', 'week', 'slotsList', 'games']),
    'day',
);
