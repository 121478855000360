import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Avatar, Flex, Loader, SelectProps, Typography,
} from '@alphakits/ui/dist';
import { QuestroomRO } from '@escapenavigator/types/dist/questroom/questroom.ro';
import { SelectSearch } from 'src/components/select-search';
import { selectAllLocations } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

import { CellView } from '../base/cell-view';
import { ImagesGroup } from '../base/images-group';

import styles from '../index.module.css';

type Props = {
    questrooms: QuestroomRO[];
    loading?: boolean;
    asCell?: boolean;
    questroomtimeRule?: boolean;
} & Pick<
    SelectProps,
    'selected' | 'disabled' | 'label' | 'onChange' | 'hint' | 'error' | 'multiple' | 'block'
>;

export const QuestroomsSelect: React.FC<Props> = ({
    questrooms,
    disabled,
    loading,
    asCell,
    questroomtimeRule,
    onChange,
    multiple,
    block = true,
    label,
    hint,
    selected,
    error,
}) => {
    const locations = useAppSelector(selectAllLocations);
    const { t } = useTranslation();

    const selectedQuestrooms = questrooms.filter((q) =>
        (Array.isArray(selected) ? selected.includes(q.id) : selected === q.id));

    const time = selectedQuestrooms[0]?.time;

    return (
        <SelectSearch
            disabled={ disabled }
            valueRenderer={ () => {
                if (!selectedQuestrooms.length) return null;
                if (selectedQuestrooms.length === 1) return selectedQuestrooms[0].title;

                return `${t('Выбрано')} ${selectedQuestrooms.length}`;
            } }
            fieldProps={ {
                rightAddons: loading ? <Loader /> : <ImagesGroup images={ selectedQuestrooms } />,
            } }
            Field={
                asCell
                    ? (props) => (
                        <CellView
                            selectedItem={ selectedQuestrooms[0] }
                            loading={ loading }
                            { ...props }
                        />
                    )
                    : undefined
            }
            label={ label }
            allowUnselect={ true }
            multiple={ multiple }
            options={ locations.map((l) => ({
                label: l.title,
                options: questrooms
                    .filter((q) => q.locationId === l.id)
                    .map((q) => {
                        const differentTime = questroomtimeRule && time && time !== q.time;

                        return {
                            key: q.id,
                            disabled: differentTime,
                            content: (
                                <Flex className={ styles.option } justify="start" gap="sm">
                                    <Avatar key={ q.id } src={ q.photo } />
                                    <Typography.Text
                                        color={ differentTime ? 'tertiary' : 'primary' }
                                        view="title"
                                        weight="medium"
                                    >
                                        { q.title }
                                        { !q.templateId && ` (${t('Нет расписания')})` }
                                        { differentTime && ` (${t('время отличается')})` }
                                    </Typography.Text>
                                </Flex>
                            ),
                        };
                    }),
            })) }
            onChange={ onChange }
            selected={ selected }
            hint={ hint }
            error={ error }
            block={ block }
        />
    );
};
