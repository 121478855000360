/* eslint-disable no-restricted-globals */
import React, { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TicketOutlineM } from '@alphakits/icons';
import {
    CalendarInput,
    convertToOptions,
    Flex,
    FlexColumns,
    IconButton,
    ModalContext,
    Select,
    showError,
    Typography,
} from '@alphakits/ui';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { SlotRO } from '@escapenavigator/types/dist/slot/slot.ro';
import cn from 'classnames';
import { format } from 'date-fns';
import { CompanySwitcher } from 'src/components/layout/core/company-switcher';
import { NotificationButton } from 'src/components/layout/core/notification-button';
import { SearchButton } from 'src/components/layout/core/search-button';
import { UserButton } from 'src/components/layout/core/user-button';
import { DateParam, LocationIdParam } from 'src/constants/params';
import { CertificatesaleNewModal } from 'src/modals/certificatesale-new';
import { OrderModal } from 'src/modals/order';
import { OrderNewModal } from 'src/modals/order-new';
import { ManageBreaksModal } from 'src/modals/slots/manage-breaks';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import {
    fetchCalendarSlots,
    selectAllLocations,
    selectAllQuestrooms,
    selectAllSlots,
} from 'src/store';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { DefaultPageProps } from 'src/types';
import { useQueryParam } from 'use-query-params';

import { RenderBookingCell } from '../calendar/render-booking-cell';

import styles from './index.module.css';

export const CalendarMobilePage: React.FC<DefaultPageProps> = ({ userData: { role } }) => {
    const { openModal } = useContext(ModalContext);
    const { t, i18n } = useTranslation();
    const api = useApi();

    const openOrderModal = openModal(OrderModal, 'm', true);
    const openOrderNewModal = openModal(OrderNewModal);
    const openSlotsBreakModal = openModal(ManageBreaksModal);
    const openNewCertificateModal = openModal(CertificatesaleNewModal);
    const { current } = useCurrentUser();
    const questrooms = useAppSelector(selectAllQuestrooms({}));
    const locations = useAppSelector(selectAllLocations);
    const slots = useAppSelector(selectAllSlots(questrooms));
    const slotsLoading = useAppSelector((state) => state.slots.loading);

    const [date, setDate] = useQueryParam('date', DateParam);
    const [locationId, setLocationId] = useQueryParam(
        'locationId',
        LocationIdParam(locations[0]?.id),
    );

    const dispatch = useAppDispatch();

    const fetch = ({ date, locationId }) => {
        dispatch(
            fetchCalendarSlots({
                date,
                locationId,
            }),
        );
    };

    const refetch = useCallback(() => {
        fetch({ date, locationId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date, locationId]);

    const { forceRemoveHoldFetch, forceRemoveHoldLoading } = useApiMethod({
        api: api.slots.forceRemoveHold,
        errorCallback: (e) => showError(e.message),
        successCallback: () => {
            refetch();
        },
    });

    const { removeFetch: deleteSlot, removeLoading } = useApiMethod({
        api: api.slots.remove,
        errorCallback: (e) => showError(e.message),
        successCallback: () => {
            refetch();
        },
    });

    const { addOrRemoveBreakFetch, addOrRemoveBreakLoading } = useApiMethod({
        api: api.slots.addOrRemoveBreak,
        errorCallback: (e) => showError(e.message),
        successCallback: () => {
            refetch();
        },
    });

    const canEditOrders = role.totalAccess || role.accessToOrdersSection;

    useEffect(() => {
        fetch({ date, locationId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date, locationId]);

    const handleOpenOrderModal = (slot: SlotRO) => {
        if (!slot.order) {
            openOrderNewModal({ initialSlot: slot });

            return;
        }

        openOrderModal({ recordId: slot.order.id });
    };

    const slotDotsActions = (slot: SlotRO) => {
        if (slot.breakReason) {
            return {
                [t('Убрать перерыв')]: () =>
                    addOrRemoveBreakFetch({ data: { breakReason: null }, id: slot.id }),
            };
        }

        if (slot.hold && !slot.order) {
            return {
                [t('Удалить холд')]: () => {
                    if (confirm(t('holdWarning'))) {
                        forceRemoveHoldFetch(slot.hold);
                    }
                },
            };
        }

        if (!slot?.order?.id) {
            return {
                [t('openSlotBreakModal')]: () =>
                    openSlotsBreakModal({
                        startDate: slot.date,
                        endDate: slot.date,
                        startTime: slot.start,
                        endTime: slot.end,
                        questroomId: slot.questroomId,
                        type: 'add',
                        callback: refetch,
                    }),
                [t('deleteSlot')]: () => deleteSlot(slot.id),
            };
        }

        return null;
    };

    if (!questrooms.length) return null;

    const loading =
        addOrRemoveBreakLoading || slotsLoading || removeLoading || forceRemoveHoldLoading;

    if (!questrooms.length) return null;

    return (
        <div
            style={ {
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            } }
        >
            <div className={ styles.calendar_header }>
                <FlexColumns columns={ 1 } gr={ 16 }>
                    <Flex gap="sm">
                        { current.companies?.length ? (
                            <CompanySwitcher />
                        ) : (
                            <Typography.Title view="xxsmall" tag="div" weight="bold">
                                Calendar
                            </Typography.Title>
                        ) }

                        <Flex gap="sm" align="center">
                            <IconButton
                                icon={ TicketOutlineM }
                                view="secondary"
                                onClick={ () => openNewCertificateModal({ openModal }) }
                            />

                            { role.id && <SearchButton /> }

                            { role.id && <NotificationButton /> }

                            <UserButton />
                        </Flex>
                    </Flex>
                    <FlexColumns columns={ 2 }>
                        <Select
                            label={ t('location') }
                            showEmptyOptionsList={ true }
                            options={ convertToOptions(locations) }
                            selected={ locationId }
                            onChange={ ({ selected }) => {
                                setLocationId(+selected?.key);
                            } }
                            block={ true }
                            optionsListWidth="field"
                        />

                        <CalendarInput
                            size="m"
                            lang={ i18n.language }
                            onChange={ (e, { date: d }) => {
                                setDate(format(d, 'yyyy-MM-dd'));
                            } }
                            block={ true }
                            label={ t('Выберите дату') }
                            value={ format(new Date(date), 'dd.MM.yyyy') }
                        />
                    </FlexColumns>
                </FlexColumns>
            </div>

            <div className={ cn(styles.content, { [styles.content__loading]: loading }) }>
                <FlexColumns columns={ 1 } gr={ 8 }>
                    { slots.map((data) => (
                        <RenderBookingCell
                            slot={ data }
                            view="mobile"
                            onSlotClick={ canEditOrders && handleOpenOrderModal }
                            slotActions={ slotDotsActions }
                        />
                    )) }
                </FlexColumns>
            </div>

            { /* { user.useTimer && <Timer /> } */ }
        </div>
    );
};
