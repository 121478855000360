import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClockCircleM } from '@alphakits/icons';
import {
    Amount, Button, Flex, Typography,
} from '@alphakits/ui';
import { currencyByCountry } from '@escapenavigator/types/dist/constants/currency-by-country';
import { PartnerProgramEnum } from '@escapenavigator/types/dist/profile/enum/partner-program.enum';
import { ProfileRO } from '@escapenavigator/types/dist/profile/profile.ro';
import { MarketingCell } from 'src/components/marketing-cell';

type Props = {
    onClick: (program: PartnerProgramEnum) => void;
    loading: boolean;
    profile: ProfileRO;
};

export const OtherCrmCard: React.FC<Props> = ({ loading, onClick, profile }) => {
    const { t } = useTranslation();

    return (
        <MarketingCell
            title={ profile.crm || 'Your CRM' }
            description={ t('your crm description') }
            prices={ [
                {
                    amount: '10%',
                    description: t('от стоимости игры', { value: '' }),
                    hint: t('Поддерживать интеграцию затратно'),
                },
                {
                    amount: (
                        <Amount
                            weight="bold"
                            value={ 0 }
                            currency={ currencyByCountry[profile.country] }
                        />
                    ),
                    description: t('бесплатно без условий'),
                },
            ] }
        >
            { profile.partnerProgram !== PartnerProgramEnum.REQUESTED && (
                <Button
                    onClick={ () => onClick(PartnerProgramEnum.REQUESTED) }
                    view="outlined"
                    size="s"
                    loading={ loading }
                    block={ true }
                >
                    { t('Подключиться к программе') }
                </Button>
            ) }

            { profile.partnerProgram === PartnerProgramEnum.REQUESTED && (
                <Typography.Text view="caps" color="secondary">
                    <Flex gap="xs" align="start">
                        <ClockCircleM />
                        <Flex direction="column" justify="start" align="start">
                            <span>{ t('Заявка в обработке') }</span>
                            <Button
                                view="ghost"
                                loading={ loading }
                                onClick={ () => onClick(PartnerProgramEnum.NONE) }
                            >
                                <Typography.Text view="caps" color="link">
                                    { t('Отменить') }
                                </Typography.Text>
                            </Button>
                        </Flex>
                    </Flex>
                </Typography.Text>
            ) }
        </MarketingCell>
    );
};
