import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { EmptyPage } from '@alphakits/ui/dist';
import { SUCCESS_IMAGE } from 'src/constants';

type Props = {
    close: () => void;
};

export const VerificationCompleteModal: React.FC<Props> = ({ close }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    return (
        <EmptyPage
            title={ t('completeVerification.title') }
            subtitle={ t('completeVerification.companyLink') }
            buttonText={ t('Готово') }
            onClick={ () => {
                close();
                navigate('/');
            } }
            image={ SUCCESS_IMAGE }
        />
    );
};
