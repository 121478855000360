import { useEffect, useState } from 'react';
import differenceInSeconds from 'date-fns/differenceInSeconds';

export const useTimer = (date: Date) => {
    const [secondsLeft, setSecondsLeft] = useState(differenceInSeconds(date, new Date()));

    useEffect(() => {
        const tick = setTimeout(() => {
            setSecondsLeft(differenceInSeconds(date, new Date()));
        }, 1000);

        return () => {
            clearTimeout(tick);
        };
    }, [secondsLeft, date]);

    return { secondsLeft };
};
