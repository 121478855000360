/* eslint-disable complexity */
import React, { useContext, useMemo, useState } from 'react';
import { SearchM } from '@alphakits/icons';
import {
    Button,
    InputAutocomplete,
    Loader,
    ModalContext,
    OptionShape,
    TFunction,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { ClientRO } from '@escapenavigator/types/dist/client/client.ro';
import { getFullName } from '@escapenavigator/utils/dist/get-full-name';
import { differenceInYears } from 'date-fns';
import { ClientEditModal } from 'src/modals/client-edit';
import { useApi } from 'src/providers/api/context';

type Props = {
    onSelect: (client?: ClientRO) => void;
    error?: string;
    t: TFunction;
    customButtonLabel?: string;
};

export const ClientSearch: React.FC<Props> = ({
    onSelect, t, error, customButtonLabel,
}) => {
    const api = useApi();

    const { openModal } = useContext(ModalContext);
    const openClientCreateModal = openModal(ClientEditModal);

    const [clients, setClients] = useState<ClientRO[]>([]);

    const { queryFetch, queryLoading } = useApiMethod({
        api: api.clients.query,
        successCallback: ({ data }) => setClients(data.items),
    });

    const [searchText, setSearchText] = useState('');

    const options: OptionShape[] = useMemo(
        () =>
            clients.map((c) => ({
                key: c.id,
                content: `${getFullName(c)}${
                    c.birthday ? `, ${differenceInYears(new Date(), new Date(c.birthday))} y.o` : ''
                }${c.email ? `, ${c.email}` : ''}`,
            })),
        [clients],
    );

    const handleChange = (id) => {
        const c = clients.filter((c) => c.id === id)[0];

        onSelect(c);
        setSearchText('');
    };

    const handleInputChange = (text) => {
        setSearchText(text);

        if (text && text.length > 2) {
            queryFetch({
                limit: 10,
                page: 1,
                searchText: text,
                where: {},
            });
        }
    };

    const hint = useMemo(() => {
        if (queryLoading) return 'Loading...';
        if (!searchText.length) return t('Поиск клиента по фамилии или email адресу');
        if (searchText.length < 3) return t('Минимум 3 символа');
        if (!clients.length) return t('Клиент не найден');

        return ' ';
    }, [clients, queryLoading, searchText, t]);

    return (
        <React.Fragment>
            <InputAutocomplete
                options={ options }
                block={ true }
                size="s"
                inputProps={ {
                    leftAddons: queryLoading ? <Loader /> : <SearchM />,
                    clear: true,
                    onClear: () => {
                        setSearchText('');
                        setClients([]);
                    },
                } }
                closeOnSelect={ true }
                onInput={ (e) => {
                    handleInputChange(e.target.value);
                    setClients([]);
                } }
                onChange={ (e) => handleChange(e.selected.key) }
                value={ searchText }
                error={ error }
                hint={ hint }
            />

            <Button
                size="s"
                onClick={ () => openClientCreateModal({ successCallback: onSelect }) }
                view="outlined"
            >
                { customButtonLabel || t('Создать нового клиента') }
            </Button>
        </React.Fragment>
    );
};
