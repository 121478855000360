import React, { ReactNode } from 'react';
import {
    ExcelM, GearM, PersonsTwoM, TicketOutlineM,
} from '@alphakits/icons';
import {
    Amount, BgColors, Image, Status,
} from '@alphakits/ui/dist';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { LocationRO } from '@escapenavigator/types/dist/location/location.ro';
import { OrderRO } from '@escapenavigator/types/dist/order/order.ro';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { QuestroomRO } from '@escapenavigator/types/dist/questroom/questroom.ro';
import { SourceEnum } from '@escapenavigator/types/dist/shared/source.enum';
import { convertUTCDateToZonedDate } from '@escapenavigator/utils/dist';
import { getFullName } from '@escapenavigator/utils/dist/get-full-name';
import { TFunction } from 'i18next';
import escapeNavigator from 'src/components/layout/logo/escapeNavigator.png';
import nowEscape from 'src/components/layout/logo/nowEscape.png';
import { getOrderData } from 'src/utils/get-order-data';

import styles from './index.module.css';

export const sourceIcons: Record<SourceEnum, ReactNode | null> = {
    widget: <TicketOutlineM />,
    user: <PersonsTwoM />,
    support: <GearM />,
    agregator: null,
    nowEscape: null,
    exported: <ExcelM />,
};

export const sourceImages: Record<SourceEnum, string | null> = {
    widget: null,
    user: null,
    support: null,
    agregator: escapeNavigator,
    nowEscape,
    exported: null,
};

export const sourceColors: Record<SourceEnum, BgColors> = {
    widget: 'accent',
    user: 'attention',
    support: 'secondary',
    agregator: 'primary',
    nowEscape: 'positive',
    exported: 'secondary',
};

type Props = {
    t: TFunction;
    questrooms: QuestroomRO[];
    locations: LocationRO[];
    currency: ProfileCurrencyEnum;
};

export const GameHistoryColumns = ({
    t,
    questrooms,
    currency,
    locations,
}: Props): TableColumns<OrderRO> => [
    {
        header: t('tables.dateOf'),
        accessor: 'utcDate',
        sort: true,
        row: {
            title: ({ row }) => {
                const location = locations?.filter(
                    (l) =>
                        questrooms.filter((q) => q.id === row.questroomId)[0]?.locationId === l.id,
                )[0];

                if (!location) return 'Escape room deleted';

                return convertUTCDateToZonedDate({
                    date: row.utcDate,
                    timeZone: location.timeZone,
                });
            },

            subtitle: ({ row }) => `Order №${row.code}`,
            icon: ({ row }) => (
                <Image
                    view="ellipse"
                    src={ sourceImages[row.source] }
                    className={ styles[row.source] }
                    bgColor={ sourceColors[row.source] }
                    color="primary"
                    icon={ sourceIcons[row.source] }
                />
            ),
        },
    },
    {
        header: t('game'),
        accessor: 'id',
        sort: true,
        row: {
            title: ({ row }) => questrooms.find((q) => q.id === row.questroomId)?.title || '-',
            subtitle: ({ row }) => `${row.players} ${t('ppl')} ${row.language || ''}`,
        },
    },

    {
        header: t('tables.client'),
        accessor: 'client',
        row: {
            title: ({ row }) => getFullName(row.client),
            subtitle: ({ row }) => row.client?.phone || row.client?.email || 'Deleted customer',
        },
    },

    {
        header: t('component.amount'),
        accessor: 'total',
        row: {
            title: ({ row }) => (
                <Amount
                    type="decimal"
                    weight="bold"
                    color="primary"
                    currency={ currency }
                    value={ row.total }
                />
            ),
            subtitle: ({ row }) =>
                (
                    <React.Fragment>
                        { t('paidUp') }{ ' ' }
                        <Amount
                            type="decimal"
                            currencyOpacity={ false }
                            view="caps"
                            currency={ currency }
                            value={ row.payed }
                        />
                    </React.Fragment>
                ) as any,
        },
    },
    {
        header: '',
        accessor: 'slotId',
        align: 'right',
        row: {
            title: ({ row }) => {
                const { status } = getOrderData(row, t);

                return (
                    <Status view="contrast" color={ status.color }>
                        { status.title }
                    </Status>
                );
            },
        },
    },
];
