import React from 'react';
import { Amount } from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { CertificateRO } from '@escapenavigator/types/dist/certificate/certificate.ro';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { TFunction } from 'i18next';
import { PriceRange } from 'src/components/price-range';

type Props = {
    t: TFunction;
    currency: ProfileCurrencyEnum;
};

export const CertificateColumns = ({ t, currency }: Props): TableColumns<CertificateRO> => [
    {
        header: t('tables.title'),
        accessor: 'title',
        row: {
            image: 'photo',
        },
    },
    {
        header: t('Номинал'),
        accessor: 'nominals',
        row: {
            title: ({ row }) => (
                <PriceRange price={ Object.fromEntries(row.nominals.map((n, i) => [i, n])) } />
            ),
        },
    },

    {
        header: t('tables.extraPrice'),
        accessor: 'extraPrice',
        row: {
            title: ({ row }) => (
                <Amount type="decimal" value={ row.extraPrice || 0 } currency={ currency } />
            ),
        },
    },

    {
        header: t('tables.delivery'),
        accessor: 'deliveryPrice',
        row: {
            title: ({ row }) => (
                <Amount type="decimal" value={ row.deliveryPrice || 0 } currency={ currency } />
            ),
        },
    },

    {
        header: t('tables.validity'),
        accessor: 'validity',
        row: {
            title: ({ row }) => `${row.validity} ${t('days', { count: +row.validity })}`,
        },
    },
];
