import { useCallback, useEffect, useState } from 'react';
import { showError } from '@alphakits/ui';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { OrderStatusEnum } from '@escapenavigator/types/dist/order/enum/order-status.enum';
import { OrderRO } from '@escapenavigator/types/dist/order/order.ro';
import { DateParam, LocationIdParam, ViewParam } from 'src/constants/params';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import {
    fetchCalendarSessions,
    fetchCalendarSlots,
    removeAllSlots,
    selectAllLocations,
    selectAllQuestrooms,
    selectAllSlots,
    selectQuestroomsByIds,
    selectQuestroomsByLocationId,
} from 'src/store';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useQueryParam } from 'use-query-params';

import { startOfWeek } from './utils/start-of-week';
import { CalendarView } from './types';

export const useCalendarData = () => {
    const dispatch = useAppDispatch();
    const {
        current: { profileId },
    } = useCurrentUser();
    const api = useApi();

    const [ordersForPrint, setOrdersForPrint] = useState<OrderRO[]>([]);
    const allLocations = useAppSelector(selectAllLocations);
    const allQuestrooms = useAppSelector(selectAllQuestrooms({ deleted: false, closed: false }));

    const storedLocationKey = `escapeNavigatorLocation/${profileId}`;

    let storedLocationId = +localStorage.getItem(storedLocationKey);

    if (!storedLocationId || !allLocations.some((l) => l.id === storedLocationId)) {
        const defaultLocaion = allLocations[0]?.id;

        localStorage.setItem(storedLocationKey, `${defaultLocaion}`);
        storedLocationId = defaultLocaion;
    }

    const locationQuestrooms = useAppSelector(selectQuestroomsByLocationId(storedLocationId));

    const [storedQuestroomsIds, setStoredQuestroomsIds] = useState(
        locationQuestrooms.map((q) => q.id),
    );

    const selectedQuestrooms = useAppSelector(selectQuestroomsByIds(storedQuestroomsIds));

    const [date] = useQueryParam('calendarDate', DateParam);
    const [view, setView] = useQueryParam('view', ViewParam);

    const [locationId, setLocationId] = useQueryParam(
        'locationId',
        LocationIdParam(storedLocationId),
    );

    const { queryFetch, queryLoading } = useApiMethod({
        api: api.orders.query,
        successCallback: ({ data }) => setOrdersForPrint(data.items),
        errorCallback: ({ message }) => showError(message),
    });

    const slots = useAppSelector(selectAllSlots(selectedQuestrooms));
    const slotsLoading = useAppSelector((state) => state.slots.loading);

    const fetch = useCallback(() => {
        setOrdersForPrint([]);
        dispatch(removeAllSlots());

        if (!storedQuestroomsIds.length) return;

        if (view === 'games') {
            queryFetch({
                page: 1,
                limit: 1000,
                sort: 'utcDate',
                order: 'ASC',
                where: {
                    questroomsIds: storedQuestroomsIds,
                    date: [date, date],
                    orderStatus: OrderStatusEnum.NOT_CANCELED,
                    relations: [
                        'upsellings',
                        'promocodes',
                        'certificates',
                        'discounts',
                        'uploadedCertificates',
                    ],
                },
            });
        } else {
            if (view === 'day') dispatch(fetchCalendarSessions({ date }));
            dispatch(
                fetchCalendarSlots({
                    date: view === 'week' ? startOfWeek(date) : date,
                    locationId,
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, locationId, date, view]);

    useEffect(() => {
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date, locationId, view]);

    const { removeFetch, removeLoading } = useApiMethod({
        api: api.slots.remove,
        errorCallback: (e) => showError(e.message),
        successCallback: () => {
            fetch();
        },
    });

    const { addOrRemoveBreakFetch, addOrRemoveBreakLoading } = useApiMethod({
        api: api.slots.addOrRemoveBreak,
        errorCallback: (e) => showError(e.message),
        successCallback: () => {
            fetch();
        },
    });

    const { forceRemoveHoldFetch, forceRemoveHoldLoading } = useApiMethod({
        api: api.slots.forceRemoveHold,
        errorCallback: (e) => showError(e.message),
        successCallback: () => {
            fetch();
        },
    });

    const changeQuestrooms = (ids: number[]) => {
        setStoredQuestroomsIds(ids);
    };

    const changeLocation = (id: number) => {
        localStorage.setItem(storedLocationKey, `${id}`);

        const questromsIds = allQuestrooms.filter((q) => q.locationId === id).map((q) => q.id);

        changeQuestrooms(questromsIds);

        setLocationId(id);
    };

    const changeView = (value: CalendarView) => {
        setView(value);
    };

    return {
        locationId,
        changeLocation,

        selectedQuestrooms,
        changeQuestrooms,
        forceRemoveHoldFetch,

        view,
        date,
        changeView,

        orders: ordersForPrint,
        ordersLoading: queryLoading,

        refetch: fetch,

        slots,
        loading: slotsLoading || removeLoading || addOrRemoveBreakLoading || forceRemoveHoldLoading,
        removeSlot: removeFetch,
        addOrRemoveBreak: addOrRemoveBreakFetch,
    };
};
