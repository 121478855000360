import React from 'react';
import { useDispatch } from 'react-redux';
import {
    Checkbox,
    FlexColumns,
    Input,
    Padding,
    useCrudFormRequests,
    useGetOne,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { CreateRoleDto } from '@escapenavigator/types/dist/role/create-role.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';
import { removeRole, upsertRole } from 'src/store';

type Props = {
    recordId?: number;
    close: () => void;
    t: TFunction;
};

export const RoleModal: React.FC<Props> = ({ recordId, close, t }) => {
    const dispatch = useDispatch();
    const { roles } = useApi();
    const { record, loading, error } = useGetOne(recordId, roles.getOne);

    const {
        save,
        remove,
        removing,
        updating,
        error: softError,
    } = useCrudFormRequests({
        recordId,
        removeRequest: roles.remove,
        saveRequest: recordId ? roles.update : roles.create,
        saveCallback: (savedRecord) => dispatch(upsertRole(savedRecord)),
        removeCallback: (removedRecord) => dispatch(removeRole(removedRecord.id)),
        close,
    });

    const canEditLoggedTime = t('Может редактировать залогированное время');

    return (
        <RestForm
            recordId={ recordId }
            title={ t('modalsHeader.role') }
            initialValues={ serializeRecord(CreateRoleDto, record) }
            validate={ validateByDto(t) }
            save={ save }
            remove={ remove }
            error={ error }
            softError={ softError }
            loading={ loading }
            updating={ updating || removing }
            close={ close }
            t={ t }
        >
            { ({
                values, handleChange, setFieldValue, errors, touched,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    <Input
                        dataTestId="title"
                        label={ t('component.roleTitle') }
                        value={ values.title }
                        onChange={ handleChange('title') }
                        error={ touched.title && errors.title }
                        required={ true }
                        block={ true }
                    />
                    <Checkbox
                        label={ t('component.totalAccess') }
                        checked={ !!values.totalAccess }
                        onChange={ (e, { checked }) => setFieldValue('totalAccess', checked) }
                    />

                    { !values.totalAccess && (
                        <React.Fragment>
                            <Checkbox
                                label={ t('Доступ к редактированию других сотррудников') }
                                checked={ !!values.canEditUsersAccessAndTime }
                                onChange={ (e, { checked }) =>
                                    setFieldValue('canEditUsersAccessAndTime', checked) }
                            />

                            { values.canEditUsersAccessAndTime && (
                                <Padding padding="0 32px">
                                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                                        <Checkbox
                                            label={ canEditLoggedTime }
                                            checked={ !!values.canEditWorkedHours }
                                            onChange={ (e, { checked }) =>
                                                setFieldValue('canEditWorkedHours', checked) }
                                        />
                                        <Checkbox
                                            label={ t('Может менять права доступа') }
                                            checked={ !!values.canEditUsersAccess }
                                            onChange={ (e, { checked }) =>
                                                setFieldValue('canEditUsersAccess', checked) }
                                        />
                                    </FlexColumns>
                                </Padding>
                            ) }

                            <Checkbox
                                label={ t('Доступ к настройкам системы') }
                                checked={ !!values.accessToSettingsSection }
                                onChange={ (e, { checked }) =>
                                    setFieldValue('accessToSettingsSection', checked) }
                            />

                            <Checkbox
                                label={ t('Доступ к статистике') }
                                checked={ !!values.accessToStatisticSection }
                                onChange={ (e, { checked }) =>
                                    setFieldValue('accessToStatisticSection', checked) }
                            />

                            <Checkbox
                                label={ t('Доступ к бухгалтерии') }
                                checked={ !!values.accessToFinanceSection }
                                onChange={ (e, { checked }) =>
                                    setFieldValue('accessToFinanceSection', checked) }
                            />

                            <Checkbox
                                label={ t('Доступ к клиентской базе') }
                                checked={ !!values.accessToClientsSection }
                                onChange={ (e, { checked }) =>
                                    setFieldValue('accessToClientsSection', checked) }
                            />

                            <Checkbox
                                label={ t('Доступ к разделу маркетинга') }
                                checked={ !!values.accessToMarketingSection }
                                onChange={ (e, { checked }) =>
                                    setFieldValue('accessToMarketingSection', checked) }
                            />

                            <Checkbox
                                label={ t('Может изменять расписание квестов') }
                                checked={ !!values.canEditSchedule }
                                onChange={ (e, { checked }) =>
                                    setFieldValue('canEditSchedule', checked) }
                            />
                            { values.canEditSchedule && (
                                <Padding padding="0 32px">
                                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                                        <Checkbox
                                            label={ t('Может редактировать перерывы') }
                                            checked={ !!values.canCreateBreaks }
                                            onChange={ (e, { checked }) =>
                                                setFieldValue('canCreateBreaks', checked) }
                                        />
                                    </FlexColumns>
                                </Padding>
                            ) }

                            <Checkbox
                                label={ t('Может упрвавлять собственными дежурствами') }
                                checked={ !!values.canEditOwnWorkedHours }
                                onChange={ (e, { checked }) =>
                                    setFieldValue('canEditOwnWorkedHours', checked) }
                            />

                            <Checkbox
                                label={ t('Доступ к бронированию') }
                                checked={ !!values.accessToOrdersSection }
                                onChange={ (e, { checked }) =>
                                    setFieldValue('accessToOrdersSection', checked) }
                            />

                            { values.accessToOrdersSection && (
                                <Padding padding="0 32px">
                                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                                        <Checkbox
                                            label={ t(
                                                'Может редактировать брони по истечении 24 часов с даты игры',
                                            ) }
                                            checked={ !!values.canAlwaysEditOrders }
                                            onChange={ (e, { checked }) =>
                                                setFieldValue('canAlwaysEditOrders', checked) }
                                        />

                                        <Checkbox
                                            label={ t('Может добавлять возвраты') }
                                            checked={ !!values.canAddRefunds }
                                            onChange={ (e, { checked }) =>
                                                setFieldValue('canAddRefunds', checked) }
                                        />

                                        <Checkbox
                                            label={ t('Может удалят штрафы') }
                                            checked={ !!values.canDeleteFines }
                                            onChange={ (e, { checked }) =>
                                                setFieldValue('canDeleteFines', checked) }
                                        />

                                        <Checkbox
                                            label={ t('Может назначать модераторов игр') }
                                            checked={ !!values.canEditModerators }
                                            onChange={ (e, { checked }) =>
                                                setFieldValue('canEditModerators', checked) }
                                        />
                                    </FlexColumns>
                                </Padding>
                            ) }
                        </React.Fragment>
                    ) }
                </FlexColumns>
            ) }
        </RestForm>
    );
};
