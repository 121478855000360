import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GearM, InfoMarkM, PlusCircleM } from '@alphakits/icons';
import {
    Button,
    FlexColumns,
    Loader,
    ModalContext,
    PageWithControls,
    showError,
    ToastPlate,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CrmVerificationEnum } from '@escapenavigator/types/dist/profile/enum/crm-verifiaction.enum';
import { WidgetTypeEnum } from '@escapenavigator/types/dist/widget/enum/widget-type.enum';
import { WidgetRO } from '@escapenavigator/types/dist/widget/widget.ro';
import { NotificationModal } from 'src/modals/notification';
import { WidgetModal } from 'src/modals/widget';
import { WidgetCustomizationModal } from 'src/modals/widget-customization';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';

import { InitWidgets } from './components/init-widgets';
import { WidgetComponent } from './components/widget-component';

export const WidgetsSettingsPage: React.FC = () => {
    const { t } = useTranslation();
    const { widgets } = useApi();
    const { profile } = useCurrentUser();
    const { openModal } = useContext(ModalContext);

    const [data, setData] = useState<WidgetRO[]>();

    const { getInstructionTokenLoading, getInstructionTokenFetch } = useApiMethod({
        api: widgets.getInstructionToken,
        successCallback: ({ data }) => {
            window.open(`${process.env.REACT_APP_ORDERS_DOMAIN}?guideId=${data.token}`);
        },
        errorCallback: ({ message }) => showError(message),
    });

    const { queryLoading, queryFetch } = useApiMethod({
        api: widgets.query,
        successCallback: ({ data }) => setData(data),
        errorCallback: ({ message }) => showError(message),
    });

    const openNewWidgetModal = () =>
        openModal(WidgetModal)({
            refetch: () => queryFetch(undefined),
        });

    useEffect(() => {
        queryFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!data || queryLoading) {
        return <Loader />;
    }

    if (!data.length) {
        return <InitWidgets setWidgets={ setData } />;
    }

    const serializedWidgets = data.reduce((acc, widget) => {
        acc[widget.type] = acc[widget.type] || [];
        acc[widget.type].push(widget);

        return acc;
    }, {} as Record<WidgetTypeEnum, WidgetRO[]>);

    return (
        <PageWithControls
            buttons={ [
                {
                    onClick: () => getInstructionTokenFetch(undefined),
                    loading: getInstructionTokenLoading,
                    text: t('Инструкция по установке'),
                    leftAddons: <InfoMarkM />,
                },
                {
                    onClick: () => openNewWidgetModal(),
                    text: t('Добавить виджет'),
                    leftAddons: <PlusCircleM />,
                },
                {
                    onClick: () => openModal(WidgetCustomizationModal)(),
                    text: t('customization'),
                    leftAddons: <GearM />,
                },
            ] }
        >
            { profile.crmVerification !== CrmVerificationEnum.SUCCEED && (
                <ToastPlate
                    view="neutral"
                    title={ t('widget-guide:testModeTitle') }
                    bottomAddons={
                        profile.crmVerification === CrmVerificationEnum.READY_TO_USE && (
                            <Button
                                onClick={ () => openModal(NotificationModal)() }
                                view="outlined"
                                size="xs"
                            >
                                { t('Все готово к запуску') }
                            </Button>
                        )
                    }
                >
                    { t('widget-guide:testModeDescription') }

                    { profile.crmVerification !== CrmVerificationEnum.READY_TO_USE && (
                        <React.Fragment>
                            <br />
                            { t('widget-guide:testModeNextStep') }
                        </React.Fragment>
                    ) }
                </ToastPlate>
            ) }

            <FlexColumns columns={ 1 } gr={ 24 }>
                <WidgetComponent
                    type={ WidgetTypeEnum.ALL_QUESTROOM }
                    widgets={ serializedWidgets[WidgetTypeEnum.ALL_QUESTROOM] }
                    refetch={ () => queryFetch(undefined) }
                />
                <WidgetComponent
                    type={ WidgetTypeEnum.ONE_QUESTROOM }
                    widgets={ serializedWidgets[WidgetTypeEnum.ONE_QUESTROOM] }
                    refetch={ () => queryFetch(undefined) }
                />
                <WidgetComponent
                    type={ WidgetTypeEnum.WIDGET_BUTTON }
                    widgets={ serializedWidgets[WidgetTypeEnum.WIDGET_BUTTON] }
                    refetch={ () => queryFetch(undefined) }
                />
                <WidgetComponent
                    type={ WidgetTypeEnum.ALL_CERTIFICATES }
                    widgets={ serializedWidgets[WidgetTypeEnum.ALL_CERTIFICATES] }
                    refetch={ () => queryFetch(undefined) }
                />
            </FlexColumns>
        </PageWithControls>
    );
};
