import React from 'react';
import { formatDate } from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { DaySessionsRO } from '@escapenavigator/types/dist/user/sessions/day-sessions.ro';
import { convertHHMMToMinutes } from '@escapenavigator/utils/dist';
import { convertSecondsToHHMMSS } from 'src/components/schedule/utils/convert-seconds-to-hhmmss';
import { UserCell } from 'src/components/user-cell';

export const useSessionsColumns = ({ i18n, t }): TableColumns<DaySessionsRO> => {
    const getWorkingTime = (row: DaySessionsRO) =>
        convertSecondsToHHMMSS(
            row.sessions.reduce((acc, session) => {
                const minutes =
                    convertHHMMToMinutes(session.end) - convertHHMMToMinutes(session.start);

                return acc + minutes * 60;
            }, 0),
        );

    return [
        {
            header: t('employee'),
            sort: true,
            accessor: 'userId',
            row: {
                title: ({ row }) => <UserCell id={ row.userId } size="m" />,
            },
        },
        {
            header: t('filters.date'),
            sort: true,
            accessor: 'date',
            row: {
                title: ({ row }) =>
                    formatDate(row.date, { lang: i18n.language, format: 'dd MMMM yyyy' }),
            },
        },
        {
            header: t('Рабочее время'),
            accessor: 'id',
            sort: true,
            row: {
                title: ({ row }) => getWorkingTime(row),
            },
        },
    ];
};
