import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDownS, ChevronUpS, MinusS } from '@alphakits/icons';
import {
    Amount,
    Button,
    Flex,
    FlexColumns,
    IconButton,
    Select,
    Typography,
} from '@alphakits/ui/dist';
import { FormLoading } from '@alphakits/ui/dist/form/components/form-loading';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { QuestroomRO } from '@escapenavigator/types/dist/questroom/questroom.ro';
import { MonthlyGeneralStatiscticsRO } from '@escapenavigator/types/dist/statistics/monthly-general-statisctics.ro';
import { addMonths, differenceInMonths, format } from 'date-fns';
import { ModalSection } from 'src/components/layout/modal-section';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { selectAllQuestrooms } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

import { useLocationsSelect } from '../components/use-locations-select';

const defaultOrdersData = {
    questroomId: 0,
    orders: 0,
    players: 0,
    ordersInside: 0,
    ordersWidget: 0,
    ordersAgregator: 0,
    ordersTotalAmount: 0,
    ordersPrice: 0,
    ordersUpsellings: 0,
    ordersFines: 0,
    ordersPaid: 0,
    ordersPaidByCash: 0,
    ordersPaidByCard: 0,
    ordersPaidOnline: 0,
    ordersToPay: 0,
    ordersPaidByHandDiscount: 0,
    ordersPaidByCertificate: 0,
    ordersPaidByPromocode: 0,
    ordersNotPayedAmount: 0,
    canceledOrders: 0,
    canceledWithFine: 0,
    canceledWithFineFines: 0,
    canceledWithFineFinesPaid: 0,
    canceledWithFineToPay: 0,
};

const getTotal = (data: MonthlyGeneralStatiscticsRO['questrooms']) => {
    if (!data) return { ...defaultOrdersData };

    return data.reduce(
        (acc, q) => {
            Object.keys(acc).forEach((key) => {
                acc[key] += q[key];
            });

            return acc;
        },
        { ...defaultOrdersData },
    );
};

const InlineRow: React.FC<{
    title: React.ReactNode;
    firstValue: number;
    secondValue?: number;
    currency?: ProfileCurrencyEnum;
}> = ({
    secondValue = 0, firstValue = 0, title, currency,
}) => {
    const diff = useMemo(() => {
        if (!secondValue && !firstValue) return 0;
        if (!secondValue) return -100;
        if (!firstValue) return 100;

        return +((secondValue / firstValue) * 100 - 100).toFixed(1);
    }, [secondValue, firstValue]);

    const view = useMemo(() => {
        if (diff > 0) return 'positive';
        if (diff < 0) return 'negative';

        return 'secondary';
    }, [diff]);

    return (
        <FlexColumns columns={ 4 } gc={ 16 }>
            <Typography.Text view="title" color="primary" weight="medium">
                { title }
            </Typography.Text>

            { currency ? (
                <React.Fragment>
                    <Amount
                        weight="medium"
                        type="decimal"
                        view="title"
                        color="primary"
                        currency={ currency }
                        value={ firstValue }
                    />

                    <Amount
                        weight="medium"
                        type="decimal"
                        view="title"
                        color="primary"
                        currency={ currency }
                        value={ secondValue }
                    />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Typography.Text view="title" color="primary" weight="medium">
                        { firstValue }
                    </Typography.Text>

                    <Typography.Text view="title" color="primary" weight="medium">
                        { secondValue }
                    </Typography.Text>
                </React.Fragment>
            ) }

            <Typography.Text view="title" weight="bold" color={ view }>
                <Flex gap="xxs" justify="start">
                    { view === 'negative' && <ChevronDownS width={ 10 } /> }
                    { view === 'positive' && <ChevronUpS width={ 10 } /> }
                    { view === 'secondary' && <MinusS width={ 10 } /> }
                    { Math.abs(diff) }%
                </Flex>
            </Typography.Text>
        </FlexColumns>
    );
};

const Section: React.FC<{
    title: string;
    data: {
        firstMonthTotal: MonthlyGeneralStatiscticsRO['questrooms'][0];
        secondMonthTotal: MonthlyGeneralStatiscticsRO['questrooms'][0];
        firstMonthData: MonthlyGeneralStatiscticsRO['questrooms'];
        secondMonthData: MonthlyGeneralStatiscticsRO['questrooms'];
        questrooms: QuestroomRO[];
    };
    field: keyof MonthlyGeneralStatiscticsRO['questrooms'][0];
    currency?: ProfileCurrencyEnum;
}> = ({
    field, data, title, currency,
}) => {
    const firstValue = data.firstMonthTotal?.[field];
    const secondValue = data.secondMonthTotal?.[field];
    const [showAll, setShowAll] = useState(false);

    return (
        <div style={ { background: showAll ? '#f3f5f9' : '#fff', marginBottom: showAll ? 8 : 0 } }>
            <InlineRow
                title={ (
                    <Flex gap="md" justify="start">
                        <div>{ title }</div>
                        <IconButton
                            view="secondary"
                            icon={ showAll ? ChevronUpS : ChevronDownS }
                            onClick={ () => setShowAll(!showAll) }
                        />
                    </Flex>
                ) }
                currency={ currency }
                firstValue={ firstValue }
                secondValue={ secondValue }
            />

            { showAll &&
                data.questrooms.map((questroom) => {
                    const fisrtValues =
                        data.firstMonthData.filter((q) => q.questroomId === questroom.id)[0] || {};
                    const secondValues =
                        data.secondMonthData?.filter((q) => q.questroomId === questroom.id)[0] ||
                        {};

                    return (
                        <InlineRow
                            title={ questroom.title }
                            currency={ currency }
                            firstValue={ fisrtValues[field] }
                            secondValue={ secondValues?.[field] }
                        />
                    );
                }) }
        </div>
    );
};

const Statistic: React.FC<{
    loading: boolean;
    firstMonthData: MonthlyGeneralStatiscticsRO;
    secondMonthData?: MonthlyGeneralStatiscticsRO;
}> = ({ loading, firstMonthData, secondMonthData }) => {
    const { t } = useTranslation();
    const questrooms = useAppSelector(selectAllQuestrooms({ deleted: false, closed: false }));

    const {
        profile: { currency },
    } = useCurrentUser();

    const firstMonthTotal = useMemo(() => getTotal(firstMonthData?.questrooms), [firstMonthData]);

    const secondMonthTotal = useMemo(
        () => getTotal(secondMonthData?.questrooms),
        [secondMonthData],
    );

    if (loading) return <FormLoading />;

    if (!firstMonthData) return null;

    const data = {
        firstMonthData: firstMonthData?.questrooms,
        secondMonthData: secondMonthData?.questrooms,
        firstMonthTotal,
        secondMonthTotal,
        questrooms,
    };

    return (
        <React.Fragment>
            <ModalSection>
                <Section title={ t('Активных игр') } data={ data } field="orders" />

                <Section title={ t('Кол-во игроков') } data={ data } field="players" />

                <Section
                    title={ t('На сумму') }
                    currency={ currency }
                    data={ data }
                    field="ordersTotalAmount"
                />

                <Section
                    title={ t('Базовая цена') }
                    currency={ currency }
                    data={ data }
                    field="ordersPrice"
                />
                <Section
                    title={ t('Доп услуги') }
                    currency={ currency }
                    data={ data }
                    field="ordersUpsellings"
                />
                <Section title={ t('Штрафы') } currency={ currency } data={ data } field="ordersFines" />
            </ModalSection>

            <ModalSection title={ t('Структура платежей') }>
                <Section
                    title={ t('Оплачено на квесте') }
                    currency={ currency }
                    data={ data }
                    field="ordersPaid"
                />
                <Section
                    title={ t('Оплачено онлайн') }
                    currency={ currency }
                    data={ data }
                    field="ordersPaidOnline"
                />
                <Section
                    title={ t('Применено сертификатов') }
                    currency={ currency }
                    data={ data }
                    field="ordersPaidByCertificate"
                />
                <Section
                    title={ t('Применено промокодов') }
                    currency={ currency }
                    data={ data }
                    field="ordersPaidByPromocode"
                />
                <Section
                    title={ t('Ручные скидки') }
                    currency={ currency }
                    data={ data }
                    field="ordersPaidByHandDiscount"
                />
                <Section
                    title={ t('Еще не оплачено') }
                    currency={ currency }
                    data={ data }
                    field="ordersToPay"
                />
            </ModalSection>

            <ModalSection title={ t('Отмены') }>
                <Section title={ t('Отменено игр') } data={ data } field="canceledOrders" />

                <Section title={ t('Из них со штрафом') } data={ data } field="canceledWithFine" />

                <Section
                    title={ t('На сумму') }
                    currency={ currency }
                    data={ data }
                    field="canceledWithFineFines"
                />

                <Section
                    title={ t('paidUp') }
                    currency={ currency }
                    data={ data }
                    field="canceledWithFineFinesPaid"
                />

                <Section
                    title={ t('Не оплаченых штрафов на сумму') }
                    currency={ currency }
                    data={ data }
                    field="canceledWithFineToPay"
                />
            </ModalSection>
        </React.Fragment>
    );
};

export const CompareStatistic: React.FC = () => {
    const { t } = useTranslation();

    const { statiscticsApi } = useApi();

    const monthes = useMemo(() => {
        const month = differenceInMonths(new Date(), new Date('2023-06-01'));

        return new Array(month).fill(0).map((_, index) => {
            const date = addMonths(new Date(), month - month - index);

            return {
                key: format(date, 'MM-yyyy'),
                content: format(date, 'MMMM, yyyy'),
            };
        });
    }, []);

    const { selectedQuestroomsIds, Locations } = useLocationsSelect();

    const [params, setParams] = useState({
        firstMonth: monthes[0].key,
        secondMonth: undefined,
    });

    const {
        getMonthlyGeneralStatiscticsFetch: firstFetch,
        getMonthlyGeneralStatiscticsLoading: firstLoading,
        getMonthlyGeneralStatiscticsData: firstData,
    } = useApiMethod({ api: statiscticsApi.getMonthlyGeneralStatisctics });

    const {
        getMonthlyGeneralStatiscticsFetch: secondFetch,
        getMonthlyGeneralStatiscticsLoading: secondLoading,
        getMonthlyGeneralStatiscticsData: secondData,
    } = useApiMethod({ api: statiscticsApi.getMonthlyGeneralStatisctics });

    const loading = secondLoading || firstLoading;

    const handleFetch = () => {
        firstFetch({
            month: params.firstMonth,
            questroomsIds: selectedQuestroomsIds,
        });

        if (params.secondMonth) {
            secondFetch({
                month: params.secondMonth,
                questroomsIds: selectedQuestroomsIds,
            });
        }
    };

    return (
        <FlexColumns columns={ 1 } gr={ 40 }>
            { Locations }
            <FlexColumns columns={ 3 } gc={ 16 }>
                <Select
                    selected={ params.firstMonth }
                    label={ t('Первый месяц') }
                    disabled={ loading }
                    onChange={ ({ selected }) => {
                        setParams({ ...params, firstMonth: `${selected?.key}` });
                    } }
                    options={ monthes }
                    optionsListWidth="field"
                />

                <Select
                    selected={ params.secondMonth }
                    label={ t('Второй месяц') }
                    disabled={ loading }
                    onChange={ ({ selected }) => {
                        setParams({ ...params, secondMonth: `${selected?.key}` });
                    } }
                    options={ monthes }
                    optionsListWidth="field"
                />

                <Button view="primary" size="s" onClick={ handleFetch }>
                    { t('Применить') }
                </Button>
            </FlexColumns>

            <Statistic loading={ loading } firstMonthData={ firstData } secondMonthData={ secondData } />

            <br />
            <br />
            <br />
        </FlexColumns>
    );
};
