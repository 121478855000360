import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckmarkCircleFillM, ClockCircleM } from '@alphakits/icons';
import {
    Button, FlexColumns, ModalContext, Status,
} from '@alphakits/ui';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { IntegrationCard } from 'src/components/integration-card';
import { Section } from 'src/components/layout/section';
import { CertificateServiceModal } from 'src/modals/certificate-service';
import { TabPanel, Tabs } from 'src/modals/components/tabs';
import { useApi } from 'src/providers/api/context';

import { CertificatesPage } from './certificates';
import { NavigatorCertificates } from './navigator';
import { UploadedCertificates } from './uploaded';

export const CertificateSalesPage: React.FC = () => {
    const { t } = useTranslation();
    const { openModal } = useContext(ModalContext);
    const { certificates } = useApi();
    const {
        findOrCreateCertificateServiceData,
        findOrCreateCertificateServiceLoading,
        findOrCreateCertificateServiceFetch,
    } = useApiMethod({
        api: certificates.findOrCreateCertificateService,
    });

    useEffect(() => {
        findOrCreateCertificateServiceFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Tabs view="header" defaultActive="Navigator">
            <TabPanel name="Navigator">
                <NavigatorCertificates />
            </TabPanel>

            <TabPanel name={ t('Uploaded') }>
                <UploadedCertificates />
            </TabPanel>

            <TabPanel name={ t('настройки') }>
                <FlexColumns columns={ 1 } gr={ 24 }>
                    <IntegrationCard
                        loading={ findOrCreateCertificateServiceLoading }
                        fullWidth={ true }
                        title={ `${t('vertificateService')}` }
                        description={ t('outOfTheBoxServiceDescription') }
                        image="https://d2z3luly8bezbw.cloudfront.net/fit-in/300x300/inside/art/marketing/cross-sale.png"
                    >
                        { !!findOrCreateCertificateServiceData?.enabled && (
                            <Status color="green" view="soft" size="m">
                                <CheckmarkCircleFillM /> { t('Активирована') }
                            </Status>
                        ) }

                        { !findOrCreateCertificateServiceData ||
                            (!findOrCreateCertificateServiceData?.enabled && (
                                <Status color="grey" view="soft" size="m">
                                    <ClockCircleM /> { t('Выключено') }
                                </Status>
                            )) }

                        <Button
                            onClick={ () =>
                                openModal(CertificateServiceModal)({
                                    record: findOrCreateCertificateServiceData,
                                    cb: () => findOrCreateCertificateServiceFetch(undefined),
                                }) }
                            view="outlined"
                            size="xs"
                        >
                            { t('настройки') }
                        </Button>
                    </IntegrationCard>

                    <Section title={ t('menu./certificates') }>
                        <CertificatesPage />
                    </Section>
                </FlexColumns>
            </TabPanel>
        </Tabs>
    );
};
