import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext, Select } from '@alphakits/ui/dist';
import { convertToOptions } from '@escapenavigator/utils/dist/convert-to-options';
import { TariffModal } from 'src/modals/tariff';
import { selectAllTariffs } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

type Props = {
    onChange: (id?: number) => void;
    value?: number;
    error?: string;
};

export const TariffSelect: React.FC<Props> = ({ onChange, value, error }) => {
    const tariffs = useAppSelector(selectAllTariffs) || [];
    const { t } = useTranslation();

    const { openModal } = useContext(ModalContext);

    const openTariffModal = openModal(TariffModal);

    return (
        <Select
            dataTestId="tariffId"
            label={ t('Тариф') }
            actionButton={ {
                label: t('Добавить'),
                onClick: () => openTariffModal({ cb: (record) => onChange(record.id) }),
            } }
            options={ convertToOptions(tariffs) }
            onChange={ ({ selected }) => {
                onChange(+selected?.key);
            } }
            optionsListProps={ {
                emptyPlaceholder: t('Нет тарифов'),
            } }
            selected={ value }
            error={ error }
            block={ true }
        />
    );
};
