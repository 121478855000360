/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { CheckmarkCircleM } from '@alphakits/icons';
import { Flex, Typography } from '@alphakits/ui';
import cn from 'classnames';

import styles from './index.module.css';

type Props = {
    title: string;
    color: string;
    selected: boolean;
    onClick: () => void;
};

export const ScheduleSlot: React.FC<Props> = ({
    title, color, selected, onClick,
}) => (
    <button
        onClick={ () => onClick() }
        className={ cn(styles.slot_schedule) }
        style={ { backgroundColor: color } }
        type="button"
    >
        <Flex>
            <Typography.Text weight="medium" view="caps" color="primary-inverted">
                { title }
            </Typography.Text>
            { selected && <CheckmarkCircleM /> }
        </Flex>
    </button>
);
