import React from 'react';
import { CloseS } from '@alphakits/icons';
import { IconButton, showError, SuperListItem } from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { OrderWorkersRO } from '@escapenavigator/types/dist/order/workers/order-workers.ro';
import { getFullName } from '@escapenavigator/utils/dist/get-full-name';
import { useApi } from 'src/providers/api/context';

type CommentsSectionProps = {
    worker: OrderWorkersRO;
    cb: () => void;
    editAccess: boolean;
};

export const WorkerRow: React.FC<CommentsSectionProps> = ({ worker, cb, editAccess }) => {
    const { orders } = useApi();

    const { removeWorkerLoading, removeWorkerFetch } = useApiMethod({
        api: orders.removeWorker,
        errorCallback: ({ message }) => showError(message),
        successCallback: cb,
    });

    return (
        <SuperListItem
            key={ worker.id }
            leftAddons={ (
                <IconButton
                    loading={ removeWorkerLoading }
                    onClick={ () => removeWorkerFetch(worker.id) }
                    icon={ CloseS }
                />
            ) }
            disabled={ !editAccess }
            text={ worker.position.title }
            rightAddons={ getFullName({ ...worker.user, id: 0 }) }
        />
    );
};
