import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext, Table } from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { PositionModal } from 'src/modals/position';
import { useApi } from 'src/providers/api/context';
import { getLanguage } from 'src/utils/get-language';

import { Columns } from './columns';

export const PositionsPage: React.FC = () => {
    const { openModal } = useContext(ModalContext);
    const { i18n, t } = useTranslation();

    const { users } = useApi();
    const { getAllPositionsData, getAllPositionsFetch, getAllPositionsLoading } = useApiMethod({
        api: users.getAllPositions,
    });

    useEffect(() => {
        getAllPositionsFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = useMemo(() => Columns(t), [t]);

    const openRoleModal = openModal(PositionModal);

    return (
        <Table.TableComponent
            columns={ columns }
            hideSearch={ true }
            onRowClick={ (record) =>
                openRoleModal({ record, cb: () => getAllPositionsFetch(undefined) }) }
            onCreateClick={ () => openRoleModal({ cb: () => getAllPositionsFetch(undefined) }) }
            records={ getAllPositionsData || [] }
            loading={ getAllPositionsLoading }
            language={ getLanguage(i18n) }
        />
    );
};
