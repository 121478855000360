import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    AmountInput,
    Flex,
    FlexColumns,
    Input,
    Tag,
    useCrudFormRequests,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CreateQuestroomScenarioEmailDto } from '@escapenavigator/types/dist/questroom-scenario-email/create-questroom-scenario-email.dto';
import { QuestroomScenarioEmailRO } from '@escapenavigator/types/dist/questroom-scenario-email/questroom-scenario-email.ro';
import { serializeRecord, validateByDto } from '@escapenavigator/utils';
import { Quill } from 'src/components/quill';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';

type Props = {
    record?: QuestroomScenarioEmailRO;
    questroomId: number;
    cb: () => void;
    close: () => void;
};

export const EditScenarioModal: React.FC<Props> = ({
    record, cb, close, questroomId,
}) => {
    const { t } = useTranslation();
    const [isBefore, setIsBefore] = useState(!record?.sendBefore || record?.sendBefore < 0);
    const { profile } = useCurrentUser();
    const { questroomScenarioEmailApi, orders } = useApi();

    const { recreateMarketingEmailsFetch } = useApiMethod({
        api: orders.recreateMarketingEmails,
    });

    const {
        save,
        remove,
        updating,
        removing,
        error: softError,
    } = useCrudFormRequests({
        saveRequest: record?.id
            ? questroomScenarioEmailApi.update
            : questroomScenarioEmailApi.create,
        removeRequest: questroomScenarioEmailApi.remove,
        recordId: record?.id,
        close,
        saveCallback: () => {
            cb();
            recreateMarketingEmailsFetch({ type: 'scenario' });
        },
        removeCallback: cb,
    });

    return (
        <RestForm
            recordId={ record?.id }
            initialValues={ serializeRecord(
                CreateQuestroomScenarioEmailDto,
                record || { questroomId },
            ) }
            validate={ validateByDto(t) }
            softError={ softError }
            remove={ remove }
            updating={ updating }
            removing={ removing }
            title={ record?.id ? `${record.title.slice(0, 40)}...` : t('Добавить имеил') }
            t={ t }
            close={ close }
            save={ (values) =>
                save({ ...values, sendBefore: values.sendBefore * (isBefore ? -1 : 1) }) }
        >
            { ({
                values, setFieldValue, handleChange, touched, errors,
            }) => (
                <FlexColumns columns={ 1 }>
                    <Input
                        label={ t('Заголовок письма') }
                        value={ values.title }
                        onChange={ handleChange('title') }
                        error={ touched.title && errors.title }
                        block={ true }
                    />

                    <Flex justify="start" gap="sm">
                        <Tag
                            view={ isBefore ? 'primary-inverted' : 'primary' }
                            text={ t('до игры') }
                            onClick={ () => setIsBefore(true) }
                        />
                        <Tag
                            view={ isBefore ? 'primary' : 'primary-inverted' }
                            text={ t('после игры') }
                            onClick={ () => setIsBefore(false) }
                        />
                    </Flex>

                    <AmountInput
                        label={ isBefore ? t('Отправить до игры') : t('Отправить с момента игры') }
                        suffix={ t('common:минут') }
                        type="default"
                        value={ Math.abs(values.sendBefore) }
                        onChange={ (_, { value }) => setFieldValue('sendBefore', value) }
                        error={ touched.sendBefore && errors.sendBefore }
                        block={ true }
                    />

                    <Quill
                        text={ values.text }
                        lng={ profile.language }
                        error={ touched.text && errors.text }
                        onChange={ (content) => setFieldValue('text', content) }
                    />
                </FlexColumns>
            ) }
        </RestForm>
    );
};
