import { showError } from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';
import { selectUserById } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

type Props = {
    orderId: number;
    moderatorId: number | null;
    successCallback: (moderatorId: number | null) => void;
    t: TFunction;
};

export const useModeratorData = ({ orderId, moderatorId, successCallback }: Props) => {
    const { orders } = useApi();

    const moderator = useAppSelector(selectUserById(moderatorId));

    const { updateModeratorFetch, updateModeratorLoading } = useApiMethod({
        api: orders.updateModerator,
        errorCallback: (err) => showError(err?.message),
        successCallback: ({ data }) => {
            successCallback(data.moderatorId);
        },
    });

    return {
        loading: updateModeratorLoading,
        update: (moderatorId?: number) =>
            updateModeratorFetch({ id: orderId, data: { moderatorId } }),
        moderator,
    };
};
