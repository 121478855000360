import { initClientApi } from '@escapenavigator/services/dist/api/init-client-api';
import { TariffRO } from '@escapenavigator/types/dist/tariff/tariff.ro';
import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';

import { RootState } from '../store';

const tariffsAdapter = createEntityAdapter<TariffRO>();
const { tariffs } = initClientApi(process.env.REACT_APP_API_DOMAIN);

export const fetchTariffs = createAsyncThunk<TariffRO[]>('tariffs/fetchTariffs', async () => {
    const { data } = await tariffs.query(undefined);

    return data;
});

const tariffsSlice = createSlice({
    name: 'tariffs',
    initialState: tariffsAdapter.getInitialState(),
    reducers: {
        upsertTariff(state, action: PayloadAction<TariffRO>) {
            tariffsAdapter.upsertOne(state, action.payload);
        },
        removeTariff(state, action: PayloadAction<TariffRO['id']>) {
            tariffsAdapter.removeOne(state, action.payload);
        },
    },
    extraReducers: (build) => {
        build.addCase(fetchTariffs.fulfilled, (state, action: PayloadAction<TariffRO[]>) => {
            tariffsAdapter.addMany(state, action.payload);
        });
    },
});

export const { selectAll: selectAllTariffs, selectById: selectTariffById } =
    tariffsAdapter.getSelectors<RootState>((state) => state.tariffs);

export const { upsertTariff, removeTariff } = tariffsSlice.actions;

export default tariffsSlice.reducer;
