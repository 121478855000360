import React from 'react';
import { MoneyStackM } from '@alphakits/icons';
import { Status } from '@alphakits/ui/dist';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { FinanceitemTypeEnum } from '@escapenavigator/types/dist/financeitem/enum/financeitem-type.enum';
import { FinanceitemResponseObject } from '@escapenavigator/types/dist/financeitem/financeitem.ro';
import { TFunction } from 'i18next';

export const FinanceitemColumns = (t: TFunction): TableColumns<FinanceitemResponseObject> => [
    {
        header: t('tables.title'),
        accessor: 'title',
        row: {
            icon: () => <MoneyStackM />,
        },
    },
    {
        header: t('tables.type'),
        accessor: 'type',
        row: {
            title: ({ row }) => (
                <Status
                    view="contrast"
                    color={ row.type === FinanceitemTypeEnum.EXPENSE ? 'red' : 'green' }
                >
                    { t(`options.financeItem.${row.type}`) }
                </Status>
            ),
        },
    },
    {
        header: t('tables.description'),
        accessor: 'comment',
    },
];
