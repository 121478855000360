import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoMarkThinM } from '@alphakits/icons';
import {
    Box, Button, Flex, FlexColumns, Skeleton, Tooltip, Typography,
} from '@alphakits/ui';

type Props = {
    title?: string;
    subtitle?: string;
    id?: string;
    loading?: boolean;
    buttonLoading?: boolean;
    buttonLabel?: string;
    hint?: string | React.ReactNode;
    initial?: boolean;
    buttonAddon?: React.ReactNode;
    onClick?: () => void;
};

export const Section: React.FC<Props> = ({
    title,
    id,
    children,
    hint,
    subtitle,
    loading,
    buttonLoading,
    initial,
    buttonLabel,
    buttonAddon,
    onClick,
}) => {
    const { t } = useTranslation();

    return (
        <Box border={ true } background="var(--color-bg-primary)" rounded="md" id={ id } padding="l">
            <FlexColumns columns={ 1 } gr={ 16 }>
                { title && (
                    <FlexColumns columns={ 1 } gr={ 12 }>
                        <Flex gap="md">
                            <Flex gap="md" justify="start">
                                <Skeleton visible={ loading }>
                                    <Typography.Title tag="div" view="xsmall" weight="bold">
                                        { title }
                                    </Typography.Title>
                                </Skeleton>

                                { hint && (
                                    <Tooltip
                                        trigger="hover"
                                        position="bottom"
                                        content={ <div style={ { width: 300 } }>{ hint }</div> }
                                        dataTestId="test-id"
                                    >
                                        <InfoMarkThinM />
                                    </Tooltip>
                                ) }
                            </Flex>

                            { !initial && buttonAddon }

                            { !initial && !buttonAddon && onClick && (
                                <Skeleton visible={ loading }>
                                    <Button
                                        view={ initial ? 'primary' : 'outlined' }
                                        size={ initial ? 's' : 'xs' }
                                        loading={ buttonLoading }
                                        onClick={ onClick }
                                    >
                                        { buttonLabel || t('Добавить') }
                                    </Button>
                                </Skeleton>
                            ) }
                        </Flex>

                        { subtitle && (
                            <Typography.Text view="primary-medium" weight="medium" color="primary">
                                { subtitle }
                            </Typography.Text>
                        ) }
                    </FlexColumns>
                ) }

                <Skeleton visible={ loading }>
                    { !!children && (
                        <FlexColumns columns={ 1 } gr={ 24 }>
                            { children }
                        </FlexColumns>
                    ) }

                    { initial && buttonAddon }

                    { initial && !buttonAddon && onClick && (
                        <div>
                            <Skeleton visible={ loading }>
                                <Button
                                    view={ initial ? 'primary' : 'outlined' }
                                    size={ initial ? 's' : 'xs' }
                                    loading={ buttonLoading }
                                    onClick={ onClick }
                                >
                                    { buttonLabel || t('Добавить') }
                                </Button>
                            </Skeleton>
                        </div>
                    ) }
                </Skeleton>
            </FlexColumns>
        </Box>
    );
};
