import React from 'react';
import { FlexColumns, Input, useCrudFormRequests } from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { CreateUserPositionDto } from '@escapenavigator/types/dist/user/position/create-user-position.dto';
import { UserPositionRO } from '@escapenavigator/types/dist/user/position/user-position.ro';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

type Props = {
    record?: UserPositionRO;
    close: () => void;
    cb: () => void;
    t: TFunction;
};

export const PositionModal: React.FC<Props> = ({
    record, close, t, cb,
}) => {
    const { users } = useApi();

    const {
        save,
        remove,
        removing,
        updating,
        error: softError,
    } = useCrudFormRequests({
        recordId: record?.id,
        removeRequest: users.removePosition,
        saveRequest: record?.id ? users.updatePosition : users.createPosition,
        saveCallback: cb,
        removeCallback: cb,
        close,
    });

    return (
        <RestForm
            recordId={ record?.id }
            title={ record?.title || t('Новая должность') }
            initialValues={ serializeRecord(CreateUserPositionDto, record || {}) }
            validate={ validateByDto(t) }
            save={ save }
            remove={ remove }
            softError={ softError }
            updating={ updating || removing }
            close={ close }
            t={ t }
        >
            { ({
                values, handleChange, errors, touched,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    <Input
                        dataTestId="title"
                        label={ t('component.roleTitle') }
                        value={ values.title }
                        onChange={ handleChange('title') }
                        error={ touched.title && errors.title }
                        required={ true }
                        block={ true }
                    />
                </FlexColumns>
            ) }
        </RestForm>
    );
};
