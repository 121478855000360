import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyPage } from '@alphakits/ui/dist';
import { SUCCESS_IMAGE } from 'src/constants';

type Props = {
    close: () => void;
};

export const SubscriptionChanged: React.FC<Props> = ({ close }) => {
    const { t } = useTranslation();

    return (
        <EmptyPage
            title={ t('Подписка изменена') }
            buttonText={ t('Готово') }
            onClick={ () => {
                close();
            } }
            image={ SUCCESS_IMAGE }
        />
    );
};
