import React from 'react';
import { FlexColumns } from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { CreateRuleDto } from '@escapenavigator/types/dist/slot-rule/create-rule.dto';
import { RuleRO } from '@escapenavigator/types/dist/slot-rule/rule.ro';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { useOnboarding } from 'src/providers/ongoarding/context';
import { selectAllRules } from 'src/store';
import { useAppSelector } from 'src/store/hooks';
import { useRulesApi } from 'src/store/hooks/use-rules';

import { BookingRuleForm } from '../slots/booking-rule-form';

type Props = {
    record?: RuleRO;
    close: () => void;
    t: TFunction;
};

export const SlotBookingRuleModal: React.FC<Props> = ({ record, close, t }) => {
    const {
        createRule, updateRule, removeRule, updating,
    } = useRulesApi();
    const { refetchProgress } = useOnboarding();

    const handleSave = (data: CreateRuleDto) => {
        const promise = record?.id ? updateRule({ id: record?.id, data }) : createRule(data);

        return promise.then(() => {
            close();
            refetchProgress();
        });
    };

    const otherRules = useAppSelector(selectAllRules);

    const title = record?.id || !!otherRules.length ? record?.title : 'Standart';

    return (
        <RestForm
            recordId={ record?.id }
            title={ record?.title || t('bookingRules.title') }
            initialValues={ serializeRecord(CreateRuleDto, record?.id ? record : { title }) }
            validate={ validateByDto(t) }
            save={ handleSave }
            remove={ record?.id ? () => removeRule(record?.id).then(() => close()) : null }
            updating={ updating }
            close={ close }
            t={ t }
        >
            { ({
                values, setFieldValue, errors, touched,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    <BookingRuleForm
                        values={ values }
                        setFieldValue={ setFieldValue }
                        errors={ errors }
                        touched={ touched }
                        withTitle={ true }
                    />
                </FlexColumns>
            ) }
        </RestForm>
    );
};
