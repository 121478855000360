import React from 'react';
import {
    Flex, FlexColumns, Tag, Typography,
} from '@alphakits/ui';

type Props = {
    value: string;
    onChange: (val: string) => void;
    label?: string;
    options: Array<{ key: string; content: string }>;
};

export const ChipSelect: React.FC<Props> = ({
    label, value, onChange, options,
}) => (
    <FlexColumns columns={ 1 } gr={ 8 }>
        { label && (
            <Typography.Text view="caps" color="secondary">
                { label }
            </Typography.Text>
        ) }

        <Flex wrap={ true } justify="start" gap="md">
            { options.map((o) => (
                <Tag
                    view={ value === o.key ? 'primary-inverted' : 'primary' }
                    text={ o.content }
                    onClick={ () => onChange(o.key) }
                />
            )) }
        </Flex>
    </FlexColumns>
);
