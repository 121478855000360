import { CertificateUploadedRO } from '@escapenavigator/types/dist/certificate-uploaded/certificate-uploaded.ro';
import { differenceInHours, parse } from 'date-fns';

import { Status } from '..';

export const getUploadedCertificateValidity = (row: CertificateUploadedRO): Status => {
    const expiried =
        differenceInHours(parse(row.expireDate, 'yyyy-MM-dd', new Date()), new Date()) < 0;

    if (row.order || row.used) return 'used';

    if (expiried) return 'expired';

    return 'active';
};
