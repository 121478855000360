import { TariffRO } from '@escapenavigator/types/dist/tariff/tariff.ro';

type MakePriceProps = {
    minPlayers: number;
    maxPlayers: number;
    price: TariffRO['price'];
};

export const makePrice = ({ minPlayers, maxPlayers, price = {} }: MakePriceProps) => {
    const res = {};

    for (let i = +minPlayers; i <= +maxPlayers; i++) {
        res[i] = price[i] || 0;
    }

    return res;
};

export const selectMinOptions = (max: number, t) =>
    Array.from({ length: max }, (_, index) => {
        const count = index + 1;

        return { key: count, content: `${count} ${t('ppl')}` };
    });

export const selectMaxOptions = (min: number, t) =>
    Array.from({ length: 30 }, (_, index) => {
        const count = min + index;

        return { key: count, content: `${count} ${t('ppl')}` };
    });
