import React, { useEffect } from 'react';
import {
    FlexColumns, Input, Select, Typography, useCrudFormRequests,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { languageNameByLanguage } from '@escapenavigator/types/dist/constants/language-name-by-language';
import { MailchimpStatusEnum } from '@escapenavigator/types/dist/mailchimp/mailchimp-status.enum';
import { MailchimpDto } from '@escapenavigator/types/dist/mailchimp/mailchimp.dto';
import { MailchimpRO } from '@escapenavigator/types/dist/mailchimp/mailchimp.ro';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';

import { PageLoader } from '../../components/page-loader';

type Props = {
    record: MailchimpRO;
    close: () => void;
    cb: () => void;
    t: TFunction;
};

export const MailchimpModal: React.FC<Props> = ({
    close, t, record, cb,
}) => {
    const { mailchimpApi } = useApi();
    const { profile } = useCurrentUser();

    const { getListsFetch, getListsLoading, getListsData } = useApiMethod({
        api: mailchimpApi.getLists,
    });

    useEffect(() => {
        getListsFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { removeFetch, removeLoading } = useApiMethod({
        api: mailchimpApi.remove,
        successCallback: () => {
            cb();
            close();
        },
    });

    const {
        save,
        updating,
        error: softError,
    } = useCrudFormRequests({
        saveRequest: mailchimpApi.update,
        saveCallback: cb,
        close,
    });

    if (!getListsData || getListsLoading) return <PageLoader />;

    return (
        <RestForm
            recordId={ 1 }
            title="Mailchimp"
            removing={ removeLoading }
            remove={ () => removeFetch(undefined) }
            initialValues={ serializeRecord(MailchimpDto, {
                ...record,
                status: MailchimpStatusEnum.ENABLED,
                locales: profile.availableLanguages.map(
                    (l) =>
                        record.locales?.find((ll) => ll.language === l) || {
                            language: l,
                            text: t('mailchimpPlaceholder', { lng: l }),
                        },
                ),
            }) }
            validate={ validateByDto(t) }
            save={ save }
            softError={ softError }
            updating={ updating }
            close={ close }
            t={ t }
        >
            { ({ values, handleChange, setFieldValue }) => (
                <FlexColumns columns={ 1 } gr={ 32 } gc={ 0 }>
                    { !!getListsData?.length && (
                        <FlexColumns columns={ 1 } gr={ 8 }>
                            <FlexColumns columns={ 1 } gr={ 4 } gc={ 8 }>
                                <Typography.Text view="title" weight="bold">
                                    Audience Selection
                                </Typography.Text>
                                <Typography.Text view="caps" color="secondary">
                                    { t('audienceLabelDescription') }
                                </Typography.Text>
                            </FlexColumns>

                            <Select
                                block={ true }
                                multiple={ false }
                                options={ getListsData.map((d) => ({
                                    key: d.id,
                                    content: d.name,
                                })) }
                                selected={ values.listId }
                                onChange={ ({ selected }) => setFieldValue('listId', selected?.key) }
                                optionsListWidth="field"
                            />
                        </FlexColumns>
                    ) }

                    <FlexColumns columns={ 1 } gr={ 8 }>
                        <FlexColumns columns={ 1 } gr={ 4 } gc={ 8 }>
                            <Typography.Text view="title" weight="bold">
                                { t('mailchimLabels') }
                            </Typography.Text>
                            <Typography.Text view="caps" color="secondary">
                                { t('mailchimLabelsDescription') }
                            </Typography.Text>
                        </FlexColumns>

                        <FlexColumns columns={ 1 } gr={ 8 }>
                            { values.locales.map((l, i) => {
                                const language = languageNameByLanguage[l.language];

                                return (
                                    <Input
                                        dataTestId="text"
                                        label={ language }
                                        value={ l.text }
                                        onChange={ handleChange(`locales.${i}.text`) }
                                        block={ true }
                                    />
                                );
                            }) }
                        </FlexColumns>
                    </FlexColumns>
                </FlexColumns>
            ) }
        </RestForm>
    );
};
