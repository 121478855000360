/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/semi */
import { initClientApi } from '@escapenavigator/services/dist/api/init-client-api';
import { UserRO } from '@escapenavigator/types/dist/user/crud/user.ro';
import { CalendarSessionsDto } from '@escapenavigator/types/dist/user/sessions/calendar-sessions.dto';
import { DaySessionsRO } from '@escapenavigator/types/dist/user/sessions/day-sessions.ro';
import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';

import { RootState } from '../store';

export type SerializedSession = {
    user: UserRO;
    original: DaySessionsRO;
    start: string;
    end: string;
    locationId: number;
    id: string;
};

const sessionsAdapter = createEntityAdapter<DaySessionsRO>();

const api = initClientApi(process.env.REACT_APP_API_DOMAIN);

export const fetchCalendarSessions = createAsyncThunk<DaySessionsRO[], CalendarSessionsDto>(
    'sessions/fetchCalendarSessions',
    async (query) => {
        const { data } = await api.users.getCalendarSessions(query);

        return data;
    },
);

const sessionsSlice = createSlice({
    name: 'sessions',
    initialState: sessionsAdapter.getInitialState({
        loading: false,
        entities: [],
    }),
    reducers: {
        upsetSession(state, action: PayloadAction<DaySessionsRO>) {
            sessionsAdapter.upsertOne(state, action.payload);
        },
        removeSession(state, action: PayloadAction<DaySessionsRO['id']>) {
            sessionsAdapter.removeOne(state, action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchCalendarSessions.fulfilled,
            (state, action: PayloadAction<DaySessionsRO[]>) => {
                state.loading = false;
                sessionsAdapter.addMany(state, action.payload);
            },
        );

        builder.addCase(fetchCalendarSessions.pending, (state) => {
            state.loading = true;
            sessionsAdapter.removeAll(state);
        });
    },
});

const { selectAll } = sessionsAdapter.getSelectors<RootState['sessions']>((state) => state);

const serializeSessions = ({
    sessions,
    users,
    date,
}: {
    sessions: DaySessionsRO[];
    users: UserRO[];
    date: string;
}): SerializedSession[] => {
    const serializedUsers = users.reduce((acc, q) => {
        acc[q.id] = q;

        return acc;
    }, {} as Record<number, UserRO>);

    return sessions
        .filter((s) => s.date === date)
        .reduce((acc, s) => {
            const user = serializedUsers[s.userId];

            return [
                ...acc,
                ...s.sessions.map(({ start, end, locationId }) => ({
                    id: s.date + start,
                    original: s,
                    start,
                    end,
                    locationId,
                    user,
                })),
            ];
        }, []);
};

export const selectAllRawSessions = (date: string) =>
    createSelector(
        (state: RootState) => state,
        (state) => selectAll(state.sessions).filter((s) => s.date === date),
    );

export const selectAllSessions = (users: UserRO[], date: string) =>
    createSelector(
        (state: RootState) => state,
        (state) => serializeSessions({ sessions: selectAll(state.sessions), users, date }),
    );

export const selectLocationSessions = (users: UserRO[], date: string, locationId: number) =>
    createSelector(
        (state: RootState) => state,
        (state) =>
            serializeSessions({ sessions: selectAll(state.sessions), users, date }).filter(
                (s) => s.locationId === locationId,
            ),
    );

export const { upsetSession, removeSession } = sessionsSlice.actions;

export default sessionsSlice.reducer;
