import React from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarM, TrashS } from '@alphakits/icons';
import { Button } from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { GoogleCalendarRO } from '@escapenavigator/types/dist/google-calendar/google-calendar.ro';
import { selectAllLocations } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

export const CalendarColumns = ({
    removeFetch,
}: {
    removeFetch: (id: number) => void;
}): TableColumns<GoogleCalendarRO> => {
    const { t } = useTranslation();
    const loactions = useAppSelector(selectAllLocations);

    return [
        {
            header: 'Google calendar',
            accessor: 'title',
            row: {
                icon: () => <CalendarM />,
            },
        },
        {
            header: t('Локации'),
            accessor: 'allLocations',
            row: {
                title: ({ row }) =>
                    (row.allLocations
                        ? 'All locations'
                        : loactions
                            .filter((l) => row.locationIds?.includes(l.id))
                            .map((l) => l.title)
                            .join(', ')),
            },
        },
        {
            header: '',
            accessor: 'id',
            row: {
                title: ({ row }) => (
                    <Button
                        onClick={ () => removeFetch(row.id) }
                        view="outlined"
                        size="xs"
                        leftAddons={ <TrashS /> }
                    />
                ),
            },
        },
    ];
};
