import React from 'react';
import { Tag } from '@alphakits/ui';

type Props = {
    text: string;
    row: string;
    removeParam: (row: string) => void;
};

export const SimpleRowValue = ({ text, removeParam, row }: Props) => (
    <Tag text={ text } onClick={ () => {} } onCloseClick={ () => removeParam(row) } />
);
