import React from 'react';
import { Flex, Tag } from '@alphakits/ui/dist';

export const daysTemplate = {
    0: 'Mo',
    1: 'Tu',
    2: 'We',
    3: 'Th',
    4: 'Fr',
    5: 'Sa',
    6: 'Su',
};

type Props = {
    selected: number[];
    onChange: (days: number[]) => void;
};

export const DaysOfWeek: React.FC<Props> = ({ selected = [], onChange }) => {
    const handleAddOrRemove = (day: number) => {
        const copy = [...selected];
        const newDays = copy.includes(day) ? copy.filter((d) => d !== day) : [...copy, day];

        onChange(newDays.length ? newDays : null);
    };

    return (
        <Flex gap="sm" justify="start">
            { Object.entries(daysTemplate).map((day) => (
                <Tag
                    text={ day[1] }
                    onClick={ () => handleAddOrRemove(+day[0]) }
                    view={ selected.includes(+day[0]) ? 'primary-inverted' : 'primary' }
                />
            )) }
        </Flex>
    );
};
