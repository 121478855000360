import React from 'react';
import { PortfolioM } from '@alphakits/icons';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { PartnerResponseObject } from '@escapenavigator/types/dist/partner/partner.ro';

export const PartnerColumns = (t): TableColumns<PartnerResponseObject> => [
    {
        header: t('tables.title'),
        accessor: 'title',
        row: {
            icon: () => <PortfolioM />,
        },
    },
    {
        header: t('tables.contact'),
        accessor: 'phone',
        row: {
            subtitle: 'email',
        },
    },
    {
        header: t('tables.partner'),
        accessor: 'contact',
    },
];
