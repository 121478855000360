import React from 'react';
import { Tag } from '@alphakits/ui';
import { selectUserByIdOrIds } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

import { TagProps } from './types';

export const User: React.FC<TagProps> = ({ value, row, removeParam }) => {
    const users = useAppSelector(selectUserByIdOrIds(Array.isArray(value) ? value : [value]));
    const label = row ? `${row}: ` : '';
    const text =
        users.length === 1
            ? `${label}${users[0].name} ${users[0].surname}`
            : `${label}${users.map((user) => ` ${user.name} ${user.surname}`)}`;

    return <Tag text={ text } onClick={ () => {} } onCloseClick={ () => removeParam(row) } />;
};
