import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { Route } from 'src/router/types';

import { CollapsibleMenuButton } from './button/collapsed';
import { MenuButton } from './button';

import styles from './index.module.css';

type Props = {
    routes: Record<string, Route>;
};

export const MenuButtonsList: React.FC<Props> = ({ routes }) => {
    const { pathname } = useLocation();

    const routesMap = useMemo(() => Object.entries(routes).filter((r) => !r[1].pure), [routes]);

    return (
        <div className={ styles.component }>
            { routesMap
                .filter(([, route]) => route.type !== 'subpage')
                .map(([path, route]) => {
                    if (route.type === 'group') {
                        return (
                            <CollapsibleMenuButton
                                key={ route.title }
                                rightAddons={ route.rightAddon }
                                leftAddons={ route.icon }
                                label={ route.title }
                                active={ pathname.includes(path) }
                            >
                                { Object.entries(route.children)
                                    .filter(([, route]) => route.type !== 'subpage')
                                    .map(([nestedPath, nestedRoute]) => (
                                        <MenuButton
                                            rightAddons={ nestedRoute.rightAddon }
                                            active={ pathname === nestedPath }
                                            key={ nestedRoute.title }
                                            href={ nestedPath }
                                            secondary={ true }
                                        >
                                            { nestedRoute.title }
                                        </MenuButton>
                                    )) }
                            </CollapsibleMenuButton>
                        );
                    }

                    return (
                        <MenuButton
                            leftAddons={ route.icon }
                            rightAddons={ route.rightAddon }
                            active={ path === pathname }
                            key={ path }
                            href={ path }
                        >
                            { route.title }
                        </MenuButton>
                    );
                }) }
        </div>
    );
};
