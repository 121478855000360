import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusS } from '@alphakits/icons';
import {
    Flex, FlexColumns, ModalContext, Tag, Typography,
} from '@alphakits/ui/dist';
import { TagsEnum } from '@escapenavigator/types/dist/shared/enum/tags.enum';

import { QuestroomTagsModal } from './tags';

type Props = {
    setTags: (tags: TagsEnum[]) => void;
    tags: TagsEnum[];
    error?: string;
};

export const QuestroomTagsPicker: React.FC<Props> = ({ setTags, tags, error }) => {
    const { openModal } = useContext(ModalContext);
    const { t } = useTranslation();

    return (
        <FlexColumns columns={ 1 } gr={ 16 }>
            <Flex justify="start" gap="xs" wrap={ true }>
                { tags.map((v) => (
                    <Tag
                        view="primary"
                        key={ v }
                        onClick={ () => openModal(QuestroomTagsModal)({ setTags, tags }) }
                        text={ t(`tags:${v}`) }
                    />
                )) }

                <Tag
                    onClick={ () => openModal(QuestroomTagsModal)({ setTags, tags }) }
                    view="primary"
                    leftAddons={ <PlusS /> }
                    text={ t('Добавить') }
                />
            </Flex>

            { error && (
                <Typography.Text view="caps" color="negative">
                    { error }
                </Typography.Text>
            ) }
        </FlexColumns>
    );
};
