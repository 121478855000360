export function clearQuill(text: string) {
    const quillContent = text;

    const cleanedContent = quillContent
        .replace(/<p><br><\/p>/g, '') // Удаляем пустые параграфы
        .replace(/<p>\s*<\/p>/g, '') // Удаляем параграфы, содержащие только пробелы или пустые
        .replace(/<br\s*\/?>/g, ''); // Удаляем лишние теги <br>

    return cleanedContent;
}
