import { LocationRO } from '@escapenavigator/types/dist/location/location.ro';
import { QuestroomRO } from '@escapenavigator/types/dist/questroom/questroom.ro';

export const getQuestroomsList = (questrooms: QuestroomRO[], locations: LocationRO[]) => {
    const data = questrooms.map((q) => ({
        ...q,
        location: locations.find((l) => l.id === q.locationId),
    }));

    return data.filter((q) => !!q.location).map((q) => ({
        key: q.id,
        content: `${q.title}, ${q.location.title}`,
    }));
};
