import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDownS, GearM } from '@alphakits/icons';
import {
    Button, CheckboxGroup, Flex, showSuccess, SidepanelHeader, Tag,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { LogTitleEnum } from '@escapenavigator/types/dist/log/log-title.enum';
import { CrmVerificationEnum } from '@escapenavigator/types/dist/profile/enum/crm-verifiaction.enum';
import { UpdateCurrentUserDto } from '@escapenavigator/types/dist/user/current/update-current-user.dto';
import { serializeRecord } from '@escapenavigator/utils';
import { Picker } from 'src/components/picker';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';

import { NotificationsBody } from './components/notifications-body';

import styles from './index.module.css';

enum TagsEnum {
    ALL = 'all',
    NEW_ORDERS = 'new_orders',
    NEW_CETIFICATES = 'new_certificates',
    TRANSACTIONS = 'transactions',
    ALL_ORDERS = 'all_orders',
    IMPORTANT = 'inportant',
    ONBOARDING = 'onboarding',
}

type Props = {
    close: () => void;
};

export const NotificationModal: React.FC<Props> = ({ close }) => {
    const { users } = useApi();
    const { t } = useTranslation();
    const { profile, current, setUser } = useCurrentUser();

    const setupCompite = profile.crmVerification === CrmVerificationEnum.SUCCEED;
    const initialTag = setupCompite ? TagsEnum.IMPORTANT : TagsEnum.ONBOARDING;
    const [setupImportant, setSetupImportant] = useState(false);
    const [tag, setTag] = useState<TagsEnum>(initialTag);

    const otherSelected =
        tag !== TagsEnum.IMPORTANT &&
        tag !== TagsEnum.ALL &&
        !(!setupCompite && tag === TagsEnum.ONBOARDING);

    const { currentUpdateFetch, currentUpdateLoading } = useApiMethod({
        api: users.currentUpdate,
        successCallback: ({ data }) => {
            setUser({ ...current, user: data });
            setSetupImportant(false);
            showSuccess('Saved');
        },
    });

    const handleUpdateUser = useCallback(
        (pushNotifications: UpdateCurrentUserDto['pushNotifications']) => {
            currentUpdateFetch({
                data: serializeRecord(UpdateCurrentUserDto, {
                    ...current.user,
                    pushNotifications: pushNotifications || [],
                }),
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [current.user],
    );

    const pickerOptions = Object.fromEntries(
        Object.values(TagsEnum)
            .filter((tg) => tg !== TagsEnum.ALL && tg !== TagsEnum.IMPORTANT)
            .map((tg) => [t(`options.logs.${tg}`), () => setTag(tg)]),
    );

    if (setupImportant) {
        return (
            <RestForm
                title={ t('Настройки уведомлений') }
                subtitle={ t('Укажите важные нотификации') }
                back={ () => setSetupImportant(false) }
                initialValues={ { pushNotifications: current.user.pushNotifications || [] } }
                save={ (values) => handleUpdateUser(values.pushNotifications) }
                updating={ currentUpdateLoading }
                close={ close }
                t={ t }
            >
                { ({ values, setFieldValue }) => (
                    <CheckboxGroup
                        selected={ values.pushNotifications || [] }
                        data={ Object.values(LogTitleEnum).map((key) => ({
                            key,
                            content: t(`logs.${key}`),
                        })) }
                        onChange={ (v) => setFieldValue('pushNotifications', v || []) }
                    />
                ) }
            </RestForm>
        );
    }

    return (
        <div className={ styles.container }>
            <SidepanelHeader
                title={ t('notification') }
                rightAddons={ (
                    <Button view="ghost" onClick={ () => setSetupImportant(true) }>
                        <GearM />
                    </Button>
                ) }
                bottomAddons={ (
                    <Flex justify="start" wrap={ false } gap="xs">
                        { !setupCompite && (
                            <Tag
                                text={ t('options.logs.onboarding') }
                                size="m"
                                view={ tag === TagsEnum.ONBOARDING ? 'primary-inverted' : 'primary' }
                                onClick={ () => setTag(TagsEnum.ONBOARDING) }
                            />
                        ) }

                        <Tag
                            text={ t('options.logs.important') }
                            size="m"
                            view={ tag === TagsEnum.IMPORTANT ? 'primary-inverted' : 'primary' }
                            onClick={ () => setTag(TagsEnum.IMPORTANT) }
                        />

                        <Tag
                            text={ t(`options.logs.${TagsEnum.ALL}`) }
                            size="m"
                            view={ tag === TagsEnum.ALL ? 'primary-inverted' : 'primary' }
                            onClick={ () => setTag(TagsEnum.ALL) }
                        />

                        <Picker
                            t={ t }
                            actions={ pickerOptions }
                            content={ (
                                <Tag
                                    text={ otherSelected ? t(`options.logs.${tag}`) : t('Другое') }
                                    size="m"
                                    rightAddons={ <ChevronDownS /> }
                                    view={ otherSelected ? 'primary-inverted' : 'primary' }
                                />
                            ) }
                        />
                    </Flex>
                ) }
            />

            <div className={ styles.content }>
                <NotificationsBody
                    openSettings={ () => setSetupImportant(true) }
                    tag={ tag }
                    close={ close }
                />
            </div>
        </div>
    );
};
