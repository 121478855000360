import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext, Table } from '@alphakits/ui/dist';
import { PromocodeModal } from 'src/modals/promocode';
import { PromocodeInfoModal } from 'src/modals/promocode-info';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { selectAllQuestrooms } from 'src/store';
import { useAppSelector } from 'src/store/hooks';
import { getLanguage } from 'src/utils/get-language';

import { PromocodeColumns } from './columns';

export const PromocodesPage: React.FC = () => {
    const { t } = useTranslation();

    const { promocodes } = useApi();
    const { openModal } = useContext(ModalContext);
    const { i18n } = useTranslation();
    const {
        profile: { currency },
    } = useCurrentUser();

    const questrooms = useAppSelector(selectAllQuestrooms({ deleted: false, closed: false }));

    const columns = useMemo(
        () => PromocodeColumns({ questrooms, t, currency }),
        [t, questrooms, currency],
    );

    const openNewPromocodeModal = openModal(PromocodeModal);
    const openPromocodeModal = openModal(PromocodeInfoModal, 'm', true);

    const handleQuery = (values) =>
        promocodes.query({ ...values, where: { ...values.where, type: 'noCrossSale' } });

    return (
        <Table.TableComponent
            columns={ columns }
            searchPlaceholder={ `${t('Поиск по номеру купона')}` }
            onRowClick={ ({ id }) => openPromocodeModal({ recordId: +id }) }
            onCreateClick={ (cb) => openNewPromocodeModal({ cb }) }
            language={ getLanguage(i18n) }
            promise={ handleQuery }
        />
    );
};
