/* eslint-disable react/no-array-index-key */
import React from 'react';
import { TrashM } from '@alphakits/icons';
import {
    Button, Flex, FlexColumns, IconButton, Input, ListAllert,
} from '@alphakits/ui/dist';
import { TFunction } from 'i18next';

import styles from './index.module.css';

type Props = {
    emailList: string[];
    onChange: (emails: string[]) => void;
    error?: string;
    t: TFunction;
};

export const EmailListBuilder: React.FC<Props> = ({
    emailList = [], onChange, error, t,
}) => {
    const emails = [...emailList];

    return (
        <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
            { emailList.map((email, index) => (
                <Flex key={ index + 1 }>
                    <Input
                        dataTestId="email"
                        label="Email"
                        value={ email }
                        onChange={ (e, { value }) => {
                            emails[index] = value;
                            onChange([...emails]);
                        } }
                        required={ true }
                        block={ true }
                    />

                    <IconButton
                        icon={ TrashM }
                        onClick={ () => {
                            emails.splice(index, 1);
                            onChange([...emails]);
                        } }
                    />
                </Flex>
            )) }

            <Button onClick={ () => onChange([...emails, '']) } size="xs" view="outlined">
                { t('Добавить email') }
            </Button>

            { !!error && <ListAllert className={ styles.allert } text={ error } view="negative" /> }
        </FlexColumns>
    );
};
