import React, { useState } from 'react';
import { ChevronDownThinM } from '@alphakits/icons';
import { Collapse, FlexColumns } from '@alphakits/ui/dist';
import cn from 'classnames';

import { Base } from './base';

import styles from './index.module.css';

interface CollapsibleMenuButtonProps {
    leftAddons?: React.ReactNode;
    rightAddons?: React.ReactNode;
    defaultExpanded?: boolean;
    active: boolean;
    label?: string;
}

export const CollapsibleMenuButton: React.FC<CollapsibleMenuButtonProps> = ({
    children,
    leftAddons,
    rightAddons,
    defaultExpanded,
    active,
    label,
}) => {
    const [expanded, setExpanded] = useState(defaultExpanded);

    return (
        <FlexColumns columns={ 1 } gr={ 2 }>
            <button
                className={ cn(styles.menu_button, {
                    [styles.active]: active,
                    [styles.expanded]: expanded,
                }) }
                type="button"
                onClick={ () => setExpanded(!expanded) }
            >
                <Base
                    leftAddons={ leftAddons }
                    rightAddons={ (
                        <React.Fragment>
                            { rightAddons }
                            <ChevronDownThinM className={ styles.chevron } />
                        </React.Fragment>
                    ) }
                >
                    { label }
                </Base>
            </button>

            <Collapse className={ styles.collapseWrapper } expanded={ expanded }>
                <FlexColumns columns={ 1 } gr={ 2 }>
                    { children }
                </FlexColumns>
            </Collapse>
        </FlexColumns>
    );
};
