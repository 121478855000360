import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Flex,
    formatDate,
    ModalContext,
    Status,
    StatusProps,
    Typography,
} from '@alphakits/ui/dist';
import { ProfileSubscriptionTypeEnum } from '@escapenavigator/types/dist/profile/enum/profile-subscription-type.enum';
import { ProfileSubscriptionEnum } from '@escapenavigator/types/dist/profile/enum/profile-subscription.enum';
import { Section } from 'src/components/layout/section';
import { ChangeSubscriptionPlanModal } from 'src/modals/change-subscription-plan';
import { useCurrentUser } from 'src/providers/current-user/context';

type Props = {
    loading: boolean;
};

export const SubscriptionSection: React.FC<Props> = ({ loading }) => {
    const { t, i18n } = useTranslation();

    const { openModal } = useContext(ModalContext);
    const { profile } = useCurrentUser();

    const status = useMemo((): {
        text: string;
        color: StatusProps['color'];
    } => {
        if (profile.subscriptionCancelationDate) {
            return {
                text: `Ended ${formatDate(profile.subscriptionCancelationDate * 1000, {
                    lang: i18n.language,
                    format: 'dd MMM',
                })}`,
                color: 'grey',
            };
        }

        if (profile.subscriptionStatus === ProfileSubscriptionEnum.ACTIVE) {
            return {
                text: 'Active',
                color: 'green',
            };
        }

        if (profile.subscriptionStatus === ProfileSubscriptionEnum.TRAILING) {
            return {
                text: 'Trial',
                color: 'green',
            };
        }

        if (profile.subscriptionStatus === ProfileSubscriptionEnum.PAUSED) {
            return {
                text: 'Paused',
                color: 'grey',
            };
        }

        return {
            text: 'Awaiting payment',
            color: 'orange',
        };
    }, [profile, i18n]);

    const subscriptionTitle = useMemo(() => {
        if (profile.subscriptionType === ProfileSubscriptionTypeEnum.PRO) {
            return `Navigator CRM Pro: ${profile.questroomsCount} Escape rooms`;
        }

        return 'Navigator CRM Free';
    }, [profile]);

    return (
        <Section
            title={ t('Подписка') }
            buttonLabel="Change"
            loading={ loading }
            onClick={ () => openModal(ChangeSubscriptionPlanModal, 'm')({ type: 'change' }) }
        >
            <Flex align="center" justify="start" gap="sm">
                <Typography.Text view="title">{ subscriptionTitle }</Typography.Text>

                <Status view="contrast" upperCase={ true } color={ status.color }>
                    { status.text }
                </Status>
            </Flex>
        </Section>
    );
};
