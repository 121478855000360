import React, { useContext, useState } from 'react';
import { SearchM } from '@alphakits/icons';
import {
    Button,
    FlexColumns,
    InfoBlockHeader,
    InfoBlockWrapper,
    Input,
    Loader,
    ModalContext,
    SidepanelHeader,
    Typography,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { getFullName } from '@escapenavigator/utils/dist/get-full-name';
import { TFunction } from 'i18next';
import { CertificatesaleDescription } from 'src/components/details/certificatesale-description.tsx';
import { CertificateuploadDescription } from 'src/components/details/certificateupload-description.tsx';
import { PromocodeDescription } from 'src/components/details/promodoe-description.tsx';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';

import { CertificatesaleModal } from '../certificatesale';
import { ClientModal } from '../client';
import { ModalTable } from '../components/modal-table';
import { OrderTableItem } from '../components/order-table-item';
import { PersonTableItem } from '../components/person-table-item';
import { OrderModal } from '../order';
import { PromocodeInfoModal } from '../promocode-info';

import styles from './index.module.css';

type Props = {
    t: TFunction;
};

export const GlobalSearchModal: React.FC<Props> = ({ t }) => {
    const { searchApi } = useApi();
    const {
        profile: { currency },
    } = useCurrentUser();

    const [showResults, setShowResults] = useState(false);
    const [text, setText] = useState('');

    const { globalSearchData, globalSearchLoading, globalSearchFetch } = useApiMethod({
        api: searchApi.globalSearch,
    });

    const { openModal } = useContext(ModalContext);

    return (
        <div className={ styles.container }>
            <SidepanelHeader title={ t('Глобальный поиск') } />

            <div className={ styles.content }>
                <FlexColumns columns={ 1 } gr={ 12 }>
                    { showResults ? (
                        <React.Fragment>
                            { globalSearchLoading ? (
                                <Loader />
                            ) : (
                                <React.Fragment>
                                    { !globalSearchData && (
                                        <Typography.Text view="title" weight="bold">
                                            { t('Нет результатов') }
                                        </Typography.Text>
                                    ) }

                                    { !!globalSearchData?.orders.length && (
                                        <React.Fragment>
                                            <InfoBlockHeader title={ t('reservation') } />

                                            <ModalTable>
                                                { globalSearchData.orders.map((order) => (
                                                    <OrderTableItem
                                                        onClick={ () =>
                                                            openModal(
                                                                OrderModal,
                                                                'm',
                                                            )({ recordId: order.id }) }
                                                        order={ order }
                                                    />
                                                )) }
                                            </ModalTable>
                                        </React.Fragment>
                                    ) }

                                    { !!globalSearchData?.clients.length && (
                                        <React.Fragment>
                                            <InfoBlockHeader title={ t('Клиенты') } />

                                            <ModalTable>
                                                { globalSearchData.clients.map((client) => (
                                                    <PersonTableItem
                                                        onClick={ () =>
                                                            openModal(
                                                                ClientModal,
                                                                'm',
                                                                true,
                                                            )({ recordId: client.id }) }
                                                        title={ getFullName(client) }
                                                        subtitle={ client.phone }
                                                    />
                                                )) }
                                            </ModalTable>
                                        </React.Fragment>
                                    ) }

                                    { (!!globalSearchData?.uploadedCertificates.length ||
                                        !!globalSearchData?.certificates.length) && (
                                        <React.Fragment>
                                            <InfoBlockHeader title={ t('Сертификаты') } />

                                            { globalSearchData?.uploadedCertificates.map((cert) => (
                                                <InfoBlockWrapper view="bordered">
                                                    <CertificateuploadDescription
                                                        certificatesale={ cert }
                                                    />
                                                </InfoBlockWrapper>
                                            )) }

                                            { globalSearchData?.certificates.map((cert) => (
                                                <InfoBlockWrapper view="bordered">
                                                    <CertificatesaleDescription
                                                        onClick={ () =>
                                                            openModal(
                                                                CertificatesaleModal,
                                                                'm',
                                                                true,
                                                            )({ certificatesaleId: cert.id }) }
                                                        certificatesale={ cert }
                                                    />
                                                </InfoBlockWrapper>
                                            )) }
                                        </React.Fragment>
                                    ) }

                                    { !!globalSearchData?.promocodes.length && (
                                        <React.Fragment>
                                            <InfoBlockHeader title={ t('Промокоды') } />

                                            { globalSearchData?.promocodes.map((cert) => (
                                                <InfoBlockWrapper view="bordered">
                                                    <PromocodeDescription
                                                        currency={ currency }
                                                        onClick={ () =>
                                                            openModal(
                                                                PromocodeInfoModal,
                                                                'm',
                                                                true,
                                                            )({ recordId: cert.id }) }
                                                        promocode={ cert }
                                                    />
                                                </InfoBlockWrapper>
                                            )) }
                                        </React.Fragment>
                                    ) }
                                    <Button
                                        size="s"
                                        onClick={ () => setShowResults(false) }
                                        view="outlined"
                                    >
                                        { t('Повторить поиск') }
                                    </Button>
                                </React.Fragment>
                            ) }
                            <br />
                            <br />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Input
                                value={ text }
                                leftAddons={ <SearchM /> }
                                block={ true }
                                onChange={ (e, { value }) => setText(value) }
                                hint={ t(
                                    'Поиск работает по клиентам, играм, сертификатам и промокодам',
                                ) }
                            />

                            <Button
                                disabled={ text.length < 3 }
                                view="primary"
                                loading={ globalSearchLoading }
                                size="m"
                                onClick={ () => {
                                    setShowResults(true);
                                    globalSearchFetch(text);
                                } }
                            >
                                { t('component.search') }
                            </Button>
                        </React.Fragment>
                    ) }
                </FlexColumns>
            </div>
        </div>
    );
};
