import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Button,
    Flex,
    FlexColumns,
    Input,
    showError,
    showSuccess,
    Switch,
    Typography,
} from '@alphakits/ui/dist';
import { BaseForm } from '@alphakits/ui/dist/form/templates/base-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { ProfileWaiverRO } from '@escapenavigator/types/dist/profile/waiver/profile-waiver.ro';
import { UpdateProfileWaiverDto } from '@escapenavigator/types/dist/profile/waiver/update-waiver.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils';
import { PageLoader } from 'src/components/page-loader';
import { Quill } from 'src/components/quill';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { clearQuill } from 'src/utils/clear-quill';

export const waiverUrl =
    'https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/widget/waiver.webp';

export const WaiverPage: React.FC = () => {
    const { t, i18n } = useTranslation();
    const defaultWaiver = useRef<UpdateProfileWaiverDto>();
    const { profile } = useCurrentUser();
    const { profiles } = useApi();

    const [initLoading, setInitLoading] = useState(true);
    const [waiver, setWaiver] = useState<ProfileWaiverRO>();

    const { getProfileWaiverData: data, getProfileWaiverFetch } = useApiMethod({
        api: profiles.getProfileWaiver,
        errorCallback: (err) => showError(err.message),
        successCallback: ({ data }) => {
            setWaiver(data);
            setInitLoading(false);
        },
    });

    useEffect(() => {
        i18n.reloadResources(
            ['en', 'de', 'et', 'ru', 'es', 'pt', 'pl', 'fr', 'it', 'el', 'nl', 'cs'],
            'crm-waviers',
            () => {
                defaultWaiver.current = {
                    active: true,
                    title: t('crm-waviers:templates.title', { lng: profile.language }),
                    text: t('crm-waviers:templates.text', {
                        companyName: profile.title,
                        lng: profile.language,
                    }),
                    label: t('crm-waviers:templates.checkbox', { lng: profile.language }),
                };

                getProfileWaiverFetch({ ...defaultWaiver.current, active: false });
            },
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { updateProfileWaiverFetch: updateFetch, updateProfileWaiverLoading: updateLoading } =
        useApiMethod({
            api: profiles.updateProfileWaiver,
            errorCallback: (err) => showError(err.message),
            successCallback: ({ data }) => {
                showSuccess('Waiver updated');
                setWaiver(data);
            },
        });

    if (initLoading || updateLoading || !data) return <PageLoader />;

    return (
        <FlexColumns columns={ 1 } gr={ 32 }>
            <Box padding="md" rounded="md" border={ true }>
                <Flex justify="start" gap="xl" align="start">
                    <img src={ waiverUrl } width={ 120 } alt="" />
                    <Typography.Text view="primary-medium" color="primary">
                        { t('waiwerPageDescription') }
                    </Typography.Text>
                </Flex>
            </Box>

            <BaseForm
                enableReinitialize={ true }
                initialValues={ serializeRecord(UpdateProfileWaiverDto, waiver) }
                validate={ validateByDto(t) }
                loading={ updateLoading }
                save={ (data) =>
                    updateFetch({
                        data: {
                            ...data,
                            text: clearQuill(data.text),
                        },
                    }) }
                t={ t }
            >
                { ({
                    values,
                    setFieldValue,
                    setValues,
                    handleChange,
                    submitForm,
                    dirty,
                    touched,
                    errors,
                }) => (
                    <FlexColumns columns={ 1 }>
                        <Switch
                            label={ values.active ? t('Активирована') : t('Выключено') }
                            onChange={ (_, value) => {
                                setFieldValue('active', value.checked);
                            } }
                            checked={ values.active }
                        />

                        { values.active && (
                            <React.Fragment>
                                <Input
                                    label="Title"
                                    value={ values.title }
                                    onChange={ handleChange('title') }
                                    error={ touched.title && errors.title }
                                    block={ true }
                                />

                                <Input
                                    label="Checkbox text"
                                    value={ values.label }
                                    onChange={ handleChange('label') }
                                    error={ touched.label && errors.label }
                                    block={ true }
                                />

                                <Quill
                                    text={ values.text }
                                    lng={ profile.language }
                                    error={ touched.text && errors.text }
                                    onChange={ (content) => setFieldValue('text', content) }
                                />
                            </React.Fragment>
                        ) }
                        { (dirty || values.active) && (
                            <Flex gap="md" justify="start">
                                <Button
                                    onClick={ () => {
                                        setFieldValue('text', clearQuill(values.text));
                                        setTimeout(() => submitForm());
                                    } }
                                    loading={ false }
                                    disabled={ !dirty }
                                    size="s"
                                    view="primary"
                                >
                                    { t('save') }
                                </Button>

                                { dirty && (
                                    <Button
                                        onClick={ () => setValues(defaultWaiver.current) }
                                        size="s"
                                        view="outlined"
                                    >
                                        { t('Рекомендуемые значения') }
                                    </Button>
                                ) }
                            </Flex>
                        ) }

                        <br />
                        <br />
                        <br />
                    </FlexColumns>
                ) }
            </BaseForm>
        </FlexColumns>
    );
};
