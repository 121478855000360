import React from 'react';
import {
    AmountInput, FlexColumns, Textarea, ToastPlate,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CreateOrderPenaltyDto } from '@escapenavigator/types/dist/order-penalty/create-order-penalty.dto';
import { OrderRO } from '@escapenavigator/types/dist/order/order.ro';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

type Props = {
    t: TFunction;
    close: () => void;
    setOrder: (order: OrderRO) => void;
    orderId: OrderRO['id'];
    currency: ProfileCurrencyEnum;
};

export const AddPenalty: React.FC<Props> = ({
    close, t, orderId, setOrder, currency,
}) => {
    const { orderPenalties } = useApi();

    const { createFetch, createLoading, createError } = useApiMethod({
        api: orderPenalties.create,
        successCallback: ({ data }) => {
            setOrder(data);
            close();
        },
    });

    return (
        <RestForm
            initialValues={ serializeRecord(CreateOrderPenaltyDto, {
                orderId,
            }) }
            validate={ validateByDto(t) }
            loading={ false }
            updating={ createLoading }
            removing={ false }
            title={ t('Выставление штрафа') }
            t={ t }
            close={ close }
            save={ async (data) => {
                createFetch({ data });
            } }
        >
            { ({
                values, handleChange, errors, touched, setFieldValue,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    <AmountInput
                        value={ values.amount }
                        error={ touched.amount && errors.amount }
                        onChange={ (e, { value }) => setFieldValue('amount', value) }
                        label={ t('component.amount') }
                        suffix={ currency }
                        block={ true }
                        required={ true }
                        type="decimal"
                    />

                    <Textarea
                        value={ values.reason }
                        onChange={ handleChange('reason') }
                        error={ touched.reason && errors.reason }
                        required={ true }
                        label={ t('Причина') }
                        maxLength={ 200 }
                        counter={ true }
                    />

                    { createError && (
                        <ToastPlate view="negative" title="Error">
                            { createError.message }
                        </ToastPlate>
                    ) }
                </FlexColumns>
            ) }
        </RestForm>
    );
};
