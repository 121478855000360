import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext, Table } from '@alphakits/ui/dist';
import { PartnerModal } from 'src/modals/partner';
import { useApi } from 'src/providers/api/context';
import { getLanguage } from 'src/utils/get-language';

import { PartnerColumns } from './columns';

export const PartnersPage: React.FC = () => {
    const { openModal } = useContext(ModalContext);
    const { i18n, t } = useTranslation();

    const columns = useMemo(() => PartnerColumns(t), [t]);
    const { partners } = useApi();

    const openPartnerModal = openModal(PartnerModal);

    return (
        <Table.TableComponent
            columns={ columns }
            searchPlaceholder={ t('tables.partnerSettingSearch') }
            onRowClick={ ({ id }, cb) => openPartnerModal({ recordId: +id, cb }) }
            onCreateClick={ (cb) => openPartnerModal({ cb }) }
            language={ getLanguage(i18n) }
            promise={ partners.query as any }
        />
    );
};
