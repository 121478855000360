import React from 'react';
import { Tag } from '@alphakits/ui';
import { selectCertificateById } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

import { TagProps } from './types';

export const Certificate: React.FC<TagProps> = ({
    value, row, removeParam, rowField,
}) => {
    const certificate = useAppSelector(
        selectCertificateById(+(Array.isArray(value) ? value[0] : value)),
    );

    return (
        <Tag
            text={ (rowField ? `${rowField}: ` : '') + certificate.title }
            onClick={ () => {} }
            onCloseClick={ () => removeParam(row) }
        />
    );
};
