import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckmarkCircleFillM, ClockCircleM } from '@alphakits/icons';
import { ModalContext, StatusProps } from '@alphakits/ui';
import { CrmVerificationEnum } from '@escapenavigator/types/dist/profile/enum/crm-verifiaction.enum';
import { PartnerProgramEnum } from '@escapenavigator/types/dist/profile/enum/partner-program.enum';
import { ProfileSubscriptionTypeEnum } from '@escapenavigator/types/dist/profile/enum/profile-subscription-type.enum';
import { ProfileRO } from '@escapenavigator/types/dist/profile/profile.ro';
import { NotificationModal } from 'src/modals/notification';

type ProgramStatus = {
    title: string;
    subtitle?: string;
    buttonLabel?: string;
    buttonLoading?: boolean;
    onClick?: () => void;
    color: StatusProps['color'];
    icon: React.ReactNode;
};

export const useProgramStatus = ({
    updateProgram,
    loading,
    profile,
}: {
    updateProgram: (value: PartnerProgramEnum) => void;
    loading: boolean;
    profile: ProfileRO;
}) => {
    const { t } = useTranslation();
    const { openModal } = useContext(ModalContext);

    const openNotification = openModal(NotificationModal);

    return useMemo((): ProgramStatus => {
        if (
            profile.subscriptionType !== ProfileSubscriptionTypeEnum.NONE &&
            profile.partnerProgram !== PartnerProgramEnum.ACTIVE &&
            profile.partnerProgram !== PartnerProgramEnum.PAUSED
        ) return null;

        if (
            profile.partnerProgram === PartnerProgramEnum.ACTIVE &&
            profile.crmVerification === CrmVerificationEnum.SUCCEED
        ) {
            return {
                title: t('Активирована'),
                color: 'green',
                icon: <CheckmarkCircleFillM />,
            };
        }

        if (
            profile.partnerProgram === PartnerProgramEnum.ACTIVE &&
            profile.crmVerification !== CrmVerificationEnum.SUCCEED
        ) {
            return {
                title: t('Ожидает настройки'),
                subtitle: t('Завершите настройку CRM'),
                buttonLabel: t('Завершить настройки'),
                buttonLoading: loading,
                onClick: () => openNotification(),
                color: 'orange',
                icon: <ClockCircleM />,
            };
        }

        if (profile.partnerProgram === PartnerProgramEnum.NEED_SETUP) {
            return {
                title: t('Ожидает настройки'),
                subtitle: t('Завершите интеграцию c', { value: profile.crm }),
                buttonLabel: t('Завершить настройки'),
                buttonLoading: loading,
                onClick: () => openNotification(),
                color: 'orange',
                icon: <ClockCircleM />,
            };
        }

        if (profile.partnerProgram === PartnerProgramEnum.PAUSED) {
            return {
                title: t('Приостановлена'),
                subtitle: t('Клиенты не видят ваше расписание'),
                buttonLabel: t('Возобновить'),
                buttonLoading: loading,
                onClick: () => updateProgram(PartnerProgramEnum.ACTIVE),
                color: 'grey',
                icon: <ClockCircleM />,
            };
        }

        return null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile, loading]);
};
