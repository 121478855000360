import React from 'react';
import { TabPanel, Tabs } from 'src/modals/components/tabs';

import { CompareStatistic } from './compare';
import { GeneralStatistics } from './general';
import { RevenueStatistics } from './revenue';

export const StatisticsPage: React.FC = () => (
    <Tabs view="header" defaultActive="General">
        <TabPanel name="General">
            <GeneralStatistics />
        </TabPanel>

        <TabPanel name="Revenue">
            <RevenueStatistics />
        </TabPanel>

        <TabPanel name="Compare">
            <CompareStatistic />
        </TabPanel>
    </Tabs>
);
