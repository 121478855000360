import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { showError, Skeleton } from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { ProfileRO } from '@escapenavigator/types/dist/profile/profile.ro';
import { FreeCrmCard } from 'src/pages/settings/partners-programm/components/free-crm-card';
import { ProCrmCard } from 'src/pages/settings/partners-programm/components/pro-crm-card';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { useOnboarding } from 'src/providers/ongoarding/context';
import { selectAllQuestrooms } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

type Props = {
    cb: (data: ProfileRO) => void;
};

export const SubscriptionCards: React.FC<Props> = ({ cb }) => {
    const { profiles } = useApi();
    const { profile, setProfile } = useCurrentUser();
    const navigate = useNavigate();

    const { refetchProgress } = useOnboarding();
    const allQuestrooms = useAppSelector(selectAllQuestrooms({ deleted: false, closed: false }));

    const { getPricesData, getPricesFetch, getPricesLoading } = useApiMethod({
        api: profiles.getPrices,
        errorCallback: ({ message }) => showError(message),
    });

    useEffect(() => {
        getPricesFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const price = useMemo(() => {
        if (!getPricesData) return 0;

        const currentPrice = getPricesData.pro?.tiers
            .map((p) => ({ ...p, to: p.to || 10000 }))
            .sort((a, b) => (a.to > b.to ? 1 : -1))
            .find((p) => p.to >= allQuestrooms.length);

        return allQuestrooms.length * currentPrice.price;
    }, [getPricesData, allQuestrooms.length]);

    const { updateSubscriptionFetch, updateSubscriptionLoading } = useApiMethod({
        api: profiles.updateSubscription,
        errorCallback: () => showError("Can't update subscription at the moment"),
        successCallback: ({ data }) => {
            setProfile(data.profile);
            refetchProgress(true);
            navigate('/');
            cb(data.profile);
        },
    });

    return (
        <React.Fragment>
            <Skeleton visible={ getPricesLoading }>
                <FreeCrmCard
                    onClick={ () =>
                        updateSubscriptionFetch({
                            priceId: getPricesData?.free?.id,
                            currency:
                                getPricesData?.free?.currency?.toUpperCase() as ProfileCurrencyEnum,
                        }) }
                    loading={ updateSubscriptionLoading }
                    subscriptionType={ profile.subscriptionType }
                    partnerScreen={ false }
                    currency={ getPricesData?.free?.currency }
                />
            </Skeleton>

            <Skeleton visible={ getPricesLoading }>
                <ProCrmCard
                    price={ price }
                    onClick={ () =>
                        updateSubscriptionFetch({
                            priceId: getPricesData?.pro?.id,
                            currency:
                                getPricesData?.pro?.currency?.toUpperCase() as ProfileCurrencyEnum,
                        }) }
                    loading={ updateSubscriptionLoading }
                    subscriptionType={ profile.subscriptionType }
                    partnerScreen={ false }
                    currency={ getPricesData?.pro.currency }
                />
            </Skeleton>
        </React.Fragment>
    );
};
