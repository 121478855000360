import React from 'react';
import {
    Amount, CopyButton, formatDate, Image, Status,
} from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { CertificateSaleRO } from '@escapenavigator/types/dist/certificate-sale/certificate-sale.ro';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { TFunction } from 'i18next';
import { sourceColors, sourceIcons, sourceImages } from 'src/pages/orders/columns';
import { getCertificateData } from 'src/utils/get-certificate-data';

type Props = {
    t: TFunction;
    currency: ProfileCurrencyEnum;
    i18n: any;
};

export const CertificatesColumns = ({
    t,
    currency,
    i18n,
}: Props): TableColumns<CertificateSaleRO> => [
    {
        header: t('tables.dateOfSale'),
        accessor: 'createdAt',
        sort: true,
        row: {
            title: ({ row }) =>
                formatDate(row.createdAt, { format: 'dd MMMM, HH:mm', lang: i18n.language }),
            icon: ({ row }) => (
                <Image
                    view="ellipse"
                    src={ sourceImages[row.source] }
                    bgColor={ sourceColors[row.source] }
                    color="primary"
                    icon={ sourceIcons[row.source] }
                />
            ),
        },
    },

    {
        header: t('tables.certificate'),
        accessor: 'certificate',
        row: {
            title: ({ row }) => <CopyButton text={ row.code } />,
            subtitle: ({ row }) => row.certificate?.title || '-',
        },
    },

    {
        header: t('tables.client'),
        accessor: 'certificate',
        row: {
            title: ({ row }) => `${row.client?.name} ${row.client?.surname}`,
            subtitle: ({ row }) => row.client?.phone,
        },
    },

    {
        header: t('tables.delivery'),
        accessor: 'deliveryType',
        row: {
            title: ({ row }) =>
                (row.deliveryType ? t(`options.delivery.${row.deliveryType}`) : '-'),
        },
    },
    {
        header: t('Номинал'),
        accessor: 'total',
        row: {
            title: ({ row }) => (
                <Amount color="primary" type="decimal" currency={ currency } value={ row.nominal } />
            ),
            subtitle: ({ row }) =>
                (
                    <React.Fragment>
                        { t('tables.price') }{ ' ' }
                        <Amount
                            color="secondary"
                            view="caps"
                            type="decimal"
                            currency={ currency }
                            value={ row.total }
                        />
                    </React.Fragment>
                ) as any as string,
        },
    },

    {
        header: t('Годен до'),
        accessor: 'expireDate',
        sort: true,
        row: {
            title: ({ row }) =>
                formatDate(row.expireDate, { format: 'dd MMMM yyyy', lang: i18n.language }),
        },
    },
    {
        header: '',
        align: 'right',
        accessor: 'sended',
        row: {
            title: ({ row }) => {
                const data = getCertificateData(row, t);

                return (
                    <Status color={ data.color } view="contrast">
                        { data.title }
                    </Status>
                );
            },
        },
    },
];
