import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { useApi } from 'src/providers/api/context';

type Props = {
    orderId: number;
};

export const OrderMetadata: React.FC<Props> = ({ orderId }) => {
    const { orders } = useApi();
    const { t } = useTranslation();
    const { getMetadataFetch, getMetadataData } = useApiMethod({
        api: orders.getMetadata,
    });

    useEffect(() => {
        getMetadataFetch({ orderId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!getMetadataData) return null;

    return (
        <Collapse
            collapsedLabel={ t('importOriginalData') }
            expandedLabel={ t('Скрыть') }
            dataTestId="minHoursForBookingCollapse"
        >
            <pre>
                <code>{ getMetadataData.metadata }</code>
            </pre>
        </Collapse>
    );
};
