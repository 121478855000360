import React from 'react';

import styles from './index.module.css';

export type FormFooterProps = {
    children?: React.ReactNode;
};

export const FormFooter: React.FC<FormFooterProps> = ({ children }) => (
    <div className={ styles.component }>{ children }</div>
);
