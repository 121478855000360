import React from 'react';
import { AmountInput, FlexColumns } from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CreateOrderPaymentDto } from '@escapenavigator/types/dist/order-transaction/create-order-payment.dto';
import { OrderRO } from '@escapenavigator/types/dist/order/order.ro';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { CashboxesSelect } from 'src/components/selects/cashbox-select';
import { useApi } from 'src/providers/api/context';

type Props = {
    close: () => void;
    setOrder: (order: OrderRO) => void;
    orderId: OrderRO['id'];
    locationId: number;
    t: TFunction;
    currency: ProfileCurrencyEnum;
    amount?: number;
};

export const Refund: React.FC<Props> = ({
    t,
    close,
    orderId,
    setOrder,
    currency,
    amount,
    locationId,
}) => {
    const { orderTransactionsApi } = useApi();

    const { createOrderRefundFetch, createOrderRefundLoading, createOrderRefundError } =
        useApiMethod({
            api: orderTransactionsApi.createOrderRefund,
            successCallback: ({ data }) => {
                setOrder(data);
                close();
            },
        });

    return (
        <RestForm
            t={ t }
            title={ t('Оформление возврата') }
            initialValues={ serializeRecord(CreateOrderPaymentDto, { orderId, amount }) }
            validate={ validateByDto(t) }
            loading={ false }
            softError={ createOrderRefundError?.message }
            updating={ createOrderRefundLoading }
            removing={ false }
            close={ close }
            save={ (data) => createOrderRefundFetch({ data }) }
        >
            { ({
                values, errors, touched, setFieldValue,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    <CashboxesSelect
                        selected={ values.cashboxId }
                        error={ touched.cashboxId && errors.cashboxId }
                        onChange={ ({ selected }) => setFieldValue('cashboxId', selected?.key) }
                        type="all"
                        locationId={ locationId }
                        label={ t('component.selectCashbox') }
                    />
                    <AmountInput
                        value={ +values.amount }
                        type="decimal"
                        error={ touched.amount && errors.amount }
                        onChange={ (e, { value }) => setFieldValue('amount', value) }
                        label={ t('component.amount') }
                        required={ true }
                        suffix={ currency }
                        block={ true }
                    />
                </FlexColumns>
            ) }
        </RestForm>
    );
};
