import React from 'react';
import { QuestionMarkS } from '@alphakits/icons';
import { Tooltip } from '@alphakits/ui';

type Props = {
    text: string;
};

export const HintTooltip: React.FC<Props> = ({ text }) => (
    <Tooltip content={ <div style={ { width: 300 } }>{ text }</div> }>
        <QuestionMarkS color="var(--color-graphic-secondary)" />
    </Tooltip>
);
