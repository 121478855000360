import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Checkbox,
    CheckboxGroup,
    convertToOptions,
    FlexColumns,
    Padding,
    Typography,
} from '@alphakits/ui/dist';
import { useCurrentUser } from 'src/providers/current-user/context';
import { selectAllLocations } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

type Data = {
    allLocations: boolean;
    locationIds: number[];
};

type Props = {
    error?: boolean;
    title?: string;
    disabled?: boolean;
    onChange: (data: Data) => void;
} & Data;

export const LocationsPicker: React.FC<Props> = ({
    allLocations,
    locationIds,
    disabled,
    onChange,
    error,
    title,
}) => {
    const { current } = useCurrentUser();
    const { t } = useTranslation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const locations = useAppSelector(selectAllLocations) || [];

    const selectedIds = useMemo(() => {
        if (allLocations) return locations.map((l) => l.id);

        return locationIds;
    }, [allLocations, locations, locationIds]);

    return (
        <FlexColumns columns={ 1 } gr={ 8 }>
            <Checkbox
                label={ (
                    <Typography.Text view="title" weight="bold" color="primary">
                        { title || t('Все локации') }
                    </Typography.Text>
                ) }
                disabled={ disabled || (current.mode !== 'admin' && !current.user.allLocations) }
                checked={ allLocations }
                onChange={ (e, { checked }) =>
                    onChange({
                        allLocations: checked,
                        locationIds: [],
                    }) }
            />

            { (!allLocations || (current.mode !== 'admin' && !current.user.allLocations)) && (
                <Padding padding="0 0 0 16px">
                    <CheckboxGroup
                        disabled={ disabled }
                        gr={ 8 }
                        selected={ selectedIds }
                        data={ convertToOptions(locations) }
                        onChange={ (v) =>
                            onChange({
                                allLocations: false,
                                locationIds: v,
                            }) }
                    />
                </Padding>
            ) }

            { error && (
                <Typography.Text view="title" color="negative">
                    { t('validationErrors:locationsLength') }
                </Typography.Text>
            ) }
        </FlexColumns>
    );
};
