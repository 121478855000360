import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button, CheckboxGroup, FlexColumns, showError, showSuccess,
} from '@alphakits/ui/dist';
import { BaseForm } from '@alphakits/ui/dist/form/templates/base-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { UpdateCurrentDto } from '@escapenavigator/types/dist/profile/update-current.dto';
import { WidgetBookingFiledEnum } from '@escapenavigator/types/dist/widget/enum/widget-booking-filed.enum';
import { serializeRecord, validateByDto } from '@escapenavigator/utils';
import { Section } from 'src/components/layout/section';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';

export const Fields: React.FC = () => {
    const { t } = useTranslation();
    const { profile, setProfile } = useCurrentUser();
    const { profiles } = useApi();

    const { updateCurrentFetch, updateCurrentLoading } = useApiMethod({
        api: profiles.updateCurrent,
        successCallback: ({ data }) => {
            showSuccess('Saved');
            setProfile(data);
        },
        errorCallback: ({ message }) => showError(message),
    });

    return (
        <Section title={ t('Обязательные поля') }>
            <BaseForm
                initialValues={ serializeRecord(UpdateCurrentDto, profile) }
                validate={ validateByDto(t) }
                enableReinitialize={ true }
                save={ (values) => updateCurrentFetch({ data: values }) }
                t={ t }
            >
                { ({ values, setFieldValue, submitForm }) => (
                    <React.Fragment>
                        <FlexColumns columns={ 2 }>
                            <CheckboxGroup
                                title={ t('Отметьте поля') }
                                dataTestId="bookingFields"
                                data={ Object.values(WidgetBookingFiledEnum).map((key) => ({
                                    key,
                                    content: t(key.toLowerCase()),
                                })) }
                                selected={ values.bookingFields }
                                onChange={ (values) => setFieldValue('bookingFields', values) }
                            />
                            <CheckboxGroup
                                title={ t('Обязательные поля по звонку') }
                                dataTestId="adminBookingFields"
                                data={ Object.values(WidgetBookingFiledEnum).map((key) => ({
                                    key,
                                    content: t(key.toLowerCase()),
                                })) }
                                selected={ values.adminBookingFields }
                                onChange={ (values) => setFieldValue('adminBookingFields', values) }
                            />
                        </FlexColumns>

                        <div>
                            <Button
                                view="outlined"
                                size="s"
                                loading={ updateCurrentLoading }
                                onClick={ () => submitForm() }
                            >
                                { t('Сохранить обязательные поля') }
                            </Button>
                        </div>
                    </React.Fragment>
                ) }
            </BaseForm>
        </Section>
    );
};
