import React from 'react';
import { FlexColumns, Input, useCrudFormRequests } from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { UpdateCurrentUserDto } from '@escapenavigator/types/dist/user/current/update-current-user.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { selectUserById, upsertUser } from 'src/store';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';

type Props = {
    close: () => void;
    t: TFunction;
};

export const CurrentUserModal: React.FC<Props> = ({ close, t }) => {
    const { users } = useApi();

    const dispatch = useAppDispatch();

    const {
        current: {
            user: { id },
        },
    } = useCurrentUser();
    const user = useAppSelector(selectUserById(id));

    const {
        updating,
        error: softError,
        save,
    } = useCrudFormRequests({
        saveRequest: users.currentUpdate,
        saveCallback: (data) => dispatch(upsertUser(data)),
        close,
    });

    return (
        <React.Fragment>
            <RestForm
                title={ t('modalsHeader.myData') }
                initialValues={ serializeRecord(UpdateCurrentUserDto, user) }
                validate={ validateByDto(t) }
                save={ save }
                close={ close }
                updating={ updating }
                t={ t }
                softError={ softError }
            >
                { ({
                    values, handleChange, errors, touched,
                }) => (
                    <React.Fragment>
                        <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                            <Input
                                dataTestId="name"
                                label={ t('name') }
                                value={ values.name }
                                onChange={ handleChange('name') }
                                error={ touched.name && errors.name }
                                block={ true }
                            />
                            <Input
                                dataTestId="surname"
                                label={ t('surname') }
                                value={ values.surname }
                                onChange={ handleChange('surname') }
                                error={ touched.surname && errors.surname }
                                block={ true }
                            />
                            <Input
                                dataTestId="email"
                                label={ t('email') }
                                value={ values.email }
                                onChange={ handleChange('email') }
                                error={ touched.email && errors.email }
                                block={ true }
                            />
                            <Input
                                dataTestId="phone"
                                label={ t('phone') }
                                value={ values.phone }
                                onChange={ handleChange('phone') }
                                error={ touched.phone && errors.phone }
                                block={ true }
                            />
                            { /* <Select
                                dataTestId="language"
                                label={ t('component.systemLanguage') }
                                options={ languageOptions(Object.values(Languages)) }
                                selected={ values.language }
                                error={ touched.language && errors.language as string }
                                onChange={ ({ selected }) =>
                                    setFieldValue('language', selected?.key) }
                                block={ true }
                                required={ true }
                                optionsListWidth="field"
                            /> */ }
                        </FlexColumns>
                    </React.Fragment>
                ) }
            </RestForm>
        </React.Fragment>
    );
};
