import React, { useContext } from 'react';
import { PersonIosM } from '@alphakits/icons';
import {
    Button, Flex, Image, ModalContext, Typography,
} from '@alphakits/ui/dist';
import format from 'date-fns/format';
import { UpdateUserSessionModal } from 'src/modals/user-session/update';
import { useCurrentUser } from 'src/providers/current-user/context';
import { SerializedSession } from 'src/store';

import styles from './index.module.css';

type Props = {
    session: SerializedSession;
    cb?: () => void;
};

export const RenderUserCell = ({ session, cb }: Props) => {
    const { openModal } = useContext(ModalContext);

    const openSessionModal = openModal(UpdateUserSessionModal);

    const {
        current: { role, user },
    } = useCurrentUser();

    const ownSession = session.user?.id === user.id;
    const accessedDate = format(new Date(), 'yyyy-MM-dd') <= session.original.date;

    const canEditOwnSession = (role.totalAccess || role.canEditOwnWorkedHours) && ownSession && accessedDate;
    const caneditOtherSessions = role.totalAccess || role.canEditWorkedHours;

    const canEditFileds = caneditOtherSessions || canEditOwnSession;

    return (
        <Button
            disabled={ !canEditFileds }
            className={ styles.container }
            onClick={ () =>
                openSessionModal({
                    session: session.original,
                    cb,
                }) }
            view="ghost"
        >
            <Flex gap="sm" direction="column" align="center" justify="start">
                <Image
                    icon={ session.user?.photo ? undefined : <PersonIosM /> }
                    src={ session.user?.photo || '' }
                    view="ellipse"
                    size="s"
                />

                <Typography.Text view="caps" className={ styles.time }>
                    { session.start } { session.end }
                </Typography.Text>
            </Flex>
        </Button>
    );
};
