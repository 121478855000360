import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext, Table } from '@alphakits/ui/dist';
import { Section } from 'src/components/layout/section';
import { TariffModal } from 'src/modals/tariff';
import { selectAllTariffs } from 'src/store';
import { useAppSelector } from 'src/store/hooks';
import { getLanguage } from 'src/utils/get-language';

import { TariffColumns } from './tariff-columns';

export const Tariffs: React.FC = () => {
    const { i18n, t } = useTranslation();

    const { openModal } = useContext(ModalContext);
    const openTariffModal = openModal(TariffModal);

    const tariffs = useAppSelector(selectAllTariffs) || [];

    const tariffColumns = TariffColumns();

    return (
        <Section
            title={ t('menu./tariffs') }
            subtitle={ !tariffs.length && t('Добавьте тарифы') }
            onClick={ () => openTariffModal() }
            id="tariffSettings"
            initial={ !tariffs.length }
        >
            { !!tariffs.length && (
                <Table.TableComponent
                    columns={ tariffColumns }
                    hideSearch={ true }
                    height="default"
                    onRowClick={ ({ id }) => openTariffModal({ recordId: +id }) }
                    records={ tariffs }
                    language={ getLanguage(i18n) }
                />
            ) }
        </Section>
    );
};
