import React from 'react';
import { useTranslation } from 'react-i18next';
import { ListM } from '@alphakits/icons';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { TariffRO } from '@escapenavigator/types/dist/tariff/tariff.ro';
import { PriceRange } from 'src/components/price-range';

export const TariffColumns = (): TableColumns<TariffRO> => {
    const { t } = useTranslation();

    return [
        {
            header: t('tables.title'),
            accessor: 'title',
            row: {
                icon: () => <ListM />,
            },
        },
        {
            header: t('Кол-во игроков'),
            accessor: 'id',
            row: {
                title: ({ row }) => {
                    const players = Object.keys(row.price);

                    return `${players[0]} - ${players[players.length - 1]} ${t('ppl')}`;
                },
            },
        },
        {
            header: t('tables.price'),
            accessor: 'price',
            row: {
                title: ({ row }) => <PriceRange price={ row.price } />,
            },
        },
    ];
};
