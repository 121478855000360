import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Amount, Button } from '@alphakits/ui';
import { ProfileSubscriptionTypeEnum } from '@escapenavigator/types/dist/profile/enum/profile-subscription-type.enum';
import { MarketingCell, MarketingCellProps } from 'src/components/marketing-cell';

import { ConnectedCell } from './connected-status';

type Props = {
    onClick: () => void;
    loading: boolean;
    partnerScreen: boolean;
    currency: string;
    price: number;
    subscriptionType: ProfileSubscriptionTypeEnum;
};
export const ProCrmCard: React.FC<Props> = ({
    onClick,
    loading,
    partnerScreen,
    subscriptionType,
    currency,
    price,
}) => {
    const { t } = useTranslation();

    const prices: MarketingCellProps['prices'] = [
        {
            amount: (
                <Amount
                    weight="bold"
                    value={ +price || 0 }
                    type="decimal"
                    currency={ currency?.toUpperCase() }
                />
            ),
            description: t('мес включая налоги', { value: '' }),
        },
    ];

    if (partnerScreen) prices.unshift({ amount: '8%', description: t('от стоимости игры', { value: '' }) });

    const label = useMemo(() => {
        if (partnerScreen) return t('Подключиться к программе');

        return subscriptionType === ProfileSubscriptionTypeEnum.FREE ? 'Upgrade' : 'Get Pro';
    }, [subscriptionType, partnerScreen, t]);

    return (
        <MarketingCell
            brand={ true }
            title="Navigator CRM Pro"
            description={ t('Расширенное решение для серьезных') }
            prices={ prices }
            features={ [
                {
                    title: t('benefits:certificatesAndPromocodes.title'),
                },
                {
                    title: t('benefits:crossSales.title'),
                },
                {
                    title: t('benefits:analytics.title'),
                },
                {
                    title: t('benefits:workingShifts.title'),
                },
                {
                    title: t('benefits:upsales.title'),
                },
            ] }
            loading={ loading }
        >
            { subscriptionType === ProfileSubscriptionTypeEnum.PRO ? (
                <ConnectedCell />
            ) : (
                <Button onClick={ onClick } loading={ loading } view="primary" size="s" block={ true }>
                    { label }
                </Button>
            ) }
        </MarketingCell>
    );
};
