import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusS } from '@alphakits/icons';
import {
    Button,
    Cell,
    Flex,
    FlexColumns,
    Image,
    ListAllert,
    ModalContext,
} from '@alphakits/ui/dist';
import { BaseForm } from '@alphakits/ui/dist/form/templates/base-form';
import { QuestroomRO } from '@escapenavigator/types/dist/questroom/questroom.ro';
import { QuestroomModal } from 'src/modals/questroom';
import { selectAllLocations, selectAllQuestrooms } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

import { VerifyCompanyContainer } from '../components/container';
import { QuestroomCell } from '../components/questroom-cell';

type Props = {
    error: string;
    shaking: boolean;
    scrollToError: () => void;
    cb: () => void;
    back: () => void;
};

export const VerifyQuestrooms: React.FC<Props> = ({
    scrollToError, error, shaking, cb, back,
}) => {
    const { t } = useTranslation();
    const { openModal } = useContext(ModalContext);

    const allLocations = useAppSelector(selectAllLocations);
    const allQuestrooms = useAppSelector(selectAllQuestrooms({}));

    const questroomsWithErrors = allQuestrooms.map((q) => ({
        ...q,
        location: allLocations.find((l) => l.id === q.locationId),
        hasError:
            !q.closed &&
            (!q.questroomTags.length ||
                !q.playersMin ||
                !q.playersMax ||
                !q.fear ||
                !q.difficult ||
                !q.minAge),
    }));

    const openQuestroomModal = (questroom?: QuestroomRO) =>
        openModal(QuestroomModal)({ questroom });

    return (
        <BaseForm
            save={ async () => {
                if (questroomsWithErrors.filter((q) => q.hasError).length) return scrollToError();

                return cb();
            } }
            enableReinitialize={ true }
            initialValues={ {} }
            t={ t }
        >
            { ({ submitForm }) => (
                <VerifyCompanyContainer>
                    <FlexColumns columns={ 1 } gr={ 16 }>
                        { questroomsWithErrors.map((questroom) => (
                            <QuestroomCell
                                questroom={ questroom }
                                openQuestroomModal={ openQuestroomModal }
                                shaking={ shaking }
                            />
                        )) }

                        <div>
                            <Button view="ghost" onClick={ () => openQuestroomModal() }>
                                <Cell.Base
                                    title={ t('addQuestroom') }
                                    addon={ <Image icon={ <PlusS /> } /> }
                                />
                            </Button>
                        </div>

                        { error && <ListAllert view="negative" text={ error } /> }

                        <Flex gap="md" wrap={ true }>
                            <Button view="outlined" block={ true } onClick={ back }>
                                { t('Назад') }
                            </Button>

                            <Button block={ true } view="primary" onClick={ submitForm }>
                                { t('verifyCompany.steps.finish') }
                            </Button>
                        </Flex>
                    </FlexColumns>
                </VerifyCompanyContainer>
            ) }
        </BaseForm>
    );
};
