import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Amount, Button } from '@alphakits/ui';
import { ProfileSubscriptionTypeEnum } from '@escapenavigator/types/dist/profile/enum/profile-subscription-type.enum';
import { MarketingCell, MarketingCellProps } from 'src/components/marketing-cell';
import { ConnectedCell } from 'src/pages/settings/partners-programm/components/connected-status';

type Props = {
    onClick: () => void;
    loading: boolean;
    subscriptionType: ProfileSubscriptionTypeEnum;
    currency: string;
    partnerScreen: boolean;
};
export const FreeCrmCard: React.FC<Props> = ({
    onClick,
    loading,
    partnerScreen,
    subscriptionType,
    currency,
}) => {
    const { t } = useTranslation();
    const prices: MarketingCellProps['prices'] = [
        {
            amount: <Amount weight="bold" value={ 0 } currency={ currency?.toUpperCase() } />,
            description: t('бесплатно без условий'),
        },
    ];

    if (partnerScreen) prices.unshift({ amount: '8%', description: t('от стоимости игры', { value: '' }) });

    const label = useMemo(() => {
        if (partnerScreen) return t('Подключиться к программе');

        return subscriptionType === ProfileSubscriptionTypeEnum.PRO ? 'Downgrade' : 'Get Basic';
    }, [subscriptionType, partnerScreen, t]);

    return (
        <MarketingCell
            title="Navigator Free CRM"
            description={ t('Все что нужно для эффективной работы') }
            prices={ prices }
            loading={ loading }
            features={ [
                {
                    title: t('benefits:flexibleSchedule.title'),
                },
                {
                    title: t('benefits:bookingWidgets.title'),
                },
                {
                    title: t('benefits:оnlinePayments.title'),
                },
                {
                    title: t('benefits:statistics.title'),
                },
                {
                    title: t('benefits:export.title'),
                },
            ] }
        >
            { subscriptionType === ProfileSubscriptionTypeEnum.FREE ? (
                <ConnectedCell />
            ) : (
                <Button onClick={ onClick } loading={ loading } view="outlined" size="s" block={ true }>
                    { label }
                </Button>
            ) }
        </MarketingCell>
    );
};
