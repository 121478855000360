import React from 'react';
import { BuildingsM, CashM } from '@alphakits/icons';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { CashboxRO } from '@escapenavigator/types/dist/cashbox/cashbox.ro';

const icons = {
    cash: <CashM />,
    bank: <BuildingsM />,
};

export const CashboxColumns = (t, locations): TableColumns<CashboxRO> => [
    {
        header: t('tables.title'),
        accessor: 'title',
        row: {
            icon: ({ row }) => icons[row.type],
            subtitle: ({ row }) => t(`options.cashbox.${row.type}`),
        },
    },
    {
        header: t('Локации'),
        accessor: 'allLocations',
        row: {
            title: ({ row }) =>
                (row.allLocations
                    ? 'All locations'
                    : locations
                        .filter((l) => row.locationIds?.includes(l.id))
                        .map((l) => l.title)
                        .join(', ')),
        },
    },
];
