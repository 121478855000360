/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { PersonsTwoM } from '@alphakits/icons';
import { Image, Table } from '@alphakits/ui/dist';

type Icon = {
    icon?: React.ReactNode;
    image?: never;
};

type Img = {
    icon?: never;
    image: string;
};

type Props = {
    title: string;
    subtitle: string;
    onClick?: () => void;
} & (Icon | Img);

export const PersonTableItem: React.FC<Props> = ({
    title,
    subtitle,
    icon = PersonsTwoM,
    image,
    onClick,
}) => (
    <tr onClick={ onClick } style={ { cursor: 'pointer' } }>
        <Table.Cell
            title={ title }
            subtitle={ subtitle }
            addon={ image ? <Image src={ image } /> : <Image icon={ icon } /> }
        />
    </tr>
);
