/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Status, Table } from '@alphakits/ui/dist';
import { OrderRO } from '@escapenavigator/types/dist/order/order.ro';
import { convertUTCDateToZonedDate } from '@escapenavigator/utils/dist';
import { selectLocationById, selectQuestroomById } from 'src/store';
import { useAppSelector } from 'src/store/hooks';
import { getOrderData } from 'src/utils/get-order-data';

type Props = {
    order: OrderRO;
    onClick: () => void;
};

export const OrderTableItem: React.FC<Props> = ({ order, onClick }) => {
    const { t } = useTranslation();
    const data = getOrderData(order, t);
    const questroom = useAppSelector(selectQuestroomById(order.questroomId));
    const location = useAppSelector(selectLocationById(questroom.locationId));

    return (
        <tr onClick={ onClick } style={ { cursor: 'pointer' } }>
            <Table.Cell
                title={ questroom.title }
                subtitle={ convertUTCDateToZonedDate({
                    date: order.utcDate,
                    timeZone: location.timeZone,
                    showTZ: true,
                }) }
                addon={ <Image size="s" src={ questroom.photo } /> }
            />
            <td>
                <Status view="contrast" color={ data.status.color }>
                    { data.status.title }
                </Status>
            </td>
        </tr>
    );
};
