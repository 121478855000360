import { initClientApi } from '@escapenavigator/services/dist/api/init-client-api';
import { CashboxTypeEnum } from '@escapenavigator/types';
import { CashboxRO } from '@escapenavigator/types/dist/cashbox/cashbox.ro';
import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';

import { RootState } from '../store';

const cashboxesAdapter = createEntityAdapter<CashboxRO>();
const { cashboxes } = initClientApi(process.env.REACT_APP_API_DOMAIN);

export const fetchCashboxes = createAsyncThunk<CashboxRO[]>(
    'cashboxes/fetchCashboxes',
    async () => {
        const { data } = await cashboxes.query(undefined);

        return data;
    },
);

const cashboxesSlice = createSlice({
    name: 'cashboxes',
    initialState: cashboxesAdapter.getInitialState(),
    reducers: {
        addCashbox(state, action: PayloadAction<CashboxRO>) {
            cashboxesAdapter.upsertOne(state, action.payload);
        },
        removeCashbox(state, action: PayloadAction<CashboxRO['id']>) {
            cashboxesAdapter.removeOne(state, action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCashboxes.fulfilled, (state, action: PayloadAction<CashboxRO[]>) => {
            cashboxesAdapter.addMany(state, action.payload);
        });
    },
});

const { selectAll, selectById } = cashboxesAdapter.getSelectors<RootState>(
    (state) => state.cashboxes,
);

export const selectAllCashboxes = selectAll;

export const { addCashbox, removeCashbox } = cashboxesSlice.actions;

export const selectCashboxByLocation = (id: number, type: CashboxTypeEnum) =>
    createSelector(
        (state: RootState) => state,
        (state) => {
            const list = selectAll(state);

            return list.find((c) => c.locationIds?.includes(id) && c.type === type);
        },
    );

export const selectCashboxById = (id: number) =>
    createSelector(
        (state: RootState) => state,
        (state) => selectById(state, id) || ({ title: 'Unknown cashbox', id } as CashboxRO),
    );

export const selectCashboxByIdOrIds = (ids: string[]) =>
    createSelector(
        (state: RootState) => state,
        (state) =>
            Object.values(state.cashboxes.entities).filter((cashbox) =>
                ids.includes(`${cashbox.id}`)),
    );

export default cashboxesSlice.reducer;
