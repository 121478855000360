import React, { useState } from 'react';
import {
    Button, Flex, FlexColumns, Sidepanel, Tag, Typography,
} from '@alphakits/ui/dist';
import { TagsEnum } from '@escapenavigator/types/dist/shared/enum/tags.enum';
import { TFunction } from 'i18next';

type Props = {
    setTags: (tags: TagsEnum[]) => void;
    tags: TagsEnum[];
    close: () => void;
    t: TFunction;
};

export const QuestroomTagsModal: React.FC<Props> = ({
    close, t, setTags, tags,
}) => {
    const [selected, setSelected] = useState(tags);

    const tagsByCategory = {
        escape_room_thema: [
            'crime', // Детективы
            'ancientworlds', // Древние миры
            'historical', // Исторические квесты
            'jailbreak', // Квесты «Побег из тюрьмы»
            'apocalypse', // Квесты на тему апокалипсиса
            'wizards', // Квесты о волшебниках
            'mistery', // Мистические квесты
            'futuristic', // Фантастические квесты
            'adventure', // Квесты-приключения
            'raid', // Квесты с ограблением
            'films', // Квесты по фильмам
            'zombie', // Квесты про зомби
            'maniac', // Квесты про маньяка
            'museum', // Музейные квесты
            'rolebased', // Ролевые квесты
            'suspense', // Саспенс
            'closedyeyes', // Морфеус
            'quiz', // Викторины
            'horror', // Ужасы
            'fantasy', // Фэнтезийные квесты
            'antistress', // Антистресс
            'erotic', // Эротические квесты
            'immersive', // Иммерсивные квесты
        ],
        escape_room_availability: [
            'outdor', // Выездные квесты
            'invalid', // Подходят огрианичено мобильным
            'adults', // Квесты для взрослых
            'love', // Квесты для влюбленных
            'pair', // Квесты для двоих
            'corporate', // Квесты для корпоратива
            'kids0', // Квесты для детей 0+
            'kids6', // Квесты для детей 6+
            'kids10', // Квесты для детей 10+
            'kids14', // Квесты 14+ лет
            'adult18', // Квесты 18+ лет
        ],
        escape_room_options: [
            'performance_no_contact', // Бесконтактные перфоманы
            'performance_contact', // Контактные перфоманы
            'duelmode', // Квесты дуэли
            'present_inside', // Найди подарок в квесте
            'marry_me', // Предложение руки и сердца
            'allday', // Круглосуточные квесты
            'chip', // Дешевые квесты
            'vr', // Квесты с виртуальной реальностью
        ],
    };

    const handleHange = (tag: TagsEnum) => {
        if (selected.includes(tag)) return setSelected(selected.filter((t) => t !== tag));
        if (selected.length >= 5) return alert('Maximum 5 tags');

        return setSelected([...selected, tag]);
    };

    return (
        <Sidepanel
            bottomAddons={ (
                <Button
                    view="primary"
                    size="s"
                    block={ true }
                    onClick={ () => {
                        setTags(selected);
                        close();
                    } }
                >
                    { t('save') }
                </Button>
            ) }
            title={ t('questroomTags') }
        >
            <FlexColumns columns={ 1 } gr={ 32 }>
                { Object.entries(tagsByCategory).map(([category, values]) => (
                    <FlexColumns key={ category } columns={ 1 } gr={ 20 }>
                        <Typography.Title tag="div" view="xxsmall" weight="bold" color="primary">
                            { t(`tags:${category}`) }
                        </Typography.Title>

                        <Flex justify="start" gap="sm" wrap={ true }>
                            { values.map((v) => (
                                <Tag
                                    onClick={ () => handleHange(v as TagsEnum) }
                                    view={
                                        selected.includes(v as TagsEnum)
                                            ? 'primary-inverted'
                                            : 'primary'
                                    }
                                    key={ v }
                                    text={ t(`tags:${v}`) }
                                />
                            )) }
                        </Flex>
                    </FlexColumns>
                )) }
            </FlexColumns>
        </Sidepanel>
    );
};
