/* eslint-disable no-template-curly-in-string */
/* eslint-disable react/no-unescaped-entities */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
    CheckmarkCircleFillM,
    ChevronForwardM,
    ClockCircleM,
    GiftM,
    TicketM,
} from '@alphakits/icons';
import {
    Button,
    Cell,
    FlexColumns,
    Image,
    ModalContext,
    TFunction,
    Typography,
} from '@alphakits/ui/dist';
import { WidgetTypeEnum } from '@escapenavigator/types/dist/widget/enum/widget-type.enum';
import { WidgetRO } from '@escapenavigator/types/dist/widget/widget.ro';
import { WidgetInfoModal } from 'src/modals/widget-info';

type Props = {
    widgets: WidgetRO[];
    type: WidgetTypeEnum;
    refetch: () => void;
};

const icons: Record<WidgetTypeEnum, React.ReactNode | null> = {
    [WidgetTypeEnum.ALL_CERTIFICATES]: <GiftM />,
    [WidgetTypeEnum.ALL_QUESTROOM]: <TicketM />,
    [WidgetTypeEnum.WIDGET_BUTTON]: <TicketM />,
    [WidgetTypeEnum.ONE_QUESTROOM]: null,
};

export const getWidgetTitle = (type: WidgetTypeEnum, t: TFunction) => {
    switch (type) {
        case WidgetTypeEnum.ALL_QUESTROOM:
            return t('widget-guide:guide.all_questrooms.title');
        case WidgetTypeEnum.ONE_QUESTROOM:
            return t('widget-guide:guide.one_questroom.title');
        case WidgetTypeEnum.WIDGET_BUTTON:
            return t('widget-guide:guide.button_widget.title');
        default:
            return t('widget-guide:guide.all_certificates.title');
    }
};

export const getWidgetHint = (type: WidgetTypeEnum, t: TFunction) => {
    switch (type) {
        case WidgetTypeEnum.ALL_QUESTROOM:
            return t('widget-guide:guide.all_questrooms.hint');
        case WidgetTypeEnum.ONE_QUESTROOM:
            return t('widget-guide:guide.one_questroom.hint');
        case WidgetTypeEnum.WIDGET_BUTTON:
            return t('widget-guide:guide.button_widget.hint');
        default:
            return t('widget-guide:guide.all_certificates.hint');
    }
};

export const getTitle = (widget: WidgetRO, t: TFunction) => {
    if (
        widget.type === WidgetTypeEnum.ALL_QUESTROOM ||
        widget.type === WidgetTypeEnum.WIDGET_BUTTON
    ) {
        return widget.allLocations
            ? t('widget-guide:guide.all_questrooms.all')
            : widget.locations.map((l) => l.title).join(', ');
    }

    if (widget.type === WidgetTypeEnum.ONE_QUESTROOM) {
        return widget.questroom?.title;
    }

    return widget.allCertificates
        ? t('widget-guide:guide.all_certificates.all')
        : widget.certificates.map((l) => l.title).join(', ');
};

export const WidgetComponent: React.FC<Props> = ({ widgets = [], type, refetch }) => {
    const { t } = useTranslation();

    const { openModal } = useContext(ModalContext);

    return (
        <FlexColumns columns={ 1 } gr={ 16 }>
            <Typography.Text view="caps" uppercase={ true } color="secondary">
                { getWidgetTitle(type, t) }
            </Typography.Text>

            { !widgets.length && (
                <Typography.Text view="caps" color="secondary">
                    { t('No data') }
                </Typography.Text>
            ) }

            <FlexColumns columns={ 1 } gr={ 12 }>
                { widgets.map((widget) => (
                    <Button
                        rightAddons={ <ChevronForwardM /> }
                        view="ghost"
                        key={ widget.uuid }
                        block={ true }
                        onClick={ () => openModal(WidgetInfoModal, 'm')({ record: widget, refetch }) }
                    >
                        <Cell.Base
                            title={ getTitle(widget, t) }
                            subtitle={ (
                                <Typography.Text
                                    view="caps"
                                    color={ widget.activity ? 'positive' : 'secondary' }
                                >
                                    { widget.activity ? t('Installed') : t('Not installed') }
                                </Typography.Text>
                            ) }
                            addon={ (
                                <Image
                                    addon={ {
                                        color: widget.activity ? 'positive' : 'secondary',
                                        icon: widget.activity ? (
                                            <CheckmarkCircleFillM />
                                        ) : (
                                            <ClockCircleM />
                                        ),
                                    } }
                                    border={ true }
                                    view="ellipse"
                                    bgColor="secondary"
                                    icon={ type !== WidgetTypeEnum.ONE_QUESTROOM && icons[type] }
                                    src={
                                        type === WidgetTypeEnum.ONE_QUESTROOM
                                            ? widget.questroom?.photo
                                            : ''
                                    }
                                />
                            ) }
                        />
                    </Button>
                )) }
            </FlexColumns>
        </FlexColumns>
    );
};
