import React from 'react';
import { ListM } from '@alphakits/icons';
import { Typography } from '@alphakits/ui/dist';

import styles from './index.module.css';

export const TableEmpty = ({ text }: { text: string }) => (
    <div className={ styles.empty_component }>
        <ListM />
        <Typography.Text view="caps" color="secondary" weight="medium">
            { text }
        </Typography.Text>
    </div>
);
