import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext, Table } from '@alphakits/ui/dist';
import { UpsellingModal } from 'src/modals/upselling';
import { useCurrentUser } from 'src/providers/current-user/context';
import { selectAllOptions } from 'src/store';
import { useAppSelector } from 'src/store/hooks';
import { getLanguage } from 'src/utils/get-language';

import { OptionColumns } from './columns';

export const UpsellingSettingsPage: React.FC = () => {
    const { openModal } = useContext(ModalContext);
    const { i18n, t } = useTranslation();
    const options = useAppSelector(selectAllOptions) || [];
    const {
        profile: { currency },
    } = useCurrentUser();

    const columns = useMemo(() => OptionColumns({ t, currency }), [t, currency]);

    const openOptionModal = openModal(UpsellingModal);

    return (
        <Table.TableComponent
            columns={ columns }
            searchPlaceholder={ t('tables.optionSettingSearch') }
            onRowClick={ ({ id }) => openOptionModal({ recordId: +id }) }
            onCreateClick={ () => openOptionModal() }
            records={ options }
            language={ getLanguage(i18n) }
        />
    );
};
