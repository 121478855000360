import React from 'react';
import {
    FlexColumns,
    ImageUploader,
    Textarea,
    ToastPlate,
    useCrudFormRequests,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { OrderRO } from '@escapenavigator/types/dist/order/order.ro';
import { ImageTypeEnum } from '@escapenavigator/types/dist/upload/enum/image-type.enum';
import { CreateUserMessageDto } from '@escapenavigator/types/dist/user-message/create-user-message.dto';
import { UserMessageResponseObject } from '@escapenavigator/types/dist/user-message/user-message.ro';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

type Props = {
    t: TFunction;
    close: () => void;
    saveCallback: (message: UserMessageResponseObject) => void;
    orderId?: OrderRO['id'];
    certificateSaleId?: UserMessageResponseObject['certificateSaleId'];
};

export const UserComment: React.FC<Props> = ({
    t,
    close,
    orderId,
    certificateSaleId,
    saveCallback,
}) => {
    const { userMessages, uploads } = useApi();

    const {
        save,
        remove,
        removing,
        updating,
        error: softError,
    } = useCrudFormRequests({
        removeRequest: userMessages.remove,
        saveRequest: userMessages.create,
        saveCallback,
        close,
    });

    const initialValues = {
        certificateSaleId,
        orderId,
    };

    return (
        <RestForm
            title={ t('Заметка') }
            initialValues={ serializeRecord(CreateUserMessageDto, initialValues) }
            validate={ validateByDto(t) }
            save={ save }
            loading={ false }
            softError={ softError }
            updating={ updating || removing }
            remove={ remove }
            close={ close }
            t={ t }
        >
            { ({
                values, handleChange, errors, touched, setFieldValue,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    <ToastPlate view="default">
                        { t('Заметки доступны только для сотрудников квеста и не видны игрокам') }
                    </ToastPlate>

                    <Textarea
                        value={ values.text }
                        label={ t('Введите текст') }
                        onChange={ handleChange('text') }
                        required={ true }
                        error={ touched.text && errors.text }
                        block={ true }
                        maxLength={ 500 }
                        counter={ true }
                    />

                    <ImageUploader
                        title={ t('Приложить фото') }
                        value={ values.photos }
                        onUpdate={ (value) => setFieldValue('photos', value) }
                        multiple={ true }
                        type={ ImageTypeEnum.ORDER }
                        uploadRequest={ uploads.uploadImage }
                    />
                </FlexColumns>
            ) }
        </RestForm>
    );
};
