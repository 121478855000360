import { SlotTemplateElement } from '@escapenavigator/types/dist/slot-template/slot-template-element';
import { ChangeTemplateSlotsFormDto } from '@escapenavigator/types/dist/slot/change-template-slots-form.dto';
import {
    addDays, differenceInDays, format, parse,
} from 'date-fns';

export type ScheduleSlot = {
    id: string;
    slot: SlotTemplateElement;
    color: string;
};

export const daysOfWeekNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const colors = ['#90B9F7', '#B790F7', '#F7AF90', '#e690f7', '#f99090', '#63b96d'];

export const getDaysOfWeek = (start, end) => {
    const startDate = parse(start, 'yyyy-MM-dd', new Date());

    const endDate = parse(end, 'yyyy-MM-dd', new Date());

    const diff = differenceInDays(endDate, startDate);
    let daysOfWeek = [0, 1, 2, 3, 4, 5, 6];

    if (diff < 6) {
        daysOfWeek = new Array(diff + 1)
            .fill('')
            .map((_, i) => +format(addDays(startDate, i), 'i') - 1);
    }

    return daysOfWeek;
};

const serializeSlots = ({ slots }: { slots: SlotTemplateElement[] }): ScheduleSlot[] => {
    const colorGroups = [];

    return slots.map((slot) => {
        const slotId = slot.date + slot.start;
        const slotGroupId = `${slot.tariffId} ${slot.ruleId}`;

        if (!colorGroups.includes(slotGroupId)) {
            colorGroups.push(slotGroupId);
        }

        return {
            id: slotId,
            slot,
            color: colors[colorGroups.indexOf(slotGroupId)],
        };
    });
};

type AddSlotsProps = {
    initial: ScheduleSlot[];
    slots: SlotTemplateElement[];
};

type DeleteSlotsProps = {
    ids: string[];
    initial: ScheduleSlot[];
};

type EditSlotsProps = {
    ids: string[];
    values: Pick<ChangeTemplateSlotsFormDto, 'ruleId' | 'tariffId'>;
    initial: ScheduleSlot[];
};

const hasConflict = (s: SlotTemplateElement, slot: SlotTemplateElement) =>
    s.date === slot.date &&
    ((slot.start >= s.start && slot.start < s.end) || (slot.end <= s.end && slot.end > s.start));

export const addSlots = ({ initial = [], slots }: AddSlotsProps): ScheduleSlot[] => {
    const legalSlots = initial
        .filter(({ slot }) => !slots.some((s) => hasConflict(s, slot)))
        .map(({ slot }) => slot);

    return serializeSlots({ slots: [...legalSlots, ...slots] });
};

export const deleteSlots = ({ ids, initial }: DeleteSlotsProps): ScheduleSlot[] =>
    initial.filter((s) => !ids.includes(s.id));

export const editSlots = ({ ids, values, initial }: EditSlotsProps): ScheduleSlot[] => {
    const res = initial.map((s) => (ids.includes(s.id) ? { ...s.slot, ...values } : s.slot));

    return serializeSlots({ slots: res });
};
