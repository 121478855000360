import React from 'react';
import { CheckboxGroup, useCrudFormRequests } from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { UpdateCurrentUserNotificationsDto } from '@escapenavigator/types/dist/user/current/update-current-user-notifications.dto';
import { NotificationEnum } from '@escapenavigator/types/dist/user/enum/notification.enum';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { enumToOptions } from '@escapenavigator/utils/dist/enum-to-options';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';

type Props = {
    close: () => void;
    t: TFunction;
};

export const CurrentUserNotificationsModal: React.FC<Props> = ({ close, t }) => {
    const { users } = useApi();

    const {
        current: {
            user: { notifications },
        },
    } = useCurrentUser();

    const {
        updating,
        error: softError,
        save,
    } = useCrudFormRequests({
        saveRequest: users.currentUpdateNotifications,
        saveCallback: () => window.location.reload(),
        close,
    });

    const notificationOptions = enumToOptions(NotificationEnum, t, 'notifications');

    return (
        <React.Fragment>
            <RestForm
                title={ t('typography.aboutEvents') }
                initialValues={ serializeRecord(UpdateCurrentUserNotificationsDto, {
                    notifications,
                }) }
                validate={ validateByDto(t) }
                save={ save }
                close={ close }
                updating={ updating }
                t={ t }
                softError={ softError }
            >
                { ({ values, setFieldValue }) => (
                    <CheckboxGroup
                        data={ notificationOptions }
                        selected={ values?.notifications || [] }
                        onChange={ (v) => setFieldValue('notifications', v) }
                    />
                ) }
            </RestForm>
        </React.Fragment>
    );
};
