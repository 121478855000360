import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext, Table } from '@alphakits/ui/dist';
import { OrderModal } from 'src/modals/order';
import { UploadCertificateModal } from 'src/modals/upload-certificate';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { getLanguage } from 'src/utils/get-language';

import { Columns } from './columns';

export const UploadedCertificates: React.FC = () => {
    const { t } = useTranslation();

    const { certificateUploadsApi } = useApi();
    const { openModal } = useContext(ModalContext);
    const { i18n } = useTranslation();
    const {
        profile: { currency },
    } = useCurrentUser();

    const columns = useMemo(
        () =>
            Columns({
                onOrderOpenClick: (recordId) =>
                    openModal(OrderModal, 'm', true)({ recordId, successCalback: () => {} }),
                t,
                currency,
            }),
        [t, openModal, currency],
    );

    return (
        <Table.TableComponent
            columns={ columns }
            searchPlaceholder={ `${t('Поиск по номеру купона')}` }
            onCreateClick={ (cb) => openModal(UploadCertificateModal)({ cb }) }
            language={ getLanguage(i18n) }
            promise={ certificateUploadsApi.query }
        />
    );
};
