import React from 'react';
import { Divider, FlexColumns } from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { CreateOrderUpsellingDto } from '@escapenavigator/types/dist/order-upselling/create-order-upselling.dto';
import { UpsellingResponseObject } from '@escapenavigator/types/dist/upselling/upselling.ro';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { UpsellingItem } from 'src/modals/components/upselling-item';
import { useCurrentUser } from 'src/providers/current-user/context';
import { selectAllOptions } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

export type CustomUpsellingData = {
    amount: number;
    comment: string;
};

type Props = {
    questroomId: number;
    addUpsellings: (dto: Pick<CreateOrderUpsellingDto, 'upsellings'>) => void;
    close: () => void;
    t: TFunction;
};

export const AddUpselling: React.FC<Props> = ({
    t, close, addUpsellings, questroomId,
}) => {
    const {
        profile: { currency },
    } = useCurrentUser();

    const upsellings = useAppSelector(selectAllOptions) || [];

    const serialized = upsellings?.filter(
        (u) => u.allQuestrooms || (!u.allQuestrooms && u.questroomsIds.includes(questroomId)),
    );

    return (
        <RestForm
            initialValues={ serializeRecord(CreateOrderUpsellingDto, {
                upsellings: [],
            }) }
            validate={ validateByDto(t) }
            loading={ false }
            removing={ false }
            title={ t('Дополнительные услуги') }
            t={ t }
            close={ close }
            save={ async (values) => {
                addUpsellings({ upsellings: values.upsellings });
                close();
            } }
        >
            { ({
                values, setFieldValue, errors, touched,
            }) => {
                const selected = values.upsellings || [];

                const addOrRemove = (upselling: UpsellingResponseObject) => {
                    const selectedIds = selected.map((s) => s.id) || [];

                    const newState = selectedIds.includes(upselling.id)
                        ? selected.filter((s) => s.id !== upselling.id)
                        : [
                            ...selected,
                            {
                                id: upselling.id,
                                amount: upselling.price || 0,
                                title: upselling.title,
                                byEachPerson: upselling.byEachPerson,
                                flexiblePrice: upselling.flexiblePrice,
                            },
                        ];

                    setFieldValue('upsellings', newState);
                };

                const changeUpsellingData = (
                    upselling: CreateOrderUpsellingDto['upsellings'][0],
                    { amount, comment }: CustomUpsellingData,
                ) => {
                    setFieldValue(
                        'upsellings',
                        selected.map((s) =>
                            (upselling.id === s.id ? { ...s, amount, comment } : s)),
                    );
                };

                return (
                    !!upsellings.length && (
                        <FlexColumns columns={ 1 } gr={ 8 }>
                            { serialized.map((upselling) => {
                                const current = values.upsellings.filter(
                                    (s) => s.id === upselling.id,
                                )[0];

                                return (
                                    <React.Fragment key={ upselling.id }>
                                        <UpsellingItem
                                            upselling={ upselling }
                                            currency={ currency }
                                            amount={ current?.amount || upselling.price }
                                            comment={ current?.comment }
                                            selected={ !!current }
                                            t={ t }
                                            onClick={ () => addOrRemove(upselling) }
                                            onChange={ (data) =>
                                                changeUpsellingData(upselling, data) }
                                            error={ touched.upsellings && errors.upsellings }
                                        />
                                        <Divider />
                                    </React.Fragment>
                                );
                            }) }
                        </FlexColumns>
                    )
                );
            } }
        </RestForm>
    );
};
