import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext, Table } from '@alphakits/ui/dist';
import { Section } from 'src/components/layout/section';
import { SlotBookingRuleModal } from 'src/modals/slot-booking-rules';
import { useRulesApi } from 'src/store/hooks/use-rules';
import { getLanguage } from 'src/utils/get-language';

import { Ruleolumns } from './rule-columns';

export const BookingRules: React.FC = () => {
    const { i18n, t } = useTranslation();

    const { openModal } = useContext(ModalContext);

    const { rules, loading } = useRulesApi();

    const columns = Ruleolumns();

    const openRuleModal = openModal(SlotBookingRuleModal);

    return (
        <Section
            title={ t('bookingRules.title') }
            id="bookingRulesSettings"
            subtitle={ !rules.length && t('Установите правила бронирования') }
            onClick={ () => openRuleModal() }
            initial={ !rules.length }
            loading={ loading }
        >
            { !!rules.length && (
                <Table.TableComponent
                    columns={ columns }
                    hideSearch={ true }
                    height="default"
                    language={ getLanguage(i18n) }
                    onRowClick={ (record) => openRuleModal({ record }) }
                    records={ rules }
                    loading={ loading }
                />
            ) }
        </Section>
    );
};
