import React from 'react';
import { PlusS, TrashS } from '@alphakits/icons';
import {
    Button, FlexColumns, Input, Textarea, useCrudFormRequests,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { CreateQuestroomDto } from '@escapenavigator/types/dist/questroom/create-questroom.dto';
import { QuestroomRO } from '@escapenavigator/types/dist/questroom/questroom.ro';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';
import { upsertQuestroom } from 'src/store';
import { useAppDispatch } from 'src/store/hooks';

type Props = {
    questroom?: QuestroomRO;
    close: () => void;
    t: TFunction;
};

export const QuestroomModeModal: React.FC<Props> = ({ close, t, questroom }) => {
    const recordId = questroom?.id;
    const dispatch = useAppDispatch();

    const { questrooms: questroomsApi } = useApi();

    const {
        save,
        removing,
        updating,
        error: softError,
    } = useCrudFormRequests({
        recordId,
        saveRequest: questroomsApi.update,
        saveCallback: (savedRecord) => {
            dispatch(upsertQuestroom(savedRecord));
        },
        close,
    });

    return (
        <RestForm
            recordId={ +recordId }
            title={ t('Режимы игры') }
            initialValues={ serializeRecord(CreateQuestroomDto, questroom) }
            validate={ validateByDto(t) }
            save={ save }
            softError={ softError }
            updating={ updating || removing }
            close={ close }
            t={ t }
        >
            { ({
                values, setFieldValue, errors, touched,
            }) => (
                <FlexColumns columns={ 1 } gr={ 24 } gc={ 0 }>
                    { values.modes.map((l, i) => {
                        const err = errors.modes?.[i] as any;
                        const touch = touched.modes?.[i];

                        return (
                            <FlexColumns columns={ 1 } gr={ 12 } gc={ 8 }>
                                <Input
                                    dataTestId="title"
                                    label={ t('tables.title') }
                                    error={ touch?.title && err?.title }
                                    value={ l.title }
                                    onChange={ (_, { value }) =>
                                        setFieldValue(`modes.${i}.title`, value) }
                                    block={ true }
                                />

                                <Textarea
                                    label={ t('tables.description') }
                                    value={ l.description }
                                    error={ touch?.description && err?.description }
                                    onChange={ (_, { value }) =>
                                        setFieldValue(`modes.${i}.description`, value) }
                                    block={ true }
                                    maxLength={ 1000 }
                                />

                                <div>
                                    <Button
                                        onClick={ () => {
                                            values.modes.splice(i, 1);
                                            setFieldValue('modes', values.modes);
                                        } }
                                        leftAddons={ <TrashS /> }
                                        view="ghost"
                                        size="xs"
                                    >
                                        { t('remove') }
                                    </Button>
                                </div>
                            </FlexColumns>
                        );
                    }) }

                    <div>
                        <Button
                            onClick={ () =>
                                setFieldValue('modes', [
                                    ...values.modes,
                                    {
                                        title: '',
                                        description: '',
                                    },
                                ]) }
                            leftAddons={ <PlusS /> }
                            view="outlined"
                            size="xs"
                        >
                            { t('Добавить') }
                        </Button>
                    </div>
                </FlexColumns>
            ) }
        </RestForm>
    );
};
