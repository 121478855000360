import React from 'react';
import { DownloadM, InfoMarkS, OpenS } from '@alphakits/icons';
import {
    Amount,
    CopyButton,
    formatDate,
    IconButton,
    Image,
    Status,
    Tooltip,
} from '@alphakits/ui/dist';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { CertificateUploadedRO } from '@escapenavigator/types/dist/certificate-uploaded/certificate-uploaded.ro';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { differenceInHours, parse } from 'date-fns';
import { TFunction } from 'i18next';

const colors = (
    row: CertificateUploadedRO,
    t: TFunction,
): {
    color: 'green' | 'orange' | 'red' | 'blue' | 'grey' | 'teal' | 'purple';
    title: string;
} => {
    const expiried =
        differenceInHours(parse(row.expireDate, 'yyyy-MM-dd', new Date()), new Date()) < 0;

    if (row.order || row.used) {
        return {
            color: 'purple',
            title: t('Использован'),
        };
    }

    if (expiried) {
        return {
            color: 'red',
            title: t('Просрочен'),
        };
    }

    return {
        color: 'green',
        title: t('Загружен'),
    };
};

type Props = {
    onOrderOpenClick: (id: number) => void;
    t: TFunction;
    currency: ProfileCurrencyEnum;
};

export const Columns = ({
    onOrderOpenClick,
    t,
    currency,
}: Props): TableColumns<CertificateUploadedRO> => [
    {
        header: t('tables.dateOfSale'),
        accessor: 'createdDate',
        sort: true,
        row: {
            title: ({ row }) => formatDate(row.createdDate, { format: 'dd.MM.yyyy' }),
            icon: () => <Image view="ellipse" bgColor="accent" icon={ <DownloadM /> } />,
            subtitle: ({ row }) => row.source,
        },
    },
    {
        header: t('tables.certificate'),
        accessor: 'source',
        row: { title: ({ row }) => <CopyButton text={ row.code } /> },
    },
    {
        header: t('tables.client'),
        accessor: 'client',
    },
    {
        header: t('tables.price'),
        accessor: 'nominal',
        row: {
            title: ({ row }) => (
                <Amount type="decimal" color="primary" currency={ currency } value={ row.nominal } />
            ),
        },
    },
    {
        header: t('Годен до'),
        accessor: 'expireDate',
        sort: true,
        row: {
            title: ({ row }) => formatDate(row.expireDate, { format: 'dd.MM.yyyy' }),
        },
    },
    {
        header: 'Metadata',
        accessor: 'metadata',
        row: {
            title: ({ row }) => (
                <Tooltip
                    trigger="click"
                    position="top"
                    content={ <React.Fragment>{ row.metadata }</React.Fragment> }
                    dataTestId="test-id"
                >
                    <IconButton view="ghost" icon={ InfoMarkS } />
                </Tooltip>
            ),
        },
    },

    {
        header: '',
        align: 'right',
        sort: true,
        accessor: 'used',
        row: {
            title: ({ row }) => {
                const data = colors(row, t);

                return (
                    <React.Fragment>
                        <Status color={ data.color } view="contrast">
                            { data.title }
                        </Status>

                        { row.order && (
                            <IconButton
                                icon={ OpenS }
                                onClick={ (e) => {
                                    e.stopPropagation();
                                    onOrderOpenClick(row.order.id);
                                } }
                            />
                        ) }
                    </React.Fragment>
                );
            },
        },
    },
];
