import React from 'react';
import { FlexColumns, Typography, useCrudFormRequests } from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { languageNameByLanguage } from '@escapenavigator/types/dist/constants/language-name-by-language';
import { CreateLocationDto } from '@escapenavigator/types/dist/location/create-location.dto';
import { LocationRO } from '@escapenavigator/types/dist/location/location.ro';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { FeedbackTextarea } from 'src/pages/marketing/sales/common/feedback-textarea';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { upsertLocation } from 'src/store';
import { useAppDispatch } from 'src/store/hooks';
import { getLocales } from 'src/utils/get-locales';

type Props = {
    location: LocationRO;
    close: () => void;
    t: TFunction;
};

export const LocationLocalizationModal: React.FC<Props> = ({ close, t, location }) => {
    const recordId = location?.id;
    const dispatch = useAppDispatch();
    const { profile } = useCurrentUser();

    const { locations } = useApi();

    const {
        save,
        removing,
        updating,
        error: softError,
    } = useCrudFormRequests({
        recordId,
        saveRequest: locations.update,
        saveCallback: (savedRecord) => {
            dispatch(upsertLocation(savedRecord));
        },
        close,
    });

    const locales = getLocales({
        language: profile.language,
        availableLanguages: profile.availableLanguages,
        locales: location?.locales || [],
        defaultLocale: {
            howToFind: location?.howToFind,
            prepareText: location?.prepareText,
        },
    });

    return (
        <RestForm
            recordId={ +recordId }
            title={ t('Локализация') }
            initialValues={ serializeRecord(CreateLocationDto, { ...location, locales }) }
            validate={ validateByDto(t) }
            save={ save }
            softError={ softError }
            updating={ updating || removing }
            close={ close }
            t={ t }
        >
            { ({
                values, setFieldValue, errors, touched,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    { values.locales.map((l, i) => {
                        const language = languageNameByLanguage[l.language];
                        const err = errors.locales?.[i] as any;
                        const touch = touched.locales?.[i];

                        return (
                            <FlexColumns columns={ 1 } gr={ 8 } gc={ 8 }>
                                <Typography.Text view="title" weight="bold">
                                    { language }
                                </Typography.Text>

                                <FeedbackTextarea
                                    id="howToFind"
                                    text={ l.howToFind }
                                    error={ touch?.howToFind && err?.howToFind }
                                    noLinks={ true }
                                    title={ t('component.howToFind') }
                                    lang={ l.language }
                                    handlers={ [] }
                                    onChange={ (value) =>
                                        setFieldValue(`locales.${i}.howToFind`, value) }
                                />

                                <FeedbackTextarea
                                    id="prepareText"
                                    text={ l.prepareText }
                                    error={ touch?.prepareText && err?.prepareText }
                                    noLinks={ true }
                                    title={ t('Что важно знать перед бронированием') }
                                    lang={ l.language }
                                    handlers={ [] }
                                    onChange={ (value) =>
                                        setFieldValue(`locales.${i}.prepareText`, value) }
                                />
                            </FlexColumns>
                        );
                    }) }
                </FlexColumns>
            ) }
        </RestForm>
    );
};
