import { Languages } from '@escapenavigator/types/dist/shared/enum/languages.enum';
import { TFunction } from 'i18next';

import { HandlerKey } from './get-handlers';

export function setValue(text: string) {
    const cursorPosition = this.quill.getSelection().index;

    this.quill.insertText(cursorPosition, text);
}

const ifParams: HandlerKey[] = [
    'photos',
    'gameResult',
    'servicesList',
    'howToFindLocation',
    'importantInfo',
    'prepareInfo',
];

export function pasteText({
    namespace,
    param,
    t,
    lng,
}: {
    param: HandlerKey;
    t: TFunction;
    namespace: string;
    lng: Languages;
}) {
    if (ifParams.includes(param)) {
        return `
{{#if ${param}}}
${t(`${namespace}:baseValues.${param}`, { value: `{${param}}`, lng })}
{{/if}}
`;
    }

    return `{${param}}`;
}
