import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, FlexColumns, Sidepanel } from '@alphakits/ui/dist';
import { LocationRO } from '@escapenavigator/types/dist/location/location.ro';
import { OrderEmailRO } from '@escapenavigator/types/dist/order-emails/order-email.ro';

import { MarketingCell } from '../components/marketing-cell';

type Props = {
    orderEmails: OrderEmailRO[];
    location: LocationRO;
    cb: () => void;
    close: () => void;
};

export const MarketingModal: React.FC<Props> = ({
    orderEmails, location, cb, close,
}) => {
    const { t } = useTranslation();

    return (
        <Sidepanel title={ t('имеилы') }>
            <FlexColumns columns={ 1 } gr={ 24 }>
                { orderEmails.map((orderEmail) => (
                    <React.Fragment>
                        <MarketingCell
                            orderEmail={ orderEmail }
                            cb={ () => {
                                close();
                                cb();
                            } }
                            view="default"
                            location={ location }
                        />
                        <Divider />
                    </React.Fragment>
                )) }
            </FlexColumns>
        </Sidepanel>
    );
};
