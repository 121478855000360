import React from 'react';
import {
    Amount, Box, FlexColumns, Skeleton, Typography,
} from '@alphakits/ui';
import { useCurrentUser } from 'src/providers/current-user/context';

type Props = {
    title: string;
    amount: number;
    loading: boolean;
};

export const AmountBox: React.FC<Props> = ({ title, amount, loading }) => {
    const { profile } = useCurrentUser();

    return (
        <Skeleton visible={ loading } animate={ true }>
            <Box border={ true } padding="sm" rounded="sm">
                <FlexColumns columns={ 1 } gr={ 8 }>
                    <Typography.Text view="title" color="primary" weight="bold">
                        { title }
                    </Typography.Text>

                    <Typography.Title tag="div" color="positive" view="medium" weight="bold">
                        <Amount
                            currency={ profile.currency }
                            type="decimal"
                            weight="bold"
                            value={ amount || 0 }
                        />
                    </Typography.Title>
                </FlexColumns>
            </Box>
        </Skeleton>
    );
};
