import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext, Table } from '@alphakits/ui/dist';
import { ClientModal } from 'src/modals/client';
import { ClientsFiltersModal } from 'src/modals/filters/clients';
import { useApi } from 'src/providers/api/context';
import { mapper } from 'src/tags';
import { getLanguage } from 'src/utils/get-language';

import { ClientsColumns } from './columns';

export const ClientsPage: React.FC = () => {
    const { clients } = useApi();
    const { t, i18n } = useTranslation();

    const { openModal } = useContext(ModalContext);

    const columns = React.useMemo(() => ClientsColumns(t, i18n), [t, i18n]);

    const openClientModal = openModal(ClientModal, 'm', true);
    const openFilters = openModal(ClientsFiltersModal);

    return (
        <Table.TableComponent
            filtersButtonLabel={ t('filters.label') }
            onRowClick={ ({ id }) => openClientModal({ recordId: +id }) }
            onFiltersClick={ ({ submitCallback, initialValues }) =>
                openFilters({
                    apply: submitCallback,
                    initialValues: initialValues.where,
                }) }
            tagsBuilder={ mapper(t) }
            columns={ columns }
            searchPlaceholder={ t('tables.clientsSearch') }
            promise={ clients.query as any }
            language={ getLanguage(i18n) }
        />
    );
};
