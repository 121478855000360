import React from 'react';
import { FlexColumns, Typography } from '@alphakits/ui/dist';

type Props = {
    title?: string;
};

export const ModalSection: React.FC<Props> = ({ title, children }) => (
    <FlexColumns columns={ 1 } gr={ 12 }>
        { title && (
            <Typography.Title tag="div" view="xxsmall" weight="bold">
                { title }
            </Typography.Title>
        ) }

        <FlexColumns columns={ 1 } gr={ 16 }>
            { children }
        </FlexColumns>
    </FlexColumns>
);
