import React from 'react';
import { useRoutes } from 'react-router';
import { EmptyPage, TFunction } from '@alphakits/ui';
import { ProfileRO } from '@escapenavigator/types/dist/profile/profile.ro';
import { CurrentUserRO } from '@escapenavigator/types/dist/user/current/current-user.ro';

import { getRoutes } from './routes-tree';

type Props = {
    t: TFunction;
    currentUser: CurrentUserRO;
    profile: ProfileRO;
    isDesktop: boolean;
};

export const useAppRoutes = (props: Props) => {
    const routes = getRoutes(props);

    const router = useRoutes(
        Object.entries(routes).reduce(
            (acc, [path, route]) => {
                if (route.type === 'path') {
                    acc.push({
                        path,
                        element: route.element,
                    });
                } else {
                    Object.entries(route.children).forEach(([nestedPath, nestedRoute]) => {
                        acc.push({
                            path: nestedPath,
                            element: nestedRoute.element,
                        });
                    });
                }

                return acc;
            },
            [
                {
                    path: '*',
                    element: (
                        <EmptyPage
                            title="OOOPS"
                            code="404"
                            buttonText="Go to main page"
                            subtitle=""
                            href="/"
                        />
                    ) as React.ReactNode,
                },
            ],
        ),
    );

    return { router, routes };
};
