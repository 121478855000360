import React from 'react';
import { useTranslation } from 'react-i18next';
import { EyeS, UpdateS } from '@alphakits/icons';
import {
    Button, Flex, FlexColumns, Input, ToastPlate, Typography,
} from '@alphakits/ui/dist';
import { languageNameByLanguage } from '@escapenavigator/types/dist/constants/language-name-by-language';
import { CrossSaleLocaleDto } from '@escapenavigator/types/dist/profile/cross-sale/cross-sale.dto';
import { FormFooter } from 'src/components/form-footer';

import { HandlerKey } from '../../../../../components/quill/get-handlers';
import { FeedbackTextarea } from '../feedback-textarea';

type Props = {
    close: () => void;
    submit: () => void;
    errors: any;
    onExampleClick: () => void;
    customError?: string;
    onDefaultsClick: () => void;
    showDefaults: boolean;
    handlers: Array<{ title: string; key: HandlerKey; description: string }>;
    setFieldValue: (key: string, value: string) => void;
    locales: CrossSaleLocaleDto[];
};

export const CommonCrossSaleForm: React.FC<Props> = ({
    setFieldValue,
    submit,
    onExampleClick,
    onDefaultsClick,
    showDefaults,
    customError,
    handlers,
    errors,
    children,
    locales,
    close,
}) => {
    const { t } = useTranslation();

    return (
        <FlexColumns columns={ 1 } gr={ 32 }>
            <Flex gap="sm" justify="start">
                <Button
                    view="outlined"
                    leftAddons={ <EyeS /> }
                    size="xs"
                    onClick={ () => onExampleClick() }
                >
                    { t('Пример') }
                </Button>

                { showDefaults && (
                    <Button
                        view="outlined"
                        leftAddons={ <UpdateS /> }
                        size="xs"
                        onClick={ () => onDefaultsClick() }
                    >
                        { t('Рекомендуемые значения') }
                    </Button>
                ) }
            </Flex>

            <FlexColumns columns={ 1 }>
                <FlexColumns columns={ 1 } gr={ 16 }>
                    <Typography.Title tag="div" view="xsmall" weight="bold">
                        { t('Парметры отправки') }
                    </Typography.Title>
                    { children }
                </FlexColumns>
            </FlexColumns>

            { locales.map((locale, index) => (
                <FlexColumns columns={ 1 }>
                    <Typography.Title tag="div" view="xsmall" weight="bold">
                        { languageNameByLanguage[locale.language] }
                    </Typography.Title>
                    <Input
                        label={ t('Заголовок письма') }
                        value={ locales[index].title }
                        onChange={ (e, { value }) => setFieldValue(`locales.${index}.title`, value) }
                        required={ true }
                        hint={ t('crossSaleTitleHint') }
                        error={ errors?.locales?.[index]?.title }
                        block={ true }
                    />

                    <FeedbackTextarea
                        text={ locales[index].feedbackText }
                        handlers={ handlers }
                        lang={ locale.language }
                        error={ errors?.locales?.[index]?.feedbackText }
                        onChange={ (text) => setFieldValue(`locales.${index}.feedbackText`, text) }
                    />
                </FlexColumns>
            )) }

            { customError && <ToastPlate view="negative">{ customError }</ToastPlate> }

            <FormFooter>
                <Flex gap="md" justify="start">
                    <Button
                        view="primary"
                        block={ true }
                        size="s"
                        onClick={ () => !customError && submit() }
                    >
                        { t('save') }
                    </Button>

                    <Button view="outlined" block={ true } size="s" onClick={ close }>
                        { t('cancel') }
                    </Button>
                </Flex>
            </FormFooter>
        </FlexColumns>
    );
};
