import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDownM } from '@alphakits/icons';
import { Flex, ModalContext, Typography } from '@alphakits/ui';
import { QuestroomRO } from '@escapenavigator/types/dist/questroom/questroom.ro';
import { Picker } from 'src/components/picker';
import { QuestroomsGroup } from 'src/components/schedule/components/questrooms-group';
import { ChangeScheduleModal } from 'src/modals/slots/change-schedule';
import { CreateSingleSlotModal } from 'src/modals/slots/create-single-slot';
import { ManageBreaksModal } from 'src/modals/slots/manage-breaks';
import { CreateUserSessionModal } from 'src/modals/user-session/create';
import { useCurrentUser } from 'src/providers/current-user/context';
import { selectAllLocations, selectLocationById, selectQuestroomsByLocationId } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

import { CalendarView } from '../types';

import styles from '../index.module.css';

type Props = {
    selectedLocationId: number;
    date: string;
    selectedQuestrooms: QuestroomRO[];
    view: CalendarView;
    changeView: (view: CalendarView) => void;
    refetch: () => void;
    changeSelectedQuestroomsIds: (ids: number[]) => void;
    changeLocation: (id: number) => void;
};

export const CalendarHeader: React.FC<Props> = ({
    selectedLocationId,
    date,
    selectedQuestrooms,
    view,
    changeView,
    changeLocation,
    refetch,
    changeSelectedQuestroomsIds,
}) => {
    const { t } = useTranslation();
    const {
        current: { role },
    } = useCurrentUser();

    const { openModal } = useContext(ModalContext);

    const selectedLocation = useAppSelector(selectLocationById(selectedLocationId));
    const questroomsInLocation = useAppSelector(selectQuestroomsByLocationId(+selectedLocationId));
    const allLocations = useAppSelector(selectAllLocations);

    const openScheduleTemplateModal = openModal(ChangeScheduleModal, 'full');
    const openSlotsBreakModal = openModal(ManageBreaksModal);
    const openNewSlotModal = openModal(CreateSingleSlotModal);

    const scheduleActions = () => {
        const actions = {
            [t('Добавить смену')]: () =>
                openModal(CreateUserSessionModal)({
                    date,
                    cb: refetch,
                    locationId: selectedLocationId,
                }),
        };

        if (role.canCreateBreaks || role.totalAccess) {
            actions[t('Добавить или удалить перерыв')] = () =>
                openSlotsBreakModal({ callback: refetch, type: 'add' });
        }

        if (role.canEditSchedule || role.totalAccess) {
            actions[t('Добавить слот')] = () => openNewSlotModal({ callback: refetch, date });
            actions[t('Изменить время слотов')] = () =>
                openScheduleTemplateModal({ callback: refetch });
        }

        return actions;
    };

    return (
        <Flex justify="start" gap="xl" className="noPrint">
            <Flex gap="l" justify="start" className={ styles.flex }>
                <Picker
                    t={ t }
                    view="outlined"
                    className={ styles.calendar_header__side }
                    position="bottom-end"
                    content={ (
                        <Flex gap="sm" align="center">
                            <Typography.Title
                                className={ styles.component }
                                view="medium"
                                tag="div"
                                weight="bold"
                                color="primary"
                            >
                                { t(view) }
                            </Typography.Title>

                            <ChevronDownM />
                        </Flex>
                    ) }
                    actions={ {
                        [t('slotsList')]: () => changeView('slotsList'),
                        [t('day')]: () => changeView('day'),
                        [t('games')]: () => changeView('games'),
                    } }
                />
            </Flex>

            <Flex gap="xl">
                { view !== 'games' && questroomsInLocation.length > 1 && (
                    <QuestroomsGroup
                        view={ view }
                        questrooms={ questroomsInLocation }
                        selectedQuestroomsIds={ selectedQuestrooms.map((q) => q.id) }
                        onChange={ changeSelectedQuestroomsIds }
                    />
                ) }

                <Flex gap="sm">
                    { allLocations.length > 1 && (
                        <Picker
                            t={ t }
                            view="outlined"
                            className={ styles.calendar_header__side }
                            position="bottom-end"
                            content={ selectedLocation.title }
                            actions={ Object.fromEntries(
                                allLocations.map((l) => [l.title, () => changeLocation(l.id)]),
                            ) }
                        />
                    ) }

                    <Picker
                        t={ t }
                        className={ styles.dots_btn }
                        actions={ scheduleActions() }
                        content={ t('Действия') }
                    />
                </Flex>
            </Flex>
        </Flex>
    );
};
