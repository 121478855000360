import React, { useContext, useState } from 'react';
import {
    CheckmarkCircleFillM, ClockCircleM, PencilM, TrashM,
} from '@alphakits/icons';
import {
    Flex,
    FlexColumns,
    ModalContext,
    PageWithControls,
    Sidepanel,
    Status,
    Tag,
    ToastPlate,
    Typography,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CrmVerificationEnum } from '@escapenavigator/types/dist/profile/enum/crm-verifiaction.enum';
import { Languages } from '@escapenavigator/types/dist/shared/enum/languages.enum';
import { WidgetTypeEnum } from '@escapenavigator/types/dist/widget/enum/widget-type.enum';
import { WidgetRO } from '@escapenavigator/types/dist/widget/widget.ro';
import { TFunction } from 'i18next';
import { TextCell } from 'src/components/text-cell';
import {
    getTitle,
    getWidgetTitle,
} from 'src/pages/settings/widgets-settings/components/widget-component';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';

import { WidgetModal } from '../widget';

const themes = [
    { key: 'light' as const, title: 'Light theme' },
    { key: 'dark' as const, title: 'Dark theme' },
] as const;

type Props = {
    record: WidgetRO;
    close: () => void;
    refetch: () => void;
    t: TFunction;
};

const getCode = (uuid: string, lang: Languages, theme, type: WidgetTypeEnum) =>
    `<div 
     data-widget-id="${uuid}" 
     data-theme="${theme}" 
     data-language="${lang}" 
     data-widget-type="${type === WidgetTypeEnum.WIDGET_BUTTON ? 'button' : 'schedule'}">
</div>`;

export const WidgetInfoModal: React.FC<Props> = ({
    record, close, refetch, t,
}) => {
    const { widgets } = useApi();
    const { profile } = useCurrentUser();

    const { openModal } = useContext(ModalContext);

    const [locale, setLocale] = useState(profile.language);
    const [theme, setTheme] = useState<'dark' | 'light'>('dark');

    const openWidgetModal = () =>
        openModal(WidgetModal)({
            record,
            refetch: () => {
                close();
                refetch();
            },
        });

    const { removeLoading, removeFetch } = useApiMethod({
        api: widgets.remove,
        successCallback: () => {
            refetch();
            close();
        },
    });

    const prefix = profile.crmVerification === CrmVerificationEnum.SUCCEED ? '' : '?testMode=true';
    const socialLink = `${process.env.REACT_APP_ORDERS_DOMAIN}/booking/${record.uuid}${prefix}`;

    return (
        <Sidepanel title={ `ID: ${record.uuid}` }>
            <PageWithControls
                buttons={ [
                    {
                        onClick: () => removeFetch(record.id),
                        loading: removeLoading,
                        text: t('remove'),
                        leftAddons: <TrashM />,
                    },
                    {
                        onClick: openWidgetModal,
                        text: t('Редактировать'),
                        leftAddons: <PencilM />,
                    },
                ] }
            >
                <FlexColumns columns={ 1 } gr={ 24 }>
                    <div>
                        <Status view="soft" size="m" color={ record.activity ? 'green' : 'grey' }>
                            { record.activity ? <CheckmarkCircleFillM /> : <ClockCircleM /> }
                            { record.activity ? t('Installed') : t('Not installed') }
                        </Status>
                    </div>

                    <TextCell title="Type" subtitle={ getWidgetTitle(record.type, t) } />

                    <TextCell title="Locations" subtitle={ getTitle(record, t) } />

                    { record.type === WidgetTypeEnum.ALL_QUESTROOM && (
                        <TextCell
                            title={ t('socialWdigetLink') }
                            subtitle={
                                (
                                    <a
                                        href={ socialLink }
                                        target="_blank"
                                        style={ { color: 'var(--color-text-primary)' } }
                                        rel="noreferrer"
                                    >
                                        { socialLink }
                                    </a>
                                ) as any as string
                            }
                        />
                    ) }

                    { profile.crmVerification === CrmVerificationEnum.SUCCEED ? (
                        <TextCell
                            title="Widget destination URL"
                            subtitle={
                                (
                                    <a
                                        href={ record.url }
                                        target="_blank"
                                        style={ { color: 'var(--color-text-primary)' } }
                                        rel="noreferrer"
                                    >
                                        { record.url }
                                    </a>
                                ) as any as string
                            }
                        />
                    ) : (
                        <TextCell
                            title="Widget destination URL (TEST MODE)"
                            subtitle={
                                (
                                    <a
                                        href={ `${record.url}?testMode=true` }
                                        style={ { color: 'var(--color-text-primary)' } }
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        { `${record.url}${
                                            record.url?.includes('?') ? '&' : '?'
                                        }testMode=true` }
                                    </a>
                                ) as any as string
                            }
                        />
                    ) }
                </FlexColumns>

                <FlexColumns columns={ 1 } gr={ 16 }>
                    <Typography.Title tag="div" view="xxsmall" weight="bold" color="primary">
                        Widget code
                    </Typography.Title>

                    <Flex gap="xl" justify="start">
                        { profile.availableLanguages.length > 1 && (
                            <Flex gap="sm" justify="start">
                                { profile.availableLanguages.map((l) => (
                                    <Tag
                                        key={ l }
                                        view={ locale === l ? 'primary-inverted' : 'primary' }
                                        onClick={ () => setLocale(l) }
                                        text={ l.toUpperCase() }
                                    />
                                )) }
                            </Flex>
                        ) }

                        <Flex gap="sm" justify="start">
                            { themes.map((value) => (
                                <Tag
                                    key={ value.key }
                                    view={ theme === value.key ? 'primary-inverted' : 'primary' }
                                    onClick={ () => setTheme(value.key) }
                                    text={ value.title }
                                />
                            )) }
                        </Flex>
                    </Flex>

                    <Typography.Text view="caps" color="secondary">
                        { t('Короткая инструкия по виджетам') }
                    </Typography.Text>

                    <pre>{ getCode(record.uuid, locale, theme, record.type) }</pre>

                    <ToastPlate view="default">
                        { t('Не забудьте поставить скрипт') } <br />
                        { `<script src="${process.env.REACT_APP_WIDGET_DOMAIN}/widget-loader.js"></script>` }
                    </ToastPlate>
                </FlexColumns>
            </PageWithControls>
        </Sidepanel>
    );
};
