import React, { useCallback, useEffect, useState } from 'react';
import { SearchS } from '@alphakits/icons';
import {
    Input,
    OptionsList,
    OptionsListProps,
    Select,
    SelectProps,
    useClickOutside,
} from '@alphakits/ui';

import styles from './index.module.css';

export const CustomOptionsList = React.forwardRef<HTMLDivElement, OptionsListProps>(
    ({ inputProps, ...props }, ref) => {
        const inputRef = React.useRef<HTMLInputElement>(null);

        useEffect(() => {
            if (inputRef?.current) {
                inputRef?.current?.focus();
            }
        }, []);

        return (
            <div ref={ ref }>
                <div style={ { padding: '5px 20px' } }>
                    <Input
                        size="s"
                        leftAddons={ <SearchS /> }
                        { ...inputProps }
                        fieldClassName={ styles.innerInput }
                        block={ true }
                        ref={ inputRef }
                    />
                </div>
                <OptionsList { ...props } ref={ ref } />
            </div>
        );
    },
);

type SelectSearchProps = Omit<SelectProps, 'options'> & {
    options: any;
    searchType?: 'text' | 'number';
};

export const SelectSearch = ({
    options,
    searchType = 'text',
    onChange,
    selected = '',
    ...restProps
}: SelectSearchProps) => {
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [searchText, setSearchText] = useState('');
    const listRef = React.useRef<HTMLDivElement>(null);

    const searchOptions = useCallback(
        (e, { value }) => {
            setSearchText(value);

            try {
                if (!value) return setFilteredOptions(options);

                const text = value.toLowerCase();

                const data = options.reduce(
                    (acc, o) => [...acc, ...(o.options ? o.options : [o])],
                    [],
                );

                const res = data.filter(({ key, value }) =>
                    JSON.stringify({ key, value }).toLowerCase().includes(text));

                return setFilteredOptions(res);
            } catch (error) {
                console.log(error);

                return [];
            }
        },
        [options],
    );

    const handleClear = () => {
        setSearchText('');
        setFilteredOptions(options);
    };

    const handleSelect = (payload: Parameters<typeof onChange>[0]) => {
        onChange(payload);
        handleClear();
    };

    useClickOutside(listRef, handleClear);

    return (
        <Select
            { ...restProps }
            selected={ selected }
            onChange={ handleSelect }
            showEmptyOptionsList={ true }
            optionsListProps={ {
                ref: listRef,
                inputProps: {
                    onChange: searchOptions,
                    value: searchText,
                    type: searchType,
                    clear: true,
                    onClear: handleClear,
                },
            } }
            OptionsList={ CustomOptionsList }
            options={ filteredOptions.length ? filteredOptions : options }
        />
    );
};
