import React, { useMemo } from 'react';
import { Button, IButtonProps } from '@alphakits/ui/dist';

type Props = {
    time: string;
    onClick: () => void;
    selected: boolean;
    disabled: boolean;
};

export const WidgetSlot = ({
    time, onClick, selected, disabled,
}: Props) => {
    const view: IButtonProps['view'] = useMemo(() => {
        if (selected) return 'primary';
        if (disabled) return 'secondary';

        return 'outlined';
    }, [selected, disabled]);

    return (

        <Button
            block={ true }
            disabled={ disabled }
            onClick={ onClick }
            view={ view }
            type="button"
        >
            { time }
        </Button>

    );
};
