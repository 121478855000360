import React from 'react';
import {
    AmountInput,
    Checkbox,
    CheckboxGroups,
    FlexColumns,
    Input,
    Padding,
    Textarea,
    Typography,
    useCrudFormRequests,
    useGetOne,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { CreateUpsellingDto } from '@escapenavigator/types/dist/upselling/create-upselling.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { TimeInput } from 'src/components/time-input';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import {
    removeOption, selectAllLocations, selectAllQuestrooms, upsertOption,
} from 'src/store';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';

import { DaysOfWeek } from '../slots/autocomplite/days-of-week';
import { getOptionForTimeSelect } from '../slots/autocomplite/utils';

type Props = {
    recordId?: number;
    close: () => void;
    t: TFunction;
};

export const UpsellingModal: React.FC<Props> = ({ recordId, close, t }) => {
    const dispatch = useAppDispatch();
    const { upsellings } = useApi();
    const questrooms = useAppSelector(selectAllQuestrooms({})) || [];
    const locations = useAppSelector(selectAllLocations) || [];
    const {
        profile: { currency },
    } = useCurrentUser();

    const { record, loading, error } = useGetOne(+recordId, upsellings.getOne);

    const {
        save,
        remove,
        removing,
        updating,
        error: softError,
    } = useCrudFormRequests({
        recordId,
        removeRequest: upsellings.remove,
        saveRequest: recordId ? upsellings.update : upsellings.create,
        saveCallback: (savedRecords) => dispatch(upsertOption(savedRecords)),
        removeCallback: (removedRecord) => dispatch(removeOption(removedRecord.id)),
        close,
    });

    return (
        <RestForm
            recordId={ recordId }
            title={ record?.title || t('modalsHeader.option') }
            initialValues={ serializeRecord(CreateUpsellingDto, record) }
            validate={ validateByDto(t) }
            save={ save }
            remove={ remove }
            error={ error }
            softError={ softError }
            loading={ loading }
            updating={ updating || removing }
            close={ close }
            t={ t }
        >
            { ({
                values, handleChange, setFieldValue, errors, touched,
            }) => (
                <FlexColumns columns={ 1 } gr={ 32 } gc={ 0 }>
                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                        <Input
                            dataTestId="title"
                            label={ t('tables.title') }
                            value={ values.title }
                            error={ touched.title && errors.title }
                            onChange={ handleChange('title') }
                            required={ true }
                            block={ true }
                        />

                        <Checkbox
                            dataTestId="flexiblePrice"
                            label={ t('component.flexiblePrice') }
                            checked={ values.flexiblePrice }
                            onChange={ (e, { checked }) => setFieldValue('flexiblePrice', checked) }
                            hint={ t('typography.flexibleService') }
                        />

                        { !values.flexiblePrice && (
                            <React.Fragment>
                                <AmountInput
                                    dataTestId="price"
                                    label={ t('tables.price') }
                                    value={ values.price }
                                    error={ touched.price && errors.price }
                                    onChange={ (e, { value }) => setFieldValue('price', value) }
                                    block={ true }
                                    required={ true }
                                    type="decimal"
                                    suffix={ currency }
                                />

                                <Checkbox
                                    dataTestId="byEachPerson"
                                    label={ t('byEachPerson') }
                                    checked={ values.byEachPerson }
                                    onChange={ (e, { checked }) =>
                                        setFieldValue('byEachPerson', checked) }
                                />
                            </React.Fragment>
                        ) }

                        <Textarea
                            dataTestId="description"
                            label={ t('tables.description') }
                            value={ values.description }
                            onChange={ handleChange('description') }
                            block={ true }
                            maxLength={ 400 }
                            counter={ true }
                        />

                        <Textarea
                            dataTestId="notice"
                            label={ t('Заметка') }
                            hint={ t(
                                'Заметки доступны только для сотрудников квеста и не видны игрокам',
                            ) }
                            value={ values.comment }
                            onChange={ handleChange('comment') }
                            block={ true }
                            maxLength={ 400 }
                            counter={ true }
                        />
                    </FlexColumns>

                    <FlexColumns columns={ 1 } gr={ 20 }>
                        <Typography.Title tag="div" view="xxsmall" weight="bold">
                            { t('Достуность услуг') }
                        </Typography.Title>

                        <DaysOfWeek
                            selected={ values.availableDays }
                            onChange={ (days) => setFieldValue('availableDays', days) }
                        />

                        <FlexColumns columns={ 2 } gr={ 16 }>
                            <TimeInput
                                dataTestId="start"
                                label={ t('from') }
                                options={ getOptionForTimeSelect() }
                                onChange={ ({ key }) => setFieldValue('availableFrom', key) }
                                selected={ values.availableFrom }
                                block={ true }
                            />
                            <TimeInput
                                dataTestId="end"
                                label={ t('to') }
                                options={ getOptionForTimeSelect() }
                                onChange={ ({ key }) => setFieldValue('availableTo', key) }
                                selected={ values.availableTo }
                                block={ true }
                            />
                        </FlexColumns>

                        <Checkbox
                            dataTestId="fullPayment"
                            label={ t('Услуга только по предоплате') }
                            checked={ values.fullPayment }
                            onChange={ (e, { checked }) => setFieldValue('fullPayment', checked) }
                        />

                        { !values.flexiblePrice && (
                            <Checkbox
                                dataTestId="crossSale"
                                label={ t('upsellingCrossSale') }
                                checked={ values.crossSale }
                                onChange={ (e, { checked }) => setFieldValue('crossSale', checked) }
                            />
                        ) }

                        <Checkbox
                            dataTestId="allQuestrooms"
                            label={ t('component.forAllQuestrooms') }
                            checked={ values.allQuestrooms }
                            onChange={ (e, { checked }) => setFieldValue('allQuestrooms', checked) }
                        />

                        { !values.allQuestrooms && (
                            <React.Fragment>
                                <Padding padding="8px 0" />
                                { touched.allQuestrooms && (
                                    <Typography.Text color="negative" view="title" weight="medium">
                                        { errors.questroomsIds }
                                    </Typography.Text>
                                ) }
                                <CheckboxGroups
                                    parents={ locations }
                                    childs={ questrooms }
                                    selected={ values.questroomsIds }
                                    onChange={ (value) => setFieldValue('questroomsIds', value) }
                                />
                            </React.Fragment>
                        ) }
                    </FlexColumns>
                    <br />
                </FlexColumns>
            ) }
        </RestForm>
    );
};
