/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { DotsM } from '@alphakits/icons';
import {
    InfoBlockHeader,
    InfoBlockItem,
    InfoBlockWrapper,
    ListAllert,
    Loader,
    ModalContext,
    Padding,
    SidepanelHeader,
} from '@alphakits/ui/dist';
import { FormLoading } from '@alphakits/ui/dist/form/components/form-loading';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { ClientRO } from '@escapenavigator/types/dist/client/client.ro';
import { getFullName } from '@escapenavigator/utils/dist/get-full-name';
import { TFunction } from 'i18next';
import { CertificatesalesList } from 'src/components/certificatesales-list';
import { ClientInfo } from 'src/components/client-info';
import { OrdersList } from 'src/components/orders-list';
import { Picker } from 'src/components/picker';
import { useApi } from 'src/providers/api/context';

import { ClientEditModal } from '../client-edit';
import { DoubleModal, ModalRightSide, SidePanelHeaderWrapper } from '../components/double-modal';

type Props = { recordId?: number; t: TFunction; cb?: (client: ClientRO) => void };

export const ClientModal: React.FC<Props> = ({ recordId, t, cb }) => {
    const { clients } = useApi();
    const { openModal } = useContext(ModalContext);
    const [client, setClient] = useState<ClientRO>();

    const { getOneLoading, getOneFetch } = useApiMethod({
        api: clients.getOne,
        successCallback: ({ data }) => setClient(data),
    });

    const { toggleBlockLoading, toggleBlockFetch } = useApiMethod({
        api: clients.toggleBlock,
        successCallback: ({ data }) => setClient(data),
    });

    useEffect(() => {
        getOneFetch(recordId);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordId]);

    if (getOneLoading || !client) {
        return (
            <DoubleModal>
                <FormLoading />
                <ModalRightSide loading={ true } />
            </DoubleModal>
        );
    }

    const pickerActions = () => ({
        [t('typography.editClient')]: () =>
            openModal(ClientEditModal)({
                recordId: client.id,
                successCallback: (data) => {
                    setClient(data);
                    if (cb) cb(data);
                },
            }),

        [client.blockedDate ? t('unlockClient') : t('blockClient')]: () =>
            toggleBlockFetch(client.id),
    });

    return (
        <DoubleModal>
            <div>
                <Padding padding="0 32px">
                    <SidePanelHeaderWrapper>
                        <SidepanelHeader
                            title={ getFullName(client) }
                            rightAddons={
                                toggleBlockLoading ? (
                                    <Loader />
                                ) : (
                                    <Picker
                                        position="bottom-end"
                                        t={ t }
                                        content={ DotsM }
                                        actions={ pickerActions() }
                                    />
                                )
                            }
                            bottomAddons={
                                client.blockedDate && <ListAllert text={ `${t('clientBlocked')} ` } />
                            }
                        />
                    </SidePanelHeaderWrapper>

                    <OrdersList title={ t('Автор брони') } criteria={ { clientId: recordId } } />

                    <OrdersList
                        title={ t('Участвовал в играх') }
                        criteria={ { participantId: recordId } }
                    />

                    <Padding padding="20px" />

                    <CertificatesalesList criteria={ { clientId: recordId } } />
                </Padding>
            </div>

            <ModalRightSide loading={ getOneLoading }>
                <ClientInfo noOnClick={ true } client={ client } />

                { !!client.previousData?.length && (
                    <InfoBlockWrapper view="bordered">
                        <InfoBlockHeader title={ t('Предыдущие данные клиента') } />

                        { client.previousData.map((data) =>
                            Object.entries(data).map(([field, value]) => (
                                <InfoBlockItem
                                    key={ field + value }
                                    label={ field.toUpperCase() }
                                    value={ value }
                                />
                            ))) }
                    </InfoBlockWrapper>
                ) }
            </ModalRightSide>
        </DoubleModal>
    );
};
