import React from 'react';
import { Image, Typography } from '@alphakits/ui/dist';
import { selectUserById } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

import styles from './index.module.css';

type Props = {
    id: number;
    size?: 's' | 'm';
};

export const UserCell: React.FC<Props> = ({ id, size }) => {
    const user = useAppSelector(selectUserById(id));

    if (!user) return <React.Fragment>Deleted</React.Fragment>;

    return (
        <div className={ styles.user }>
            <Image size={ size } view="ellipse" src={ user.photo || undefined } />
            <Typography.Text weight="medium" view={ size === 'm' ? 'title' : 'caps' }>
                { user.name } { user.surname }
            </Typography.Text>
        </div>
    );
};
