import React, { useCallback, useEffect, useState } from 'react';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CrmVerificationEnum } from '@escapenavigator/types/dist/profile/enum/crm-verifiaction.enum';
import { ProfileSubscriptionTypeEnum } from '@escapenavigator/types/dist/profile/enum/profile-subscription-type.enum';
import { OnboardingStepsRO } from '@escapenavigator/types/dist/profile/onboarding-steps.ro';
import { useApi } from 'src/providers/api/context';

import { useCurrentUser } from '../current-user/context';

export type SubscriptionStatus = 'active' | 'paused' | 'none' | 'not_payed' | 'trialing';

type Context = {
    steps?: OnboardingStepsRO;
    showBellAlert?: boolean;
    removeBellAlert?: () => void;
    loading?: boolean;
    refetchProgress?: (val?: boolean) => void;
};

export const OnboardingContext = React.createContext<Context>({});

export const OnboardingProvider = ({ children }) => {
    const { profile, setProfile } = useCurrentUser();
    const { profiles } = useApi();
    const [steps, setSteps] = useState<OnboardingStepsRO>();
    const [showBellAlert, setShowBellAlert] = useState(false);

    const { getOnboardingStepsFetch, getOnboardingStepsLoading } = useApiMethod({
        api: profiles.getOnboardingSteps,
        successCallback: ({ data }) => {
            setProfile(data.profile);
            setSteps(data);
        },
    });

    useEffect(() => {
        if (profile.crmVerification === CrmVerificationEnum.SUCCEED) return;

        setShowBellAlert(true);
    }, [steps, profile.crmVerification]);

    const refetchProgress = useCallback(
        (force?: boolean) => {
            const { subscriptionType, crmVerification } = profile;

            if (
                !force &&
                (subscriptionType === ProfileSubscriptionTypeEnum.NONE ||
                    crmVerification === CrmVerificationEnum.SUCCEED)
            ) {
                return;
            }

            getOnboardingStepsFetch(undefined);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [profile.crmVerification, profile.subscriptionType],
    );

    useEffect(() => {
        refetchProgress();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <OnboardingContext.Provider
            value={ {
                loading: getOnboardingStepsLoading,
                showBellAlert,
                removeBellAlert: () => setShowBellAlert(false),
                refetchProgress,
                steps,
            } }
        >
            { children }
        </OnboardingContext.Provider>
    );
};

export const useOnboarding = () => React.useContext(OnboardingContext);
