/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { PlusS, TrashM } from '@alphakits/icons';
import {
    AmountInput, Button, FlexColumns, IconButton, ListAllert,
} from '@alphakits/ui/dist';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { TFunction } from 'i18next';

import styles from './index.module.css';

export interface TariffBuilderProps {
    price: number[];
    currency: ProfileCurrencyEnum;
    onChange: (price: number[]) => void;
    error?: string;
    t: TFunction;
}

export const NominalsBuilder = ({
    price = [0],
    onChange,
    error,
    t,
    currency,
}: TariffBuilderProps) => {
    const [prices, setPrices] = useState(price);

    useEffect(() => {
        onChange(prices);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prices]);

    return (
        <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
            <FlexColumns columns={ 2 } gr={ 16 } gc={ 16 }>
                { prices.map((p, index) => (
                    <AmountInput
                        dataTestId={ `price_${index}` }
                        value={ +p }
                        type="decimal"
                        suffix={ currency }
                        onChange={ (e, { value }) => {
                            prices[index] = value;
                            setPrices([...prices]);
                        } }
                        required={ true }
                        block={ true }
                        rightAddons={ (
                            <IconButton
                                icon={ TrashM }
                                onClick={ () => {
                                    prices.splice(index, 1);
                                    setPrices([...prices]);
                                } }
                            />
                        ) }
                    />
                )) }
            </FlexColumns>

            <Button
                onClick={ () => setPrices([...prices, 0]) }
                size="xs"
                view="ghost"
                leftAddons={ <PlusS /> }
            >
                { t('Добавить вариант цены') }
            </Button>

            { !!error && <ListAllert className={ styles.allert } text={ error } view="negative" /> }
        </FlexColumns>
    );
};
