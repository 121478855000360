import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EditS, PlusS } from '@alphakits/icons';
import {
    Button,
    FlexColumns,
    ModalContext,
    showError,
    Sidepanel,
    SuperListItem,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { useApi } from 'src/providers/api/context';

import { EditScenarioModal } from './edit';

type Props = {
    questroomId: number;
};

export const ScenarioEmailsModal: React.FC<Props> = ({ questroomId }) => {
    const { t } = useTranslation();
    const { openModal } = useContext(ModalContext);

    const { questroomScenarioEmailApi } = useApi();

    const { queryData, queryFetch, queryLoading } = useApiMethod({
        api: questroomScenarioEmailApi.query,
        errorCallback: (err) => showError(err.message),
    });

    useEffect(() => {
        queryFetch({ questroomId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Sidepanel
            title={ t('scenarioEmails') }
            subtitle={ t('scenarioEmailsDescription') }
            loading={ queryLoading }
            headerBottomAddons={ (
                <Button
                    onClick={ () =>
                        openModal(
                            EditScenarioModal,
                            'm',
                        )({ questroomId, cb: () => queryFetch({ questroomId }) }) }
                    view="outlined"
                    size="xs"
                    leftAddons={ <PlusS /> }
                >
                    { t('Добавить имеил') }
                </Button>
            ) }
        >
            <FlexColumns columns={ 1 }>
                { queryData?.map((s) => (
                    <SuperListItem
                        text={ s.title }
                        rightAddons={ (
                            <Button
                                onClick={ () =>
                                    openModal(
                                        EditScenarioModal,
                                        'm',
                                    )({
                                        record: s,
                                        questroomId,
                                        cb: () => queryFetch({ questroomId }),
                                    }) }
                                view="outlined"
                                size="xs"
                                leftAddons={ <EditS /> }
                            />
                        ) }
                        weight="bold"
                    />
                )) }
            </FlexColumns>
        </Sidepanel>
    );
};
