import { initClientApi } from '@escapenavigator/services/dist/api/init-client-api';
import { LocationRO } from '@escapenavigator/types/dist/location/location.ro';
import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';

import { RootState } from '../store';

const locationsAdapter = createEntityAdapter<LocationRO>();
const { locations } = initClientApi(process.env.REACT_APP_API_DOMAIN);

export const fetchLocations = createAsyncThunk<LocationRO[]>(
    'locations/fetchLocations',
    async () => {
        const { data } = await locations.query(undefined);

        return data;
    },
);

const locationsSlice = createSlice({
    name: 'locations',
    initialState: locationsAdapter.getInitialState(),
    reducers: {
        upsertLocation(state, action: PayloadAction<LocationRO>) {
            locationsAdapter.upsertOne(state, action.payload);
        },
        removeLocation(state, action: PayloadAction<LocationRO['id']>) {
            locationsAdapter.removeOne(state, action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchLocations.fulfilled, (state, action: PayloadAction<LocationRO[]>) => {
            locationsAdapter.addMany(state, action.payload);
        });
    },
});

const { selectAll, selectById } =
    locationsAdapter.getSelectors<RootState>((state) => state.locations);

export const selectAllLocations = selectAll;

export const selectLocationById = (id) =>
    createSelector(
        (state: RootState) => state,
        (state) => selectById(state, id) || ({ title: 'Removed location', id } as LocationRO),
    );

export const { upsertLocation, removeLocation } = locationsSlice.actions;

export default locationsSlice.reducer;
