import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { EyeS } from '@alphakits/icons';
import {
    Flex,
    formatDate,
    IconButton,
    ModalContext,
    Status,
    SuperListItem,
} from '@alphakits/ui/dist';
import { ProfileEmailCardRO } from '@escapenavigator/types/dist/profile/email/profile-email-card.ro';
import { ViewEmail } from 'src/modals/view-email';

type Props = {
    email: ProfileEmailCardRO;
};

export const LogEmail: React.FC<Props> = ({ email }) => {
    const { openModal } = useContext(ModalContext);
    const { i18n } = useTranslation();

    return (
        <React.Fragment>
            <SuperListItem
                text={ email.to }
                hint={ formatDate(new Date(email.createdAt), {
                    lang: i18n.language,
                }) }
            />
            <Flex>
                <Status color="blue" size="s" view="contrast">
                    { email.status }
                </Status>

                <IconButton
                    icon={ EyeS }
                    view="secondary"
                    onClick={ () => openModal(ViewEmail, 'm')({ profileEmailId: email.id }) }
                />
            </Flex>
        </React.Fragment>
    );
};
