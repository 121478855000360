import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronBackThinM, ChevronForwardThinM } from '@alphakits/icons';
import {
    Box, Button, Flex, FlexColumns, formatDate, Image, Typography,
} from '@alphakits/ui/dist';
import {
    addMonths, endOfMonth, isSameMonth, startOfMonth, subMonths,
} from 'date-fns';

export type MonthlyReportProps = {
    children?: React.ReactNode;
    onChange: (props: { startDate: Date; endDate: Date }) => void;
};

export const MonthlyReport: React.FC<MonthlyReportProps> = ({ children, onChange }) => {
    const { i18n } = useTranslation();

    const [query, setQuery] = useState({ date: new Date(), view: 'month' as const });

    const canShowNextDate = useMemo(() => {
        if (query.view === 'month') {
            return isSameMonth(query.date, new Date());
        }

        return false;
    }, [query]);

    const fullQuery = useMemo(
        () => ({
            startDate: startOfMonth(query.date),
            endDate: endOfMonth(query.date),
        }),
        [query],
    );

    useEffect(() => {
        onChange(fullQuery);
    }, [fullQuery, onChange]);

    const next = () => {
        if (query.view) {
            setQuery({ ...query, date: addMonths(query.date, 1) });
        }
    };

    const prev = () => {
        if (query.view) {
            setQuery({ ...query, date: subMonths(query.date, 1) });
        }
    };

    return (
        <Box background="var(--color-bg-secondary)" rounded="xs" padding="l">
            <FlexColumns columns={ 1 } gr={ 8 }>
                <Flex>
                    <Typography.Title tag="div" view="xsmall" weight="bold" color="primary">
                        { formatDate(query.date, { format: 'LLLL', lang: i18n.language }) }
                    </Typography.Title>

                    <Flex gap="sm" justify="end">
                        <Button onClick={ prev } view="ghost">
                            <Image
                                bgColor="primary"
                                size="s"
                                icon={ <ChevronBackThinM /> }
                                view="circle"
                            />
                        </Button>

                        <Button onClick={ next } view="ghost" disabled={ canShowNextDate }>
                            <Image
                                size="s"
                                bgColor={ canShowNextDate ? 'secondary' : 'primary' }
                                icon={ <ChevronForwardThinM /> }
                                view="circle"
                            />
                        </Button>
                    </Flex>
                </Flex>
                <Flex justify="start" gap="sm">
                    { children }
                </Flex>
            </FlexColumns>
        </Box>
    );
};
