import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    CalendarInput,
    FlexColumns,
    formatDate,
    ListAllert,
    useCrudFormRequests,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { CertificateSaleRO } from '@escapenavigator/types/dist/certificate-sale/certificate-sale.ro';
import { UpdateCertificateSaleExpireDateDto } from '@escapenavigator/types/dist/certificate-sale/update-certificate-sale-expire-date.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { differenceInDays } from 'date-fns';
import { useApi } from 'src/providers/api/context';

type Props = {
    close: () => void;
    setCertificateSale: (values: CertificateSaleRO) => void;
    certificatesale: CertificateSaleRO;
};

export const DateChange: React.FC<Props> = ({ close, certificatesale, setCertificateSale }) => {
    const { certificatesales } = useApi();
    const { t, i18n } = useTranslation();

    const { save, updating } = useCrudFormRequests({
        recordId: certificatesale.id,
        saveRequest: certificatesales.updateExpireDate,
        close,
        saveCallback: (row) => setCertificateSale(row),
    });

    const daysBeforeExpired = differenceInDays(new Date(certificatesale.expireDate), new Date());

    const allertText = `${t('daysBeforeCertificateExpired', { count: daysBeforeExpired })} ${t(
        'days',
        { count: daysBeforeExpired },
    )}`;

    return (
        <RestForm
            title={ t('component.certificateValidity') }
            initialValues={ serializeRecord(UpdateCertificateSaleExpireDateDto, {}) }
            validate={ validateByDto(t) }
            save={ save }
            loading={ false }
            updating={ updating }
            removing={ false }
            close={ close }
            t={ t }
        >
            { ({
                values, setFieldValue, errors, touched,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    <ListAllert text={ allertText } />

                    <CalendarInput
                        label={ t('Старая дата') }
                        value={ formatDate(certificatesale.expireDate, {
                            lang: i18n.language,
                            format: 'dd.MM.yyyy',
                        }) }
                        size="m"
                        lang=""
                        disabled={ true }
                        block={ true }
                    />

                    <CalendarInput
                        label={ t('Новая дата') }
                        value={ values.expireDate }
                        // value={ formatDate(values.expireDate, { lang: i18n.language, format: 'dd.MM.yyyy' }) }
                        maxDate={ Date.now() + 1000 * 60 * 60 * 24 * 365 * 4 }
                        minDate={ Date.now() - 1000 * 60 * 60 * 24 * 7 }
                        size="m"
                        lang={ i18n.language }
                        error={ touched.expireDate && errors.expireDate }
                        onChange={ (e, { value }) => setFieldValue('expireDate', value) }
                        block={ true }
                    />
                </FlexColumns>
            ) }
        </RestForm>
    );
};
