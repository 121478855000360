import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlexColumns } from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { SlotRO } from '@escapenavigator/types/dist/slot/slot.ro';
import { UpdateSlotDto } from '@escapenavigator/types/dist/slot/update-slot.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TimeInput } from 'src/components/time-input';
import { useApi } from 'src/providers/api/context';

import { getOptionForTimeSelect } from '../autocomplite/utils';

type Props = {
    close: () => void;
    callback: () => void;
    record: SlotRO;
};

export const UpdateSingleSlotModal: React.FC<Props> = ({ close, callback, record }) => {
    const { t } = useTranslation();

    const { slots } = useApi();

    const { updateSlotLoading, updateSlotError, updateSlotFetch } = useApiMethod({
        api: slots.updateSlot,
        successCallback: () => {
            close();
            if (callback) callback();
        },
    });

    const handleSubmit = async (values: UpdateSlotDto) => {
        updateSlotFetch({
            id: record.id,
            data: {
                start: values.start,
            },
        });
    };

    return (
        <RestForm
            title={ `${t('Создание слота')}` }
            save={ handleSubmit }
            close={ close }
            t={ t }
            validate={ validateByDto(t) }
            initialValues={ serializeRecord(UpdateSlotDto, {
                start: record.start,
            }) }
            softError={ updateSlotError?.message }
            loading={ updateSlotLoading }
        >
            { ({
                values, setFieldValue, touched, errors,
            }) => (
                <FlexColumns columns={ 1 } gr={ 32 }>
                    <TimeInput
                        label={ t('Время начала игры') }
                        block={ true }
                        options={ getOptionForTimeSelect() }
                        selected={ values.start }
                        error={ touched.start && errors.start }
                        onChange={ ({ key }) => setFieldValue('start', key) }
                    />
                </FlexColumns>
            ) }
        </RestForm>
    );
};
