import React from 'react';
import {
    Box, Flex, FlexColumns, Image, Skeleton, Tooltip, Typography,
} from '@alphakits/ui/dist';

import styles from './index.module.css';

type Props = {
    loading?: boolean;
    title: string;
    description: string;
    isSoon?: boolean;
    fullWidth?: boolean;
    image: string;
};
export const IntegrationCard: React.FC<Props> = ({
    loading,
    description,
    title,
    isSoon,
    image,
    fullWidth,
    children,
}) => (
    <Skeleton visible={ loading }>
        <div style={ { opacity: isSoon ? 0.5 : 1 } }>
            <Box width={ fullWidth ? undefined : 260 } padding="ml" rounded="md" border={ true }>
                <FlexColumns columns={ 1 } gr={ 16 }>
                    <div>
                        <Image className={ styles.img } src={ image } size="l" view="circle" />
                    </div>
                    <FlexColumns columns={ 1 } gr={ 4 }>
                        <Typography.Title tag="div" view="xxsmall" weight="bold" color="primary">
                            { title }
                        </Typography.Title>

                        <Tooltip
                            content={
                                <div style={ { width: 300, overflow: 'auto' } }>{ description }</div>
                            }
                            trigger="hover"
                            position="top"
                        >
                            <div>
                                <Typography.Text
                                    className={ styles.description }
                                    view="caps"
                                    color="secondary"
                                >
                                    { description }
                                </Typography.Text>
                            </div>
                        </Tooltip>
                    </FlexColumns>

                    { children && <Flex className={ styles.children }>{ children }</Flex> }
                </FlexColumns>
            </Box>
        </div>
    </Skeleton>
);
