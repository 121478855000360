import React, { useCallback, useState } from 'react';
import {
    AmountInput, Flex, FlexColumns, Tag, Textarea, ToastPlate,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CreateOrderDiscountDto } from '@escapenavigator/types/dist/order-discount/create-order-discount.dto';
import { OrderRO } from '@escapenavigator/types/dist/order/order.ro';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { formatAmount } from '@escapenavigator/utils/dist/format-amount';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

type Props = {
    t: TFunction;
    close: () => void;
    setOrder: (order: OrderRO) => void;
    order: OrderRO;
    currency: ProfileCurrencyEnum;
    amount?: number;
};
type DiscountType = 'fixed' | 'percent';
const discounts = ['fixed' as const, 'percent' as const];

export const AddHandDiscount: React.FC<Props> = ({
    close,
    t,
    order,
    setOrder,
    currency,
    amount = 0,
}) => {
    const { orderDiscounts } = useApi();
    const [discountType, setDiscountType] = useState<DiscountType>('fixed');
    const [discount, setDiscount] = useState(0);

    const { createFetch, createLoading, createError } = useApiMethod({
        api: orderDiscounts.create,
        successCallback: ({ data }) => {
            setOrder(data);
            close();
        },
    });

    const discountAmount = useCallback(
        (value: number) => {
            if (discountType === 'fixed') return 0;

            return Math.ceil((order.total * value) / 10000);
        },
        [order, discountType],
    );

    return (
        <RestForm
            t={ t }
            title={ t('Ручная скидка') }
            initialValues={ serializeRecord(CreateOrderDiscountDto, {
                orderId: order.id,
                amount,
            }) }
            validate={ validateByDto(t) }
            loading={ false }
            updating={ createLoading }
            removing={ false }
            close={ close }
            save={ async (data) => {
                const reason =
                    discountType === 'percent' ? `${data.reason} (${discount}%)` : data.reason;

                createFetch({ data: { ...data, reason } });
            } }
        >
            { ({
                values, handleChange, errors, touched, setFieldValue,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    <Flex gap="sm" justify="start">
                        { discounts.map((l) => (
                            <Tag
                                key={ l }
                                view={ discountType === l ? 'primary-inverted' : 'primary' }
                                onClick={ () => {
                                    setDiscountType(l);
                                    setFieldValue('amount', 0);
                                    setDiscount(0);
                                } }
                                text={
                                    l === 'fixed'
                                        ? t('options.crosssaleType.fixed')
                                        : t('options.crosssaleType.percent')
                                }
                            />
                        )) }
                    </Flex>
                    { discountType === 'fixed' ? (
                        <AmountInput
                            value={ values.amount }
                            suffix={ currency }
                            error={ touched.amount && errors.amount }
                            onChange={ (e, { value }) => setFieldValue('amount', value) }
                            label={ t('Укажите значение ручной скидки') }
                            required={ true }
                            block={ true }
                            type="decimal"
                        />
                    ) : (
                        <AmountInput
                            value={ discount }
                            max={ 100 }
                            suffix="%"
                            error={ touched.amount && errors.amount }
                            onChange={ (e, { value }) => {
                                setDiscount(value);
                                setFieldValue('amount', discountAmount(value));
                            } }
                            label={ t('Укажите значение ручной скидки') }
                            required={ true }
                            hint={ t('ручная скидка в процентах', {
                                value: formatAmount(values.amount, currency),
                            }) }
                            type="decimal"
                            block={ true }
                        />
                    ) }

                    <Textarea
                        value={ values.reason }
                        maxLength={ 100 }
                        counter={ true }
                        required={ true }
                        error={ touched.reason && errors.reason }
                        onChange={ handleChange('reason') }
                        label={ t('Причина') }
                        block={ true }
                    />

                    { createError && (
                        <ToastPlate view="negative" title="Error">
                            { createError.message }
                        </ToastPlate>
                    ) }
                </FlexColumns>
            ) }
        </RestForm>
    );
};
