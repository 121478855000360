import React from 'react';
import { useTranslation } from 'react-i18next';
import { SlotTemplateElement } from '@escapenavigator/types/dist/slot-template/slot-template-element';
import { Picker } from 'src/components/picker';

import { SerializedTemplate } from '.';

type Props = {
    view?: 'secondary' | 'outlined';
    templates: SerializedTemplate[];
    onChange: (slots: SlotTemplateElement[]) => void;
};

export const CopyTemplatePicker: React.FC<Props> = ({
    templates = [],
    view = 'outlined',
    onChange,
}) => {
    const { t } = useTranslation();

    return (
        <Picker
            t={ t }
            view={ view }
            position="bottom-start"
            content={ t('Скопировать шаблон') }
            actions={
                templates.length &&
                Object.fromEntries(
                    templates.map((s) => [s.questroom?.title, () => onChange(s.schedule.slots)]),
                )
            }
            options={
                !templates.length && [
                    {
                        key: 1,
                        content: t('Нет доступных шаблонов'),
                    },
                ]
            }
        />
    );
};
