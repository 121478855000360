import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    AmountInput, FlexColumns, ListAllert, Select, Typography,
} from '@alphakits/ui/dist';
import { TariffRO } from '@escapenavigator/types/dist/tariff/tariff.ro';
import { useCurrentUser } from 'src/providers/current-user/context';

import { makePrice, selectMaxOptions, selectMinOptions } from './utils';

import styles from './index.module.css';

export interface TariffBuilderProps {
    price: TariffRO['price'];
    onChange: (price: TariffRO['price']) => void;
    error?: string;
}

export const TariffBuilder = ({
    price = {
        2: 0,
        3: 0,
        4: 0,
        5: 0,
    },
    onChange,
    error,
}: TariffBuilderProps) => {
    const { t } = useTranslation();
    const [min, max] = useMemo(() => {
        const entries = Object.entries(price);

        return [+entries[0][0], +entries[entries.length - 1][0]];
    }, [price]);

    const {
        profile: { currency },
    } = useCurrentUser();

    return (
        <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
            <FlexColumns columns={ 1 } gr={ 8 } gc={ 0 }>
                <Typography.Text view="title" weight="medium">
                    { t('Кол-во игроков') }
                </Typography.Text>
                <FlexColumns columns={ 2 } gr={ 16 } gc={ 16 }>
                    <Select
                        dataTestId="min_players"
                        label={ t('from') }
                        block={ true }
                        options={ selectMinOptions(max, t) }
                        selected={ +min }
                        onChange={ ({ selected: { key } }) => {
                            onChange(
                                makePrice({
                                    minPlayers: +key,
                                    maxPlayers: max,
                                    price,
                                }),
                            );
                        } }
                    />
                    <Select
                        dataTestId="max_players"
                        label={ t('to') }
                        options={ selectMaxOptions(min, t) }
                        selected={ +max }
                        block={ true }
                        onChange={ ({ selected: { key } }) => {
                            onChange(
                                makePrice({
                                    minPlayers: min,
                                    maxPlayers: +key,
                                    price,
                                }),
                            );
                        } }
                    />
                </FlexColumns>
            </FlexColumns>

            <FlexColumns columns={ 3 } gr={ 16 } gc={ 16 }>
                { Object.entries(price).map(([ppl, amount]) => (
                    <AmountInput
                        type="decimal"
                        dataTestId={ `price_${ppl}` }
                        key={ ppl }
                        label={ `${ppl} ${t('ppl')}` }
                        value={ Number(amount) }
                        suffix={ currency }
                        onChange={ (e, { value }) => {
                            const v = +value || 0;
                            const x: string = ppl;

                            onChange({ ...price, [x]: v });
                        } }
                        required={ true }
                        block={ true }
                    />
                )) }
            </FlexColumns>

            { !!error && <ListAllert className={ styles.allert } text={ error } view="negative" /> }
        </FlexColumns>
    );
};
