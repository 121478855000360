import React, { useMemo } from 'react';
import { ChevronForwardS } from '@alphakits/icons';
import {
    AmountInput,
    Button,
    convertToOptions,
    Flex,
    FlexColumns,
    Input,
    Select,
    Typography,
    useCrudFormRequests,
    useGetOne,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CashboxTypeEnum } from '@escapenavigator/types/dist';
import { CreateOnlineCashboxDto } from '@escapenavigator/types/dist/cashbox/create-online-cashbox.dto';
import { UpdateOnlineCashboxDto } from '@escapenavigator/types/dist/cashbox/update-online-cashbox.dto';
import { PrepaymentTypeEnum } from '@escapenavigator/types/dist/slot/enum/prepayment-type.enum';
import { enumToOptions, serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { ImagesGroup } from 'src/components/selects/base/images-group';
import { QuestroomsSelect } from 'src/components/selects/questrooms-select';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { useOnboarding } from 'src/providers/ongoarding/context';
import {
    addCashbox,
    fetchCertificates,
    fetchQuestrooms,
    removeCashbox,
    selectAllCertificates,
    selectAllQuestrooms,
} from 'src/store';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';

type Props = {
    close: () => void;
    t: TFunction;
    recordId?: number;
};

export const CashboxOnlineModal: React.FC<Props> = ({ recordId, close, t }) => {
    const dispatch = useAppDispatch();

    const { current } = useCurrentUser();

    const { cashboxes } = useApi();

    const questrooms = useAppSelector(
        selectAllQuestrooms({
            deleted: false,
            closed: false,
        }),
    );

    const { refetchProgress } = useOnboarding();

    const certificates = useAppSelector(selectAllCertificates);

    const { record, loading, error } = useGetOne(recordId, cashboxes.getOne);

    const { getVerificationLinkFetch, getVerificationLinkLoading } = useApiMethod({
        api: cashboxes.getVerificationLink,
        successCallback: ({ data }) => {
            window.location.href = data;
        },
    });

    const {
        save,
        updating,
        remove,
        error: softError,
    } = useCrudFormRequests({
        recordId,
        removeRequest: recordId && cashboxes.remove,
        removeCallback: (removed) => {
            dispatch(removeCashbox(removed.id));
            dispatch(fetchQuestrooms());
            dispatch(fetchCertificates());
        },
        saveRequest: recordId ? cashboxes.updateOnline : cashboxes.createOnline,
        saveCallback: (savedRecord) => {
            dispatch(addCashbox(savedRecord));
            dispatch(fetchQuestrooms());
            dispatch(fetchCertificates());
            refetchProgress();
        },
        close,
    });

    const initialIds = useMemo(() => {
        const type = record?.type || CashboxTypeEnum.PAYPAL;
        const row = type === CashboxTypeEnum.PAYPAL ? 'paypalCashbox' : 'onlinePaymentsCashbox';

        const filter = record?.id ? (q) => q[row] === record.id : (q) => !q[row];

        return {
            questroomsIds: questrooms.filter(filter).map((q) => q.id),
            certificatesIds: certificates.filter(filter).map((q) => q.id),
        };
    }, [record, questrooms, certificates]);

    return (
        <RestForm
            recordId={ +recordId }
            title={ record?.type === CashboxTypeEnum.STRIPE ? 'Stripe wallet' : 'PayPal wallet' }
            headerBottomAddons={
                record?.id && (
                    <Flex direction="column" gap="md" align="start" justify="start">
                        <Typography.Text view="title">
                            { record?.type === CashboxTypeEnum.STRIPE ? 'Stripe ID' : 'PayPal email' }{ ' ' }
                            { record.identificator }
                        </Typography.Text>

                        { record?.type === CashboxTypeEnum.STRIPE && (
                            <Button
                                view="ghost"
                                size="xs"
                                rightAddons={ <ChevronForwardS /> }
                                loading={ getVerificationLinkLoading }
                                onClick={ () => getVerificationLinkFetch(record?.id) }
                            >
                                { t('Верифицировать Stirpe кошелек') }
                            </Button>
                        ) }
                    </Flex>
                )
            }
            initialValues={ serializeRecord(CreateOnlineCashboxDto, {
                type: record.type || CashboxTypeEnum.PAYPAL,
                increaseType: PrepaymentTypeEnum.NO,
                ...initialIds,
                ...record,
                title: record?.id ? record.title : 'PayPal',
            }) }
            validate={ validateByDto(t) }
            save={ (values) =>
                save(recordId ? serializeRecord(UpdateOnlineCashboxDto, values) : values) }
            error={ error }
            remove={ remove }
            softError={ softError }
            loading={ loading }
            updating={ updating }
            close={ close }
            t={ t }
        >
            { ({
                values, handleChange, errors, touched, setFieldValue,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    { values.type === CashboxTypeEnum.PAYPAL && !record?.id && (
                        <Input
                            disabled={ !!record?.id }
                            dataTestId="identificator"
                            label="Paypal Email"
                            value={ values.identificator }
                            error={ touched.identificator && errors.identificator }
                            onChange={ handleChange('identificator') }
                            block={ true }
                        />
                    ) }

                    { !!record?.id && (
                        <Input
                            dataTestId="title"
                            label={ t('tables.title') }
                            value={ values.title }
                            error={ touched.title && errors.title }
                            onChange={ handleChange('title') }
                            block={ true }
                        />
                    ) }

                    <QuestroomsSelect
                        label="Escape rooms"
                        questrooms={ questrooms }
                        selected={ values.questroomsIds }
                        multiple={ true }
                        onChange={ ({ selectedMultiple }) =>
                            setFieldValue(
                                'questroomsIds',
                                selectedMultiple.map((i) => i.key),
                            ) }
                    />

                    <Select
                        label={ t('Сертификаты') }
                        options={ convertToOptions(certificates) }
                        selected={ values.certificatesIds }
                        allowUnselect={ true }
                        valueRenderer={ () => {
                            if (!values.certificatesIds?.length) return null;

                            return `${t('Выбрано')} ${values.certificatesIds?.length}`;
                        } }
                        fieldProps={ {
                            rightAddons: (
                                <ImagesGroup
                                    images={ certificates.filter((c) =>
                                        values.certificatesIds.includes(c.id)) }
                                />
                            ),
                        } }
                        multiple={ true }
                        onChange={ ({ selectedMultiple = [] }) =>
                            setFieldValue(
                                'certificatesIds',
                                selectedMultiple.map((i) => i.key),
                            ) }
                    />

                    <FlexColumns columns={ PrepaymentTypeEnum.NO === values.increaseType ? 1 : 2 }>
                        <Select
                            dataTestId="increaseType"
                            label={ t('Наценка при онлайн-оплате') }
                            options={ enumToOptions(PrepaymentTypeEnum, t, 'prepayment') }
                            onChange={ ({ selected }) =>
                                setFieldValue('increaseType', selected?.key) }
                            selected={ values.increaseType }
                            block={ true }
                        />

                        { PrepaymentTypeEnum.NO !== values.increaseType && (
                            <AmountInput
                                type="decimal"
                                dataTestId="increase"
                                value={ values.increase }
                                minority={ 1 }
                                suffix={
                                    PrepaymentTypeEnum.PERCENT === values.increaseType
                                        ? '%'
                                        : current.extra.currency
                                }
                                error={ touched.increase && errors.increase }
                                onChange={ (e, { value }) => setFieldValue('increase', value) }
                                block={ true }
                            />
                        ) }
                    </FlexColumns>
                </FlexColumns>
            ) }
        </RestForm>
    );
};
