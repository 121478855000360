import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button, FlexColumns, Input, ListAllert, Typography,
} from '@alphakits/ui/dist';
import { BaseForm } from '@alphakits/ui/dist/form/templates/base-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { ProfileStatusEnum } from '@escapenavigator/types/dist/profile/enum/profile-step.enum';
import { CompliteSetupDto } from '@escapenavigator/types/dist/user/setup/complite-setup.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils';
import { Logo } from 'src/components/layout/logo';
import { StepperContainer } from 'src/components/stepper-container';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { upsertUser } from 'src/store';
import { useAppDispatch } from 'src/store/hooks';

export const VerifyUser: React.FC = () => {
    const { t } = useTranslation();
    const { profiles } = useApi();
    const {
        profile, setProfile, current, setUser,
    } = useCurrentUser();

    const { updateStepFetch } = useApiMethod({
        api: profiles.updateStep,
        successCallback: ({ data }) => {
            setProfile(data);

            window.scrollTo({ top: 0 });
        },
    });

    const dispatch = useAppDispatch();

    const { users } = useApi();

    const { compliteSetupFetch, compliteSetupLoading, compliteSetupError } = useApiMethod({
        api: users.compliteSetup,
        successCallback: ({ data }) => {
            setUser({ ...current, user: data });
            dispatch(upsertUser(data));
        },
    });

    const insideError = compliteSetupError?.message;

    useEffect(() => {
        if (profile.status !== ProfileStatusEnum.VERIFIED) {
            updateStepFetch(ProfileStatusEnum.VERIFICATION_USER_DATA);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StepperContainer>
            <FlexColumns columns={ 1 } gr={ 32 }>
                <Logo />
                <FlexColumns columns={ 1 } gr={ 16 }>
                    <Typography.Title tag="div" view="xsmall" weight="bold">
                        { t('completeInvite.title') }
                    </Typography.Title>
                    <Typography.Text view="primary-medium">
                        { t('completeInvite.description') }
                    </Typography.Text>
                </FlexColumns>

                <BaseForm
                    save={ (data) => compliteSetupFetch({ data }) }
                    validate={ validateByDto(t) }
                    initialValues={ serializeRecord(CompliteSetupDto, current.user) }
                    loading={ compliteSetupLoading }
                    t={ t }
                >
                    { ({
                        values, handleChange, touched, errors, submitForm,
                    }) => (
                        <FlexColumns columns={ 1 } gr={ 24 }>
                            <FlexColumns columns={ 1 } gr={ 16 }>
                                <FlexColumns columns={ 2 } gr={ 16 }>
                                    <Input
                                        label={ t('name') }
                                        value={ values.name }
                                        onChange={ handleChange('name') }
                                        error={ touched.name && errors.name }
                                        block={ true }
                                    />

                                    <Input
                                        label={ t('surname') }
                                        value={ values.surname }
                                        onChange={ handleChange('surname') }
                                        error={ touched.surname && errors.surname }
                                        block={ true }
                                    />
                                </FlexColumns>

                                <Input
                                    label="Email"
                                    value={ values.email }
                                    onChange={ handleChange('email') }
                                    error={ touched.email && errors.email }
                                    block={ true }
                                />

                                <Input
                                    label={ t('Придумайте пароль') }
                                    value={ values.password }
                                    type="password"
                                    onChange={ handleChange('password') }
                                    error={ touched.password && errors.password }
                                    block={ true }
                                />
                            </FlexColumns>

                            { insideError && <ListAllert view="negative" text={ insideError } /> }

                            <Button view="primary" onClick={ submitForm }>
                                { t('Далее') }
                            </Button>
                        </FlexColumns>
                    ) }
                </BaseForm>
            </FlexColumns>
        </StepperContainer>
    );
};
