import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@alphakits/ui/dist';
import { TextProps } from '@alphakits/ui/dist/typography/text';

import styles from './index.module.css';

type Props = {
    text: string;
} & Pick<TextProps, 'color' | 'view'>;

export const CollapsedTypography: React.FC<Props> = ({
    text,
    color = 'primary',
    view = 'primary-small',
}) => {
    const [show, setShow] = useState(false);
    const { t } = useTranslation();
    const allowShow = text.length > 250;

    const value = show ? text : `${text.slice(0, 220)}...`;

    return (
        <Typography.Text html={ true } color={ color } view={ view }>
            { allowShow ? value : text }
            { allowShow && (
                <button className={ styles.button } type="button" onClick={ () => setShow(!show) }>
                    { show ? t('Скрыть') : t('Показать') }
                </button>
            ) }
        </Typography.Text>
    );
};
