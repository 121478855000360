import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Sidepanel, Table } from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { GoogleCalendarRO } from '@escapenavigator/types/dist/google-calendar/google-calendar.ro';
import { useApi } from 'src/providers/api/context';
import { getLanguage } from 'src/utils/get-language';

import { CalendarColumns } from './columns';

type Props = {
    calendars: GoogleCalendarRO[];
    close: () => void;
    cb: () => void;
    fetchLink: () => void;
};

export const GoogleCalendarEditModal: React.FC<Props> = ({
    calendars, close, cb, fetchLink,
}) => {
    const { googleCalendarsApi } = useApi();
    const { t, i18n } = useTranslation();
    const { removeLoading, removeFetch } = useApiMethod({
        api: googleCalendarsApi.remove,
        successCallback: () => {
            close();
            cb();
        },
    });
    const columns = CalendarColumns({ removeFetch });

    return (
        <Sidepanel
            title="Google calendars"
            headerRightAddons={ (
                <Button view="outlined" size="xs" onClick={ () => fetchLink() }>
                    { t('Добавить') }
                </Button>
            ) }
        >
            <Table.TableComponent
                columns={ columns }
                hideSearch={ true }
                height="default"
                language={ getLanguage(i18n) }
                records={ calendars }
                loading={ removeLoading }
            />
        </Sidepanel>
    );
};
