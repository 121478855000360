import React from 'react';
import { Typography } from '@alphakits/ui/dist';
import { StatusProps } from '@alphakits/ui/dist/status';
import cn from 'classnames';

import styles from './index.module.css';

type LeftAddon =
    | {
        leftAddon?: never;
        icon: React.ReactNode;
    }
    | {
        leftAddon: React.ReactNode;
        icon?: never;
    };

export type CalendarSlotProps = {
    color: StatusProps['color'] | 'white';
    past?: boolean;
    view?: 'compact' | 'default' | 'mobile';
    rightAddon: React.ReactNode;
    onClick: () => void;
    title: string;
    subtitle: string | React.ReactNode;
} & LeftAddon;

export const CalendarSlot = ({
    color = 'white',
    past,
    view = 'default',
    rightAddon,
    onClick,
    icon,
    leftAddon,
    title,
    subtitle,
}: CalendarSlotProps) => (
    <div
        onClick={ onClick }
        role="button"
        aria-hidden="true"
        className={ cn(styles.slot_wrapper, styles[`${color}`], styles[view], {
            [styles.past]: past,
        }) }
    >
        <div className={ styles.content }>
            { !!icon && <div className={ styles.icon }>{ icon }</div> }

            { !!leftAddon && <div className={ cn(styles.leftAddon, styles.icon) }>{ leftAddon }</div> }

            <div className={ cn(styles.content_text) } title={ typeof subtitle === 'string' && subtitle }>
                <Typography.Text view="title" weight="medium" color="primary">
                    { title }
                </Typography.Text>

                <Typography.Text color="secondary" view="caps">
                    { subtitle }
                </Typography.Text>
            </div>
        </div>

        <div className={ cn(styles.addon_wrapper, styles[`addon_${color}`]) }>{ rightAddon }</div>
    </div>
);
