import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Avatar, Flex, Loader, SelectProps, Typography,
} from '@alphakits/ui/dist';
import { UserRO } from '@escapenavigator/types/dist/user/crud/user.ro';
import { SelectSearch } from 'src/components/select-search';
import { useCurrentUser } from 'src/providers/current-user/context';
import { selectAllRoles, selectAllUsers } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

import { ImagesGroup } from '../base/images-group';

import styles from '../index.module.css';

type Props = {
    loading?: boolean;
    block?: boolean;
} & Pick<
    SelectProps,
    | 'selected'
    | 'disabled'
    | 'label'
    | 'onChange'
    | 'hint'
    | 'error'
    | 'multiple'
    | 'block'
    | 'Field'
>;

export const UsersSelect: React.FC<Props> = ({
    disabled,
    loading,
    onChange,
    Field,
    multiple,
    block = true,
    label,
    hint,
    selected,
    error,
}) => {
    const { t } = useTranslation();
    const {
        current: { user },
    } = useCurrentUser();
    const users = useAppSelector(selectAllUsers(user.id));
    const roles = useAppSelector(selectAllRoles);

    const getTitle = (u: UserRO) => {
        if (u.id === user.id) return 'You';

        return roles.find((r) => r.id === u.roleId)?.title || 'Other';
    };

    const options = useMemo(() => {
        const { You = {}, ...other } = users.reduce((acc, u) => {
            const title = getTitle(u);

            acc[title] = acc[title] || {
                label: title,
                options: [],
            };

            acc[title].options.push({
                key: u.id,
                content: (
                    <Flex className={ styles.option } justify="start" gap="sm">
                        <Avatar text={ u.title } src={ u.photo } />
                        <Typography.Text view="title" weight="medium">
                            { u.title }
                        </Typography.Text>
                    </Flex>
                ),
                value: u.title,
            });

            return acc;
        }, {} as any);

        return [...(You.options || []), ...Object.values(other)];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users, roles]);

    const selectedUsers = users.filter((q) =>
        (Array.isArray(selected) ? selected.includes(q.id) : selected === q.id));

    return (
        <SelectSearch
            disabled={ disabled }
            valueRenderer={ () => {
                if (!selectedUsers.length) return null;

                if (selectedUsers.length === 1) return selectedUsers[0].title;

                return `${t('Выбрано')} ${selectedUsers.length}`;
            } }
            fieldProps={ {
                rightAddons: loading ? <Loader /> : <ImagesGroup images={ selectedUsers } />,
            } }
            Field={ Field }
            label={ label }
            optionsListWidth="content"
            allowUnselect={ true }
            showEmptyOptionsList={ true }
            multiple={ multiple }
            options={ options }
            onChange={ onChange }
            selected={ selected }
            hint={ hint }
            error={ error }
            block={ block }
        />
    );
};
