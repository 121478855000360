/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Typography } from '@alphakits/ui/dist';
import cn from 'classnames';

import { TabPanelProps } from './tab-panel';

import styles from './index.module.css';

interface TabsProps {
    children: React.ReactElement<TabPanelProps> | Array<React.ReactElement<TabPanelProps>>;
    defaultActive: string;
    leftAddons?: React.ReactNode;
    onTabChange?: (tab: string) => void;
    noMargin?: boolean;
    view?: 'content' | 'header';
}

export const Tabs: React.FC<TabsProps> = ({
    children,
    defaultActive,
    onTabChange,
    view = 'content',
    noMargin,
    leftAddons,
}) => {
    const [tabHeader, setTabHeader] = useState<string[]>([]);
    const [childContent, setChildConent]: any = useState({});
    const [active, setActive] = useState(defaultActive);

    useEffect(() => {
        const headers: string[] = [];
        const childCnt: any = {};

        React.Children.forEach(children, (element) => {
            if (!React.isValidElement(element)) return;
            const { name } = element.props;

            headers.push(name);
            childCnt[name] = element.props.children;
        });

        setTabHeader(headers || []);
        setChildConent({ ...childCnt });
    }, [children]);

    const changeTab = (name: string) => {
        setActive(name);

        if (onTabChange) {
            onTabChange(name);
        }
    };

    return (
        <React.Fragment>
            <div className={ cn(styles.tabs_wrapper, noMargin && styles.noMargin) }>
                { leftAddons }

                { tabHeader.map((item: string) => {
                    const isActive = active === item;

                    return (
                        <button
                            className={ cn(styles.tab, { [styles.active]: isActive }) }
                            onClick={ () => changeTab(item) }
                            key={ item }
                            type="button"
                        >
                            { view === 'content' ? (
                                <Typography.Text
                                    view="title"
                                    color={ isActive ? 'primary' : 'secondary' }
                                    weight="medium"
                                >
                                    { item }
                                </Typography.Text>
                            ) : (
                                <Typography.Title
                                    tag="div"
                                    view="xsmall"
                                    color={ isActive ? 'primary' : 'secondary' }
                                    weight="bold"
                                >
                                    { item }
                                </Typography.Title>
                            ) }
                        </button>
                    );
                }) }
            </div>

            <React.Fragment>
                { Object.keys(childContent).map((key) => {
                    if (key === active) {
                        return <React.Fragment>{ childContent[key] }</React.Fragment>;
                    }

                    return null;
                }) }
            </React.Fragment>
        </React.Fragment>
    );
};
