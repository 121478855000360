import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PersonsIosS } from '@alphakits/icons';
import {
    Button, Image, Loader, ModalContext, showError,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { ProfileSubscriptionTypeEnum } from '@escapenavigator/types/dist/profile/enum/profile-subscription-type.enum';
import { Picker } from 'src/components/picker';
import { ChangePasswordModal } from 'src/modals/user/change-password';
import { UserModal } from 'src/modals/user/main';
import { CurrentUserNotificationsModal } from 'src/modals/user/notifications';
import { CurrentUserPreferencesModal } from 'src/modals/user/preferences';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';

export const UserButton: React.FC = React.memo(() => {
    const {
        current: { role, user },
        profile,
    } = useCurrentUser();

    const { t } = useTranslation();
    const { openModal } = useContext(ModalContext);
    const { users, admins } = useApi();

    const { logoutFetch, logoutLoading } = useApiMethod({
        api: users.logout,
        successCallback: () => window.location.assign(`${process.env.REACT_APP_AUTH_DOMAIN}/`),
        errorCallback: () => showError("Can't sign out"),
    });

    const { loginByTokenFetch, loginByTokenLoading } = useApiMethod({
        api: admins.loginByToken,
        successCallback: () => window.location.assign(`${process.env.REACT_APP_ADMIN_DOMAIN}/`),
        errorCallback: () => showError("Can't sign out"),
    });

    // useEffect(() => {
    //     if (!user.phone) {
    //         openModal(CurrentUserModal)({
    //             recordId: user.id,
    //         });
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const logout = () => logoutFetch(undefined);

    const options = () => {
        let actions = {
            [t('openCurrentUserModal')]: () => {
                openModal(
                    UserModal,
                    'm',
                )({
                    recordId: user.id,
                });
            },
        };

        if (profile.subscriptionType !== ProfileSubscriptionTypeEnum.NONE) {
            actions = {
                ...actions,
                [t('typography.aboutEvents')]: () => {
                    openModal(CurrentUserNotificationsModal)();
                },
            };
        }

        return {
            ...actions,
            [t('openChangePasswordModal')]: () => {
                openModal(ChangePasswordModal)();
            },

            [t('openCurrentUserPreferencesModal')]: () => {
                openModal(CurrentUserPreferencesModal)();
            },

            [t('logout')]: logout,
        };
    };

    if (!role.id) {
        return (
            <Button
                size="xs"
                loading={ loginByTokenLoading }
                onClick={ () => loginByTokenFetch(undefined) }
            >
                Back to admin console
            </Button>
        );
    }

    return (
        <Picker
            t={ t }
            position="bottom-end"
            content={
                logoutLoading ? (
                    <Loader />
                ) : (
                    <Image
                        size="m"
                        view="ellipse"
                        bgColor="secondary"
                        color="primary"
                        src={ user.photo || undefined }
                        icon={ user.photo ? undefined : <PersonsIosS /> }
                    />
                )
            }
            actions={ options() }
        />
    );
});
