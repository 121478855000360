/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { PersonIosM } from '@alphakits/icons';
import { formatDate } from '@alphakits/ui/dist';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { ClientRO } from '@escapenavigator/types/dist/client/client.ro';
import { getFullName } from '@escapenavigator/utils/dist/get-full-name';

export const ClientsColumns = (t, i18n): TableColumns<ClientRO> => [
    {
        header: t('tables.client'),
        accessor: 'name',
        row: {
            title: ({ row }) => getFullName(row),
            subtitle: ({ row }) => (row.blockedDate ? t('clientBlocked') : ''),
            icon: () => <PersonIosM />,
        },
    },
    {
        header: t('tables.contact'),
        accessor: 'email',
        row: {
            subtitle: 'phone',
        },
    },
    {
        header: t('reservation'),
        accessor: 'ordersCount',
    },

    {
        header: t('tables.certificate'),
        accessor: 'certificatesCount',
    },
    {
        header: t('tables.lastDateBooking'),
        accessor: 'lastOrderCreatedDate',
        row: {
            title: ({ row }) =>
                formatDate(row.lastOrderCreatedDate, {
                    lang: i18n.language,
                    format: 'dd MMMM yyyy',
                }),
        },
    },
    {
        header: t('Последняя игра'),
        accessor: 'lastOrderDate',
        row: {
            title: ({ row }) =>
                formatDate(row.lastOrderDate, { lang: i18n.language, format: 'dd MMMM yyyy' }),
        },
    },
];
