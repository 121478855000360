/* eslint-disable no-bitwise */
// function isValidHex(hex) {
//     return /^#([A-Fa-f0-9]{6})$/.test(hex);
// }

function hexToRgb(hex) {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return [r, g, b];
}

function luminance(r, g, b) {
    // Вычисление относительной яркости
    const a = [r, g, b].map((v) => {
        // eslint-disable-next-line no-param-reassign
        v /= 255;

        // eslint-disable-next-line no-restricted-properties
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });

    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

function getContrast(hexColor) {
    // Вычисление контрастности относительно белого цвета
    // if (!isValidHex(hexColor)) {
    //     throw new Error('Invalid HEX color');
    // }
    const rgb = hexToRgb(hexColor);
    const lum1 = luminance(rgb[0], rgb[1], rgb[2]);
    const lum2 = luminance(255, 255, 255); // Luminance белого цвета
    let contrast = (lum1 + 0.05) / (lum2 + 0.05);

    if (contrast < 1) {
        contrast = 1 / contrast;
    }

    return contrast;
}

export function isReadableOnWhite(hexColor) {
    // Проверка читабельности на белом фоне
    const contrast = getContrast(hexColor);

    return contrast >= 2; // Минимальный уровень контраста для нормального текста
}
