import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FlexColumns, ModalContext, Table } from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { MonthlyReport } from 'src/components/monthly-report';
import { TransactionModal } from 'src/modals/transaction';
import { useApi } from 'src/providers/api/context';
import { getLanguage } from 'src/utils/get-language';

import { Columns } from './columns';

export const ReportsPage: React.FC = () => {
    const { openModal } = useContext(ModalContext);
    const { i18n, t } = useTranslation();

    const { users } = useApi();
    const { userMonthlyReportData, userMonthlyReportFetch, userMonthlyReportLoading } =
        useApiMethod({
            api: users.userMonthlyReport,
        });

    const openTransactionModal = (props: { employeeId: number }) =>
        openModal(TransactionModal)(props);

    const getReports = useCallback((props: { startDate: Date; endDate: Date }) => {
        userMonthlyReportFetch(props);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const columns = useMemo(() => Columns(t, openTransactionModal), [t]);

    return (
        <FlexColumns columns={ 1 }>
            <MonthlyReport onChange={ getReports } />
            <Table.TableComponent
                columns={ columns }
                hideSearch={ true }
                records={ userMonthlyReportData || [] }
                loading={ userMonthlyReportLoading }
                language={ getLanguage(i18n) }
            />
        </FlexColumns>
    );
};
