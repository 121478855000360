import React, {
    useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ClockCircleM } from '@alphakits/icons';
import {
    Button,
    FlexColumns,
    formatDate,
    Loader,
    ModalContext,
    Status,
    Typography,
} from '@alphakits/ui/dist';
import { LogTitleEnum } from '@escapenavigator/types/dist/log/log-title.enum';
import { LogTypeEnum } from '@escapenavigator/types/dist/log/log-type.enum';
import { LogRO } from '@escapenavigator/types/dist/log/log.ro';
import { Log } from 'src/components/log';
import { usePaginationQuery } from 'src/hooks/use-pagination-query';
import { CertificatesaleModal } from 'src/modals/certificatesale';
import { OrderModal } from 'src/modals/order';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';

import styles from './index.module.css';

enum TagsEnum {
    ALL = 'all',
    NEW_ORDERS = 'new_orders',
    NEW_CETIFICATES = 'new_certificates',
    TRANSACTIONS = 'transactions',
    ALL_ORDERS = 'all_orders',
    IMPORTANT = 'inportant',
    ONBOARDING = 'onboarding',
}

type Props = {
    tag: TagsEnum;
};

export const NotificationsList: React.FC<Props> = ({ tag }) => {
    const { logs } = useApi();
    const { openModal } = useContext(ModalContext);
    const { t, i18n } = useTranslation();
    const {
        current: {
            user: { pushNotifications },
        },
    } = useCurrentUser();
    const [where, setWhere] = useState<Record<string, unknown>>({});

    const {
        records, hasMore, loading, updating, reload, nextPage,
    } = usePaginationQuery({
        api: logs.query,
        limit: 10,
    });

    const handleReload = useCallback(
        (selectedTag: TagsEnum) => {
            const query: Record<string, unknown> = {};

            if (selectedTag === TagsEnum.NEW_ORDERS) {
                query.title = [LogTitleEnum.createOrder];
            }
            if (selectedTag === TagsEnum.NEW_CETIFICATES) {
                query.title = [LogTitleEnum.createCertificate];
            }
            if (selectedTag === TagsEnum.ALL_ORDERS) {
                query.type = LogTypeEnum.ORDER;
            }
            if (selectedTag === TagsEnum.TRANSACTIONS) {
                query.type = LogTypeEnum.TRANSACTION;
            }
            if (selectedTag === TagsEnum.IMPORTANT) {
                query.title = pushNotifications || [];
            }

            reload(query);
            setWhere(query);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [pushNotifications],
    );

    const sortedLogs = useMemo(
        () =>
            records.reduce((acc, log) => {
                const date = formatDate(log.createdAt, { lang: i18n.language, format: 'dd MMMM' });

                acc[date] = acc[date] || [];
                acc[date].push(log);

                return acc;
            }, {} as Record<string, LogRO[]>),
        [records, i18n],
    );

    useEffect(() => {
        handleReload(tag);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tag]);

    const handleClick = useCallback((log: LogRO) => {
        if (log.type.includes(LogTypeEnum.ORDER)) {
            return openModal(OrderModal, 'm', true)({ recordId: log.modelId });
        }

        if (log.type.includes(LogTypeEnum.CERTIFICATE_SALE)) {
            return openModal(CertificatesaleModal, 'm', true)({ certificatesaleId: log.modelId });
        }

        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) return <Loader />;

    return (
        <FlexColumns columns={ 1 } gr={ 8 }>
            { Object.entries(sortedLogs).map(([date, logs]) => (
                <React.Fragment key={ date }>
                    <Typography.Text className={ styles.tag } view="title" weight="bold">
                        { date }
                    </Typography.Text>

                    { logs.map((log) => (
                        <Log onClick={ () => handleClick(log) } log={ log } />
                    )) }
                </React.Fragment>
            )) }

            { !sortedLogs.length && (
                <div>
                    <Status view="soft" color="grey">
                        <ClockCircleM />
                        { t('Нет данных') }
                    </Status>
                </div>
            ) }

            { hasMore && (
                <Button loading={ updating } onClick={ () => nextPage(where) }>
                    { t('loadMore') }
                </Button>
            ) }
            <br />
        </FlexColumns>
    );
};
