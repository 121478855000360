import React, { useCallback, useEffect, useState } from 'react';
import { showError } from '@alphakits/ui';
import axios from 'axios';
import { useCurrentUser } from 'src/providers/current-user/context';
import { selectAllQuestrooms, selectLocationById } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

import styles from './index.module.css';

type Props = {
    title: string;
    sections: any;
};

export const ViewEmail: React.FC<Props> = ({ title, sections }) => {
    const {
        profile: { logo, mainEmail },
    } = useCurrentUser();
    const [html, setHtml] = useState();
    const [loading, setLoading] = useState(false);
    const [questroom] = useAppSelector(selectAllQuestrooms({ deleted: false, closed: false }));
    const location = useAppSelector(selectLocationById(questroom?.locationId));

    const getTemplate = useCallback(async () => {
        setLoading(true);
        try {
            const { data } = await axios.post('https://mailer.escapenavigator.com/render', {
                title,
                logo,
                questroomEmail: mainEmail,
                address: location?.address || 'Removed location',
                tag: 'info',
                subscription: false,
                sections,
            });

            setHtml(data);
        } catch (error) {
            showError('Render error');
        } finally {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sections, title]);

    useEffect(() => {
        getTemplate();
    }, [getTemplate]);

    return (
        <div
            className={ styles.wrap }
            style={ { opacity: loading ? 0.4 : 1 } }
            dangerouslySetInnerHTML={ { __html: html } }
        />
    );
};
