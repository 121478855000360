/* eslint-disable complexity */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, showError, ToastPlate } from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { OrderRO } from '@escapenavigator/types/dist/order/order.ro';
import { convertSecondsToHHMMSS } from 'src/components/schedule/utils/convert-seconds-to-hhmmss';
import { useApi } from 'src/providers/api/context';
import { getStatusData, OrderData } from 'src/utils/get-order-data';

import { useTimer } from '../../hooks/use-order-timer';

type Props = {
    order: OrderRO;
    orderData: OrderData;
    bookImmediately: (id: number) => void;
    successCalback?: () => void;
    close: () => void;
};

export const NotConfirmedOrderAlert: React.FC<Props> = ({
    bookImmediately,
    successCalback,
    close,
    order,
    orderData,
}) => {
    const { orders } = useApi();
    const { t } = useTranslation();
    const { secondsLeft } = useTimer(new Date(order.slot?.expiration));

    const { removeFetch, removeLoading } = useApiMethod({
        api: orders.remove,
        successCallback: () => {
            if (successCalback) successCalback();

            close();
        },
        errorCallback: (err) => showError(err.message),
    });

    const paymentInProcess =
        order.slot?.expiration && secondsLeft > 0 && orderData.status.type === 'BOOKING_IN_PROCESS';

    const action = () =>
        (paymentInProcess ? bookImmediately(order.id) : removeFetch({ id: order.id }));

    const buttonLabel = paymentInProcess ? t('Подтвердить вручную') : t('remove');

    const description = paymentInProcess
        ? t('bookingInProcessTimer', { value: convertSecondsToHHMMSS(secondsLeft) })
        : t('failedBookingDescription');

    const { title } = getStatusData(t)[paymentInProcess ? 'BOOKING_IN_PROCESS' : 'FAILED_BOOKING'];

    return (
        <ToastPlate title={ title } view="attention">
            { description }
            <br />
            <br />
            <Button size="xs" loading={ removeLoading } onClick={ action } view="outlined">
                { buttonLabel }
            </Button>
        </ToastPlate>
    );
};
