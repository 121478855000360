/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/semi */
import { initClientApi } from '@escapenavigator/services/dist/api/init-client-api';
import { QuestroomRO } from '@escapenavigator/types/dist/questroom/questroom.ro';
import { CalendarDayQueryDto } from '@escapenavigator/types/dist/slot/calendar-day-query.dto';
import { SlotRO } from '@escapenavigator/types/dist/slot/slot.ro';
import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice,
    PayloadAction,
    Update,
} from '@reduxjs/toolkit';

import { RootState } from '../store';

const slotsAdapter = createEntityAdapter<SlotRO>();
const api = initClientApi(process.env.REACT_APP_API_DOMAIN);

export const fetchCalendarSlots = createAsyncThunk<SlotRO[], CalendarDayQueryDto>(
    'slots/fetchCalendarSlots',
    async (query) => {
        const { data } = await api.slots.calendarDay(query);

        return data;
    },
);

export const fetchCalendarSlotsForOneQuestroom = createAsyncThunk<SlotRO[], any>(
    'slots/fetchCalendarSlotsForOneQuestroom',
    async (query) => {
        const { data } = await api.slots.calendarDay(query);

        return data;
    },
);

const slotsSlice = createSlice({
    name: 'slots',
    initialState: slotsAdapter.getInitialState({
        loading: false,
        entities: [],
    }),
    reducers: {
        addSlot(state, action: PayloadAction<SlotRO>) {
            slotsAdapter.addOne(state, action.payload);
        },
        updateSlot(state, action: PayloadAction<Update<SlotRO>>) {
            slotsAdapter.updateOne(state, action.payload);
        },
        removeSlot(state, action: PayloadAction<SlotRO['id']>) {
            slotsAdapter.removeOne(state, action.payload);
        },
        removeAllSlots(state) {
            slotsAdapter.removeAll(state);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCalendarSlots.fulfilled, (state, action: PayloadAction<SlotRO[]>) => {
            state.loading = false;
            slotsAdapter.addMany(state, action.payload);
        });
        builder.addCase(fetchCalendarSlots.pending, (state) => {
            state.loading = true;
            slotsAdapter.removeAll(state);
        });
    },
});

const { selectAll } = slotsAdapter.getSelectors<RootState['slots']>((state) => state);

const serializeSlots = ({
    slots,
    questrooms,
}: {
    slots: SlotRO[];
    questrooms: QuestroomRO[];
}): SerializedSlot[] => {
    const serializedQuestrooms = questrooms.reduce((acc, q) => {
        acc[q.id] = q;

        return acc;
    }, {} as Record<number, QuestroomRO>);

    return slots
        .filter((s) => !!serializedQuestrooms[s.questroomId])
        .map((s) => {
            const questroom = serializedQuestrooms[s.questroomId];

            return {
                ...s,
                questroomTitle: questroom.title,
            };
        })
        .sort((a, b) => {
            if (a.start === b.start) {
                return a.questroomTitle > b.questroomTitle ? 1 : -1;
            }

            return a.start > b.start ? 1 : -1;
        });
};

export type SerializedSlot = SlotRO & {
    questroomTitle?: QuestroomRO['title'];
};

export const selectAllSlots = (questrooms: QuestroomRO[]) =>
    createSelector(
        (state: RootState) => state,
        (state) => serializeSlots({ slots: selectAll(state.slots), questrooms }),
    );

export const selectSlotsByQuestroomsIds = (
    questroomsIds: number[] = [],
    questrooms: QuestroomRO[],
) =>
    createSelector(
        (state: RootState) => state,
        (state) =>
            serializeSlots({
                slots: selectAll(state.slots).filter((s) => questroomsIds.includes(s.questroomId)),
                questrooms,
            }),
    );

export const { updateSlot, removeSlot, removeAllSlots } = slotsSlice.actions;

export default slotsSlice.reducer;
