import React, { useMemo } from 'react';
import { Flex, FlexColumns, ToastPlate } from '@alphakits/ui';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { RevenueStatiscticsRO } from '@escapenavigator/types/dist/statistics/revenue';
import { Section } from 'src/components/layout/section';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';

import { BarChartComponent } from '../components/bar';
import { DoublePieComponent } from '../components/double-pie';
import { YearsPicker, YearsPickerProps } from '../components/years-picker';

export const RevenueStatistics: React.FC = () => {
    const { statiscticsApi } = useApi();
    const {
        revenueStatiscticsData,
        revenueStatiscticsError,
        revenueStatiscticsFetch,
        revenueStatiscticsLoading,
    } = useApiMethod({
        api: statiscticsApi.revenueStatisctics,
    });

    const { profile } = useCurrentUser();

    const handleFetch: YearsPickerProps['onChange'] = ({ view, date }) => {
        revenueStatiscticsFetch({
            type: view,
            date,
        });
    };

    const { revenue, cashboxesStructure }: RevenueStatiscticsRO = useMemo(
        () => ({
            revenue: revenueStatiscticsData?.revenue || [],
            cashboxesStructure: revenueStatiscticsData?.cashboxesStructure || [],
        }),
        [revenueStatiscticsData],
    );

    return (
        <FlexColumns gr={ 24 } columns={ 1 }>
            <Flex justify="start" gap="xl" align="start">
                <YearsPicker onChange={ handleFetch } />
            </Flex>

            { revenueStatiscticsError?.message ? (
                <ToastPlate view="negative">{ revenueStatiscticsError.message }</ToastPlate>
            ) : (
                <React.Fragment>
                    <Section title="Revenue" loading={ revenueStatiscticsLoading }>
                        <BarChartComponent currency={ profile.currency } data={ revenue } />
                    </Section>

                    <Section loading={ revenueStatiscticsLoading } title="Cashboxes">
                        <DoublePieComponent
                            currency={ profile.currency }
                            data2={ cashboxesStructure }
                        />
                    </Section>
                </React.Fragment>
            ) }
        </FlexColumns>
    );
};
