import React from 'react';
import { FlexColumns, Textarea, useCrudFormRequests } from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { CreateReviewAnswerDto } from '@escapenavigator/types/dist/review/create-review-answer.dto';
import { SocialReviewRO } from '@escapenavigator/types/dist/review/social-review.ro';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

type Props = {
    review: SocialReviewRO;
    close: () => void;
    cb: () => void;
    t: TFunction;
};

export const AnswerModal: React.FC<Props> = ({
    review, close, t, cb,
}) => {
    const { socialReviews } = useApi();

    const {
        save,
        updating,
        error: softError,
    } = useCrudFormRequests({
        recordId: review.id,
        saveRequest: socialReviews.answer,
        saveCallback: cb,
        close,
    });

    return (
        <RestForm
            recordId={ review.id }
            title="Answer"
            initialValues={ serializeRecord(CreateReviewAnswerDto, {
                response: review.response,
            }) }
            validate={ validateByDto(t) }
            save={ save }
            softError={ softError }
            updating={ updating }
            close={ close }
            t={ t }
        >
            { ({ values, handleChange }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    <Textarea
                        dataTestId="comment"
                        label="Answer"
                        value={ values.response }
                        onChange={ handleChange('response') }
                        block={ true }
                        maxLength={ 1500 }
                        counter={ true }
                    />
                </FlexColumns>
            ) }
        </RestForm>
    );
};
