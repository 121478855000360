import React from 'react';

export interface TabPanelProps {
    name: string;
}

export const TabPanel: React.FC<TabPanelProps> = ({ children, name }) => {
    console.log(name);

    return <React.Fragment>{ children }</React.Fragment>;
};
