import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExcelM } from '@alphakits/icons';
import {
    Button, Flex, Loader, Typography,
} from '@alphakits/ui/dist';

type Props = {
    exportFetch: () => void;
    exportLoading: boolean;
    loading: boolean;
    count?: number;
};

export const ExportAndCount: React.FC<Props> = ({
    loading, exportLoading, count, exportFetch,
}) => {
    const { t } = useTranslation();

    return (
        <Flex>
            <Flex gap="xs" justify="start">
                <Typography.Text view="title" color="secondary">
                    { t('Найдено записей') }
                </Typography.Text>

                { loading ? (
                    <Loader />
                ) : (
                    <Typography.Text view="title" weight="bold">
                        { count }
                    </Typography.Text>
                ) }
            </Flex>

            <Button
                view="link"
                disabled={ loading || !count }
                size="xs"
                leftAddons={ <ExcelM /> }
                onClick={ exportFetch }
                loading={ exportLoading }
            >
                { t('Export to excel') }
            </Button>
        </Flex>
    );
};
