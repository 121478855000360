import React from 'react';
import { AmountInput, FlexColumns, Textarea } from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CreateOrderPaymentDto } from '@escapenavigator/types/dist/order-transaction/create-order-payment.dto';
import { OrderRO } from '@escapenavigator/types/dist/order/order.ro';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { CashboxesSelect } from 'src/components/selects/cashbox-select';
import { useApi } from 'src/providers/api/context';

type Props = {
    close: () => void;
    setOrder: (order: OrderRO) => void;
    orderId: OrderRO['id'];
    locationId: number;
    t: TFunction;
    currency: ProfileCurrencyEnum;
    amount?: number;
};

export const Transaction: React.FC<Props> = ({
    close,
    t,
    orderId,
    setOrder,
    locationId,
    currency,
    amount = 0,
}) => {
    const { orderTransactionsApi } = useApi();

    const { createOrderPaymentFetch, createOrderPaymentError, createOrderPaymentLoading } =
        useApiMethod({
            api: orderTransactionsApi.createOrderPayment,
            successCallback: ({ data }) => {
                setOrder(data);
                close();
            },
        });

    return (
        <RestForm
            t={ t }
            title={ t('Добавление платежа в кассу') }
            initialValues={ serializeRecord(CreateOrderPaymentDto, {
                orderId,
                amount,
            }) }
            validate={ validateByDto(t) }
            close={ close }
            save={ async (data) => {
                createOrderPaymentFetch({ data });
            } }
            loading={ false }
            updating={ createOrderPaymentLoading }
            removing={ false }
        >
            { ({
                values, handleChange, setFieldValue, errors, touched,
            }) => (
                <React.Fragment>
                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                        <CashboxesSelect
                            selected={ values.cashboxId }
                            error={ touched.cashboxId && errors.cashboxId }
                            onChange={ ({ selected }) => setFieldValue('cashboxId', selected?.key) }
                            type="not_online"
                            locationId={ locationId }
                            label={ t('component.selectCashbox') }
                        />
                        <AmountInput
                            value={ values.amount }
                            error={ touched.amount && errors.amount }
                            onChange={ (e, { value }) => setFieldValue('amount', value) }
                            label={ t('component.amount') }
                            suffix={ currency }
                            block={ true }
                            type="decimal"
                        />
                        <Textarea
                            value={ values.comment }
                            onChange={ handleChange('comment') }
                            label={ t('Комментарий') }
                            maxLength={ 200 }
                            counter={ true }
                            block={ true }
                        />
                    </FlexColumns>

                    { createOrderPaymentError }
                </React.Fragment>
            ) }
        </RestForm>
    );
};
