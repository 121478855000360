import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Calendar } from '@alphakits/ui';
import { add, format, startOfISOWeek } from 'date-fns';
import { DateParam, ViewParam } from 'src/constants/params';
import { ROUTE_CALENDAR } from 'src/router/constants';
import { useQueryParam } from 'use-query-params';

function getSelectedWeek(date) {
    return [
        +format(startOfISOWeek(date), 'T'),
        +format(add(startOfISOWeek(date), { days: 6 }), 'T'),
    ];
}

export const ScheduleCalendar: React.FC = () => {
    const [date, setDate] = useQueryParam('calendarDate', DateParam);

    const { pathname } = useLocation();

    const [view] = useQueryParam('view', ViewParam);

    const navigate = useNavigate();

    const changeDate = (d: number) => {
        const localDate = format(new Date(d), 'yyyy-MM-dd');

        if (pathname !== ROUTE_CALENDAR) {
            return navigate(`${ROUTE_CALENDAR}?calendarDate=${localDate}`);
        }

        return setDate(localDate);
    };

    const [selectedFrom, selectedTo] = view === 'week' ? getSelectedWeek(date) : [];

    const d = new Date(date);

    const value = Number(format(view === 'week' ? startOfISOWeek(d) : d, 'T'));

    return (
        <Calendar
            onChange={ changeDate }
            selectedFrom={ selectedFrom }
            maxDate={ Date.now() + 1000 * 60 * 60 * 24 * 365 * 2 }
            minDate={ Date.now() - 1000 * 60 * 60 * 24 * 365 * 1 }
            selectedTo={ selectedTo }
            value={ value }
        />
    );
};
