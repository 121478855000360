import React, {
    useContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
    ChevronBackS, CloseS, EditS, PlusS,
} from '@alphakits/icons';
import {
    Amount,
    Box,
    Button,
    Divider,
    Flex,
    FlexColumns,
    formatDate,
    IconButton,
    InfoBlockHeader,
    InfoBlockItem,
    Loader,
    ModalContext,
    Padding,
    SuperListItem,
    ToastPlate,
    Typography,
} from '@alphakits/ui/dist';
import { FormLoading } from '@alphakits/ui/dist/form/components/form-loading';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CertificatesaleDeliveryTypeEnum } from '@escapenavigator/types/dist/certificate-sale/enum/certificatesales-delivery-type.enum';
import { convertUTCDateToZonedDate } from '@escapenavigator/utils/dist';
import { getFullAddress } from '@escapenavigator/utils/dist/get-full-address';
import { ClientInfo } from 'src/components/client-info';
import { ClientMessage } from 'src/components/client-message';
import { Comments } from 'src/components/comments';
import { CertificatesaleDescription } from 'src/components/details/certificatesale-description.tsx';
import { IntegrationCard } from 'src/components/integration-card';
import { Log } from 'src/components/log';
import { TextCell } from 'src/components/text-cell';
import { TransactionsList } from 'src/components/transactions-list';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { getCertificateData } from 'src/utils/get-certificate-data';

import { DoubleModal, ModalRightSide } from '../components/double-modal';

import { useCertificatesApi } from './hooks/use-certificates-api';
import { AddHandDiscount } from './sub-modals/add-hand-discount';
import { CertificatesaleModalFooter } from './certificate-footer';
import { CertificatesaleModalHeder } from './certificate-header';
import { DateChange, TrackingInfo } from './sub-modals';

type Props = {
    certificatesaleId: number;
    successCallback?: () => void;
    close: () => void;
};

export const CertificatesaleModal: React.FC<Props> = ({
    certificatesaleId,
    successCallback,
    close,
}) => {
    const { openModal } = useContext(ModalContext);
    const { t, i18n } = useTranslation();
    const {
        profile: { currency },
    } = useCurrentUser();

    const {
        certificatesale,
        loading,
        setCertificateSale,
        ship,
        removeHandDiscount,
        annul,
        removePromocode,
    } = useCertificatesApi(certificatesaleId, successCallback);
    const { logs } = useApi();

    const [showLogs, setShowLogs] = useState(false);

    const { queryData, queryFetch, queryLoading } = useApiMethod({ api: logs.query });

    const deliveryData = useMemo(() => {
        if (certificatesale.deliveryType === CertificatesaleDeliveryTypeEnum.EMAIL) {
            const hint = certificatesale.sendingEmailFullUtcDate
                ? convertUTCDateToZonedDate({
                    date: certificatesale.sendingEmailFullUtcDate,
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    showTZ: true,
                })
                : t('Отправка после оплаты');

            return {
                title: 'Email',
                hint: `${certificatesale.deliveryEmail}, ${hint}`,
            };
        }
        if (certificatesale.deliveryType === CertificatesaleDeliveryTypeEnum.PICKUP) {
            return {
                title: t('options.delivery.pickup'),
                hint: t('Необходимо позвонить клиенту и согласовать место самовывоза'),
            };
        }

        return {
            title: t('options.delivery.post'),
            hint: certificatesale.deliveryAddress
                ? getFullAddress(certificatesale)
                : t('Не указаны детали отправки'),
        };
    }, [certificatesale, t]);

    useEffect(() => {
        if (certificatesaleId && showLogs) {
            queryFetch({
                page: 1,
                limit: 20,
                where: { modelId: certificatesaleId, modelName: 'certificatesale' },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [certificatesaleId, showLogs]);

    if (loading || !certificatesale.id) {
        return (
            <DoubleModal>
                <FormLoading />
                <ModalRightSide loading={ true } />
            </DoubleModal>
        );
    }

    const openExpireDateModal = () =>
        openModal(DateChange)({
            certificatesale,
            setCertificateSale,
        });

    const openTrackingInfoModal = () =>
        openModal(TrackingInfo)({
            certificatesale,
            setCertificateSale,
        });

    const certifiateData = getCertificateData(certificatesale, t);

    const canChangeData = !certificatesale.annul && !certificatesale.order;

    return (
        <DoubleModal>
            <div>
                <CertificatesaleModalHeder
                    certificatesale={ certificatesale }
                    annul={ annul }
                    certifiateData={ certifiateData }
                />
                <Padding padding="0 32px 32px 32px">
                    <FlexColumns columns={ 1 } gr={ 32 }>
                        { certifiateData.type === 'notPayed' && (
                            <ToastPlate view="attention">
                                { t('certificateNotPayedAlert') }
                            </ToastPlate>
                        ) }

                        { certificatesale.certificate?.inside ? (
                            <IntegrationCard
                                fullWidth={ true }
                                title={ `${t('vertificateService')}` }
                                description={ t('outOfTheBoxCertificateInstructions', {
                                    value: certificatesale.code,
                                }) }
                                image="https://d2z3luly8bezbw.cloudfront.net/fit-in/300x300/inside/art/marketing/cross-sale.png"
                            />
                        ) : (
                            <FlexColumns columns={ 1 } gr={ 12 }>
                                <TextCell
                                    title={ t('Годен до') }
                                    subtitle={ `${formatDate(certificatesale.expireDate, {
                                        lang: i18n.language,
                                        format: 'dd MMMM yyyy',
                                    })}` }
                                    rightAddons={
                                        canChangeData && (
                                            <IconButton
                                                onClick={ openExpireDateModal }
                                                icon={ EditS }
                                            />
                                        )
                                    }
                                />

                                <TextCell title={ deliveryData.title } subtitle={ deliveryData.hint } />

                                { certificatesale.deliveryTrackingInfo && (
                                    <TextCell
                                        title={ t('Трек номер') }
                                        subtitle={ certificatesale.deliveryTrackingInfo || '-' }
                                        rightAddons={
                                            canChangeData && (
                                                <IconButton
                                                    onClick={ openTrackingInfoModal }
                                                    icon={ EditS }
                                                />
                                            )
                                        }
                                    />
                                ) }

                                { certificatesale.deliveryComment && (
                                    <ClientMessage
                                        text={ `${t('Коммент к доставке')}: ${
                                            certificatesale.deliveryComment
                                        }` }
                                    />
                                ) }
                            </FlexColumns>
                        ) }

                        <FlexColumns columns={ 1 } gr={ 16 }>
                            <Flex>
                                <Typography.Title view="xsmall" tag="div" weight="bold">
                                    { t('К оплате') }
                                </Typography.Title>

                                <Typography.Title view="xsmall" tag="div" weight="bold">
                                    <Flex gap="sm" justify="end">
                                        { certificatesale.total !== certificatesale.toPay && (
                                            <Amount
                                                value={ certificatesale.total }
                                                color="tertiary"
                                                isCanceled={ true }
                                                currencyOpacity={ false }
                                                type="decimal"
                                                weight="bold"
                                                currency={ currency }
                                            />
                                        ) }

                                        <Amount
                                            value={ +certificatesale.toPay }
                                            color="primary"
                                            type="decimal"
                                            weight="bold"
                                            currency={ currency }
                                        />
                                    </Flex>
                                </Typography.Title>
                            </Flex>

                            <Divider />

                            <FlexColumns columns={ 1 } gr={ 12 }>
                                <SuperListItem
                                    text={ t('Номинал') }
                                    disabled={ true }
                                    rightAddons={ (
                                        <Amount
                                            value={ certificatesale.nominal }
                                            currency={ currency }
                                            color="primary"
                                            weight="bold"
                                            view="title"
                                            type="decimal"
                                        />
                                    ) }
                                />
                                <SuperListItem
                                    text={ t('Наценка на сертиификат') }
                                    disabled={ true }
                                    rightAddons={ (
                                        <Amount
                                            value={ certificatesale.extraPrice || 0 }
                                            currency={ currency }
                                            color="primary"
                                            weight="bold"
                                            view="title"
                                            type="decimal"
                                        />
                                    ) }
                                />

                                <SuperListItem
                                    text={ t('component.deliveryPrice') }
                                    disabled={ true }
                                    rightAddons={ (
                                        <Amount
                                            value={ certificatesale.deliveryPrice || 0 }
                                            currency={ currency }
                                            color="primary"
                                            weight="bold"
                                            view="title"
                                            type="decimal"
                                        />
                                    ) }
                                />

                                { certificatesale.promocodes?.map((p) => (
                                    <SuperListItem
                                        key={ p.id }
                                        leftAddons={ (
                                            <IconButton
                                                onClick={ () => removePromocode(p.id) }
                                                icon={ CloseS }
                                            />
                                        ) }
                                        text={ `${t('Применен промокод')} ${p.code}` }
                                        hint={ `${formatDate(p.createdAt, {
                                            lang: i18n.language,
                                        })} ${
                                            p.code.startsWith('SRS')
                                                ? t('Сгенерирован для Cross')
                                                : ''
                                        }` }
                                        rightAddons={ (
                                            <Amount
                                                value={ +p.amount * -1 }
                                                currency={ currency }
                                                color="positive"
                                                weight="bold"
                                                view="title"
                                                type="decimal"
                                            />
                                        ) }
                                    />
                                )) }

                                { certificatesale?.discounts?.map((discount) => (
                                    <SuperListItem
                                        key={ discount.id }
                                        leftAddons={ (
                                            <IconButton
                                                onClick={ () => removeHandDiscount(discount.id) }
                                                icon={ CloseS }
                                            />
                                        ) }
                                        text={ t('Ручная скидка') }
                                        hint={ `${formatDate(discount.createdAt, {
                                            lang: i18n.language,
                                        })}, ${discount.reason}` }
                                        rightAddons={ (
                                            <Amount
                                                value={ discount.amount }
                                                currency={ currency }
                                                color="primary"
                                                weight="bold"
                                                view="title"
                                                type="decimal"
                                            />
                                        ) }
                                    />
                                )) }

                                <TransactionsList
                                    setData={ setCertificateSale }
                                    type="certificate"
                                    transactions={ certificatesale.transactions }
                                />

                                { !certificatesale.certificate?.inside && (
                                    <Button
                                        view="ghost"
                                        onClick={ () =>
                                            openModal(AddHandDiscount)({
                                                currency,
                                                certificatesale,
                                                setCerificate: setCertificateSale,
                                                amount: certificatesale.toPay,
                                            }) }
                                    >
                                        <SuperListItem
                                            leftAddons={ <PlusS /> }
                                            text={ t('Добавить скидку') }
                                        />
                                    </Button>
                                ) }
                            </FlexColumns>
                        </FlexColumns>

                        <Comments t={ t } certificateSaleId={ certificatesale.id } />

                        { !certificatesale.certificate?.inside && (
                            <CertificatesaleModalFooter
                                certificatesale={ certificatesale }
                                ship={ ship }
                                certifiateData={ certifiateData }
                                setCertificateSale={ setCertificateSale }
                                close={ close }
                            />
                        ) }
                    </FlexColumns>
                </Padding>
            </div>

            <ModalRightSide loading={ loading }>
                { showLogs ? (
                    <React.Fragment>
                        <Button
                            size="xs"
                            leftAddons={ <ChevronBackS /> }
                            view="outlined"
                            onClick={ () => setShowLogs(false) }
                        >
                            { t('Детали') }
                        </Button>
                        <br />
                        <br />
                        { queryLoading ? (
                            <Loader />
                        ) : (
                            queryData?.items.map((log) => <Log log={ log } />)
                        ) }
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Box
                            background="var(--color-bg-primary)"
                            border={ true }
                            rounded="sm"
                            padding="md"
                        >
                            <CertificatesaleDescription certificatesale={ certificatesale } />
                        </Box>

                        <ClientInfo
                            cb={ (client) => setCertificateSale({ ...certificatesale, client }) }
                            client={ certificatesale.client }
                        />

                        <Box
                            background="var(--color-bg-primary)"
                            border={ true }
                            rounded="sm"
                            padding="md"
                        >
                            <InfoBlockHeader title={ t('Логи') } onClick={ () => setShowLogs(true) } />

                            <InfoBlockItem
                                label={ t('Последнее изменение') }
                                value={ formatDate(certificatesale.updatedAt, {
                                    lang: i18n.language,
                                }) }
                            />
                        </Box>
                    </React.Fragment>
                ) }
            </ModalRightSide>
        </DoubleModal>
    );
};
