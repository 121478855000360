/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate, InfoBlockHeader, InfoBlockItem } from '@alphakits/ui/dist';
import { CertificateUploadedRO } from '@escapenavigator/types/dist/certificate-uploaded/certificate-uploaded.ro';

type Props = { certificatesale: CertificateUploadedRO };

export const CertificateuploadDescription: React.FC<Props> = ({ certificatesale }) => {
    const { t, i18n } = useTranslation();

    return (
        <React.Fragment>
            <InfoBlockHeader title={ `${t('tables.certificate')} №${certificatesale.code}` } />

            <InfoBlockItem
                label={ t('Действует до') }
                value={ formatDate(certificatesale.expireDate, {
                    lang: i18n.language,
                    format: 'dd MMMM yyyy',
                }) }
            />

            <InfoBlockItem
                label={ t('tables.source') }
                // rightAddons={ <Badge content="UTM" size="s" /> }
                value={ t(`options.source.${certificatesale.source}`) }
            />
            <InfoBlockItem
                label={ t('Дата создания') }
                value={ formatDate(certificatesale.createdAt, { lang: i18n.language }) }
            />
        </React.Fragment>
    );
};
