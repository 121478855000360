/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Amount, formatDate, InfoBlockHeader, InfoBlockItem,
} from '@alphakits/ui/dist';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { PromocodeTypeEnum } from '@escapenavigator/types/dist/promocode/emun/promocode-type.enum';
import { PromocodeRO } from '@escapenavigator/types/dist/promocode/promocode.ro';
import { getPromocodeApplyingsRule } from 'src/pages/marketing/promocodes/columns';

import { PromocodeDetails } from '../promocode-details';

type Props = {
    promocode: PromocodeRO;
    currency: ProfileCurrencyEnum;
    onClick?: () => void;
};

export const PromocodeDescription: React.FC<Props> = ({ promocode, onClick, currency }) => {
    const { t, i18n } = useTranslation();

    return (
        <React.Fragment>
            <InfoBlockHeader onClick={ onClick } title={ `${t('Промокод')} №${promocode.code}` } />

            { promocode.crossSale && <InfoBlockItem label={ t('Сгенерирован для Cross') } /> }

            { !promocode.validTo && !promocode.validFrom && (
                <InfoBlockItem label={ t('Действует бессрочно') } />
            ) }

            { promocode.validFrom && (
                <InfoBlockItem
                    label={ t('Действует с') }
                    value={ formatDate(promocode.validFrom, {
                        lang: i18n.language,
                        format: 'dd MMMM yyyy',
                    }) }
                />
            ) }

            { promocode.validTo && (
                <InfoBlockItem
                    label={ t('Действует до') }
                    value={ formatDate(promocode.validTo, {
                        lang: i18n.language,
                        format: 'dd MMMM yyyy',
                    }) }
                />
            ) }

            <InfoBlockItem label={ t('Применения') } value={ `${promocode.applyings}` } />

            <InfoBlockItem
                label={ t(`options.promocodes.${promocode.type}`) }
                value={ (
                    <Amount
                        type="decimal"
                        weight="bold"
                        value={ promocode.discount }
                        currency={ PromocodeTypeEnum.FIXED ? currency : '%' }
                    />
                ) }
            />

            <InfoBlockItem label={ getPromocodeApplyingsRule(promocode, t) } />

            <PromocodeDetails promocode={ promocode } />
        </React.Fragment>
    );
};
