import React from 'react';
import { StarFillS } from '@alphakits/icons';
import {
    Box, Button, Flex, Image, Status, Tooltip, Typography,
} from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { SocialReviewRO } from '@escapenavigator/types/dist/review/social-review.ro';

const getColor = (rate: number) => {
    if (rate === 5) return 'positive';
    if (rate === 4) return 'accent';
    if (rate === 3) return 'attention';

    return 'negative';
};

const Tool = ({ text }: { text: string }) => {
    if (!text?.length || text.length <= 100) return <React.Fragment>{ text }</React.Fragment>;

    return (
        <Tooltip trigger="hover" position="bottom" content={ <Box width={ 400 }>{ text }</Box> }>
            <div>{ text.slice(0, 100) }</div>
        </Tooltip>
    );
};

export const columns: TableColumns<SocialReviewRO> = [
    {
        header: 'Escape room',
        accessor: 'questroom',
        row: {
            title: ({ row }) => (
                <Typography.Text view="title" color={ getColor(row.rating) } weight="bold">
                    <Flex gap="xs" justify="start">
                        <StarFillS /> { row.rating }
                    </Flex>
                </Typography.Text>
            ),
            subtitle: ({ row }) => row.questroom.title,
            icon: ({ row }) => <Image view="ellipse" src={ row.questroom.photo } />,
        },
    },
    {
        header: 'Text',
        accessor: 'text',
        width: '30%',
        row: {
            title: ({ row }) => <Tool text={ row.text } />,
        },
    },
    {
        width: '30%',
        header: 'Answer',
        accessor: 'response',
        row: {
            title: ({ row }) =>
                (row.response ? (
                    <Tool text={ row.response } />
                ) : (
                    <Button view="primary" size="xs">
                        Add answer
                    </Button>
                )),
        },
    },
    {
        header: 'Status',
        accessor: 'accepted',
        row: {
            title: ({ row }) => (
                <Status view="contrast" color={ row.accepted ? 'green' : 'grey' }>
                    { row.accepted ? 'Shown' : 'Hidden' }
                </Status>
            ),
            subtitle: ({ row }) => (row.accepted ? 'Available for everyone' : 'On moderation'),
        },
    },
];
