import React, { useEffect, useMemo } from 'react';
import { Box, FlexColumns, Loader } from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { Log } from 'src/components/log';
import { useApi } from 'src/providers/api/context';

import { LogEmail } from '../log-email';

type Props = {
    recordId: number;
    modelName: string;
};

export const Logs: React.FC<Props> = ({ recordId, modelName }) => {
    const { logs, profiles } = useApi();

    const { queryData, queryFetch, queryLoading } = useApiMethod({ api: logs.query });
    const { getAllCustomersEmailData, getAllCustomersEmailFetch, getAllCustomersEmailLoading } =
        useApiMethod({ api: profiles.getAllCustomersEmail });

    useEffect(() => {
        queryFetch({
            page: 1,
            limit: 50,
            where: {
                modelId: recordId,
                modelName,
            },
        });

        const props =
            modelName === 'order'
                ? {
                    orderId: recordId,
                }
                : {
                    certificateId: recordId,
                };

        getAllCustomersEmailFetch({
            page: 1,
            limit: 20,
            where: props,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const allLogs = useMemo(() => {
        const emails = (getAllCustomersEmailData?.items || []).map((email) => ({
            createdAt: email.createdAt,
            type: 'email' as const,
            email,
        }));

        const logs = (queryData?.items || []).map((action) => ({
            createdAt: action.createdAt,
            type: 'log' as const,
            action,
        }));

        return [...emails, ...logs].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
    }, [getAllCustomersEmailData, queryData]);

    if (queryLoading || getAllCustomersEmailLoading) return <Loader />;

    return (
        <React.Fragment>
            { allLogs?.map((log) =>
                (log.type === 'log' ? (
                    <Log log={ log.action } />
                ) : (
                    <Box
                        border={ true }
                        background="var(--color-bg-primary)"
                        padding="md"
                        rounded="sm"
                    >
                        <FlexColumns columns={ 1 } gr={ 12 }>
                            <LogEmail email={ log.email } />
                        </FlexColumns>
                    </Box>
                ))) }
        </React.Fragment>
    );
};
