/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill, { Quill } from 'react-quill';
import { InfoMarkS } from '@alphakits/icons';
import {
    Button, Divider, Flex, Tooltip, Typography,
} from '@alphakits/ui';
import { Languages } from '@escapenavigator/types/dist/shared/enum/languages.enum';
import { ImageTypeEnum } from '@escapenavigator/types/dist/upload/enum/image-type.enum';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import ImageUploader from 'quill-image-uploader';
import QuillResizeImage from 'quill-resize-image';
import { useApi } from 'src/providers/api/context';

import { HandlerKey } from '../../../../../components/quill/get-handlers';

import 'quill-image-uploader/dist/quill.imageUploader.min.css';
import 'react-quill/dist/quill.snow.css';
import styles from './index.module.css';

const ImageFormatAttributesList = ['alt', 'height', 'width', 'style'];

const BaseImageFormat = Quill.import('formats/image');

class ImageFormat extends BaseImageFormat {
    static formats(domNode) {
        return ImageFormatAttributesList.reduce((formats, attribute) => {
            if (domNode.hasAttribute(attribute)) {
                // eslint-disable-next-line no-param-reassign
                formats[attribute] = domNode.getAttribute(attribute);
            }

            return formats;
        }, {});
    }

    format(name, value) {
        if (ImageFormatAttributesList.indexOf(name) > -1) {
            if (value) {
                this.domNode.setAttribute(name, value);
            } else {
                this.domNode.removeAttribute(name);
            }
        } else {
            super.format(name, value);
        }
    }
}

Quill.register(ImageFormat, true);
Quill.register('modules/imageUploader', ImageUploader);
Quill.register('modules/resize', QuillResizeImage);

type Props = {
    id?: string;
    title?: string;
    noLinks?: boolean;
    text: string;
    lang: Languages;
    error?: string;
    handlers: Array<{ title: string; key: HandlerKey; description: string }>;
    onChange: (value: string) => void;
};

function setValue(text: string) {
    const cursorPosition = this.quill.getSelection().index;

    this.quill.insertText(cursorPosition, text);
}

const Btn = ({
    text,
    action,
    description,
}: {
    text: string;
    action: string;
    description: string;
}) => (
    <Button view="secondary" size="xs" className={ classNames(action, styles.action) }>
        <Flex gap="xs">
            { text }

            <Tooltip
                trigger="hover"
                position="top"
                content={ (
                    <div style={ { width: 300, overflow: 'auto' } }>
                        <Typography.Text view="primary-medium">{ description }</Typography.Text>
                    </div>
                ) }
            >
                <InfoMarkS style={ { opacity: 0.4 } } />
            </Tooltip>
        </Flex>
    </Button>
);
const ifParams: HandlerKey[] = [
    'photos',
    'gameResult',
    'servicesList',
    'howToFindLocation',
    'importantInfo',
    'prepareInfo',
];

function pasteText(param: HandlerKey, t: TFunction, lng: Languages) {
    if (ifParams.includes(param)) {
        return `
{{#if ${param}}}
${t(`crm-crosssales:baseValues.${param}`, { value: `{${param}}`, lng })}
{{/if}}
`;
    }

    return `{${param}}`;
}

export const FeedbackTextarea: React.FC<Props> = ({
    onChange,
    text,
    handlers,
    lang,
    error,
    id = '',
    title,
    noLinks,
}) => {
    const { t } = useTranslation();
    const { uploads } = useApi();
    const modules = useMemo(() => {
        const handlersObject = {
            handlers: {},
        };

        handlers.forEach((handler) => {
            handlersObject.handlers[handler.key] = function () {
                setValue.call(this, pasteText(handler.key, t, lang));
            };
        });

        return {
            toolbar: {
                container: `#toolbar${lang}${id}`,
                handlers: handlersObject.handlers,
            },
            resize: {
                locale: {},
            },
            imageUploader: {
                upload: (file) => {
                    const data = new FormData();

                    data.append('file', file);
                    data.append('type', ImageTypeEnum.POSTER);

                    return uploads
                        .uploadImage({ data, onUploadProgress: () => {} })
                        .then(({ data }) => data.url);
                },
            },
        };
    }, []);

    const handleChange = (txt: string) => {
        onChange(txt);
    };

    return (
        <div className={ styles.wrap }>
            { title && (
                <Typography.Text color="primary" view="title" weight="bold">
                    { title }
                </Typography.Text>
            ) }
            <div>
                <div id={ `toolbar${lang}${id}` } className={ styles.toolbar }>
                    <Flex gap="xs" justify="start">
                        { !noLinks && <button type="button" className="ql-link" /> }
                        <button type="button" className="ql-bold" />
                        <button type="button" className="ql-image" />
                        <button type="button" className="ql-clean" />
                    </Flex>
                    { !!handlers?.length && (
                        <React.Fragment>
                            <Divider size="m" />

                            <Flex gap="xs" justify="start" wrap={ true }>
                                { handlers.map((h) => (
                                    <Btn
                                        key={ h.key }
                                        description={ h.description }
                                        text={ h.title }
                                        action={ `ql-${h.key}` }
                                    />
                                )) }
                            </Flex>
                        </React.Fragment>
                    ) }
                </div>

                <ReactQuill
                    className={ styles.editor }
                    value={ text }
                    onChange={ handleChange }
                    theme="snow"
                    modules={ modules }
                />
            </div>

            { error && (
                <Typography.Text view="caps" color="negative">
                    { error }
                </Typography.Text>
            ) }
        </div>
    );
};
