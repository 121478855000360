import React from 'react';
import { Sidepanel, ToastPlate } from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { OrderRO } from '@escapenavigator/types/dist/order/order.ro';
import { TFunction } from 'i18next';
import { AddCertificate } from 'src/components/add-certificate';
import { useApi } from 'src/providers/api/context';
import { selectLocationById, selectQuestroomById } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

type Props = {
    t: TFunction;
    close: () => void;
    orderId: number;
    questroomId: number;
    order: OrderRO;
    setOrder: (order: OrderRO) => void;
};

export const Certificate: React.FC<Props> = ({
    t, orderId, setOrder, close, order,
}) => {
    const { orderCertificates, orderCertificateUploads, orderPromocodes } = useApi();

    const questroom = useAppSelector(selectQuestroomById(order.questroomId));
    const location = useAppSelector(selectLocationById(questroom?.locationId));

    const createCertificateApi = useApiMethod({
        api: orderCertificates.create,
        successCallback: ({ data }) => {
            close();
            setOrder(data);
        },
    });

    const createCertificateUploadApi = useApiMethod({
        api: orderCertificateUploads.create,
        successCallback: ({ data }) => {
            close();
            setOrder(data);
        },
    });

    const createPromocodeApi = useApiMethod({
        api: orderPromocodes.create,
        successCallback: ({ data }) => {
            close();
            setOrder(data);
        },
    });

    const loading =
        createCertificateApi.createLoading ||
        createCertificateUploadApi.createLoading ||
        createPromocodeApi.createLoading;

    const error =
        createCertificateApi.createError ||
        createCertificateUploadApi.createError ||
        createPromocodeApi.createError;

    const apply = ({ id, type, code }: { id: number; type: string; code: string }) => {
        if (type === 'certificate') {
            createCertificateApi.createFetch({
                data: { certificateId: id, orderId },
            });
        }

        if (type === 'promocode') {
            createPromocodeApi.createFetch({
                data: { code, orderId },
            });
        }

        if (type === 'certificateUploaded') {
            createCertificateUploadApi.createFetch({
                data: { certificateId: id, orderId },
            });
        }
    };

    return (
        <Sidepanel
            loading={ loading }
            title={ t('Добавление купона') }
            bottomAddons={ error && <ToastPlate view="negative">{ error.message }</ToastPlate> }
        >
            <AddCertificate apply={ apply } type="order" order={ order } timeZone={ location.timeZone } />
        </Sidepanel>
    );
};
