import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyPage, ModalContext } from '@alphakits/ui/dist';
import { SUCCESS_IMAGE } from 'src/constants';

import { NotificationModal } from '../notification';

type Props = {
    close: () => void;
};

export const SuccessSubscribe: React.FC<Props> = ({ close }) => {
    const { t } = useTranslation();
    const { openModal } = useContext(ModalContext);

    return (
        <EmptyPage
            title={ t('Добро пожаловать в команду') }
            subtitle={ t('Описание после подписки') }
            buttonText={ t('Приступить к настройкам') }
            onClick={ () => {
                close();
                openModal(NotificationModal)();
            } }
            image={ SUCCESS_IMAGE }
        />
    );
};
