import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDownS } from '@alphakits/icons';
import { Flex, showError } from '@alphakits/ui';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { Picker } from 'src/components/picker';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';

import { Base } from '../menu-buttons-list/button/base';

export const CompanySwitcher: React.FC = () => {
    const { users } = useApi();

    const { profile, current } = useCurrentUser();

    const { loginSwitchFetch, loginSwitchLoading } = useApiMethod({
        api: users.loginSwitch,
        successCallback: () => window.location.reload(),
        errorCallback: ({ message }) => showError(message),
    });

    const { t } = useTranslation();

    const companiesPicker = useMemo(
        () =>
            Object.fromEntries(
                current.companies.map((c) => [c.title, () => loginSwitchFetch(c.id)]),
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [current.companies],
    );

    if (!current.companies?.length) return null;

    return (
        <Picker
            actions={ companiesPicker }
            loading={ loginSwitchLoading }
            t={ t }
            view="ghost"
            block={ true }
            content={ (
                <Flex>
                    <Base rightAddons={ <ChevronDownS /> }>{ profile.title }</Base>
                </Flex>
            ) }
        />
    );
};
