import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext, Table } from '@alphakits/ui/dist';
import { format } from 'date-fns';
import { CreateUserSessionModal } from 'src/modals/user-session/create';
import { UpdateUserSessionModal } from 'src/modals/user-session/update';
import { useApi } from 'src/providers/api/context';
import { mapper } from 'src/tags';
import { getLanguage } from 'src/utils/get-language';

import { useSessionsColumns } from './columns';

export const UserSessionsPage: React.FC = () => {
    const { i18n, t } = useTranslation();
    const { openModal } = useContext(ModalContext);

    const {
        users: { querySessions },
    } = useApi();

    const columns = useSessionsColumns({ i18n, t });

    return (
        <Table.TableComponent
            searchPlaceholder={ t('component.search') }
            columns={ columns }
            tagsBuilder={ mapper(t) }
            onRowClick={ (session, cb) => openModal(UpdateUserSessionModal)({ session, cb }) }
            language={ getLanguage(i18n) }
            onCreateClick={ (cb) => {
                openModal(CreateUserSessionModal)({
                    date: format(new Date(), 'yyyy-MM-dd'),
                    cb,
                    locationId: null,
                });
            } }
            promise={ querySessions }
            // filtersButtonLabel={ t('filters.label') }
            // onFiltersClick={ ({ submitCallback, initialValues }) =>
            // openFilters({ apply: submitCallback, initialValues: initialValues.where }) }
        />
    );
};
