import React from 'react';
import {
    AmountInput, FlexColumns, Input, Select, useCrudFormRequests,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { CreateSmtpDto } from '@escapenavigator/types/dist/profile/smtp/create-smtp.dto';
import { SmtpEnum } from '@escapenavigator/types/dist/profile/smtp/smtp.enum';
import { SmtpRO } from '@escapenavigator/types/dist/profile/smtp/smtp.ro';
import { enumToOptions, serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { LocationsPicker } from 'src/components/locations-picker';
import { useApi } from 'src/providers/api/context';

type Props = {
    close: () => void;
    cb: () => void;
    t: TFunction;
    record?: SmtpRO;
};

export const SmtpModal: React.FC<Props> = ({
    close, cb, t, record,
}) => {
    const { profiles } = useApi();

    const {
        save,
        updating,
        error: softError,
    } = useCrudFormRequests({
        recordId: record?.id,
        saveRequest: record?.id ? profiles.updateSmtp : profiles.createSmtp,
        removeRequest: profiles.removeSmtp,
        saveCallback: cb,
        removeCallback: cb,
        close,
    });

    return (
        <RestForm
            recordId={ record?.id }
            title="SMTP integration"
            initialValues={ serializeRecord(
                CreateSmtpDto,
                record || {
                    service: SmtpEnum.Custom,
                    allLocations: true,
                    locationIds: [],
                },
            ) }
            validate={ validateByDto(t) }
            softError={ softError }
            save={ save }
            updating={ updating }
            close={ close }
            t={ t }
        >
            { ({
                values, errors, touched, handleChange, setFieldValue, setValues,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 }>
                    <Select
                        block={ true }
                        label="Service"
                        options={ enumToOptions(SmtpEnum) }
                        selected={ values.service }
                        error={ touched.service && errors.service }
                        onChange={ ({ selected }) => {
                            setFieldValue('service', selected?.key);
                            if (selected?.key !== SmtpEnum.Custom) {
                                setFieldValue('host', null);
                                setFieldValue('port', null);
                                setFieldValue('sender', null);
                            }
                        } }
                        optionsListWidth="field"
                    />

                    <Input
                        label="User"
                        value={ values.user }
                        error={ touched.user && errors.user }
                        onChange={ handleChange('user') }
                        block={ true }
                    />

                    <Input
                        label="Pass"
                        value={ values.pass }
                        type="password"
                        error={ touched.pass && errors.pass }
                        onChange={ handleChange('pass') }
                        block={ true }
                    />

                    <Input
                        label="Sender"
                        value={ values.sender }
                        error={ touched.sender && errors.sender }
                        onChange={ handleChange('sender') }
                        block={ true }
                    />

                    { values.service === SmtpEnum.Custom && (
                        <React.Fragment>
                            <Input
                                label="Host"
                                value={ values.host }
                                error={ touched.host && errors.host }
                                onChange={ handleChange('host') }
                                block={ true }
                            />

                            <AmountInput
                                label="Port"
                                suffix=""
                                value={ values.port }
                                error={ touched.port && errors.port }
                                onChange={ (_, { value }) => setFieldValue('port', value) }
                                block={ true }
                            />
                        </React.Fragment>
                    ) }

                    <LocationsPicker
                        error={ !!errors.locationIds }
                        title={ t('Все локации') }
                        allLocations={ values.allLocations }
                        locationIds={ values.locationIds }
                        onChange={ (value) => setValues({ ...values, ...value }) }
                    />
                </FlexColumns>
            ) }
        </RestForm>
    );
};
