import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext, Table } from '@alphakits/ui/dist';
import { FinanceItemModal } from 'src/modals/finance-item';
import { useApi } from 'src/providers/api/context';
import { getLanguage } from 'src/utils/get-language';

import { FinanceitemColumns } from './columns';

export const FinanceitemsPage: React.FC = () => {
    const { financeItems } = useApi();
    const { openModal } = useContext(ModalContext);
    const { i18n, t } = useTranslation();

    const columns = useMemo(() => FinanceitemColumns(t), [t]);

    const openFinanceitemModal = openModal(FinanceItemModal);

    return (
        <Table.TableComponent
            columns={ columns }
            hideSearch={ true }
            onRowClick={ ({ id }, cb) => openFinanceitemModal({ recordId: +id, cb }) }
            onCreateClick={ (cb) => openFinanceitemModal({ cb }) }
            language={ getLanguage(i18n) }
            promise={ financeItems.query }
        />
    );
};
