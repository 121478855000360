import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FlexColumns, Typography } from '@alphakits/ui/dist';

type Props = {
    onClick: () => void;
};

export const Step0: React.FC<Props> = ({ onClick }) => {
    const { t } = useTranslation();

    return (
        <FlexColumns columns={ 1 } gr={ 24 }>
            <Typography.Text view="primary-medium">
                { t('widget-guide:guide.initialStepHint') }
            </Typography.Text>
            <div>
                <Button view="primary" onClick={ onClick }>
                    { t('Внести данные') }
                </Button>
            </div>
        </FlexColumns>
    );
};
