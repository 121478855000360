import React from 'react';
import { CrownM, LockOutlineM } from '@alphakits/icons';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { RoleRO } from '@escapenavigator/types/dist/role/role.ro';

export const RoleColumns = (t): TableColumns<RoleRO> => [
    {
        header: t('tables.title'),
        accessor: 'title',
        row: {
            icon: ({ row }) => (row.totalAccess ? <CrownM /> : <LockOutlineM />),
        },
    },
];
