import React from 'react';
import {
    Checkbox, FlexColumns, Input, useCrudFormRequests,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { UpdateCurrentDto } from '@escapenavigator/types/dist/profile/update-current.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';

type Props = {
    close: () => void;
    t: TFunction;
};

export const FbPixelModal: React.FC<Props> = ({ close, t }) => {
    const { profiles } = useApi();
    const { profile, setProfile } = useCurrentUser();

    const {
        save,
        updating,
        error: softError,
    } = useCrudFormRequests({
        saveRequest: profiles.updateCurrent,
        saveCallback: (savedRecords) => setProfile(savedRecords),
        close,
    });

    return (
        <RestForm
            title="FB Pixel"
            initialValues={ serializeRecord(UpdateCurrentDto, profile) }
            validate={ validateByDto(t) }
            save={ save }
            softError={ softError }
            updating={ updating }
            close={ close }
            t={ t }
        >
            { ({
                values, handleChange, setFieldValue, touched, errors,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    <Checkbox
                        label={ t('Активирована') }
                        checked={ values.fbPixelEnabled }
                        onChange={ (e, { checked }) => setFieldValue('fbPixelEnabled', checked) }
                    />
                    { values.fbPixelEnabled && (
                        <Input
                            dataTestId="ga4Id"
                            label="Pixel ID"
                            value={ values.fbPixelId }
                            error={ touched.fbPixelId && errors.fbPixelId }
                            onChange={ handleChange('fbPixelId') }
                            block={ true }
                        />
                    ) }
                </FlexColumns>
            ) }
        </RestForm>
    );
};
