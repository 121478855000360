import { initClientApi } from '@escapenavigator/services/dist/api/init-client-api';
import { UpdateUserDto } from '@escapenavigator/types/dist/user/crud/update-user.dto';
import { UserRO } from '@escapenavigator/types/dist/user/crud/user.ro';
import { getFullName } from '@escapenavigator/utils/dist/get-full-name';
import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';

import { RootState } from '../store';

type User = UserRO & { title?: string };

const usersAdapter = createEntityAdapter<User>();
const { users } = initClientApi(process.env.REACT_APP_API_DOMAIN);

export const fetchUsers = createAsyncThunk<UserRO[]>('users/fetchUsers', async () => {
    const { data } = await users.query(undefined);

    return data;
});

export const updateUser = createAsyncThunk<User, UpdateUserDto>(
    'users/upadteUser',
    async (params) => {
        const { data } = await users.update({ data: params, id: null });

        return data;
    },
);

const usersSlice = createSlice({
    name: 'partners',
    initialState: usersAdapter.getInitialState(),
    reducers: {
        upsertUser(state, action: PayloadAction<UserRO>) {
            usersAdapter.upsertOne(state, action.payload);
        },
        upsertUsers(state, action: PayloadAction<UserRO[]>) {
            usersAdapter.upsertMany(state, action.payload);
        },
        removeUser(state, action: PayloadAction<UserRO['id']>) {
            usersAdapter.removeOne(state, action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUsers.fulfilled, (state, action: PayloadAction<UserRO[]>) => {
            usersAdapter.addMany(state, action.payload);
        });
        builder.addCase(updateUser.fulfilled, (state, action: PayloadAction<UserRO>) => {
            usersAdapter.upsertOne(state, action.payload);
        });
    },
});

const { selectAll, selectById } = usersAdapter.getSelectors<RootState>((state) => state.users);

export const selectAllUsers = (currentId: number) =>
    createSelector(
        (state: RootState) => state,
        (state) => {
            const list = selectAll(state);
            const current = list.find((u) => u.id === currentId);

            const up = list
                .map((u) =>
                    (u.id === currentId
                        ? {
                            ...u,
                            surname: `${u.surname} (you)`,
                            title: `${getFullName(u)}  (you)`,
                        }
                        : {
                            ...u,
                            title: getFullName(u),
                        }))
                .filter((u) => {
                    if (!current || current.allLocations || u.id === current.id) return true;

                    return (
                        u.allLocations || u.locationIds.some((l) => current.locationIds.includes(l))
                    );
                });

            return up;
        },
    );

export const selectUserById = (id) =>
    createSelector(
        (state: RootState) => state,
        (state) => {
            if (!id) {
                return null;
            }
            if (id === 999999999) {
                return {
                    name: 'Support',
                    surname: '',
                    photo: '/admin.jpg',
                } as UserRO;
            }
            if (id === 999999998) {
                return {
                    name: 'Original data',
                    surname: '',
                    photo: '/admin.jpg',
                } as UserRO;
            }

            if (id === 888888888) {
                return {
                    name: 'Client',
                    surname: '',
                    photo: '/admin.jpg',
                } as UserRO;
            }

            return (
                selectById(state, id) ||
                ({
                    name: `User №${id}`,
                    surname: '',
                    photo: '/admin.jpg',
                } as UserRO)
            );
        },
    );

export const selectUserByIdOrIds = (ids: number[] | number) =>
    createSelector(
        (state: RootState) => state,
        (state) => {
            let selected: UserRO[] = [];

            if (typeof ids === 'number') {
                selected.push(
                    selectById(state, ids) ||
                        ({
                            name: 'Unknown user',
                            surname: '',
                            id: +ids,
                        } as UserRO),
                );
            } else {
                selected = Object.values(state.users.entities).filter((user) =>
                    ids.includes(user.id));
            }

            return selected;
        },
    );

export const { upsertUser, upsertUsers, removeUser } = usersSlice.actions;

export default usersSlice.reducer;
