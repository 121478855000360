import React, { useState } from 'react';
import {
    AmountInput,
    Button,
    Flex,
    FlexColumns,
    Sidepanel,
    Tag,
    Textarea,
} from '@alphakits/ui/dist';
import { BaseForm } from '@alphakits/ui/dist/form/templates/base-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CertificateSaleRO } from '@escapenavigator/types/dist/certificate-sale/certificate-sale.ro';
import { CreateCertificatesaleDiscountDto } from '@escapenavigator/types/dist/certificate-sale/create-certificatesale-discount.dto';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { AddCertificate } from 'src/components/add-certificate';
import { useApi } from 'src/providers/api/context';

type Props = {
    t: TFunction;
    close: () => void;
    setCerificate: (values: CertificateSaleRO) => void;
    certificatesale: CertificateSaleRO;
    currency: ProfileCurrencyEnum;
    amount?: number;
};

export const AddHandDiscount: React.FC<Props> = ({
    close,
    t,
    certificatesale,
    setCerificate,
    currency,
    amount = 0,
}) => {
    const { certificatesaleDiscounts, certificatesales } = useApi();

    const { createFetch, createLoading, createError } = useApiMethod({
        api: certificatesaleDiscounts.create,
        successCallback: ({ data }) => {
            setCerificate(data);
            close();
        },
    });

    const createPromocodeApi = useApiMethod({
        api: certificatesales.createPromocode,
        successCallback: ({ data }) => {
            close();
            setCerificate(data);
        },
    });

    const apply = ({ code }: { id: number; code: string; type: string }) => {
        createPromocodeApi.createPromocodeFetch({
            data: { code, certificateId: certificatesale.id },
        });
    };

    const [type, setType] = useState<'promocode' | 'discount'>('discount');

    return (
        <Sidepanel
            title={ t('Скидка') }
            loading={ createLoading || createPromocodeApi.createPromocodeLoading }
        >
            <FlexColumns columns={ 1 } gr={ 32 } gc={ 0 }>
                <Flex justify="start" gap="sm">
                    <Tag
                        onClick={ () => setType('discount') }
                        text={ t('Ручная скидка') }
                        view={ type === 'discount' ? 'primary-inverted' : 'primary' }
                    />

                    <Tag
                        onClick={ () => setType('promocode') }
                        text={ t('Промокод') }
                        view={ type === 'promocode' ? 'primary-inverted' : 'primary' }
                    />
                </Flex>

                { type === 'discount' ? (
                    <BaseForm
                        t={ t }
                        initialValues={ serializeRecord(CreateCertificatesaleDiscountDto, {
                            certificatesaleId: certificatesale.id,
                            amount,
                        }) }
                        enableReinitialize={ true }
                        validate={ validateByDto(t) }
                        loading={ false }
                        save={ async (data) => {
                            createFetch({ data });
                        } }
                    >
                        { ({
                            values, handleChange, errors, touched, setFieldValue, submitForm,
                        }) => (
                            <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                                <AmountInput
                                    value={ values.amount }
                                    suffix={ currency }
                                    error={ touched.amount && errors.amount }
                                    onChange={ (e, { value }) => setFieldValue('amount', value) }
                                    label={ t('Укажите значение ручной скидки') }
                                    required={ true }
                                    block={ true }
                                    type="decimal"
                                />

                                <Textarea
                                    value={ values.reason }
                                    maxLength={ 100 }
                                    counter={ true }
                                    required={ true }
                                    error={ touched.reason && errors.reason }
                                    onChange={ handleChange('reason') }
                                    label={ t('Причина') }
                                    block={ true }
                                />

                                { createError }

                                <Button view="primary" onClick={ submitForm }>
                                    { t('save') }
                                </Button>
                            </FlexColumns>
                        ) }
                    </BaseForm>
                ) : (
                    <AddCertificate
                        apply={ apply }
                        type="certificate"
                        certificate={ certificatesale }
                        timeZone={ Intl.DateTimeFormat().resolvedOptions().timeZone }
                    />
                ) }
            </FlexColumns>
        </Sidepanel>
    );
};
