import { StatusProps } from '@alphakits/ui/dist';
import { differenceInHours } from 'date-fns';
import { TFunction } from 'i18next';
import { NOT_GENERATED_CODE } from 'src/constants';

type Props = {
    utcDate: string;
    canceledWithFine?: boolean;
    slotId?: number;
    total: number;
    payed: number;
    code: string;
};

export type OrderData = {
    status: {
        title: string;
        color: StatusProps['color'];
        type: 'FAILED_BOOKING' | 'BOOKING_IN_PROCESS' | 'CANCELED' | 'DEFAULT';
    };
    gameDate: Date;
    pastGame: boolean;
};

enum OrderStatus {
    CONFIRMED = 'confirmed',
    PREPAYED = 'prepayed',
    OVERPAYED = 'overpayed',
    PAYED = 'payed',
    NOT_PAYED = 'not_payed',
    CANCELED = 'canceled',
    CANCELED_WITH_FINE = 'canceled_with_fine',
    BOOKING_IN_PROCESS = 'booking_in_process',
    FAILED_BOOKING = 'failed_booking',
}

export const getStatusData = (t) =>
    ({
        NOT_PAYED: {
            color: 'blue',
            title: t(`options.orderStatus.${OrderStatus.CONFIRMED}`),
            type: 'DEFAULT',
        },
        PREPAY: {
            color: 'orange',
            title: t(`options.orderStatus.${OrderStatus.PREPAYED}`),
            type: 'DEFAULT',
        },
        PAYED: {
            color: 'green',
            title: t(`options.orderStatus.${OrderStatus.PAYED}`),
            type: 'DEFAULT',
        },
        PAST_NOT_PAYED: {
            color: 'red',
            title: t(`options.orderStatus.${OrderStatus.NOT_PAYED}`),
            type: 'DEFAULT',
        },
        OVERPAY: {
            color: 'purple',
            title: t(`options.orderStatus.${OrderStatus.OVERPAYED}`),
            type: 'DEFAULT',
        },
        BOOKING_IN_PROCESS: {
            color: 'grey',
            title: t(`options.orderStatus.${OrderStatus.BOOKING_IN_PROCESS}`),
            type: 'BOOKING_IN_PROCESS',
        },
        FAILED_BOOKING: {
            color: 'grey',
            title: t(`options.orderStatus.${OrderStatus.FAILED_BOOKING}`),
            type: 'FAILED_BOOKING',
        },
    } as const);

const getStatus = (order: Props, pastGame: boolean, t: TFunction): OrderData['status'] => {
    const canceledGameTitle = order.canceledWithFine
        ? t(`options.orderStatus.${OrderStatus.CANCELED_WITH_FINE}`)
        : t(`options.orderStatus.${OrderStatus.CANCELED}`);

    const payed = order.total === order.payed;
    const status = getStatusData(t);

    if (order.code === NOT_GENERATED_CODE && order.slotId) {
        return status.BOOKING_IN_PROCESS;
    }

    if (order.code === NOT_GENERATED_CODE && !order.slotId) {
        return status.FAILED_BOOKING;
    }

    if (!order.slotId) {
        return {
            color: payed ? 'grey' : 'red',
            title: canceledGameTitle,
            type: 'CANCELED',
        };
    }

    if (payed) return status.PAYED;

    if (order.payed > order.total) return status.OVERPAY;

    if (pastGame) return status.PAST_NOT_PAYED;

    if (order.payed) return status.PREPAY;

    return status.NOT_PAYED;
};

export const getOrderData = (order: Props, t: TFunction): OrderData => {
    const gameDate = new Date(order.utcDate);
    const pastGame = differenceInHours(gameDate, new Date()) < 0;

    return {
        status: getStatus(order, pastGame, t),
        gameDate,
        pastGame,
    };
};
