import React from 'react';
import { DragM } from '@alphakits/icons';
// import { useTranslation } from 'react-i18next';
// import { DotsS } from '@alphakits/icons';
import { Button, Typography } from '@alphakits/ui/dist';

// import cn from 'classnames';
// import { Picker } from 'src/components/picker';
import styles from './index.module.css';

export const Resource = ({
    resource,
    draggable,
}: // slotResourceActions,
{
    draggable?: boolean;
    resource: any;
    // slotResourceActions?: any;
}) => (
    // const { t } = useTranslation();

    <div
        // className={ cn(styles.resource, { [styles.clickable]: !!slotResourceActions }) }
        className={ styles.resource }
    >
        { draggable && (
            <div className={ styles.drag }>
                <Button view="filled" size="xs" leftAddons={ <DragM /> } />
            </div>
        ) }

        { resource.photo && <img alt={ resource.title } src={ resource.photo } /> }

        <Typography.Text className={ styles.title } view="caps" weight="medium" color="primary">
            { resource.title }
        </Typography.Text>

        { resource.addon }

        { resource.emptyState && <div className={ styles.emptyState }>{ resource.emptyState }</div> }

        { /* { slotResourceActions && (
                <Picker
                    t={ t }
                    classname={ styles.dots_btn }
                    actions={ slotResourceActions }
                    content={ DotsS }
                />
            ) } */ }
    </div>
);
