/* eslint-disable func-names */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import { InfoMarkS } from '@alphakits/icons';
import {
    Button, Divider, Flex, Tooltip, Typography,
} from '@alphakits/ui';
import { Languages } from '@escapenavigator/types/dist/shared/enum/languages.enum';
import classNames from 'classnames';

import { HandlerKey } from './get-handlers';
import { pasteText, setValue } from './utils';

import 'react-quill/dist/quill.snow.css';
import styles from './index.module.css';

type WithHandlers =
    | {
        ifKey: string;
        namespace: string;
        handlers: Array<{
            title: string;
            key: HandlerKey;
            description: string;
        }>;
    }
    | {
        ifKey?: never;
        handlers?: never;
        namespace?: never;
    };

type Props = {
    text: string;
    lng: Languages;
    onChange: (value: string) => void;
    error?: string;
} & WithHandlers;

const Btn = ({
    text,
    action,
    description,
}: {
    text: string;
    action: string;
    description: string;
}) => (
    <Button view="secondary" size="xs" className={ classNames(action, styles.action) }>
        <Flex gap="xs">
            { text }

            <Tooltip
                trigger="hover"
                position="top"
                content={ (
                    <div style={ { width: 300, overflow: 'auto' } }>
                        <Typography.Text view="primary-medium">{ description }</Typography.Text>
                    </div>
                ) }
            >
                <InfoMarkS style={ { opacity: 0.4 } } />
            </Tooltip>
        </Flex>
    </Button>
);

export const Quill: React.FC<Props> = ({
    onChange, text, handlers, lng, error, namespace,
}) => {
    const { t } = useTranslation();

    const modules = useMemo(() => {
        const handlersObject = {
            handlers: {},
        };

        handlers?.forEach((handler) => {
            handlersObject.handlers[handler.key] = function () {
                setValue.call(
                    this,
                    pasteText({
                        param: handler.key,
                        t,
                        lng,
                        namespace,
                    }),
                );
            };
        });

        return {
            toolbar: {
                container: `#toolbar${lng}`,
                handlers: handlersObject.handlers,
            },
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (txt: string) => {
        onChange(txt);
    };

    return (
        <div className={ styles.wrap }>
            <div id={ `toolbar${lng}` } className={ styles.toolbar }>
                <Flex gap="xs" justify="start">
                    <button type="button" className="ql-link" />
                    <button type="button" className="ql-bold" />
                    <button type="button" className="ql-clean" />
                </Flex>

                { handlers && (
                    <React.Fragment>
                        <Divider size="m" />

                        <Flex gap="xs" justify="start" wrap={ true }>
                            { handlers.map((h) => (
                                <Btn
                                    key={ h.key }
                                    description={ h.description }
                                    text={ h.title }
                                    action={ `ql-${h.key}` }
                                />
                            )) }
                        </Flex>
                    </React.Fragment>
                ) }
            </div>

            <ReactQuill
                className={ styles.editor }
                value={ text }
                onChange={ handleChange }
                theme="snow"
                modules={ modules }
            />

            { error && (
                <Typography.Text view="caps" color="negative">
                    { error }
                </Typography.Text>
            ) }
        </div>
    );
};
