import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    CalendarInput,
    FlexColumns,
    ListAllert,
    ModalContext,
    useCrudFormRequests,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CreateDaySessionsDto } from '@escapenavigator/types/dist/user/sessions/create-day-sessions.dto';
import { DaySessionsRO } from '@escapenavigator/types/dist/user/sessions/day-sessions.ro';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import format from 'date-fns/format';
import { UsersSelect } from 'src/components/selects/users-select';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { upsetSession } from 'src/store';
import { useAppDispatch } from 'src/store/hooks';

import { SessionsList } from './sessions-list';
import { UpdateUserSessionModal } from './update';

type Props = {
    date: string;
    locationId: number;
    close: () => void;
    cb?: () => void;
};

export const CreateUserSessionModal: React.FC<Props> = ({
    close, cb, date, locationId,
}) => {
    const { users: usersApi } = useApi();
    const { t, i18n } = useTranslation();
    const {
        current: { role },
    } = useCurrentUser();

    const dispatch = useAppDispatch();

    const { openModal } = useContext(ModalContext);

    const {
        getSessionByUserAndDateFetch,
        getSessionByUserAndDateData,
        getSessionByUserAndDateLoading,
    } = useApiMethod({
        api: usersApi.getSessionByUserAndDate,
    });

    const openUpdateModal = (data?: DaySessionsRO) => {
        close();
        openModal(UpdateUserSessionModal)({
            session: data || getSessionByUserAndDateData,
        });
    };

    // const sessions = useAppSelector(selectAllSessions());

    const accessedDate =
        format(new Date(), 'yyyy-MM-dd') <= date &&
        (role.totalAccess || role.canEditOwnWorkedHours);

    const caneditOtherSessions = role.totalAccess || role.canEditWorkedHours;

    const canEditDate = (caneditOtherSessions || accessedDate) && !getSessionByUserAndDateLoading;

    const canEditFileds = canEditDate && !getSessionByUserAndDateData;

    const {
        save,
        remove,
        removing,
        updating,
        error: softError,
    } = useCrudFormRequests({
        saveRequest: usersApi.createSession,
        saveCallback: (data) => {
            if (cb) cb();

            dispatch(upsetSession(data));
        },
        close,
    });

    return (
        <RestForm
            initialValues={ serializeRecord(CreateDaySessionsDto, {
                date,
                sessions: [
                    {
                        locationId,
                    },
                ],
            }) }
            validate={ validateByDto(t) }
            save={ save }
            remove={ canEditFileds && remove }
            removing={ removing }
            title={ t('Смена') }
            close={ close }
            updating={ updating }
            softError={ softError }
            t={ t }
        >
            { ({
                values, errors, touched, setFieldValue, handleChange,
            }) => (
                <FlexColumns columns={ 1 }>
                    <CalendarInput
                        lang={ i18n.language }
                        block={ true }
                        disabled={ !canEditDate }
                        label={ t('filters.date') }
                        value={ values.date }
                        onChange={ (e, { value }) => {
                            if (!values.userId) return setFieldValue('date', value);

                            return getSessionByUserAndDateFetch({
                                date: value,
                                userId: `${values.userId}`,
                            }).then(() => {
                                setFieldValue('date', value);
                            });
                        } }
                        error={ touched.date && errors.date }
                    />

                    <UsersSelect
                        block={ true }
                        label={ t('employee') }
                        disabled={ !canEditDate }
                        selected={ values.userId }
                        error={ touched.userId && errors.userId }
                        onChange={ ({ selected }) => {
                            if (!values.date) return setFieldValue('userId', +selected?.key);

                            return getSessionByUserAndDateFetch({
                                date: values.date,
                                userId: `${selected?.key}`,
                            }).then(() => {
                                setFieldValue('userId', +selected?.key);
                            });
                        } }
                    />

                    { getSessionByUserAndDateData && (
                        <FlexColumns columns={ 1 } gr={ 0 }>
                            <ListAllert text={ t('userHasShift') } />
                            <div>
                                <Button view="outlined" size="xs" onClick={ () => openUpdateModal() }>
                                    { t('Редактировать') }
                                </Button>
                            </div>
                        </FlexColumns>
                    ) }

                    <SessionsList
                        values={ values }
                        errors={ errors }
                        touched={ touched }
                        setFieldValue={ setFieldValue }
                        handleChange={ handleChange }
                        canEditFileds={ canEditFileds }
                    />
                </FlexColumns>
            ) }
        </RestForm>
    );
};
