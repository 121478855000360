import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button, Loader, ModalContext, SuperListHeader,
} from '@alphakits/ui/dist';
import { QueryDto } from '@escapenavigator/types/dist/shared/query.dto';
import { usePaginationQuery } from 'src/hooks/use-pagination-query';
import { CertificatesaleModal } from 'src/modals/certificatesale';
import { CertificateTableItem } from 'src/modals/components/certificate-table-item';
import { ModalTable } from 'src/modals/components/modal-table';
import { TableEmpty } from 'src/modals/components/table-empty';
import { useApi } from 'src/providers/api/context';

type CertificatesListProps = {
    criteria: QueryDto['where'];
};

export const CertificatesalesList = ({ criteria }: CertificatesListProps) => {
    const { certificatesales } = useApi();
    const { t } = useTranslation();

    const { openModal } = useContext(ModalContext);

    const {
        records = [],
        hasMore,
        loading,
        total,
        nextPage,
        reload,
    } = usePaginationQuery({ api: certificatesales.query, limit: 8 });

    const openCertificateModal = openModal(CertificatesaleModal, 'm', true);

    useEffect(() => {
        reload(criteria);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <SuperListHeader
                text={ `${t('tables.certificates')} (${total || 0})` }
                rightAddons={
                    hasMore && (
                        <Button view="outlined" onClick={ () => nextPage(criteria) } size="xs">
                            { t('loadMore') }
                        </Button>
                    )
                }
            />
            { records.length ? (
                <React.Fragment>
                    <ModalTable>
                        { records.map((certificatesale) => (
                            <CertificateTableItem
                                key={ certificatesale.code }
                                onClick={ () =>
                                    openCertificateModal({ certificatesaleId: certificatesale.id }) }
                                certificate={ certificatesale }
                            />
                        )) }
                    </ModalTable>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    { loading ? <Loader /> : <TableEmpty text={ t('typography.noEntriesHere') } /> }
                </React.Fragment>
            ) }
        </React.Fragment>
    );
};
