import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext, Table } from '@alphakits/ui/dist';
import { RoleModal } from 'src/modals/role';
import { selectAllRoles } from 'src/store';
import { useAppSelector } from 'src/store/hooks';
import { getLanguage } from 'src/utils/get-language';

import { RoleColumns } from './columns';

export const RolesPage: React.FC = () => {
    const { openModal } = useContext(ModalContext);
    const { i18n, t } = useTranslation();

    const roles = useAppSelector(selectAllRoles) || [];
    const columns = useMemo(() => RoleColumns(t), [t]);

    const openRoleModal = openModal(RoleModal);

    return (
        <Table.TableComponent
            columns={ columns }
            hideSearch={ true }
            onRowClick={ ({ id }) => openRoleModal({ recordId: +id }) }
            onCreateClick={ () => openRoleModal() }
            records={ roles }
            language={ getLanguage(i18n) }
        />
    );
};
