import React from 'react';
import { Link } from 'react-router-dom';

import { AlphaLogo } from './alpha-logo';

import styles from './index.module.css';

export const Logo: React.FC = () => (
    <Link to="/" className={ styles.logo }>
        <AlphaLogo />
        Escape Navigator
    </Link>
);
