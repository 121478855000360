import React from 'react';
import { ListM } from '@alphakits/icons';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { UserPositionRO } from '@escapenavigator/types/dist/user/position/user-position.ro';

export const Columns = (t): TableColumns<UserPositionRO> => [
    {
        header: t('tables.title'),
        accessor: 'title',
        row: {
            icon: () => <ListM />,
        },
    },
];
