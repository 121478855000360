import { StatusProps } from '@alphakits/ui/dist';
import { CertificateSaleRO } from '@escapenavigator/types/dist/certificate-sale/certificate-sale.ro';
import { CertificatesaleDeliveryTypeEnum } from '@escapenavigator/types/dist/certificate-sale/enum/certificatesales-delivery-type.enum';
import { CertificatesaleStatusEnum } from '@escapenavigator/types/dist/certificate-sale/enum/certificatesales-status.enum';
import { differenceInHours, parse } from 'date-fns';
import { TFunction } from 'i18next';

export type CertificateData = {
    title: string;
    color: StatusProps['color'];
    type?: 'used' | 'expired' | 'not_sended' | 'notPayed' | 'extraPayed' | 'annul';
};

const getTitle = (type: CertificatesaleDeliveryTypeEnum, t: TFunction) => {
    if (type === CertificatesaleDeliveryTypeEnum.EMAIL) return t(`options.certificateStatus.${CertificatesaleStatusEnum.WAITING_EMAIL_SENDING}`);
    if (type === CertificatesaleDeliveryTypeEnum.PICKUP) return t(`options.certificateStatus.${CertificatesaleStatusEnum.WAITING_PICKUP}`);

    return t(`options.certificateStatus.${CertificatesaleStatusEnum.WAITING_DELIVERY}`);
};

export const getCertificateData = (
    certifcate: CertificateSaleRO,
    t: TFunction,
): CertificateData => {
    const expiried =
        differenceInHours(parse(certifcate.expireDate, 'yyyy-MM-dd', new Date()), new Date()) < 0;

    if (certifcate.annul) {
        return {
            color: 'grey',
            title: t(`options.certificateStatus.${CertificatesaleStatusEnum.ANNUL}`),
            type: 'annul',
        };
    }

    if (certifcate.order) {
        return {
            color: 'purple',
            title: t(`options.certificateStatus.${CertificatesaleStatusEnum.USED}`),
            type: 'used',
        };
    }

    if (expiried) {
        return {
            color: 'grey',
            title: t(`options.certificateStatus.${CertificatesaleStatusEnum.EXPIRED}`),
            type: 'expired',
        };
    }

    if (certifcate.toPay > 0) {
        return {
            color: 'red',
            title: t(`options.certificateStatus.${CertificatesaleStatusEnum.NOT_PAYED}`),
            type: 'notPayed',
        };
    }

    if (certifcate.toPay < 0) {
        return {
            color: 'purple',
            title: t(`options.certificateStatus.${CertificatesaleStatusEnum.EXTRA_PAYED}`),
            type: 'extraPayed',
        };
    }

    if (certifcate.sended || certifcate.certificate?.inside) {
        return {
            color: 'green',
            title: t(`options.certificateStatus.${CertificatesaleStatusEnum.SENDED}`),
        };
    }

    return {
        color: 'blue',
        title: getTitle(certifcate.deliveryType, t),
        type: 'not_sended',
    };
};
