import React, { useContext, useMemo } from 'react';
import {
    EditS, LetterM, PersonIosM, PhoneM, TrashS,
} from '@alphakits/icons';
import {
    AmountInput,
    Button,
    Cell,
    Checkbox,
    convertToOptions,
    Flex,
    FlexColumns,
    Image,
    ImageUploader,
    ModalContext,
    Select,
    showError,
    showSuccess,
    Sidepanel,
    Typography,
} from '@alphakits/ui/dist';
import { BaseForm } from '@alphakits/ui/dist/form/templates/base-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { ProfileSubscriptionTypeEnum } from '@escapenavigator/types/dist/profile/enum/profile-subscription-type.enum';
import { ImageTypeEnum } from '@escapenavigator/types/dist/upload/enum/image-type.enum';
import { UpdateUserDto } from '@escapenavigator/types/dist/user/crud/update-user.dto';
import { UserRO } from '@escapenavigator/types/dist/user/crud/user.ro';
import { serializeRecord, validateByDto } from '@escapenavigator/utils';
import { TFunction } from 'i18next';
import { LocationsPicker } from 'src/components/locations-picker';
import { SendInviteButton } from 'src/pages/users/users-list/send-invite-button';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import {
    removeUser, selectAllRoles, selectUserById, upsertUser,
} from 'src/store';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';

import { TabPanel, Tabs } from '../../components/tabs';
import { CurrentUserModal } from '../personal-data';

import { UserWorkingShifts } from './working-shifts';

type Props = {
    recordId: number;
    t: TFunction;
    close: () => void;
};

export const UserModal: React.FC<Props> = ({ recordId, t, close }) => {
    const dispatch = useAppDispatch();
    const { openModal } = useContext(ModalContext);

    const { users, uploads } = useApi();
    const { current, profile } = useCurrentUser();

    const user = useAppSelector(selectUserById(recordId));

    const roles = useAppSelector(selectAllRoles);

    const isCurrent = current.user.id === user.id;
    const accessDisabled =
        isCurrent || (!current.role.canEditUsersAccess && !current.role.totalAccess);

    const canEditOther = current.role?.totalAccess || current.role?.canEditModerators;

    const handleSave = (data: UserRO) => {
        dispatch(upsertUser(data));
        showSuccess('Saved');
    };

    const { updateFetch, updateLoading } = useApiMethod({
        api: users.update,
        successCallback: ({ data }) => handleSave(data),
        errorCallback: ({ message }) => showError(message),
    });

    const { removeFetch, removeLoading } = useApiMethod({
        api: users.remove,
        successCallback: ({ data }) => {
            close();
            dispatch(removeUser(data.id));
            showSuccess('Saved');
        },
        errorCallback: ({ message }) => showError(message),
    });

    const { currentUpdateAvatarFetch, currentUpdateAvatarLoading } = useApiMethod({
        api: users.currentUpdateAvatar,
        successCallback: ({ data }) => handleSave(data),
        errorCallback: ({ message }) => showError(message),
    });

    const userRole = useMemo(
        () => roles.find((r) => r.id === user?.roleId)?.title || '-',
        [roles, user],
    );

    return (
        <Sidepanel
            headerBottomAddons={ (
                <Flex>
                    <Flex gap="md" justify="start">
                        { isCurrent ? (
                            <ImageUploader
                                title="uploader"
                                value={ [] }
                                onUpdate={ (value) =>
                                    currentUpdateAvatarFetch({ data: { photo: value[0] } }) }
                                multiple={ false }
                                uploadRequest={ uploads.uploadImage }
                                type={ ImageTypeEnum.AVATAR }
                            >
                                { ({ loading }) => (
                                    <Image
                                        src={ user.photo || '' }
                                        icon={ user.photo ? undefined : <PersonIosM /> }
                                        loading={ currentUpdateAvatarLoading || loading }
                                        view="ellipse"
                                        size="l"
                                        addon={ {
                                            filled: true,
                                            color: 'primary',
                                            icon: <EditS />,
                                        } }
                                    />
                                ) }
                            </ImageUploader>
                        ) : (
                            <Image
                                src={ user.photo || '' }
                                size="l"
                                view="ellipse"
                                icon={ user.photo ? undefined : <PersonIosM /> }
                            />
                        ) }

                        <Flex direction="column" align="start" justify="start">
                            <Typography.Title view="xxsmall" tag="div" weight="bold">
                                { user.name ? `${user.name} ${user.surname}` : user.email }
                            </Typography.Title>

                            <Typography.Text view="caps" color="secondary">
                                { userRole }
                            </Typography.Text>
                        </Flex>
                    </Flex>
                    { !user.lastVisit && <SendInviteButton id={ user.id } t={ t } /> }
                </Flex>
            ) }
        >
            <Tabs
                defaultActive={
                    profile.subscriptionType === ProfileSubscriptionTypeEnum.NONE
                        ? t('Личные данные')
                        : t('Working shifts')
                }
            >
                { profile.subscriptionType !== ProfileSubscriptionTypeEnum.NONE && (
                    <TabPanel name={ t('Working shifts') }>
                        <UserWorkingShifts user={ user } />
                    </TabPanel>
                ) }

                { /* <TabPanel name={ t('Логи') }>{ t('Логи') }</TabPanel>  */ }

                <TabPanel name={ t('component.accessSystem') }>
                    <BaseForm
                        initialValues={ serializeRecord(UpdateUserDto, user) }
                        validate={ validateByDto(t) }
                        loading={ updateLoading || removeLoading }
                        save={ (data) => updateFetch({ id: user.id, data }) }
                        t={ t }
                    >
                        { ({
                            values,
                            setFieldValue,
                            touched,
                            errors,
                            setValues,
                            submitForm,
                            resetForm,
                            dirty,
                        }) => (
                            <FlexColumns columns={ 2 }>
                                <FlexColumns columns={ 1 } gr={ 40 }>
                                    <FlexColumns columns={ 1 } gr={ 16 }>
                                        <Typography.Title view="xxsmall" tag="div" weight="bold">
                                            { t('component.accessSystem') }
                                        </Typography.Title>

                                        <Select
                                            block={ true }
                                            disabled={ accessDisabled }
                                            label={ t('component.roleUser') }
                                            onChange={ ({ selected }) =>
                                                setFieldValue('roleId', selected?.key) }
                                            selected={ values.roleId }
                                            options={ convertToOptions(roles) }
                                            error={ touched.roleId && errors.roleId }
                                        />

                                        <LocationsPicker
                                            error={ !!errors.locationIds }
                                            disabled={ accessDisabled }
                                            allLocations={ values.allLocations }
                                            locationIds={ values.locationIds }
                                            onChange={ (value) => setValues({ ...values, ...value }) }
                                        />
                                    </FlexColumns>

                                    <FlexColumns columns={ 1 } gr={ 16 }>
                                        <Typography.Title view="xxsmall" tag="div" weight="bold">
                                            { t('Рабочее время') }
                                        </Typography.Title>

                                        <AmountInput
                                            dataTestId="hoursContract"
                                            disabled={ accessDisabled }
                                            label={ t('component.workingHoursPerMonth') }
                                            min={ 0 }
                                            suffix={ t('hour', { count: +values.hoursContract }) }
                                            value={ values.hoursContract }
                                            onChange={ (e, { value }) =>
                                                setFieldValue('hoursContract', value) }
                                            block={ true }
                                        />

                                        <Checkbox
                                            label={ t('showInReports') }
                                            disabled={ accessDisabled }
                                            checked={ values.showInReports }
                                            onChange={ (e, { checked }) =>
                                                setFieldValue('showInReports', checked) }
                                        />
                                    </FlexColumns>

                                    { !accessDisabled && canEditOther && (
                                        <Flex>
                                            <Flex gap="md" justify="start">
                                                <Button
                                                    disabled={ !dirty }
                                                    onClick={ () => submitForm() }
                                                    view="primary"
                                                    size="xs"
                                                >
                                                    { t('save') }
                                                </Button>
                                                <Button
                                                    disabled={ !dirty }
                                                    onClick={ () => resetForm() }
                                                    view="outlined"
                                                    size="xs"
                                                >
                                                    { t('cancel') }
                                                </Button>
                                            </Flex>
                                            <Button
                                                onClick={ () => removeFetch(user.id) }
                                                view="ghost"
                                                disabled={ isCurrent }
                                                size="xs"
                                                leftAddons={ <TrashS /> }
                                            >
                                                { t('remove') }
                                            </Button>
                                        </Flex>
                                    ) }
                                </FlexColumns>
                            </FlexColumns>
                        ) }
                    </BaseForm>
                </TabPanel>

                <TabPanel name={ t('Личные данные') }>
                    <FlexColumns columns={ 1 } gr={ 4 }>
                        <Cell.Base
                            addon={ <Image view="ellipse" icon={ <LetterM /> } /> }
                            title={ t('email') }
                            subtitle={ user.email }
                        />

                        <Cell.Base
                            addon={ <Image view="ellipse" icon={ <PhoneM /> } /> }
                            title={ t('phone') }
                            subtitle={ user.phone || '-' }
                        />

                        { isCurrent && (
                            <div>
                                <br />
                                <Button
                                    onClick={ () =>
                                        openModal(CurrentUserModal)({ recordId: user.id }) }
                                    view="outlined"
                                    size="xs"
                                >
                                    { t('Редактировать') }
                                </Button>
                            </div>
                        ) }
                    </FlexColumns>
                </TabPanel>
            </Tabs>
        </Sidepanel>
    );
};
