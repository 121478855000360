import { CrossSaleLocaleDto } from '@escapenavigator/types/dist/profile/cross-sale/cross-sale.dto';
import { useCurrentUser } from 'src/providers/current-user/context';

type Props = {
    locales: CrossSaleLocaleDto[];
};

export const useLocales = ({ locales }: Props) => {
    const { profile } = useCurrentUser();

    return profile.availableLanguages.map((lang) => {
        const existing = locales.find((l) => l.language === lang);

        return (
            existing || {
                feedbackText: '',
                title: '',
                language: lang,
            }
        );
    });
};
