/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
    Amount,
    AmountInput,
    Checkbox,
    FlexColumns,
    Input,
    ListAllert,
    Typography,
} from '@alphakits/ui/dist';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { UpsellingResponseObject } from '@escapenavigator/types/dist/upselling/upselling.ro';
import { TFunction } from 'i18next';
import { CustomUpsellingData } from 'src/modals/order/sub-modals/add-upsellings';
import { daysTemplate } from 'src/modals/slots/autocomplite/days-of-week';

import styles from './index.module.css';

export interface UpsellingItemProps {
    upselling: UpsellingResponseObject;
    comment?: string;
    amount: number | null;
    currency: ProfileCurrencyEnum;
    selected: boolean;
    error: any;
    t: TFunction;
    onClick: () => void;
    onChange: (data: CustomUpsellingData) => void;
}

const getUpsellingDescription = (upselling: UpsellingResponseObject, t: TFunction) => {
    let res = upselling.comment || '';

    if (!!upselling.availableDays.length && upselling.availableDays.length !== 7) {
        res = res ? `${res}. ` : '';

        res += `${t('Доп услуга доступна')} ${upselling.availableDays
            .map((d) => daysTemplate[d])
            .join(', ')}`;
    }
    if (upselling.availableFrom !== '00:00') {
        res = res ? `${res},` : t('Доп услуга доступна');
        res += ` ${t('from')} ${upselling.availableFrom}`;
    }

    if (upselling.availableTo !== '23:55') {
        res = res || t('Доп услуга доступна');
        res += ` ${t('to')} ${upselling.availableTo}`;
    }

    return res;
};

export const UpsellingItem: React.FC<UpsellingItemProps> = ({
    upselling,
    currency,
    amount,
    comment,
    onClick,
    t,
    error,
    selected,
    onChange,
}) => {
    const description = getUpsellingDescription(upselling, t);

    return (
        <div className={ styles.item_wrapper }>
            <button
                onClick={ (e) => {
                    e.preventDefault();
                    onClick();
                } }
                type="button"
                className={ styles.button }
            >
                <div className={ styles.title }>
                    <Checkbox
                        label={ upselling.title }
                        align="start"
                        checked={ selected }
                        onChange={ null }
                        hint={ (
                            <FlexColumns columns={ 1 } gr={ 8 }>
                                { upselling.description && (
                                    <Typography.Text className={ styles.description }>
                                        { upselling.description }
                                    </Typography.Text>
                                ) }

                                { description && <ListAllert text={ description } view="accent" /> }
                            </FlexColumns>
                        ) }
                    />

                    { !!amount && (
                        <Amount
                            type="decimal"
                            weight="bold"
                            view="title"
                            value={ amount }
                            currency={ currency }
                        />
                    ) }
                </div>
            </button>

            { upselling.flexiblePrice && selected && (
                <FlexColumns columns={ 1 } gc={ 12 }>
                    <br />
                    <AmountInput
                        block={ true }
                        type="decimal"
                        error={ error }
                        required={ selected }
                        className={ styles.input }
                        label={ t('Введите итоговую стоимость данной услуги') }
                        value={ amount }
                        onChange={ (e, { value: v }) => onChange({ amount: +v, comment }) }
                        suffix={ currency }
                    />

                    <Input
                        value={ comment }
                        onChange={ (e, { value }) => onChange({ amount, comment: value }) }
                        label={ t('Комментарий') }
                        block={ true }
                    />
                </FlexColumns>
            ) }
        </div>
    );
};
