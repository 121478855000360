import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { Base } from './base';

import styles from './index.module.css';

interface MenuButtonProps {
    active: boolean;
    leftAddons?: React.ReactNode;
    rightAddons?: React.ReactNode;
    href: string;
    secondary?: boolean;
}

export const MenuButton: React.FC<MenuButtonProps> = ({
    href,
    children,
    active,
    leftAddons,
    rightAddons,
    secondary,
}) => (
    <Link
        className={ cn(styles.menu_button, {
            [styles.active]: active,
            [styles.secondary]: secondary,
        }) }
        to={ href }
    >
        <Base leftAddons={ leftAddons } rightAddons={ rightAddons }>
            { children }
        </Base>
    </Link>
);
