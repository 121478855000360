import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    CopyButton,
    FlexColumns,
    showError,
    Table,
    ToastPlate,
    Typography,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { getLanguage } from 'src/utils/get-language';

import { Columns } from './columns';

import styles from './index.module.css';

export const ApiKeysPage: React.FC = () => {
    const { t } = useTranslation();

    const {
        current: { role },
    } = useCurrentUser();
    const { profiles } = useApi();
    const { i18n } = useTranslation();
    const [apiKey, setApiKey] = useState('');

    const { createApiKeyFetch, createApiKeyLoading } = useApiMethod({
        api: profiles.createApiKey,
        errorCallback: ({ message }) => showError(message),
        successCallback: ({ data }) => setApiKey(data.key),
    });

    const { getApiKeysFetch, getApiKeysLoading, getApiKeysData } = useApiMethod({
        api: profiles.getApiKeys,
        errorCallback: ({ message }) => showError(message),
        successCallback: ({ data }) => console.log(data),
    });

    const { removeApiKeyFetch, removeApiKeyLoading } = useApiMethod({
        api: profiles.removeApiKey,
        errorCallback: ({ message }) => showError(message),
        successCallback: ({ data }) => console.log(data),
    });

    useEffect(() => {
        getApiKeysFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCreate = () => {
        if (!role.totalAccess) {
            return showError('Only users with total acccess can create api keys');
        }

        return createApiKeyFetch(undefined).then(getApiKeysFetch);
    };

    const onRemove = (id: number) => {
        if (!role.totalAccess) {
            return showError('Only users with total acccess can remove api keys');
        }

        return removeApiKeyFetch(id).then(getApiKeysFetch);
    };

    const columns = useMemo(
        () => Columns({ t, onRemove }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [t],
    );

    return (
        <FlexColumns columns={ 1 } gr={ 32 }>
            { apiKey && (
                <ToastPlate
                    view="positive"
                    onClose={ () => setApiKey('') }
                    hasCloser={ true }
                    title={ t('newApiKeyTitle') }
                >
                    <FlexColumns columns={ 1 } gr={ 12 }>
                        <Typography.Text view="caps" className={ styles.text }>
                            { t('newApiKeyDescription') }
                        </Typography.Text>

                        <CopyButton
                            text={
                                (
                                    <Typography.Text
                                        view="caps"
                                        weight="medium"
                                        className={ styles.text }
                                    >
                                        { apiKey }
                                    </Typography.Text>
                                ) as any as string
                            }
                            fullText={ apiKey }
                        />
                    </FlexColumns>
                </ToastPlate>
            ) }
            <Table.TableComponent
                columns={ columns }
                hideSearch={ true }
                loading={ getApiKeysLoading || removeApiKeyLoading || createApiKeyLoading }
                onCreateClick={ onCreate }
                language={ getLanguage(i18n) }
                records={ getApiKeysData || [] }
            />
        </FlexColumns>
    );
};
