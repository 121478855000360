import React, { useContext, useEffect, useState } from 'react';
import { EditM } from '@alphakits/icons';
import {
    Button,
    IconButton,
    InfoBlockWrapper,
    Loader,
    ModalContext,
    Padding,
    SidepanelHeader,
    SuperListHeader,
} from '@alphakits/ui/dist';
import { FormLoading } from '@alphakits/ui/dist/form/components/form-loading';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { PromocodeRO } from '@escapenavigator/types/dist/promocode/promocode.ro';
import { TFunction } from 'i18next';
import { PromocodeDescription } from 'src/components/details/promodoe-description.tsx';
import { usePaginationQuery } from 'src/hooks/use-pagination-query';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';

import { DoubleModal, ModalRightSide, SidePanelHeaderWrapper } from '../components/double-modal';
import { ModalTable } from '../components/modal-table';
import { OrderTableItem } from '../components/order-table-item';
import { TableEmpty } from '../components/table-empty';
import { OrderModal } from '../order';
import { PromocodeModal } from '../promocode';

type Props = { recordId?: number; t: TFunction };

export const PromocodeInfoModal: React.FC<Props> = ({ recordId, t }) => {
    const { promocodes, orderPromocodes } = useApi();
    const { openModal } = useContext(ModalContext);
    const {
        profile: { currency },
    } = useCurrentUser();
    const [promocode, setPromocode] = useState<PromocodeRO>();

    const { getOneLoading, getOneFetch } = useApiMethod({
        api: promocodes.getOne,
        successCallback: ({ data }) => setPromocode(data),
    });

    const {
        records = [],
        hasMore,
        total,
        loading,
        nextPage,
        reload,
    } = usePaginationQuery({ api: orderPromocodes.query, limit: 8 });

    const openOrderModal = openModal(OrderModal, 'm', true);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getOneFetch(recordId);
        reload({ promocodeId: recordId });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordId]);

    if (getOneLoading || !promocode) {
        return (
            <DoubleModal>
                <FormLoading />
                <ModalRightSide loading={ true } />
            </DoubleModal>
        );
    }

    return (
        <DoubleModal>
            <div>
                <Padding padding="0 32px">
                    <SidePanelHeaderWrapper>
                        <SidepanelHeader
                            title={ promocode.code }
                            rightAddons={ (
                                <IconButton
                                    icon={ EditM }
                                    onClick={ () =>
                                        openModal(PromocodeModal)({
                                            recordId: promocode.id,
                                            cb: setPromocode,
                                        }) }
                                />
                            ) }
                        />
                    </SidePanelHeaderWrapper>

                    <React.Fragment>
                        <SuperListHeader
                            text={ `${t('reservation')} (${total || 0})` }
                            rightAddons={
                                hasMore && (
                                    <Button
                                        view="outlined"
                                        onClick={ () => nextPage({ promocodeId: recordId }) }
                                        size="xs"
                                    >
                                        { t('loadMore') }
                                    </Button>
                                )
                            }
                        />
                        { records.length ? (
                            <ModalTable>
                                { records.map((order) => (
                                    <OrderTableItem
                                        onClick={ () => openOrderModal({ recordId: order.order.id }) }
                                        key={ order.id }
                                        order={ order.order }
                                    />
                                )) }
                            </ModalTable>
                        ) : (
                            <React.Fragment>
                                { loading ? (
                                    <Loader />
                                ) : (
                                    <TableEmpty text={ t('typography.noEntriesHere') } />
                                ) }
                            </React.Fragment>
                        ) }
                    </React.Fragment>
                </Padding>
            </div>

            <ModalRightSide loading={ getOneLoading }>
                <InfoBlockWrapper view="bordered">
                    <PromocodeDescription currency={ currency } promocode={ promocode } />
                </InfoBlockWrapper>
            </ModalRightSide>
        </DoubleModal>
    );
};
