/* eslint-disable complexity */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
    KnifeS, LockS, PersonsIosS, SkullS,
} from '@alphakits/icons';
import {
    Box,
    Button,
    Flex,
    FlexColumns,
    Image,
    showError,
    Skeleton,
    Typography,
    useMatchMedia,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { LocationRO } from '@escapenavigator/types/dist/location/location.ro';
import { CreateQuestroomDto } from '@escapenavigator/types/dist/questroom/create-questroom.dto';
import { QuestroomRO } from '@escapenavigator/types/dist/questroom/questroom.ro';
import { serializeRecord } from '@escapenavigator/utils';
import classNames from 'classnames';
import { CollapsedTypography } from 'src/components/collapsed-typography';
import { QuestroomTagsPicker } from 'src/modals/questroom/tags-selector';
import { useApi } from 'src/providers/api/context';
import { upsertQuestroom } from 'src/store';

import styles from '../index.module.css';

type FullQuestroom = QuestroomRO & { location: LocationRO; hasError: boolean };

type Props = {
    questroom: FullQuestroom;
    shaking: boolean;
    openQuestroomModal: (q: QuestroomRO) => void;
};

export const QuestroomCell: React.FC<Props> = ({ openQuestroomModal, questroom, shaking }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { questrooms } = useApi();
    const [isDesktop] = useMatchMedia('(min-width: 980px)');

    const { closeManyFetch, closeManyLoading } = useApiMethod({
        api: questrooms.closeMany,
        errorCallback: ({ message }) => showError(message),
        successCallback: ({ data }) => {
            data.map((q) => dispatch(upsertQuestroom(q)));
        },
    });

    const { updateFetch, updateLoading } = useApiMethod({
        api: questrooms.update,
        errorCallback: ({ message }) => showError(message),
        successCallback: ({ data }) => {
            dispatch(upsertQuestroom(data));
        },
    });

    const color = questroom.closed ? 'tertiary' : 'primary';

    return (
        <Skeleton visible={ updateLoading }>
            <Box border={ true } padding={ isDesktop ? 'xl' : 'md' } rounded="md">
                <FlexColumns columns={ 1 } className={ questroom.hasError && styles.error } gr={ 24 }>
                    <FlexColumns columns={ 1 } gr={ 4 }>
                        <Typography.Title tag="div" view="xsmall" color={ color } weight="bold">
                            { questroom.title }
                        </Typography.Title>

                        <Typography.Text view="caps" color="tertiary">
                            { t(`common:types.${questroom.type}`) }, +{ questroom.minAge }{ ' ' }
                            { questroom.closed && '[CLOSED]' }
                        </Typography.Text>

                        <Typography.Text view="title" color={ color }>
                            { questroom?.location?.address || 'Removed location' }
                        </Typography.Text>
                    </FlexColumns>

                    <Flex gap="sm" justify="start">
                        { questroom.photos.map((src) => (
                            <Image
                                src={ src }
                                className={ classNames(
                                    styles.img,
                                    questroom.closed && styles.blur,
                                    styles.img,
                                ) }
                            />
                        )) }
                    </Flex>

                    <Flex justify="start" wrap={ true } gap="md">
                        <Flex gap="xs" justify="start">
                            <PersonsIosS />

                            <Typography.Text view="caps" color={ color }>
                                { questroom.playersMin } - { questroom.playersMax }
                            </Typography.Text>
                        </Flex>

                        <Flex gap="xs" justify="start">
                            <LockS />

                            <Typography.Text
                                className={ !questroom.difficult && shaking && styles.shake }
                                view="caps"
                                color={ color }
                            >
                                { t(`common:difficult.${questroom.difficult}`) }
                            </Typography.Text>
                        </Flex>

                        <Flex gap="xs" justify="start">
                            <SkullS />

                            <Typography.Text
                                className={ !questroom.fear && shaking && styles.shake }
                                view="caps"
                                color={ color }
                            >
                                { t(`common:fear.${questroom.fear}`) }
                            </Typography.Text>
                        </Flex>

                        <Flex gap="xs" justify="start">
                            <KnifeS />

                            <Typography.Text
                                className={ !questroom.fear && shaking && styles.shake }
                                view="caps"
                                color={ color }
                            >
                                { t(`common:actors.${questroom.actors}`) }
                            </Typography.Text>
                        </Flex>
                    </Flex>

                    <FlexColumns gr={ 8 } columns={ 1 }>
                        <Typography.Text view="title" weight="bold" color={ color }>
                            { t('component.legend') }
                        </Typography.Text>

                        <CollapsedTypography
                            color={ color }
                            view="caps"
                            text={ questroom.legend || '-' }
                        />
                    </FlexColumns>

                    <FlexColumns gr={ 8 } columns={ 1 }>
                        <Typography.Text view="title" weight="bold" color={ color }>
                            { t('component.importantInfo') }
                        </Typography.Text>

                        <CollapsedTypography
                            color={ color }
                            view="caps"
                            text={ questroom.importantInfo || '-' }
                        />
                    </FlexColumns>

                    { !questroom.closed && (
                        <FlexColumns columns={ 1 } gr={ 8 }>
                            { !questroom.questroomTags.length && (
                                <Typography.Text
                                    view="caps"
                                    className={ shaking && styles.shake }
                                    color="negative"
                                >
                                    { t('Теги не указаны') }
                                </Typography.Text>
                            ) }
                            <Flex justify="start" gap="sm">
                                <QuestroomTagsPicker
                                    tags={ questroom.questroomTags }
                                    setTags={ (tags) =>
                                        updateFetch({
                                            id: questroom.id,
                                            data: serializeRecord(CreateQuestroomDto, {
                                                ...questroom,
                                                questroomTags: tags,
                                            }),
                                        }) }
                                />
                            </Flex>
                        </FlexColumns>
                    ) }

                    <Flex justify="start" gap="sm" wrap={ true }>
                        <Button
                            view="outlined"
                            size="xs"
                            block={ true }
                            onClick={ () => openQuestroomModal(questroom) }
                        >
                            { t('Редактировать') }
                        </Button>

                        <Button
                            view="outlined"
                            size="xs"
                            block={ true }
                            loading={ closeManyLoading }
                            onClick={ () =>
                                closeManyFetch({ ids: [questroom.id], closed: !questroom.closed }) }
                        >
                            { questroom.closed ? t('Квест не закрыт') : t('Квест закрыт') }
                        </Button>
                    </Flex>
                </FlexColumns>
            </Box>
        </Skeleton>
    );
};
