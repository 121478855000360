import React, { useRef } from 'react';
import {
    AmountInput, FlexColumns, ImageUploader, ToastPlate,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { OrderRO } from '@escapenavigator/types/dist/order/order.ro';
import { UpdateOrderDto } from '@escapenavigator/types/dist/order/update-order.dto';
import { ImageTypeEnum } from '@escapenavigator/types/dist/upload/enum/image-type.enum';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

type Props = {
    t: TFunction;
    close: () => void;
    changeResult: (data: Pick<UpdateOrderDto, 'photos' | 'result'>) => void;
    order: OrderRO;
};

export const Result: React.FC<Props> = ({
    close, t, order, changeResult,
}) => {
    const photosRef = useRef<HTMLInputElement>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { orders } = useApi();

    return (
        <RestForm
            initialValues={ serializeRecord(UpdateOrderDto, order) }
            validate={ validateByDto(t) }
            loading={ false }
            removing={ false }
            title={ t('Результат игры') }
            t={ t }
            close={ close }
            save={ async (data) => {
                changeResult(data);
                close();
            } }
        >
            { ({ values, setFieldValue }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    <AmountInput
                        value={ values.result }
                        onChange={ (e, { value }) => setFieldValue('result', +value) }
                        label={ t('Результат игры') }
                        block={ true }
                        suffix="min"
                        required={ true }
                    />

                    <ImageUploader
                        ref={ photosRef }
                        title={ t('Фото команды') }
                        value={ values.photos }
                        onUpdate={ (photos) => setFieldValue('photos', photos) }
                        type={ ImageTypeEnum.ORDER }
                        uploadRequest={ ({ data }) =>
                            orders.uploadParticipantsPhotos({
                                orderId: order.id,
                                data,
                                onUploadProgress: () => {},
                            }) }
                        multiple={ true }
                    />

                    <ToastPlate view="default">
                        { t('Предупреждение по клиентским фоткам') }
                    </ToastPlate>
                </FlexColumns>
            ) }
        </RestForm>
    );
};
