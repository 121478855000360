import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    Box,
    formatDate,
    Image,
    InfoBlockHeader,
    InfoBlockItem,
    Typography,
} from '@alphakits/ui/dist';
import { LogRO } from '@escapenavigator/types/dist/log/log.ro';
import { formatAmount } from '@escapenavigator/utils/dist/format-amount';
import { convertUTCDateToZonedDate } from '@escapenavigator/utils/dist/tz-date';
import { useCurrentUser } from 'src/providers/current-user/context';
import { selectUserById } from 'src/store';

import styles from './index.module.css';

type Props = {
    log: LogRO;
    onClick?: () => void;
};

export const Log: React.FC<Props> = ({ log, onClick }) => {
    const user = useSelector(selectUserById(log.userId));
    const {
        profile: { currency },
    } = useCurrentUser();
    const { i18n, t } = useTranslation();

    let description = '';
    let date = '';

    if (log.description) {
        const [d, s] = log.description.split('$___');

        description = d;

        if (s) {
            description = description.replace(',', '');
            date = date ? formatDate(s, { lang: i18n.language, format: 'dd MMMM' }) : '';
        }
    }

    const getValue = (log: LogRO, action: string) => {
        const [label, value] = action.split('___');

        if ((label === 'date' || label === 'oldDate') && value.length !== 20) {
            return [
                label,
                convertUTCDateToZonedDate({
                    date: value,
                    timeZone: log.timeZone,
                }),
            ];
        }

        if (label === 'amount') {
            return [label, formatAmount(+value, currency)];
        }

        return [label, `${value}`.split('.')?.length === 2 ? t(value) : value];
    };

    return (
        <Box border={ true } background="var(--color-bg-primary)" padding="md" rounded="sm">
            <InfoBlockHeader title={ t(`logs.${log.title}`) } onClick={ onClick } />

            <div className={ styles.user }>
                <Image size="xs" view="circle" src={ user.photo || undefined } />

                <Typography.Text view="primary-small">
                    { user.name }, { formatDate(log.createdAt, { lang: i18n.language }) }
                </Typography.Text>
            </div>

            { description && <InfoBlockItem label={ `${description} ${date}` } /> }

            { log.actions.map((action) => (
                <React.Fragment>
                    { action.actions.map((a) => {
                        const [label, value] = getValue(log, a);

                        return (
                            <InfoBlockItem
                                view="between"
                                label={ t(`logs.${label}`) }
                                value={ value }
                            />
                        );
                    }) }
                </React.Fragment>
            )) }
        </Box>
    );
};
