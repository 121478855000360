import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    CalendarInput, FlexColumns, Select, Typography,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { QueryClientDto } from '@escapenavigator/types/dist/client/query-client.dto';
import { serializeRecord } from '@escapenavigator/utils/dist';
import { FormikProps, FormikValues } from 'formik';
import { ExportAndCount } from 'src/components/export-and-count';
import { getOptions } from 'src/modals/questroom/options';
import { useApi } from 'src/providers/api/context';
import { selectAllLocations, selectAllQuestrooms } from 'src/store';
import { useAppSelector } from 'src/store/hooks';
import { downloadBlob } from 'src/utils/download-blob';
import { getQuestroomsList } from 'src/utils/get-questrooms-list';

type Props = {
    apply: (values: FormikValues) => void;
    initialValues: Partial<QueryClientDto>;
    close: () => void;
};

const Form = ({
    props: { values, setFieldValue },
    close,
}: {
    props: FormikProps<QueryClientDto>;
    close: () => void;
}) => {
    const { t, i18n } = useTranslation();
    const { clients } = useApi();

    const { exportClientsFetch, exportClientsLoading } = useApiMethod({
        api: clients.exportClients,
        successCallback: ({ data, headers }) => {
            downloadBlob(data, headers);
            close();
        },
    });

    const questrooms = useAppSelector(selectAllQuestrooms({})) || [];
    const locations = useAppSelector(selectAllLocations) || [];

    const { countData, countLoading, countFetch } = useApiMethod({
        api: clients.count,
    });

    useEffect(() => {
        countFetch(values);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    return (
        <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
            <FlexColumns columns={ 1 } gr={ 8 } gc={ 0 }>
                <Typography.Text view="title" weight="medium">
                    { t('filters.lastGame') }
                </Typography.Text>
                <FlexColumns columns={ 2 } gr={ 16 } gc={ 16 }>
                    <CalendarInput
                        dataTestId="lastGame.0"
                        label={ t('from') }
                        value={ values.lastGame[0] }
                        clear={ true }
                        onClear={ () => {
                            setFieldValue('lastGame.0', '');
                        } }
                        onChange={ (e, { value }) => {
                            setFieldValue('lastGame.0', value);
                        } }
                        block={ true }
                        lang={ i18n.language }
                    />
                    <CalendarInput
                        dataTestId="lastGame.1"
                        label={ t('to') }
                        clear={ true }
                        onClear={ () => {
                            setFieldValue('lastGame.0', '');
                        } }
                        value={ values.lastGame[1] }
                        onChange={ (e, { value }) => {
                            setFieldValue('lastGame.1', value);
                        } }
                        block={ true }
                        lang={ i18n.language }
                    />
                </FlexColumns>
            </FlexColumns>

            <FlexColumns columns={ 1 } gr={ 8 } gc={ 0 }>
                <Typography.Text view="title" weight="medium">
                    { t('filters.gamesCount') }
                </Typography.Text>

                <FlexColumns columns={ 2 } gr={ 16 } gc={ 16 }>
                    <Select
                        dataTestId="gamesCount.0"
                        block={ true }
                        optionsListWidth="field"
                        label={ t('from') }
                        allowUnselect={ true }
                        options={ getOptions(1, 15) }
                        selected={ values.gamesCount[0] }
                        onChange={ ({ selected }) =>
                            setFieldValue('gamesCount.0', selected?.key || '') }
                    />
                    <Select
                        dataTestId="gamesCount.1"
                        block={ true }
                        optionsListWidth="field"
                        label={ t('to') }
                        allowUnselect={ true }
                        options={ getOptions(1, 15) }
                        selected={ values.gamesCount[1] }
                        onChange={ ({ selected }) =>
                            setFieldValue('gamesCount.1', selected?.key || '') }
                    />
                </FlexColumns>
            </FlexColumns>

            <Select
                dataTestId="visitedQuestroomsIds"
                multiple={ true }
                block={ true }
                allowUnselect={ true }
                optionsListWidth="field"
                label={ t('component.wasOnQuest') }
                options={ getQuestroomsList(questrooms, locations) }
                selected={ values.visitedQuestroomsIds }
                onChange={ ({ selectedMultiple }) =>
                    setFieldValue(
                        'visitedQuestroomsIds',
                        selectedMultiple.map((i) => i.key),
                    ) }
            />
            <Select
                dataTestId="notVisitedQuestroomsIds"
                multiple={ true }
                block={ true }
                optionsListWidth="field"
                allowUnselect={ true }
                label={ t('component.wasntOnQuest') }
                options={ getQuestroomsList(questrooms, locations) }
                selected={ values.notVisitedQuestroomsIds }
                onChange={ ({ selectedMultiple }) =>
                    setFieldValue(
                        'notVisitedQuestroomsIds',
                        selectedMultiple.map((i) => i.key),
                    ) }
            />

            <Select
                dataTestId="hasCertificates"
                block={ true }
                optionsListWidth="field"
                label={ t('Сертификаты') }
                allowUnselect={ true }
                options={ [
                    { key: 'any', content: t('filters.hasCertificates.any') },
                    { key: 'used', content: t('filters.hasCertificates.used') },
                    { key: 'not_used', content: t('filters.hasCertificates.not_used') },
                ] }
                selected={ values.hasCertificates }
                onChange={ ({ selected }) => setFieldValue('hasCertificates', selected?.key) }
            />

            <Select
                dataTestId="blockedClient"
                block={ true }
                optionsListWidth="field"
                label={ t('filters.blockedClient.title') }
                allowUnselect={ true }
                options={ [
                    { key: 'blocked', content: t('filters.blockedClient.blocked') },
                    { key: 'not_blocked', content: t('filters.blockedClient.not_blocked') },
                ] }
                selected={ values.blockedClient }
                onChange={ ({ selected }) => setFieldValue('blockedClient', selected?.key) }
            />

            <Select
                dataTestId="alowedToAds"
                block={ true }
                optionsListWidth="field"
                label={ t('filters.alowedToAds.title') }
                allowUnselect={ true }
                options={ [
                    { key: 'allowed', content: t('filters.alowedToAds.allowed') },
                    { key: 'not_allowed', content: t('filters.alowedToAds.not_allowed') },
                ] }
                selected={ values.alowedToAds }
                onChange={ ({ selected }) => setFieldValue('alowedToAds', selected?.key) }
            />

            <ExportAndCount
                loading={ countLoading }
                exportLoading={ exportClientsLoading }
                exportFetch={ () => exportClientsFetch(values) }
                count={ countData }
            />
        </FlexColumns>
    );
};

export const ClientsFiltersModal: React.FC<Props> = ({ initialValues, close, apply }) => {
    const { t } = useTranslation();

    return (
        <RestForm
            save={ (values) => {
                apply(values);
                close();
            } }
            title={ t('filters.label') }
            submitButtonText={ t('Применить') }
            initialValues={ serializeRecord(QueryClientDto, initialValues || {}) }
            close={ close }
            t={ t }
        >
            { (props: FormikProps<QueryClientDto>) => <Form props={ props } close={ close } /> }
        </RestForm>
    );
};
