/* eslint-disable no-alert */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    AllertMarkS,
    CancelM,
    DiscountS,
    LockOutlineM,
    MessageS,
    PhoneM,
    PresentS,
    SoldMarkS,
    TicketM,
} from '@alphakits/icons';
import { Tooltip } from '@alphakits/ui/dist';
import { SlotRO } from '@escapenavigator/types/dist/slot/slot.ro';
import { differenceInDays } from 'date-fns';
import { TFunction } from 'i18next';
import { PriceRange } from 'src/components/price-range';
import { CalendarSlot, CalendarSlotProps } from 'src/components/slot';
import {
    OrderSlotAddon,
    OrderSlotAddonProps,
} from 'src/components/slot/sub-components/order-slot-addon';
import { ImageButton } from 'src/components/slot/sub-components/order-slot-addon/image-button';
import { SlotActionsAddon } from 'src/components/slot/sub-components/slot-actions-addon';
import { SerializedSlot } from 'src/store';
import { getOrderData } from 'src/utils/get-order-data';

type RenderSlotProps = {
    slot: SerializedSlot;
    onSlotClick: (slot: SlotRO) => void;
    slotActions: any;
    view?: CalendarSlotProps['view'];
};

const getSlotIcon = (slot: SerializedSlot, t: TFunction) => {
    if (slot.status === 'bussy') {
        return {
            icon: <CancelM />,
            description: t('online_booking_not_available'),
        };
    }

    if (slot.status === 'call') {
        return {
            icon: <PhoneM />,
            description: t('online_booking_available_by_phone'),
        };
    }

    return {
        icon: <TicketM />,
        description: t('online_booking_available'),
    };
};

export const RenderBookingCell = ({
    slot, onSlotClick, slotActions, view,
}: RenderSlotProps) => {
    const { t } = useTranslation();

    const title = view === 'default' ? slot.start : `${slot.start} ${slot.questroomTitle}`;

    if (slot.breakReason && !slot.order) {
        return (
            <CalendarSlot
                color="grey"
                rightAddon={ <SlotActionsAddon actions={ slotActions(slot) } /> }
                onClick={ () => onSlotClick(slot) }
                title={ title }
                view={ view }
                subtitle={ slot.breakReason }
                icon={ <LockOutlineM /> }
            />
        );
    }

    if (slot.status === 'hold' && !slot.order) {
        return (
            <CalendarSlot
                color="grey"
                rightAddon={ <SlotActionsAddon actions={ slotActions(slot) } /> }
                onClick={ () => {} }
                title={ title }
                view={ view }
                subtitle="Hold"
                icon={ <LockOutlineM /> }
            />
        );
    }

    if (slot.status === 'no-resources' && !slot.order) {
        return (
            <CalendarSlot
                color="grey"
                rightAddon={ <SlotActionsAddon actions={ slotActions(slot) } /> }
                onClick={ () => {
                    if (
                        // eslint-disable-next-line no-restricted-globals
                        !confirm(
                            `This slot is marked as "No Resources"
                            \nContinue anyway?
                            \n\nTo change this rule, go to Settings / Booking Settings / Resources.`,
                        )
                    ) return;
                    onSlotClick(slot);
                } }
                title={ title }
                view={ view }
                subtitle="No resources"
                icon={ <LockOutlineM /> }
            />
        );
    }

    if (!slot.order) {
        const { icon, description } = getSlotIcon(slot, t);

        return (
            <CalendarSlot
                color="white"
                rightAddon={ <SlotActionsAddon actions={ slotActions(slot) } /> }
                onClick={ () => {
                    const isPastDate = differenceInDays(new Date(slot.date), new Date()) < 0;

                    // eslint-disable-next-line no-alert, no-restricted-globals
                    if (isPastDate && !confirm(t('slot_in_past'))) {
                        return null;
                    }

                    // eslint-disable-next-line no-alert, no-restricted-globals
                    if (slot.breakReason && !confirm(t('slot_has_break'))) {
                        return null;
                    }

                    return onSlotClick(slot);
                } }
                title={ title }
                view={ view }
                subtitle={ <PriceRange price={ slot.tariff } /> }
                icon={ (
                    <Tooltip
                        trigger="hover"
                        position="bottom"
                        content={ description }
                        dataTestId="test-id"
                    >
                        { icon }
                    </Tooltip>
                ) }
            />
        );
    }

    const orderData = getOrderData({ ...slot.order, slotId: slot.id }, t);
    const getIcons = () => {
        const icons: OrderSlotAddonProps['icons'] = [];

        if (slot.order.hasPenalties) {
            icons.push({
                icon: <AllertMarkS />,
                label: t('Начислены штрафы'),
            });
        }
        if (slot.order.hasCertificateApplies) {
            icons.push({
                icon: <PresentS />,
                label: t('Применен сертификат или промокод'),
            });
        }
        if (slot.order.hasComments) {
            icons.push({
                icon: <MessageS />,
                label: t('Есть комментарии'),
            });
        }
        if (slot.order.hasDiscounts) {
            icons.push({
                icon: <DiscountS />,
                label: t('Применена ручная скидка'),
            });
        }
        if (slot.order.hasUpsellings) {
            icons.push({
                icon: <SoldMarkS />,
                label: t('Есть доп услуги'),
            });
        }

        return icons;
    };

    return (
        <CalendarSlot
            color={ orderData.status.color }
            rightAddon={ <OrderSlotAddon icons={ getIcons() } /> }
            onClick={ () => onSlotClick(slot) }
            title={ title }
            view={ view }
            subtitle={ `${slot.order.players} ${t('ppl')}, ${slot.order.clientName}` }
            leftAddon={ (
                <ImageButton
                    color={ orderData.status.color }
                    moderatorId={ slot.order.moderatorId }
                    slot={ slot }
                />
            ) }
        />
    );
};
