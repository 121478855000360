import { initClientApi } from '@escapenavigator/services/dist/api/init-client-api';
import { UpsellingResponseObject } from '@escapenavigator/types/dist/upselling/upselling.ro';
import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';

import { RootState } from '../store';

const optionsAdapter = createEntityAdapter<UpsellingResponseObject>();
const { upsellings } = initClientApi(process.env.REACT_APP_API_DOMAIN);

export const fetchOptions = createAsyncThunk<UpsellingResponseObject[]>(
    'options/fetchOptions',
    async () => {
        const { data } = await upsellings.query(undefined);

        return data;
    },
);

const optionsSlice = createSlice({
    name: 'options',
    initialState: optionsAdapter.getInitialState(),
    reducers: {
        upsertOption(state, action: PayloadAction<UpsellingResponseObject>) {
            optionsAdapter.upsertOne(state, action.payload);
        },
        removeOption(state, action: PayloadAction<UpsellingResponseObject['id']>) {
            optionsAdapter.removeOne(state, action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchOptions.fulfilled, (state, action: PayloadAction<any[]>) => {
            optionsAdapter.addMany(state, action.payload);
        });
    },
});

const { selectAll } = optionsAdapter.getSelectors<RootState>((state) => state.options);

export const selectAllOptions = selectAll;

export const { upsertOption, removeOption } = optionsSlice.actions;

export default optionsSlice.reducer;
