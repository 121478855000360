import React from 'react';
import { PhotoS } from '@alphakits/icons';
import {
    Box, Button, FieldProps, Flex, Image, Loader, Typography,
} from '@alphakits/ui/dist';

import styles from '../index.module.css';

export const CellView = ({
    selectedItem,
    ...props
}: Pick<FieldProps, 'Arrow' | 'label' | 'innerProps'> & {
    loading?: boolean;
    selectedItem: { id: number; title?: string; photo?: string };
}) => {
    if (props.loading) return <Loader />;

    return (
        <div ref={ props.innerProps.ref }>
            <Button view="ghost" onClick={ (e) => props.innerProps.onClick(e) }>
                <Box width={ 350 } border={ true } rounded="sm" padding="sm">
                    <Flex gap="md">
                        <Flex justify="start" gap="md">
                            { selectedItem?.id ? (
                                <Image
                                    src={ selectedItem?.photo }
                                    text={ selectedItem?.title }
                                    size="m"
                                    view="ellipse"
                                />
                            ) : (
                                <Image
                                    icon={ <PhotoS /> }
                                    bgColor="secondary"
                                    size="m"
                                    view="ellipse"
                                />
                            ) }

                            <Typography.Text className={ styles.text } view="title">
                                { selectedItem?.id ? selectedItem.title : props.label }
                            </Typography.Text>
                        </Flex>

                        { props.Arrow }
                    </Flex>
                </Box>
            </Button>
        </div>
    );
};
